import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Description of my custom scalar type */
  MyCustomScalar: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AccountMappingGlInput = {
  Charges?: Maybe<Array<GlInput>>;
  Payment?: Maybe<Array<GlInput>>;
  Refund?: Maybe<Array<GlInput>>;
  Deposit?: Maybe<Array<GlInput>>;
  AdvancePayment?: Maybe<Array<GlInput>>;
  OfficialReceipt?: Maybe<Array<GlInput>>;
};

export type AccountMappingResponse = {
  __typename?: 'AccountMappingResponse';
  Description?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
};

export type AccountMappingTaxGlInput = {
  TaxGL?: Maybe<Array<TaxGlInput>>;
};

export type ActionLogEntity = InterfaceBase & {
  __typename?: 'ActionLogEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  SoftwareName?: Maybe<Scalars['String']>;
  ActionName: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  LoginDateTime: Scalars['DateTime'];
  LogoutDateTime?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
};

export type ActionLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  SoftwareName?: Maybe<Scalars['String']>;
  ActionName?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LoginDateTime?: Maybe<Scalars['DateTime']>;
  LogoutDateTime?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
};

export type ActivitiesEntity = InterfaceBase & {
  __typename?: 'ActivitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  Debtor?: Maybe<DebtorEntity>;
  Audio?: Maybe<GalleryEntity>;
};

export type ActivitiesInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};

export type AddConfirmationInput = {
  RegistrationID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
};

export type AddInformationInput = {
  RegistrationID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
  NRIC: Scalars['String'];
  BirthDate: Scalars['DateTime'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  Address?: Maybe<AddressInput>;
  SpecialRequest?: Maybe<Scalars['String']>;
  IsTerm?: Maybe<Scalars['Boolean']>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  IcPhotoFront: Array<Scalars['Upload']>;
  IcPhotoBack: Array<Scalars['Upload']>;
  Signature: Array<Scalars['Upload']>;
};

export type AdditionalMultiRegInput = {
  RegID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  IdPicture?: Maybe<Scalars['Upload']>;
  AttachmentInput?: Maybe<Scalars['Upload']>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdjustmentTaxOutput = {
  __typename?: 'AdjustmentTaxOutput';
  TTx?: Maybe<Scalars['Boolean']>;
  TTxAmount?: Maybe<Scalars['Float']>;
  SSF?: Maybe<Scalars['Boolean']>;
  SSFAmount?: Maybe<Scalars['Float']>;
};

export type AdvancePaymentEntity = InterfaceTrx & {
  __typename?: 'AdvancePaymentEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  IsPosted: Scalars['Boolean'];
  IsARPosted: Scalars['Boolean'];
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  CardNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Booking?: Maybe<BookingEntity>;
  Folio?: Maybe<FolioEntity>;
  ForfeitTax?: Maybe<Array<ForfeitTaxEntity>>;
};

export type AdvancePaymentInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  AdvancePaymentDate?: Maybe<Scalars['DateTime']>;
  PaymentDate?: Maybe<Scalars['DateTime']>;
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  einvoice_type?: Maybe<Scalars['String']>;
};

export type AdvPaymentGlEntity = InterfaceTrx & {
  __typename?: 'AdvPaymentGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AdvPaymentType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type AllocationEntity = InterfaceTrx & {
  __typename?: 'AllocationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  AllocationAmount: Scalars['Float'];
  AllocDate?: Maybe<Scalars['DateTime']>;
  CreditDetail: Array<DebitNoteEntity>;
  DebitDetail: Array<CreditNoteEntity>;
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type AmenitiesInput = {
  ID: Scalars['String'];
  GalleryID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ArAccountOsLastPayment = {
  __typename?: 'ARAccountOSLastPayment';
  LastPaymentDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
};

export type ArAccountSummary = {
  __typename?: 'ARAccountSummary';
  DNSum?: Maybe<Scalars['Float']>;
  InvSum?: Maybe<Scalars['Float']>;
  CNSum?: Maybe<Scalars['Float']>;
  RefundSum?: Maybe<Scalars['Float']>;
  ORSum?: Maybe<Scalars['Float']>;
};

export type ArAgingAnalysisRespose = {
  __typename?: 'ARAgingAnalysisRespose';
  day30: Scalars['Float'];
  day60: Scalars['Float'];
  day90: Scalars['Float'];
  day120: Scalars['Float'];
  day150: Scalars['Float'];
  day180: Scalars['Float'];
  daygt180: Scalars['Float'];
};

export type ArSpecification = {
  __typename?: 'ARSpecification';
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
  InterestTrx?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  FinalReminder?: Maybe<Scalars['Float']>;
};

export type ArSpecificationInput = {
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
  InterestTrx?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  FinalReminder?: Maybe<Scalars['Float']>;
};

export type ArSummaryInfo = {
  __typename?: 'ARSummaryInfo';
  LastCreatedDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  noOfDocument?: Maybe<Scalars['Float']>;
};

export enum ArTransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION'
}

export type AssignRoomInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
};

export type AtestEntity = InterfaceTrx & {
  __typename?: 'AtestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  Address?: Maybe<Array<Address>>;
};

export type AtestInput = {
  Address?: Maybe<Array<AddressInput>>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AvailabilityEntity = InterfaceTrx & {
  __typename?: 'AvailabilityEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  DynamicRoom?: Maybe<Scalars['Float']>;
  SystemAdj?: Maybe<Scalars['Float']>;
  Waitlist?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type AvailabilityInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  DynamicRoom?: Maybe<Scalars['Float']>;
  Waitlist?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AvailabilityOutput = {
  __typename?: 'AvailabilityOutput';
  ID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  TotalVacantShow?: Maybe<Scalars['Float']>;
  Waitlist?: Maybe<Scalars['Float']>;
  SeasonType: Scalars['String'];
  Pricing?: Maybe<Array<PricingEntity>>;
};

export type BankAccountEntity = InterfaceTrx & {
  __typename?: 'BankAccountEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BankName?: Maybe<Scalars['String']>;
  AccountHolderName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
  BankContactNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ContactPerson?: Maybe<Array<ContactPerson>>;
  Hotel?: Maybe<HotelEntity>;
  BankGLAcct?: Maybe<GuestLedgerAcctJson>;
  IsSecurityCash?: Maybe<Scalars['Boolean']>;
  IsKeyCardCash?: Maybe<Scalars['Boolean']>;
  IsCash?: Maybe<Scalars['Boolean']>;
  BankClearance?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BankName?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  AccountHolderName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
  BankContactNo?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput>;
  IsSecurityCash?: Maybe<Scalars['Boolean']>;
  IsKeyCardCash?: Maybe<Scalars['Boolean']>;
  IsCash?: Maybe<Scalars['Boolean']>;
  BankClearance?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Array<ContactPersonInput>>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  Name?: Maybe<Scalars['String']>;
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  Name?: Maybe<Scalars['String']>;
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BiFolioResponse = {
  __typename?: 'BIFolioResponse';
  Folio?: Maybe<Array<FolioEntity>>;
  TotalFolio?: Maybe<Scalars['Float']>;
};

export type BillLedgerEntity = InterfaceTrx & {
  __typename?: 'BillLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  CreatedName?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Hotel?: Maybe<HotelEntity>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  BillLedgerSignature?: Maybe<GalleryEntity>;
  TaxBillLedger?: Maybe<Array<BillLedgerTaxEntity>>;
  Payment?: Maybe<PaymentEntity>;
  PrincipalRegistration: RegistrationEntity;
};

export type BillLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  BillLedgerTaxInput?: Maybe<Array<BillLedgerTaxInput>>;
};

export type BillLedgerTaxEntity = InterfaceTrx & {
  __typename?: 'BillLedgerTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  BillLedgerID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  BillLedger?: Maybe<BillLedgerEntity>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type BillLedgerTaxInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxSchemeDetailID?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BillScheduleEntity = InterfaceTrx & {
  __typename?: 'BillScheduleEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType?: Maybe<TransactionType>;
  HotelID?: Maybe<Scalars['String']>;
  TransactionID?: Maybe<Scalars['String']>;
  AdvancePaymentID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsPosted: Scalars['Boolean'];
  SplitGroup?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  PostingRythm?: Maybe<Scalars['String']>;
  Rate?: Maybe<RateEntity>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
  TaxBillSchedule?: Maybe<Array<BillScheduleTaxEntity>>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type BillScheduleInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BillScheduleTaxEntity = InterfaceTrx & {
  __typename?: 'BillScheduleTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  BillScheduleID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  BillSchedule?: Maybe<BillScheduleEntity>;
};

export type BillSummaryInput = {
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  IsChooseRoom: Scalars['Boolean'];
  RoomID?: Maybe<Scalars['String']>;
};

export type BiReceiptRefundResponse = {
  __typename?: 'BIReceiptRefundResponse';
  BIListing?: Maybe<Array<PaymentTestClass>>;
  TotalListing?: Maybe<Scalars['Float']>;
};

export type BlockRoomLogEntity = InterfaceTrx & {
  __typename?: 'BlockRoomLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  BlockDate: Scalars['DateTime'];
  ReleaseDate: Scalars['DateTime'];
  ReleaseDT?: Maybe<Scalars['DateTime']>;
  BlockStatusID: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsRelease?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
  BlockStatus?: Maybe<RoomStatusEntity>;
  Gallery?: Maybe<GalleryEntity>;
  BlockRoomLogGallery?: Maybe<Array<BlockRoomLogGalleryEntity>>;
  ReleaseRoomStatus: Scalars['String'];
};

export type BlockRoomLogGalleryEntity = InterfaceBase & {
  __typename?: 'BlockRoomLogGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BlockRoomLogID: Scalars['String'];
  GalleryID: Scalars['String'];
  BlockRoomLog?: Maybe<BlockRoomLogEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type BlockRoomLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  BlockDate: Scalars['DateTime'];
  ReleaseDate: Scalars['DateTime'];
  BlockStatusID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsRelease?: Maybe<Scalars['Boolean']>;
  GalleryID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BookingAttachmentDetailEntity = InterfaceBase & {
  __typename?: 'BookingAttachmentDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingAttachmentID?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  BookingAttachment?: Maybe<BookingAttachmentEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type BookingAttachmentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingAttachmentID?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type BookingAttachmentEntity = InterfaceBase & {
  __typename?: 'BookingAttachmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Booking?: Maybe<BookingEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Hotel?: Maybe<HotelEntity>;
  AttachmentDetail?: Maybe<Array<BookingAttachmentDetailEntity>>;
};

export type BookingAttachmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type BookingBillScheduleResponse = {
  __typename?: 'BookingBillScheduleResponse';
  BillScheduleAmount?: Maybe<Array<BillScheduleEntity>>;
  DiscountAmount?: Maybe<Array<RateAdjustmentAuditEntity>>;
};

export type BookingCancellation = {
  __typename?: 'BookingCancellation';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RateAmount?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
};

export type BookingEntity = InterfaceTrx & {
  __typename?: 'BookingEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingNo: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  ContactID: Scalars['String'];
  GroupName?: Maybe<Scalars['String']>;
  ChargeToCityLedger?: Maybe<Scalars['Boolean']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  IsCms?: Maybe<Scalars['Boolean']>;
  IsCmsTtx?: Maybe<Scalars['Boolean']>;
  isPrepaid?: Maybe<Scalars['Boolean']>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SegmentID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  BookingRemark?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BookingStatus: BookingStatus;
  ReferenceNo?: Maybe<Scalars['String']>;
  OTACollection?: Maybe<Scalars['Boolean']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Contact?: Maybe<ContactEntity>;
  SalesChannel?: Maybe<SalesChannelEntity>;
  Segment?: Maybe<SegmentEntity>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  AllRegistration?: Maybe<Array<RegistrationEntity>>;
  BookedRegistration?: Maybe<Array<RegistrationEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  Debtor?: Maybe<DebtorEntity>;
  TotalAdvPayment: Scalars['Float'];
  AdvPaymentIsAdjust: Scalars['Boolean'];
  TotalRoomRate: Scalars['Float'];
};


export type BookingEntityBookedRegistrationArgs = {
  Status?: Maybe<Scalars['String']>;
};

export type BookingInfoResponse = {
  __typename?: 'BookingInfoResponse';
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  RoomInfo?: Maybe<Array<RoomInfoResponse>>;
};

export type BookingInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ContactID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  ChargeToCityLedger?: Maybe<Scalars['Boolean']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  IsCms?: Maybe<Scalars['Boolean']>;
  IsCmsTtx?: Maybe<Scalars['Boolean']>;
  isPrepaid?: Maybe<Scalars['Boolean']>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SegmentID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  BookingRemark?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<BookingStatus>;
  ReferenceNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BookingRoomAssignInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
};

export enum BookingStatus {
  Booked = 'Booked',
  Cancelled = 'Cancelled',
  NoShow = 'NoShow',
  Inhouse = 'Inhouse',
  CheckOut = 'CheckOut',
  Pending = 'Pending',
  Waitlist = 'Waitlist'
}

export type CbExportEntity = InterfaceTrx & {
  __typename?: 'CBExportEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  CBDate?: Maybe<Scalars['DateTime']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  CBType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  DescriptionTransfer?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};

export type ChannelManagerEntity = InterfaceBase & {
  __typename?: 'ChannelManagerEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Pass?: Maybe<Scalars['String']>;
  AuthKey?: Maybe<Scalars['String']>;
  Url?: Maybe<Scalars['String']>;
  HotelMapID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type ChannelManagerInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Pass: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ChargeType {
  Booking = 'Booking',
  Package = 'Package',
  ExtendStay = 'ExtendStay',
  ExtendStayPkg = 'ExtendStayPkg',
  LateCheckOut = 'LateCheckOut',
  RoomUpgrade = 'RoomUpgrade',
  Taxes = 'Taxes'
}

export type CheckOut = {
  __typename?: 'CheckOut';
  item?: Maybe<Item>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Errors>;
  PaySessionNo?: Maybe<Scalars['Float']>;
};

export type CheckoutReinstateResponse = {
  __typename?: 'CheckoutReinstateResponse';
  ReasonCode?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ChooseRoomAllocationEntity = InterfaceTrx & {
  __typename?: 'ChooseRoomAllocationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  LocationID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Location?: Maybe<LocationEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Room?: Maybe<RoomEntity>;
};

export type ChooseRoomAllocationInput = {
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Input: Array<InputItem>;
};

export type CityLedgerAttachmentEntity = InterfaceBase & {
  __typename?: 'CityLedgerAttachmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  OfficialReceiptID?: Maybe<Scalars['String']>;
  RefundID?: Maybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  Invoice?: Maybe<InvoiceEntity>;
  CreditNote?: Maybe<CreditNoteEntity>;
  DebitNote?: Maybe<DebitNoteEntity>;
  OfficialReceipt?: Maybe<OfficialReceiptEntity>;
  Refund?: Maybe<RefundEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type CityLedgerAttachmentInput = {
  ID: Scalars['String'];
  InvoiceID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  OfficialReceiptID?: Maybe<Scalars['String']>;
  RefundID?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CmsBookingEntity = InterfaceBase & {
  __typename?: 'CMSBookingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  ChannelManager?: Maybe<Scalars['String']>;
  IsProcess: Scalars['Boolean'];
};

export type CmsLogEntity = InterfaceBase & {
  __typename?: 'CMSLogEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LogDate: Scalars['DateTime'];
  BookingNo?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
};

export type CmsOperationControlEntity = InterfaceBase & {
  __typename?: 'CMSOperationControlEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ChannelManager?: Maybe<Scalars['String']>;
  ProcessType: Scalars['String'];
  ProcessStatus: Scalars['String'];
};

export type CmsOperationControlInput = {
  ID?: Maybe<Scalars['String']>;
  ChannelManager: Scalars['String'];
  ProcessType: Scalars['String'];
  ProcessStatus: Scalars['String'];
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type CmsResponseEntity = InterfaceBase & {
  __typename?: 'CMSResponseEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LogDate: Scalars['DateTime'];
  RawData?: Maybe<Scalars['String']>;
  ResponseData?: Maybe<Scalars['String']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyEntity = {
  __typename?: 'companyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  parentCompanyId?: Maybe<Scalars['String']>;
  baseCurrencyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  address?: Maybe<Address>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  gstNo?: Maybe<Scalars['String']>;
  ttxNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  imageLogoPosition: Scalars['String'];
  watermarkImplementation: Scalars['Boolean'];
  watermarkWording: Scalars['String'];
  isoImplementation: Scalars['Boolean'];
  isoLogoPosition: Scalars['String'];
  companyTax: Scalars['String'];
  recordStatus: Scalars['String'];
  groupGstRegNo?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  isEInvIntegrated: Scalars['Boolean'];
  Hotel?: Maybe<Array<HotelEntity>>;
};

export type CompanyInput = {
  id?: Maybe<Scalars['String']>;
  parentCompanyId?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  baseCurrencyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  gstNo?: Maybe<Scalars['String']>;
  ttxNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  imageLogoPosition?: Maybe<Scalars['String']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  isoLogoPosition?: Maybe<Scalars['String']>;
  companyTax?: Maybe<Scalars['String']>;
  recordStatus?: Maybe<Scalars['String']>;
  groupGstRegNo?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  isEInvIntegrated: Scalars['Boolean'];
};

export type CompOutput = {
  __typename?: 'CompOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export enum ComputationRules {
  FlatRate = 'FlatRate',
  PerPerson = 'PerPerson'
}

export enum ComputeMethod {
  TaxOnGross = 'TAX_ON_GROSS',
  TaxOnTaxedPrice = 'TAX_ON_TAXED_PRICE'
}

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address>;
  Designation?: Maybe<Scalars['String']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ReservationEmail?: Maybe<Scalars['String']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  ProfilePictureGallery?: Maybe<GalleryEntity>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput>;
  ProfilePicture?: Maybe<Scalars['String']>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ReservationEmail?: Maybe<Scalars['String']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  OldGuestID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContractAllotmentEntity = InterfaceBase & {
  __typename?: 'ContractAllotmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  AllotDate: Scalars['DateTime'];
  InitialAllot?: Maybe<Scalars['Float']>;
  Adjust?: Maybe<Scalars['Float']>;
  SysAdjust?: Maybe<Scalars['Float']>;
  PickUp?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
  Contract?: Maybe<ContractEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  AllowDeleteAllotment?: Maybe<Scalars['Boolean']>;
};

export type ContractAllotmentInput = {
  ID?: Maybe<Scalars['String']>;
  ContractID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  AllotDate?: Maybe<Scalars['DateTime']>;
  InitialAllot?: Maybe<Scalars['Float']>;
  Adjust?: Maybe<Scalars['Float']>;
  SysAdjust?: Maybe<Scalars['Float']>;
  PickUp?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type ContractEntity = InterfaceBase & {
  __typename?: 'ContractEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  Allotment?: Maybe<Scalars['Boolean']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  IsCMS?: Maybe<Scalars['Boolean']>;
  ChannelManager?: Maybe<Scalars['String']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  Debtor?: Maybe<DebtorEntity>;
  ContractRate?: Maybe<Array<ContractRateEntity>>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  ContractAllotmentByRoomType?: Maybe<Array<ContractAllotmentEntity>>;
  AllowDeleteContract?: Maybe<Scalars['Boolean']>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  Allotment?: Maybe<Scalars['Boolean']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  IsCMS?: Maybe<Scalars['Boolean']>;
  ChannelManager?: Maybe<Scalars['String']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
};

export type ContractRateEntity = InterfaceBase & {
  __typename?: 'ContractRateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  Contract?: Maybe<ContractEntity>;
  RatePolicy?: Maybe<RateEntity>;
};

export type ContractRateInput = {
  ID?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreLedger = InterfaceTrx & {
  __typename?: 'CoreLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type CoreLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreTaxTrxLedger = InterfaceTrx & {
  __typename?: 'CoreTaxTrxLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
};

export type CoreTrxLedger = InterfaceTrx & {
  __typename?: 'CoreTrxLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
};

export type CoreTrxLedgerInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Djibouti = 'Djibouti',
  Denmark = 'Denmark',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  EastAfrica = 'East_Africa',
  Ecuador = 'Ecuador',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  Egypt = 'Egypt',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North_Korea',
  SouthKorea = 'South_Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  MiddleEast = 'Middle_East',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Others = 'Others',
  Pakistan = 'Pakistan',
  Palestine = 'Palestine',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type CountryEntity = {
  __typename?: 'countryEntity';
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  einv_code?: Maybe<Scalars['String']>;
};

export type CountryReportOutput = {
  __typename?: 'CountryReportOutput';
  CountryName?: Maybe<Scalars['String']>;
  CountryYRoomCount?: Maybe<Scalars['Float']>;
  CountryYGuestCount?: Maybe<Scalars['Float']>;
  CountryYRoomRevenue?: Maybe<Scalars['Float']>;
  CountryYOtherRevenue?: Maybe<Scalars['Float']>;
  CountryYARR?: Maybe<Scalars['Float']>;
  CountryMRoomCount?: Maybe<Scalars['Float']>;
  CountryMGuestCount?: Maybe<Scalars['Float']>;
  CountryMRoomRevenue?: Maybe<Scalars['Float']>;
  CountryMOtherRevenue?: Maybe<Scalars['Float']>;
  CountryMARR?: Maybe<Scalars['Float']>;
  CountryDRoomCount?: Maybe<Scalars['Float']>;
  CountryDGuestCount?: Maybe<Scalars['Float']>;
  CountryDRoomRevenue?: Maybe<Scalars['Float']>;
  CountryDOtherRevenue?: Maybe<Scalars['Float']>;
  CountryDARR?: Maybe<Scalars['Float']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreditAllocationInput = {
  DebitID?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
};

export type CreditCardMapping = {
  __typename?: 'CreditCardMapping';
  Name?: Maybe<Scalars['String']>;
};

export type CreditCardMappingInput = {
  Name?: Maybe<Scalars['String']>;
};

export type CreditNoteEntity = InterfaceTrx & {
  __typename?: 'CreditNoteEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<CreditNoteTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  GroupDocumentNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type CreditNoteEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
};

export type CreditNoteTrxEntity = InterfaceTrx & {
  __typename?: 'CreditNoteTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  CreditNoteTrxTax?: Maybe<Array<CreditNoteTrxTaxEntity>>;
};

export type CreditNoteTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type CreditNoteTrxTaxEntity = InterfaceTrx & {
  __typename?: 'CreditNoteTrxTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  CreditNoteTrxID: Scalars['String'];
  CreditNoteTrx?: Maybe<CreditNoteTrxEntity>;
};

export enum Currency {
  Afn = 'AFN',
  All = 'ALL',
  Dzd = 'DZD',
  Aoa = 'AOA',
  Ars = 'ARS',
  Amd = 'AMD',
  Awg = 'AWG',
  Aud = 'AUD',
  Azn = 'AZN',
  Bsd = 'BSD',
  Bhd = 'BHD',
  Bdt = 'BDT',
  Bbd = 'BBD',
  Byn = 'BYN',
  Bzd = 'BZD',
  Bmd = 'BMD',
  Btn = 'BTN',
  Bob = 'BOB',
  Bam = 'BAM',
  Bwp = 'BWP',
  Brl = 'BRL',
  Gbp = 'GBP',
  Bnd = 'BND',
  Bgn = 'BGN',
  Mmk = 'MMK',
  Bif = 'BIF',
  Khr = 'KHR',
  Cad = 'CAD',
  Cve = 'CVE',
  Kyd = 'KYD',
  Xaf = 'XAF',
  Xpf = 'XPF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Kmf = 'KMF',
  Cdf = 'CDF',
  Crc = 'CRC',
  Hrk = 'HRK',
  Cuc = 'CUC',
  Cup = 'CUP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Djf = 'DJF',
  Dop = 'DOP',
  Xcd = 'XCD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fkp = 'FKP',
  Fjd = 'FJD',
  Gmd = 'GMD',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gtq = 'GTQ',
  Gnf = 'GNF',
  Gyd = 'GYD',
  Htg = 'HTG',
  Hnl = 'HNL',
  Hkd = 'HKD',
  Huf = 'HUF',
  Isk = 'ISK',
  Inr = 'INR',
  Idr = 'IDR',
  Irr = 'IRR',
  Iqd = 'IQD',
  Ils = 'ILS',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Jod = 'JOD',
  Kzt = 'KZT',
  Kes = 'KES',
  Kwd = 'KWD',
  Kgs = 'KGS',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lsl = 'LSL',
  Lrd = 'LRD',
  Lyd = 'LYD',
  Mop = 'MOP',
  Mkd = 'MKD',
  Mga = 'MGA',
  Mwk = 'MWK',
  Myr = 'MYR',
  Mvr = 'MVR',
  Mru = 'MRU',
  Mur = 'MUR',
  Mxn = 'MXN',
  Mdl = 'MDL',
  Mnt = 'MNT',
  Mad = 'MAD',
  Mzn = 'MZN',
  Nad = 'NAD',
  Npr = 'NPR',
  Ang = 'ANG',
  Twd = 'TWD',
  Nzd = 'NZD',
  Nio = 'NIO',
  Ngn = 'NGN',
  Kpw = 'KPW',
  Nok = 'NOK',
  Omr = 'OMR',
  Pkr = 'PKR',
  Pab = 'PAB',
  Pgk = 'PGK',
  Pyg = 'PYG',
  Pen = 'PEN',
  Php = 'PHP',
  Pln = 'PLN',
  Qar = 'QAR',
  Ron = 'RON',
  Rub = 'RUB',
  Rwf = 'RWF',
  Shp = 'SHP',
  Wst = 'WST',
  Stn = 'STN',
  Sar = 'SAR',
  Rsd = 'RSD',
  Scr = 'SCR',
  Sll = 'SLL',
  Sgd = 'SGD',
  Sbd = 'SBD',
  Sos = 'SOS',
  Zar = 'ZAR',
  Krw = 'KRW',
  Ssp = 'SSP',
  Lkr = 'LKR',
  Sdg = 'SDG',
  Srd = 'SRD',
  Szl = 'SZL',
  Sek = 'SEK',
  Chf = 'CHF',
  Syp = 'SYP',
  Tjs = 'TJS',
  Tzs = 'TZS',
  Thb = 'THB',
  Top = 'TOP',
  Ttd = 'TTD',
  Tnd = 'TND',
  Try = 'TRY',
  Tmt = 'TMT',
  Ugx = 'UGX',
  Uah = 'UAH',
  Aed = 'AED',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vuv = 'VUV',
  Ves = 'VES',
  Vnd = 'VND',
  Xof = 'XOF',
  Yer = 'YER',
  Zmw = 'ZMW'
}

export type CurrencyEntity = {
  __typename?: 'currencyEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  format: Scalars['String'];
  common_status: Scalars['String'];
};

export type CurrentInhouseOutput = {
  __typename?: 'CurrentInhouseOutput';
  RoomNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
};

export type CurrentTaxDetailsOutput = {
  __typename?: 'CurrentTaxDetailsOutput';
  HotelID?: Maybe<Scalars['String']>;
  GovTax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
};

export type CurrentTaxOutput = {
  __typename?: 'currentTaxOutput';
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type CustomizedDocumentEntity = InterfaceBase & {
  __typename?: 'CustomizedDocumentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type DashBoardRespond = {
  __typename?: 'DashBoardRespond';
  DueIn?: Maybe<Scalars['Float']>;
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalInHouseRoom?: Maybe<Scalars['Float']>;
  DueOut?: Maybe<Scalars['Float']>;
  TotalDueOut?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
  RevenueToday?: Maybe<Scalars['Float']>;
  RevenueMonthToDay?: Maybe<Scalars['Float']>;
  CollectionToday?: Maybe<Scalars['Float']>;
  CollectionMonthToDay?: Maybe<Scalars['Float']>;
  CurrentOccupiedRoom?: Maybe<Scalars['Float']>;
  CurrentInHouse?: Maybe<Scalars['Float']>;
  CurrentComplimentary?: Maybe<Scalars['Float']>;
  CurrentDayUse?: Maybe<Scalars['Float']>;
  CurrentOthers?: Maybe<Scalars['Float']>;
  CurrentOccupancyRate?: Maybe<Scalars['Float']>;
  CurrentArr?: Maybe<Scalars['Float']>;
  CurrentRoomRevenue?: Maybe<Scalars['Float']>;
  ProjectOccupiedRoom?: Maybe<Scalars['Float']>;
  ProjectInHouse?: Maybe<Scalars['Float']>;
  ProjectComplimentary?: Maybe<Scalars['Float']>;
  ProjectDayUse?: Maybe<Scalars['Float']>;
  ProjectOthers?: Maybe<Scalars['Float']>;
  ProjectOccupancyRate?: Maybe<Scalars['Float']>;
  ProjectArr?: Maybe<Scalars['Float']>;
  ProjectRoomRevenue?: Maybe<Scalars['Float']>;
};

export type Data = {
  __typename?: 'data';
  ID?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  osAmt?: Maybe<Scalars['String']>;
};


export type DebitAllocationInput = {
  CreditID?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  AllocationAmount: Scalars['Float'];
  CreditRefTable?: Maybe<Scalars['String']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
};

export type DebitNoteEntity = InterfaceTrx & {
  __typename?: 'DebitNoteEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<DebitNoteTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  QrCodeUrl?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type DebitNoteEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
};

export type DebitNoteTrxEntity = InterfaceTrx & {
  __typename?: 'DebitNoteTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  DebitNoteTrxTax?: Maybe<Array<DebitNoteTrxTaxEntity>>;
};

export type DebitNoteTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type DebitNoteTrxTaxEntity = InterfaceTrx & {
  __typename?: 'DebitNoteTrxTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  DebitNoteTrxID: Scalars['String'];
  DebitNoteTrx?: Maybe<DebitNoteTrxEntity>;
  TaxInfo?: Maybe<TaxEntity>;
};

export enum DebtorCategory {
  Corporate = 'Corporate',
  TravelAgent = 'TravelAgent',
  Government = 'Government'
}

export type DebtorContactEntity = InterfaceBase & {
  __typename?: 'DebtorContactEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContactID: Scalars['String'];
  SalesChannelID: Scalars['String'];
  Debtor?: Maybe<DebtorEntity>;
  Contact?: Maybe<ContactEntity>;
};

export type DebtorContactInput = {
  ID: Scalars['String'];
  DebtorID: Scalars['String'];
  ContactID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type DebtorEntity = InterfaceBase & {
  __typename?: 'DebtorEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ContactID: Scalars['String'];
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  CreditCardMapping?: Maybe<Array<CreditCardMapping>>;
  DebtorCategory?: Maybe<DebtorCategory>;
  Name: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CreditTerm?: Maybe<Scalars['Float']>;
  CreditLimit?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  DebtorContacts?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
  IsIndividual?: Maybe<Scalars['Boolean']>;
  IseCommerce?: Maybe<Scalars['Boolean']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  IsFrontDeskActive?: Maybe<Scalars['Boolean']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  Hotel?: Maybe<HotelEntity>;
  Contact?: Maybe<ContactEntity>;
  DebtorType?: Maybe<DebtorTypeEntity>;
  SegmentDetails?: Maybe<SegmentEntity>;
  Contract?: Maybe<Array<ContractEntity>>;
  Booking?: Maybe<Array<BookingEntity>>;
  DebtorContact?: Maybe<Array<DebtorContactEntity>>;
  DebtorProfile?: Maybe<DebtorProfileEntity>;
  accessToken?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  Tin?: Maybe<Scalars['String']>;
  TaxRegNo?: Maybe<Scalars['String']>;
  InvoiceType?: Maybe<InvoiceType>;
  LastBooking?: Maybe<Scalars['DateTime']>;
  ARTotalOutstanding: Scalars['Float'];
  ARTotalDue: Scalars['Float'];
  LastPaymentDate?: Maybe<Scalars['DateTime']>;
};

export type DebtorInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  DebtorContacts?: Maybe<Scalars['String']>;
  DebtorCategory?: Maybe<DebtorCategory>;
  CreditCardMapping?: Maybe<Array<CreditCardMappingInput>>;
  Name: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CreditTerm?: Maybe<Scalars['Float']>;
  CreditLimit?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
  IsIndividual?: Maybe<Scalars['Boolean']>;
  IseCommerce?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  IsFrontDeskActive?: Maybe<Scalars['Boolean']>;
  Tin?: Maybe<Scalars['String']>;
  TaxRegNo?: Maybe<Scalars['String']>;
  InvoiceType?: Maybe<InvoiceType>;
};

export type DebtorPaymentInput = {
  InvoiceID?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
};

export type DebtorProfileEntity = InterfaceBase & {
  __typename?: 'DebtorProfileEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  Contact?: Maybe<ContactEntity>;
  accessToken?: Maybe<Scalars['String']>;
};

export type DebtorProfileInput = {
  ID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type DebtorTypeEntity = InterfaceBase & {
  __typename?: 'DebtorTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorType: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ControlAccount?: Maybe<Scalars['String']>;
  DepositAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  AccrualAccount?: Maybe<Scalars['String']>;
  RevenueAccount?: Maybe<Scalars['String']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  Hotel?: Maybe<HotelEntity>;
};

export type DebtorTypeInput = {
  HotelID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ControlAccount?: Maybe<Scalars['String']>;
  DepositAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  AccrualAccount?: Maybe<Scalars['String']>;
  RevenueAccount?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason: Scalars['Boolean'];
  AllowSalesChannel: Scalars['Boolean'];
  AllowIncidental: Scalars['Boolean'];
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  IsServiceRequest: Scalars['Boolean'];
  Hotel?: Maybe<HotelEntity>;
  SalesChannel?: Maybe<Array<SalesChannelEntity>>;
  Reason?: Maybe<Array<ReasonEntity>>;
  IncidentalCharge?: Maybe<Array<IncidentalChargeEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
};

export type DepartmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsServiceRequest?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DepartmentReportOutput = {
  __typename?: 'DepartmentReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export enum DepositClass {
  SecurityDeposit = 'SecurityDeposit',
  KeyCardDeposit = 'KeyCardDeposit',
  Refund = 'Refund',
  Forfeiture = 'Forfeiture',
  Reversed = 'Reversed'
}

export type DepositGlEntity = InterfaceTrx & {
  __typename?: 'DepositGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DepositType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type DepositInput = {
  FolioType: FolioType;
  FolioAmount: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DepositClass: DepositClass;
  PaymentType: PaymentType;
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
};

export type DepositLedgerEntity = InterfaceTrx & {
  __typename?: 'DepositLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedID?: Maybe<Scalars['String']>;
  DepositClass: DepositClass;
  PaymentType: PaymentType;
  TrxDate: Scalars['DateTime'];
  DepositAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  ActualDepositClass?: Maybe<Scalars['String']>;
};

export type DepositLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  DepositClass: DepositClass;
  DepositNo?: Maybe<Scalars['String']>;
  PaymentType: PaymentType;
  TrxDate: Scalars['DateTime'];
  DepositAmount: Scalars['Float'];
  ReferenceNo: Scalars['String'];
  ReversedID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DepositResponse = {
  __typename?: 'DepositResponse';
  DepositResult?: Maybe<Scalars['MyCustomScalar']>;
};

export enum DiscountType {
  ByAmount = 'By_Amount',
  ByPercentage = 'By_Percentage'
}

export enum DiscrepancyReason {
  Skip = 'Skip',
  Sleep = 'Sleep'
}

export type DisplaySequenceInput = {
  ID?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
};

export type DocResponse = {
  __typename?: 'docResponse';
  Invoice?: Maybe<Array<Test>>;
  CnDoc?: Maybe<Array<CreditNoteEntity>>;
  DnDoc?: Maybe<Array<DebitNoteEntity>>;
  OrDoc?: Maybe<Array<OfficialReceiptEntity>>;
  RefundDoc?: Maybe<Array<RefundEntity>>;
};

export type DocumentNumberDetailEntity = InterfaceBase & {
  __typename?: 'DocumentNumberDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberHeaderEntity = InterfaceBase & {
  __typename?: 'DocumentNumberHeaderEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Type: Scalars['String'];
  SampleOutput?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  DocumentNumberDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
};

export type DocumentNumberHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Type: Scalars['String'];
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
};

export type DocumentNumberingEntity = InterfaceBase & {
  __typename?: 'DocumentNumberingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Booking?: Maybe<Scalars['Float']>;
  Incidental?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  Invoice?: Maybe<Scalars['Float']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
  HotelRefund?: Maybe<Scalars['Float']>;
  PaySession?: Maybe<Scalars['Float']>;
  ConsolidatedEInvoice?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type DocumentNumberingInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Booking?: Maybe<Scalars['Float']>;
  Incidental?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
  HotelRefund?: Maybe<Scalars['Float']>;
  PaySession?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DocumentTemplateEntity = InterfaceBase & {
  __typename?: 'DocumentTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type DocumentTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type DynamicRateJson = {
  __typename?: 'DynamicRateJson';
  Tier?: Maybe<Scalars['Float']>;
  RoomQuota?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type DynamicRateJsonInput = {
  Tier?: Maybe<Scalars['Float']>;
  RoomQuota?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type EditConfirmationInput = {
  RegistrationID: Scalars['String'];
  ContactID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
  OldFullName?: Maybe<Scalars['String']>;
  OldNRIC?: Maybe<Scalars['String']>;
};

export type EditInformationInput = {
  RegistrationID: Scalars['String'];
  ContactID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
  OldFullName?: Maybe<Scalars['String']>;
  OldNRIC?: Maybe<Scalars['String']>;
  NRIC: Scalars['String'];
  BirthDate: Scalars['DateTime'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  Address?: Maybe<AddressInput>;
  SpecialRequest?: Maybe<Scalars['String']>;
  IsTerm?: Maybe<Scalars['Boolean']>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  IcPhotoFront?: Maybe<Array<Scalars['Upload']>>;
  IcPhotoBack?: Maybe<Array<Scalars['Upload']>>;
  Signature: Array<Scalars['Upload']>;
};

export type EditRegInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  OldRoomTypeID?: Maybe<Scalars['String']>;
  OldRatePolicyID?: Maybe<Scalars['String']>;
  OldNoOfRoom?: Maybe<Scalars['Float']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  OldArrivalDate?: Maybe<Scalars['DateTime']>;
  OldDepartureDate?: Maybe<Scalars['DateTime']>;
  NewRoomTypeID?: Maybe<Scalars['String']>;
  NewRatePolicyID?: Maybe<Scalars['String']>;
  NewNoOfRoom?: Maybe<Scalars['Float']>;
  NewPromoCode?: Maybe<Scalars['String']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
  NewRoomRate?: Maybe<Scalars['Float']>;
  Mode?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  OverbookRoomNo?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
};

export type EditTaxPolicyInput = {
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetailInput>;
  taxId: Scalars['String'];
  deletedId: Array<Scalars['String']>;
};

export type EditTaxSchemeInput = {
  createdBy: Scalars['String'];
  modBy: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  taxSchemeDetail?: Maybe<Array<TaxSchemeDetailInput>>;
  id?: Maybe<Scalars['String']>;
};

export type EDocParamsEntity = InterfaceBase & {
  __typename?: 'EDocParamsEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocType: Scalars['String'];
  ColName: Scalars['String'];
};

export type EDocTemplateEntity = InterfaceBase & {
  __typename?: 'EDocTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  TemplateTitle?: Maybe<Scalars['String']>;
  IsDefault: Scalars['Boolean'];
  IsCustom: Scalars['Boolean'];
};

export type EDocTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  TemplateTitle?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  IsCustom?: Maybe<Scalars['Boolean']>;
};

export type EffectiveRateJson = {
  __typename?: 'EffectiveRateJson';
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ComputationRule?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PostingRythm?: Maybe<Scalars['String']>;
};

export type EffectiveRateJsonInput = {
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ComputationRule?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PostingRythm?: Maybe<Scalars['String']>;
};

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceConsolidationDetailEntity = InterfaceTrx & {
  __typename?: 'EInvoiceConsolidationDetailEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ConsolidationID: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  Amount: Scalars['Float'];
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt: Scalars['Float'];
  DocAmt: Scalars['Float'];
  EIConsolidation?: Maybe<EInvoiceConsolidationEntity>;
};

export type EInvoiceConsolidationDetailInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ConsolidationID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
};

export type EInvoiceConsolidationEntity = InterfaceTrx & {
  __typename?: 'EInvoiceConsolidationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate: Scalars['DateTime'];
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceConsolidationTrxType>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  EIConsolidationDetail?: Maybe<Array<EInvoiceConsolidationDetailEntity>>;
  Module?: Maybe<Scalars['String']>;
};

export type EInvoiceConsolidationInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
};

export enum EInvoiceConsolidationTrxType {
  Invoice = 'INVOICE',
  DebitNote = 'DEBIT_NOTE',
  CreditNote = 'CREDIT_NOTE'
}

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};

export type EInvoiceQrResponse = {
  __typename?: 'EInvoiceQrResponse';
  ID?: Maybe<Scalars['String']>;
  DocStatus?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  QrCodeUrl?: Maybe<Scalars['String']>;
};

export type EInvoiceSubscriptionEntity = InterfaceBase & {
  __typename?: 'EInvoiceSubscriptionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
};

export enum EInvoiceType {
  Account = 'Account',
  Individual = 'Individual'
}

export type EmailAttachmentInput = {
  filename?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
};

export type EmailBodyInput = {
  Body: Scalars['String'];
  Header: Scalars['String'];
};

export type EmailCampignEntity = InterfaceBase & {
  __typename?: 'EmailCampignEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  GalleryID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  ShareDate?: Maybe<Scalars['DateTime']>;
  EmailDate?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type EmailCampignInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Attachment?: Maybe<Scalars['Upload']>;
  ShareDate?: Maybe<Scalars['DateTime']>;
  EmailDate?: Maybe<Scalars['DateTime']>;
};

export type EmailLogEntity = InterfaceTrx & {
  __typename?: 'EmailLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplateEntity = InterfaceBase & {
  __typename?: 'EmailTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  Postpaid?: Maybe<Scalars['String']>;
  CancelBooking?: Maybe<Scalars['String']>;
};

export type EmailTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  CancelBooking?: Maybe<Scalars['String']>;
  PostPaid?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type EntRoleUsrAsgEntity = AuditEntity & {
  __typename?: 'EntRoleUsrAsgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type EntRoleUsrAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type Errors = {
  __typename?: 'Errors';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['String']>;
};

export type ExportedGlTransferResponse = {
  __typename?: 'ExportedGLTransferResponse';
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ExtendStayOutPut = {
  __typename?: 'ExtendStayOutPut';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type ExternalStatistic = {
  __typename?: 'ExternalStatistic';
  ID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type ExternalStatisticInput = {
  ID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type FacilityEntity = InterfaceBase & {
  __typename?: 'FacilityEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  FacilityGallery?: Maybe<Array<FacilityGalleryEntity>>;
};

export type FacilityGalleryEntity = InterfaceBase & {
  __typename?: 'FacilityGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  Facility?: Maybe<FacilityEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type FacilityGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FacilityInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FolioEntity = InterfaceBase & {
  __typename?: 'FolioEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  FolioType?: Maybe<FolioType>;
  RefID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  RefFolioID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  ClosedBy?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  SendEInvoice?: Maybe<Scalars['Boolean']>;
  IsSuspend?: Maybe<Scalars['Boolean']>;
  IsPabx?: Maybe<Scalars['Boolean']>;
  FolioName?: Maybe<Scalars['String']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  BeneficiaryAddress?: Maybe<Scalars['String']>;
  NonGuestInfo?: Maybe<NonGuestInfoJson>;
  DocumentType?: Maybe<Scalars['String']>;
  IsConsolidated?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  QrCodeUrl?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_type?: Maybe<EInvoiceType>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
  Registration?: Maybe<RegistrationEntity>;
  RefFolio?: Maybe<FolioEntity>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  RoomCharges?: Maybe<Array<RoomLedgerEntity>>;
  RoomChargesSuspend?: Maybe<Array<RoomLedgerEntity>>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  NonGuestBalance: NonGuestBalance;
};

export type FolioHistoryResponse = {
  __typename?: 'FolioHistoryResponse';
  InvoiceNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  FolioType?: Maybe<Scalars['String']>;
};

export type FolioInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  FolioType?: Maybe<FolioType>;
  RefID?: Maybe<Scalars['String']>;
  RefFolioID?: Maybe<Scalars['String']>;
  InvoiceNo?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  BeneficiaryAddress?: Maybe<Scalars['String']>;
  ClosedBy?: Maybe<Scalars['String']>;
  NonGuestInfo?: Maybe<NonGuestInfoJsonInput>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsSuspend?: Maybe<Scalars['Boolean']>;
  SendEInvoice?: Maybe<Scalars['Boolean']>;
  einvoice_type?: Maybe<EInvoiceType>;
};

export type FolioOutput = {
  __typename?: 'FolioOutput';
  FolioID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  FolioType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  RegID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  RoomRate?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
};

export enum FolioType {
  GuestFolio = 'GuestFolio',
  GroupFolio = 'GroupFolio',
  NonGuest = 'NonGuest',
  DepositFolio = 'DepositFolio',
  OpenFolio = 'OpenFolio',
  RefundForfeiture = 'RefundForfeiture'
}

export type ForecastOutPut = {
  __typename?: 'ForecastOutPut';
  Category?: Maybe<Scalars['String']>;
  TodayDate?: Maybe<Scalars['Float']>;
  TomorrowDate?: Maybe<Scalars['Float']>;
  DayAfterTomorrow?: Maybe<Scalars['Float']>;
};

export type ForfeitTaxEntity = InterfaceTrx & {
  __typename?: 'ForfeitTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  PaymentID: Scalars['String'];
  SourceTable?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['String']>;
  AdvancePayment?: Maybe<AdvancePaymentEntity>;
  Payment?: Maybe<PaymentEntity>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type FrontDeskInfoLineResponse = {
  __typename?: 'FrontDeskInfoLineResponse';
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalCheckIn?: Maybe<Scalars['Float']>;
  TotalGroupDueIn?: Maybe<Scalars['Float']>;
  TotalGroupCheckIn?: Maybe<Scalars['Float']>;
  TotalWalkIn?: Maybe<Scalars['Float']>;
  TotalDepartureDueOut?: Maybe<Scalars['Float']>;
  DepartureDueOut?: Maybe<Scalars['Float']>;
  TotalRegistrationDueOut?: Maybe<Scalars['Float']>;
  RegistrationDueOut?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  DepositSum?: Maybe<Scalars['Float']>;
  TotalDepositRoom?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
  TotalNonGuestFolio?: Maybe<Scalars['Float']>;
  TotalSuspendFolio?: Maybe<Scalars['Float']>;
  TotalContactTracingOutlet?: Maybe<Scalars['Float']>;
};

export type FrontDeskInfoResponse = {
  __typename?: 'FrontDeskInfoResponse';
  RegistrationID?: Maybe<Scalars['String']>;
  BillScheduleSum?: Maybe<Scalars['Float']>;
  IncidentalChargesSum?: Maybe<Scalars['Float']>;
  TotalOutStandingAmount?: Maybe<Scalars['Float']>;
  DepositSum?: Maybe<Scalars['Float']>;
  FolioCount?: Maybe<Scalars['Float']>;
  PaymentSum?: Maybe<Scalars['Float']>;
  AdvPaymentSum?: Maybe<Scalars['Float']>;
  ServiceRequestCount?: Maybe<Scalars['Float']>;
};

export type FrontDeskPaymentInput = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  PaymentType?: Maybe<PaymentType>;
  Amount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  PaymentStatus?: Maybe<PaymentStatus>;
  CheckOutID?: Maybe<Scalars['String']>;
  PaymentClass?: Maybe<PaymentClass>;
  TransactionType?: Maybe<TransactionType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
  RoomLedgerID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  LedgerTypeID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ModuleName?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  FromRegistration?: Maybe<Scalars['String']>;
  eInvoiceType?: Maybe<Scalars['String']>;
};

export type FrontDeskResponse = {
  __typename?: 'FrontDeskResponse';
  BookingNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  Code?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  IncidentalAmt?: Maybe<Scalars['Float']>;
  PaymentAmt?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  DepositClass?: Maybe<DepositClass>;
  PaymentType?: Maybe<PaymentType>;
  FolioID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  DepositID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionType>;
};

export type GaInHouseResponse = {
  __typename?: 'GAInHouseResponse';
  GuestListing?: Maybe<Array<RegistrationEntity>>;
  GuestBilling?: Maybe<Array<PaymentFoilioResponse>>;
};

export type GalleryEntity = InterfaceBase & {
  __typename?: 'GalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type GalleryInput = {
  ID: Scalars['String'];
  AccountID: Scalars['String'];
  BucketFileName?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export type GeneralTinEntity = {
  __typename?: 'GeneralTinEntity';
  id: Scalars['String'];
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  displaySeq?: Maybe<Scalars['Float']>;
};

export type GlAccountPeriodEntity = {
  __typename?: 'GLAccountPeriodEntity';
  AccountPeriodID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  MonthEndClose?: Maybe<Scalars['Boolean']>;
  YearEndClose?: Maybe<Scalars['Boolean']>;
  FYear?: Maybe<Scalars['String']>;
  FPeriod?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};

export type GlExportEntity = InterfaceTrx & {
  __typename?: 'GLExportEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['DateTime'];
  TrxDate: Scalars['DateTime'];
  GLType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Submenu?: Maybe<Scalars['String']>;
  Registration?: Maybe<HotelEntity>;
};

export type GlInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  GL?: Maybe<GuestLedgerAcctJsonInput>;
};

export type GlPostResponse = {
  __typename?: 'GLPostResponse';
  GLPost?: Maybe<Scalars['Boolean']>;
  ExportedGLTransfer?: Maybe<Array<ExportedGlTransferResponse>>;
};

export type GroupInHouseOutput = {
  __typename?: 'GroupInHouseOutput';
  GroupName?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type GuestAppFeatureEntity = InterfaceBase & {
  __typename?: 'GuestAppFeatureEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  MyProfile?: Maybe<Scalars['Boolean']>;
  Booking?: Maybe<Scalars['Boolean']>;
  CheckIn?: Maybe<Scalars['Boolean']>;
  ScanQR?: Maybe<Scalars['Boolean']>;
  Directories?: Maybe<Scalars['Boolean']>;
  CheckOut?: Maybe<Scalars['Boolean']>;
  MyRequest?: Maybe<Scalars['Boolean']>;
  PackageRedemption?: Maybe<Scalars['Boolean']>;
  LateCheckOut?: Maybe<Scalars['Boolean']>;
  ExtendStay?: Maybe<Scalars['Boolean']>;
  Bills?: Maybe<Scalars['Boolean']>;
  RoomService?: Maybe<Scalars['Boolean']>;
  OnlineShop?: Maybe<Scalars['Boolean']>;
  DigitalKey?: Maybe<Scalars['Boolean']>;
  SelfCheckin?: Maybe<Scalars['Boolean']>;
  SelfChooseRoom?: Maybe<Scalars['Boolean']>;
  PreCheckinAdvTime?: Maybe<Scalars['Float']>;
  Chargeable?: Maybe<Scalars['Boolean']>;
  DefaultTrx?: Maybe<Scalars['String']>;
  OnlinePayment?: Maybe<Scalars['Boolean']>;
  Incidental?: Maybe<IncidentalChargeEntity>;
  OperationHour?: Maybe<Scalars['String']>;
  CloseHour?: Maybe<Scalars['String']>;
};

export type GuestAppSettingInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  MyProfile?: Maybe<Scalars['Boolean']>;
  Booking?: Maybe<Scalars['Boolean']>;
  CheckIn?: Maybe<Scalars['Boolean']>;
  ScanQR?: Maybe<Scalars['Boolean']>;
  Directories?: Maybe<Scalars['Boolean']>;
  CheckOut?: Maybe<Scalars['Boolean']>;
  MyRequest?: Maybe<Scalars['Boolean']>;
  PackageRedemption?: Maybe<Scalars['Boolean']>;
  LateCheckOut?: Maybe<Scalars['Boolean']>;
  ExtendStay?: Maybe<Scalars['Boolean']>;
  Bills?: Maybe<Scalars['Boolean']>;
  RoomService?: Maybe<Scalars['Boolean']>;
  OnlineShop?: Maybe<Scalars['Boolean']>;
  DigitalKey?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  SelfCheckin?: Maybe<Scalars['Boolean']>;
  SelfChooseRoom?: Maybe<Scalars['Boolean']>;
  PreCheckinAdvTime?: Maybe<Scalars['Float']>;
  Chargeable?: Maybe<Scalars['Boolean']>;
  DefaultTrx?: Maybe<Scalars['String']>;
  OnlinePayment?: Maybe<Scalars['Boolean']>;
  OperationHour?: Maybe<Scalars['String']>;
  CloseHour?: Maybe<Scalars['String']>;
};

export type GuestLedgerAcctInput = {
  Div?: Maybe<Scalars['String']>;
  Dept?: Maybe<Scalars['String']>;
  Acct?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type GuestLedgerAcctJson = {
  __typename?: 'GuestLedgerAcctJson';
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseCredit?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ReverseType?: Maybe<Scalars['String']>;
  ExpenseType?: Maybe<Scalars['String']>;
};

export type GuestLedgerAcctJsonInput = {
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseCredit?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ReverseType?: Maybe<Scalars['String']>;
  ExpenseType?: Maybe<Scalars['String']>;
};

export type GuestMovementEntity = InterfaceBase & {
  __typename?: 'GuestMovementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  GuestProfile?: Maybe<GuestProfileEntity>;
  Booking?: Maybe<BookingEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type GuestMovementInput = {
  ID?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type GuestPackageRedeem = {
  __typename?: 'GuestPackageRedeem';
  GuestName?: Maybe<Scalars['String']>;
  IsRedeem?: Maybe<Scalars['Boolean']>;
  IsBillLedger?: Maybe<Scalars['Boolean']>;
  BillLedgerID?: Maybe<Scalars['String']>;
  Pax?: Maybe<Scalars['Float']>;
};

export type GuestProfileEntity = InterfaceBase & {
  __typename?: 'GuestProfileEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Company?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  IcBackPicture?: Maybe<Scalars['String']>;
  PassportPicture?: Maybe<Scalars['String']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  Booking?: Maybe<Array<BookingEntity>>;
  ICGallery?: Maybe<GalleryEntity>;
  ICBackGallery?: Maybe<GalleryEntity>;
  PassportGallery?: Maybe<GalleryEntity>;
  Contact?: Maybe<ContactEntity>;
  Hotel?: Maybe<HotelEntity>;
  accessToken?: Maybe<Scalars['String']>;
  GuestType?: Maybe<GuestType>;
  SpecialRequest?: Maybe<Scalars['String']>;
  GuestHistoryList: Array<RegistrationEntity>;
  GuestBookedRoomList: Array<RegistrationEntity>;
  GuestHistoryCount: Scalars['Float'];
  ServiceRequestCount: Scalars['Float'];
  NoOfVisit: Scalars['Float'];
  LastYearOfVisit: Scalars['Float'];
};

export type GuestProfileInput = {
  ID: Scalars['String'];
  ContactID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  IcBackPicture?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  PassportPicture?: Maybe<Scalars['String']>;
  GuestType?: Maybe<GuestType>;
  DebtorID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type GuestProfileResponse = {
  __typename?: 'GuestProfileResponse';
  GuestProfile?: Maybe<Array<GuestProfileEntity>>;
  TotalGuestProfile?: Maybe<Scalars['Float']>;
};

export type GuestReviewInfoLineResponse = {
  __typename?: 'GuestReviewInfoLineResponse';
  InActivated?: Maybe<Scalars['Float']>;
  LessThan3Star?: Maybe<Scalars['Float']>;
  MoreThan3Star?: Maybe<Scalars['Float']>;
};

export enum GuestType {
  Normal = 'Normal',
  Vip1 = 'VIP1',
  Vip2 = 'VIP2',
  Vip3 = 'VIP3',
  Vip4 = 'VIP4',
  Vip5 = 'VIP5',
  Skipper = 'Skipper',
  Blacklist = 'Blacklist'
}

export type HlsBookingRecordEntity = InterfaceBase & {
  __typename?: 'HLSBookingRecordEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
};

export type HlsBookingRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelAvailabilityOutput = {
  __typename?: 'HotelAvailabilityOutput';
  RoomTypesList?: Maybe<Array<RoomTypesListOutput>>;
  Occupancy?: Maybe<Array<Occupancy>>;
};

export type HotelEntity = InterfaceBase & {
  __typename?: 'HotelEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelName?: Maybe<Scalars['String']>;
  HotelAddress?: Maybe<Address>;
  SSTRegNo?: Maybe<Scalars['String']>;
  TTxRegNo?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Logo?: Maybe<Scalars['String']>;
  HotelType?: Maybe<PropertyType>;
  ContactID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  LiveDate?: Maybe<Scalars['DateTime']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  IsOnlinePayment?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  TTx?: Maybe<Scalars['Float']>;
  HTx?: Maybe<Scalars['Float']>;
  Levy?: Maybe<Scalars['Float']>;
  CheckInPrompt: Scalars['Boolean'];
  SecurityDeposit?: Maybe<Scalars['Float']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  DayUseID?: Maybe<Scalars['String']>;
  HouseUseID?: Maybe<Scalars['String']>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  RegCardTnC?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  CorporateURL?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotel?: Maybe<Scalars['String']>;
  ARSpecification?: Maybe<ArSpecification>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  OnlinePaymentStoreID?: Maybe<Scalars['String']>;
  KeycardVendor?: Maybe<Scalars['String']>;
  HotelCode?: Maybe<Scalars['String']>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
  RegulationClause?: Maybe<Scalars['String']>;
  PDPA?: Maybe<Scalars['String']>;
  OnlinePaymentTnC?: Maybe<Scalars['String']>;
  DocumentRemark?: Maybe<Scalars['String']>;
  GuestNotice?: Maybe<Scalars['Boolean']>;
  StateFund?: Maybe<Scalars['Boolean']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsDayUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  IsOnlineBooking?: Maybe<Scalars['Boolean']>;
  IsOOO?: Maybe<Scalars['Boolean']>;
  IsOOI?: Maybe<Scalars['Boolean']>;
  IsFloorPlan?: Maybe<Scalars['Boolean']>;
  CompanyName?: Maybe<Scalars['String']>;
  EInvoiceSCClassification?: Maybe<Scalars['String']>;
  EInvoiceRCClassification?: Maybe<Scalars['String']>;
  EInvoiceAdvPaymentClassification?: Maybe<Scalars['String']>;
  EInvoiceDepositForfeitClassification?: Maybe<Scalars['String']>;
  EInvoiceStateTaxClassification?: Maybe<Scalars['String']>;
  IsCmsAllotment?: Maybe<Scalars['Boolean']>;
  EmailBlastDays?: Maybe<Scalars['Float']>;
  Q3StatisticsMapping?: Maybe<Q3StatisticsMapping>;
  StatisticsMapping?: Maybe<StatisticsMapping>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  RoundingGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<HotelQ3RevenueAcctJson>;
  Encoders?: Maybe<Scalars['JSON']>;
  Denomination?: Maybe<Scalars['Float']>;
  RoundingMethod?: Maybe<Scalars['String']>;
  IsRoomTaxable?: Maybe<Scalars['Boolean']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  AdvanceForfeitTaxID?: Maybe<Scalars['String']>;
  DepositForfeitTaxID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MSICCode?: Maybe<Scalars['String']>;
  MSICDesc?: Maybe<Scalars['String']>;
  EInvEffectiveDate?: Maybe<Scalars['DateTime']>;
  SOAFooter?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CheckInRoomStatus?: Maybe<RoomStatusEntity>;
  CancelCheckInRoomStatus?: Maybe<RoomStatusEntity>;
  TransferRoomStatus?: Maybe<RoomStatusEntity>;
  CheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  BlockRoomReleaseStatus?: Maybe<RoomStatusEntity>;
  CancelCheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  DayUse?: Maybe<RateEntity>;
  HouseUse?: Maybe<RateEntity>;
  Complimentary?: Maybe<RateEntity>;
  AboutHotelImage?: Maybe<GalleryEntity>;
  LogoImage?: Maybe<GalleryEntity>;
  SOAImage?: Maybe<GalleryEntity>;
  ChannelManager?: Maybe<ChannelManagerEntity>;
  PaymentGateway?: Maybe<PaymentGatewayEntity>;
  Facility?: Maybe<Array<FacilityEntity>>;
  News?: Maybe<Array<NewsEntity>>;
  Segment?: Maybe<Array<SegmentEntity>>;
  DocumentNumbering?: Maybe<DocumentNumberingEntity>;
  HotelRating?: Maybe<Array<HotelRatingEntity>>;
  Department?: Maybe<Array<DepartmentEntity>>;
  Promotion?: Maybe<Array<PromotionEntity>>;
  Location?: Maybe<Array<LocationEntity>>;
  RoomStatus?: Maybe<Array<RoomStatusEntity>>;
  RoomType?: Maybe<Array<RoomTypeEntity>>;
  Room?: Maybe<Array<RoomEntity>>;
  SeasonCalendar?: Maybe<Array<SeasonCalendarEntity>>;
  LateCheckOutCharge?: Maybe<Array<LateCheckOutChargeEntity>>;
  RatePolicy?: Maybe<Array<RateEntity>>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  DebtorType?: Maybe<Array<DebtorTypeEntity>>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  Booking?: Maybe<Array<BookingEntity>>;
  EmailCampign?: Maybe<Array<EmailCampignEntity>>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Price?: Maybe<Array<PricingOutput>>;
  HotelDebtor?: Maybe<DebtorEntity>;
  HotelRate?: Maybe<Array<RoomTypeEntity>>;
  HotelGallery?: Maybe<Array<HotelGalleryEntity>>;
  HotelAdjustmentIncidentalID?: Maybe<Scalars['String']>;
  WeightedAverage: Scalars['Float'];
};

export type HotelGalleryEntity = InterfaceBase & {
  __typename?: 'HotelGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type HotelGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelName?: Maybe<Scalars['String']>;
  HotelAddress?: Maybe<AddressInput>;
  SSTRegNo?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Logo?: Maybe<Scalars['String']>;
  HotelType?: Maybe<PropertyType>;
  ContactID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  IsRoomTaxable?: Maybe<Scalars['Boolean']>;
  Q3StatisticsMapping?: Maybe<Q3StatisticsMappingInput>;
  TTx?: Maybe<Scalars['Float']>;
  HTx?: Maybe<Scalars['Float']>;
  Levy?: Maybe<Scalars['Float']>;
  CheckInPrompt?: Maybe<Scalars['Boolean']>;
  SecurityDeposit?: Maybe<Scalars['Float']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  DayUseID?: Maybe<Scalars['String']>;
  HotelCode?: Maybe<Scalars['String']>;
  HouseUseID?: Maybe<Scalars['String']>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  OnlinePaymentTnC?: Maybe<Scalars['String']>;
  RegCardTnC?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  CorporateURL?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotel?: Maybe<Scalars['String']>;
  ARSpecification?: Maybe<ArSpecificationInput>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  RegulationClause?: Maybe<Scalars['String']>;
  PDPA?: Maybe<Scalars['String']>;
  DocumentRemark?: Maybe<Scalars['String']>;
  GuestNotice?: Maybe<Scalars['Boolean']>;
  StateFund?: Maybe<Scalars['Boolean']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsDayUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  IsOOO?: Maybe<Scalars['Boolean']>;
  IsOOI?: Maybe<Scalars['Boolean']>;
  IsFloorPlan?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsCmsAllotment?: Maybe<Scalars['Boolean']>;
  EmailBlastDays?: Maybe<Scalars['Float']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  IsOnlineBooking?: Maybe<Scalars['Boolean']>;
  IsOnlinePayment?: Maybe<Scalars['Boolean']>;
  AdvanceForfeitTaxID?: Maybe<Scalars['String']>;
  DepositForfeitTaxID?: Maybe<Scalars['String']>;
  MSICCode?: Maybe<Scalars['String']>;
  MSICDesc?: Maybe<Scalars['String']>;
  EInvoiceSCClassification?: Maybe<Scalars['String']>;
  EInvoiceRCClassification?: Maybe<Scalars['String']>;
  EInvoiceAdvPaymentClassification?: Maybe<Scalars['String']>;
  EInvoiceDepositForfeitClassification?: Maybe<Scalars['String']>;
  EInvoiceStateTaxClassification?: Maybe<Scalars['String']>;
  SOAFooter?: Maybe<Scalars['String']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
};

export type HotelNotificationEntity = InterfaceBase & {
  __typename?: 'HotelNotificationEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['Boolean']>;
  NotificationType?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  RefItem?: Maybe<Scalars['String']>;
  User?: Maybe<UserEntity>;
};

export type HotelNotificationInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['Boolean']>;
  NotificationType?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  RefItem?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

/** These permissions are latest applicable for HMS */
export enum HotelPermission {
  BookingIgnoreView = 'BOOKING_IGNORE_______VIEW',
  BookingIgnoreCreate = 'BOOKING_IGNORE_______CREATE',
  BookingBookingInfoEdit = 'BOOKING_BOOKING_INFO_______EDIT',
  BookingCloneBookingCreate = 'BOOKING_CLONE_BOOKING_______CREATE',
  BookingRoomingListListView = 'BOOKING_ROOMING_LIST_LIST____VIEW',
  BookingRoomingListListCreate = 'BOOKING_ROOMING_LIST_LIST____CREATE',
  BookingRoomingListListEdit = 'BOOKING_ROOMING_LIST_LIST____EDIT',
  BookingRoomingListListDelete = 'BOOKING_ROOMING_LIST_LIST____DELETE',
  BookingRoomAssignmentListView = 'BOOKING_ROOM_ASSIGNMENT_LIST____VIEW',
  BookingRoomAssignmentListAssignment = 'BOOKING_ROOM_ASSIGNMENT_LIST____ASSIGNMENT',
  BookingSpecialRequestView = 'BOOKING_SPECIAL_REQUEST_______VIEW',
  BookingSpecialRequestCreate = 'BOOKING_SPECIAL_REQUEST_______CREATE',
  BookingAdvancePaymentInfoView = 'BOOKING_ADVANCE_PAYMENT_INFO____VIEW',
  BookingAdvancePaymentInfoCreate = 'BOOKING_ADVANCE_PAYMENT_INFO____CREATE',
  BookingAdvancePaymentInfoEdit = 'BOOKING_ADVANCE_PAYMENT_INFO____EDIT',
  BookingAttachmentsCreate = 'BOOKING_ATTACHMENTS____CREATE',
  BookingAttachmentsEdit = 'BOOKING_ATTACHMENTS____EDIT',
  BookingAttachmentsDelete = 'BOOKING_ATTACHMENTS____DELETE',
  BookingRemarksView = 'BOOKING_REMARKS_______VIEW',
  BookingRemarksCreate = 'BOOKING_REMARKS____CREATE',
  BookingBillingInstructionView = 'BOOKING_BILLING_INSTRUCTION_______VIEW',
  BookingBillingInstructionCreate = 'BOOKING_BILLING_INSTRUCTION_______CREATE',
  BookingHouseLimitView = 'BOOKING_HOUSE_LIMIT_______VIEW',
  BookingHouseLimitCreate = 'BOOKING_HOUSE_LIMIT_______CREATE',
  BookingCancelBookingCancel = 'BOOKING_CANCEL_BOOKING_______CANCEL',
  BookingCancelRoomCancel = 'BOOKING_CANCEL_ROOM_______CANCEL',
  BookingConfirmLetterView = 'BOOKING_CONFIRM_LETTER_______VIEW',
  BookingAttachmentsView = 'BOOKING_ATTACHMENTS_______VIEW',
  BookingBillingScheduleView = 'BOOKING_BILLING_SCHEDULE_______VIEW',
  BookingBillingScheduleEdit = 'BOOKING_BILLING_SCHEDULE_______EDIT',
  BookingAuditlogView = 'BOOKING_AUDITLOG_______VIEW',
  FrontDeskIgnoreView = 'FRONT_DESK_IGNORE_______VIEW',
  FrontDeskCheckInView = 'FRONT_DESK_CHECK_IN_______VIEW',
  FrontDeskCheckInWalkInCreate = 'FRONT_DESK_CHECK_IN_WALK_IN____CREATE',
  FrontDeskCheckInAssignment = 'FRONT_DESK_CHECK_IN_______ASSIGNMENT',
  FrontDeskCheckInCheckIn = 'FRONT_DESK_CHECK_IN_______CHECK_IN',
  FrontDeskCheckInBookingInfoView = 'FRONT_DESK_CHECK_IN_BOOKING_INFO____VIEW',
  FrontDeskCheckInBookingInfoEdit = 'FRONT_DESK_CHECK_IN_BOOKING_INFO____EDIT',
  FrontDeskCheckInRoomingListView = 'FRONT_DESK_CHECK_IN_ROOMING_LIST____VIEW',
  FrontDeskCheckInRoomingListListView = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_VIEW',
  FrontDeskCheckInRoomingListListEdit = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_EDIT',
  FrontDeskCheckInRoomingListListCreate = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_CREATE',
  FrontDeskCheckInRoomingListListDelete = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_DELETE',
  FrontDeskCheckInIncidentalChargesView = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____VIEW',
  FrontDeskCheckInIncidentalChargesCreate = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____CREATE',
  FrontDeskCheckInIncidentalChargesVoid = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____VOID',
  FrontDeskCheckInDepositView = 'FRONT_DESK_CHECK_IN_DEPOSIT____VIEW',
  FrontDeskCheckInDepositListCreate = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_CREATE',
  FrontDeskCheckInDepositListRefund = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_REFUND',
  FrontDeskCheckInDepositListPrint = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_PRINT',
  FrontDeskCheckInAdvancePaymentView = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT____VIEW',
  FrontDeskCheckInAdvancePaymentListCreate = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_CREATE',
  FrontDeskCheckInAdvancePaymentListRefund = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_REFUND',
  FrontDeskCheckInRemarksView = 'FRONT_DESK_CHECK_IN_REMARKS_______VIEW',
  FrontDeskCheckInRemarksCreate = 'FRONT_DESK_CHECK_IN_REMARKS____CREATE',
  FrontDeskCheckInAdvancePaymentListPrint = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_PRINT',
  FrontDeskCheckInFolioView = 'FRONT_DESK_CHECK_IN_FOLIO____VIEW',
  FrontDeskCheckInFolioListView = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_VIEW',
  FrontDeskCheckInFolioListSplit = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_SPLIT',
  FrontDeskCheckInFolioListTransfer = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_TRANSFER',
  FrontDeskCheckInFolioListInhouse = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_INHOUSE',
  FrontDeskCheckInFolioListSuspend = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_SUSPEND',
  FrontDeskCheckInFolioListPrint = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_PRINT',
  FrontDeskCheckInFolioListCreate = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_CREATE',
  FrontDeskCheckInFolioListVoid = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_VOID',
  FrontDeskCheckInSpecialRequestView = 'FRONT_DESK_CHECK_IN_SPECIAL_REQUEST____VIEW',
  FrontDeskCheckInSpecialRequestCreate = 'FRONT_DESK_CHECK_IN_SPECIAL_REQUEST____CREATE',
  FrontDeskCheckInBillingScheduleView = 'FRONT_DESK_CHECK_IN_BILLING_SCHEDULE____VIEW',
  FrontDeskCheckInBillingScheduleEdit = 'FRONT_DESK_CHECK_IN_BILLING_SCHEDULE____EDIT',
  FrontDeskCheckInCheckinCancelCancel = 'FRONT_DESK_CHECK_IN_CHECKIN_CANCEL____CANCEL',
  FrontDeskCheckInKeyCardView = 'FRONT_DESK_CHECK_IN_KEY_CARD____VIEW',
  FrontDeskCheckInKeyCardCreate = 'FRONT_DESK_CHECK_IN_KEY_CARD____CREATE',
  FrontDeskGroupCheckinView = 'FRONT_DESK_GROUP_CHECKIN_______VIEW',
  FrontDeskGroupCheckinListView = 'FRONT_DESK_GROUP_CHECKIN_LIST____VIEW',
  FrontDeskGroupCheckinListAssignment = 'FRONT_DESK_GROUP_CHECKIN_LIST____ASSIGNMENT',
  FrontDeskGroupCheckinListCheckIn = 'FRONT_DESK_GROUP_CHECKIN_LIST____CHECK_IN',
  FrontDeskCheckOutView = 'FRONT_DESK_CHECK_OUT_______VIEW',
  FrontDeskCheckOutDepositView = 'FRONT_DESK_CHECK_OUT_DEPOSIT____VIEW',
  FrontDeskCheckOutDepositListCreate = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_CREATE',
  FrontDeskCheckOutDepositListRefund = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_REFUND',
  FrontDeskCheckOutDepositListPrint = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_PRINT',
  FrontDeskCheckOutIncidentalChargesView = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____VIEW',
  FrontDeskCheckOutIncidentalChargesCreate = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____CREATE',
  FrontDeskCheckOutIncidentalChargesVoid = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____VOID',
  FrontDeskCheckOutRemarksView = 'FRONT_DESK_CHECK_OUT_REMARKS_______VIEW',
  FrontDeskCheckOutRemarksCreate = 'FRONT_DESK_CHECK_OUT_REMARKS____CREATE',
  FrontDeskCheckOutFolioView = 'FRONT_DESK_CHECK_OUT_FOLIO____VIEW',
  FrontDeskCheckOutFolioListView = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_VIEW',
  FrontDeskCheckOutFolioListSplit = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_SPLIT',
  FrontDeskCheckOutFolioListTransfer = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_TRANSFER',
  FrontDeskCheckOutFolioListInhouse = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_INHOUSE',
  FrontDeskCheckOutFolioListSuspend = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_SUSPEND',
  FrontDeskCheckOutFolioListPrint = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_PRINT',
  FrontDeskCheckOutFolioListCreate = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_CREATE',
  FrontDeskCheckOutFolioListVoid = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_VOID',
  FrontDeskCheckOutFolioHistoryView = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____VIEW',
  FrontDeskCheckOutFolioHistoryShare = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____SHARE',
  FrontDeskCheckOutFolioHistoryPrint = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____PRINT',
  FrontDeskCheckOutBillingScheduleView = 'FRONT_DESK_CHECK_OUT_BILLING_SCHEDULE____VIEW',
  FrontDeskCheckOutBillingScheduleEdit = 'FRONT_DESK_CHECK_OUT_BILLING_SCHEDULE____EDIT',
  FrontDeskRoomAssignmentView = 'FRONT_DESK_ROOM_ASSIGNMENT_______VIEW',
  FrontDeskRoomAssignmentAssignment = 'FRONT_DESK_ROOM_ASSIGNMENT_______ASSIGNMENT',
  FrontDeskRoomAssignmentAssignRoomAssignment = 'FRONT_DESK_ROOM_ASSIGNMENT_ASSIGN_ROOM____ASSIGNMENT',
  FrontDeskStayViewView = 'FRONT_DESK_STAY_VIEW_______VIEW',
  FrontDeskInhouseView = 'FRONT_DESK_INHOUSE_______VIEW',
  FrontDeskInhouseBookingInfoView = 'FRONT_DESK_INHOUSE_BOOKING_INFO____VIEW',
  FrontDeskInhouseBookingInfoEdit = 'FRONT_DESK_INHOUSE_BOOKING_INFO____EDIT',
  FrontDeskInhouseRoomingListView = 'FRONT_DESK_INHOUSE_ROOMING_LIST____VIEW',
  FrontDeskInhouseAttachmentsView = 'FRONT_DESK_INHOUSE_ATTACHMENTS____VIEW',
  FrontDeskInhouseCheckinCancelCancel = 'FRONT_DESK_INHOUSE_CHECKIN_CANCEL____CANCEL',
  FrontDeskInhouseBillingScheduleEdit = 'FRONT_DESK_INHOUSE_BILLING_SCHEDULE____EDIT',
  FrontDeskInhouseRoomingListListView = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_VIEW',
  FrontDeskInhouseRoomingListListEdit = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_EDIT',
  FrontDeskInhouseRoomingListListCreate = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_CREATE',
  FrontDeskInhouseRoomingListListDelete = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_DELETE',
  FrontDeskInhouseServiceRequestView = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____VIEW',
  FrontDeskInhouseServiceRequestListCreate = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST_LIST_CREATE',
  FrontDeskInhouseServiceRequestEdit = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____EDIT',
  FrontDeskInhouseServiceRequestUpdateStatus = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____UPDATE_STATUS',
  FrontDeskInhouseRemarksView = 'FRONT_DESK_INHOUSE_REMARKS_______VIEW',
  FrontDeskInhouseRemarksCreate = 'FRONT_DESK_INHOUSE_REMARKS____CREATE',
  FrontDeskInhouseIncidentalChargesView = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____VIEW',
  FrontDeskInhouseIncidentalChargesCreate = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____CREATE',
  FrontDeskInhouseIncidentalChargesVoid = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____VOID',
  FrontDeskInhouseDepositView = 'FRONT_DESK_INHOUSE_DEPOSIT____VIEW',
  FrontDeskInhouseDepositListCreate = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_CREATE',
  FrontDeskInhouseDepositListRefund = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_REFUND',
  FrontDeskInhouseDepositListPrint = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_PRINT',
  FrontDeskInhouseAdvancePaymentView = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT____VIEW',
  FrontDeskInhouseAdvancePaymentListCreate = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_CREATE',
  FrontDeskInhouseAdvancePaymentListRefund = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_REFUND',
  FrontDeskInhouseAdvancePaymentListPrint = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_PRINT',
  FrontDeskInhouseFolioView = 'FRONT_DESK_INHOUSE_FOLIO____VIEW',
  FrontDeskInhouseFolioListView = 'FRONT_DESK_INHOUSE_FOLIO_LIST_VIEW',
  FrontDeskInhouseFolioListSplit = 'FRONT_DESK_INHOUSE_FOLIO_LIST_SPLIT',
  FrontDeskInhouseFolioListTransfer = 'FRONT_DESK_INHOUSE_FOLIO_LIST_TRANSFER',
  FrontDeskInhouseFolioListInhouse = 'FRONT_DESK_INHOUSE_FOLIO_LIST_INHOUSE',
  FrontDeskInhouseFolioListSuspend = 'FRONT_DESK_INHOUSE_FOLIO_LIST_SUSPEND',
  FrontDeskInhouseFolioListPrint = 'FRONT_DESK_INHOUSE_FOLIO_LIST_PRINT',
  FrontDeskInhouseFolioListCreate = 'FRONT_DESK_INHOUSE_FOLIO_LIST_CREATE',
  FrontDeskInhouseFolioListVoid = 'FRONT_DESK_INHOUSE_FOLIO_LIST_VOID',
  FrontDeskInhouseEarlyCheckoutView = 'FRONT_DESK_INHOUSE_EARLY_CHECKOUT____VIEW',
  FrontDeskInhouseLateCheckoutView = 'FRONT_DESK_INHOUSE_LATE_CHECKOUT____VIEW',
  FrontDeskInhouseLateCheckoutCreate = 'FRONT_DESK_INHOUSE_LATE_CHECKOUT____CREATE',
  FrontDeskInhouseExtendStayView = 'FRONT_DESK_INHOUSE_EXTEND_STAY____VIEW',
  FrontDeskInhouseExtendStayCreate = 'FRONT_DESK_INHOUSE_EXTEND_STAY____CREATE',
  FrontDeskInhouseRateAdjustmentView = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT____VIEW',
  FrontDeskInhouseRateAdjustmentCreate = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT____CREATE',
  FrontDeskInhouseRateAdjustmentListView = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT__LIST_VIEW',
  FrontDeskInhouseRoomTransferView = 'FRONT_DESK_INHOUSE_ROOM_TRANSFER____VIEW',
  FrontDeskInhouseRoomTransferCreate = 'FRONT_DESK_INHOUSE_ROOM_TRANSFER____CREATE',
  FrontDeskInhouseRoomUpgradeView = 'FRONT_DESK_INHOUSE_ROOM_UPGRADE____VIEW',
  FrontDeskInhouseRoomUpgradeCreate = 'FRONT_DESK_INHOUSE_ROOM_UPGRADE____CREATE',
  FrontDeskInhouseFolioHistoryView = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____VIEW',
  FrontDeskInhouseFolioHistoryShare = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____SHARE',
  FrontDeskInhouseFolioHistoryPrint = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____PRINT',
  FrontDeskInhouseAttachmentsCreate = 'FRONT_DESK_INHOUSE_Attachments____CREATE',
  FrontDeskInhouseAttachmentsEdit = 'FRONT_DESK_INHOUSE_Attachments____EDIT',
  FrontDeskInhouseAttachmentsDelete = 'FRONT_DESK_INHOUSE_Attachments____DELETE',
  FrontDeskInhouseHouseLimitView = 'FRONT_DESK_INHOUSE_HOUSE_LIMIT____VIEW',
  FrontDeskInhouseHouseLimitCreate = 'FRONT_DESK_INHOUSE_HOUSE_LIMIT____CREATE',
  FrontDeskInhouseBillingScheduleView = 'FRONT_DESK_INHOUSE_BILLING_SCHEDULE____VIEW',
  FrontDeskInhouseKeyCardView = 'FRONT_DESK_INHOUSE_KEY_CARD____VIEW',
  FrontDeskInhouseKeyCardCreate = 'FRONT_DESK_INHOUSE_KEY_CARD____CREATE',
  FrontDeskDepositView = 'FRONT_DESK_DEPOSIT_______VIEW',
  FrontDeskDepositListCreate = 'FRONT_DESK_DEPOSIT_LIST____CREATE',
  FrontDeskDepositListRefund = 'FRONT_DESK_DEPOSIT_LIST____REFUND',
  FrontDeskDepositListPrint = 'FRONT_DESK_DEPOSIT_LIST____PRINT',
  FrontDeskDepositListShare = 'FRONT_DESK_DEPOSIT_LIST____SHARE',
  FrontDeskServiceRequestView = 'FRONT_DESK_SERVICE_REQUEST_______VIEW',
  FrontDeskServiceRequestListCreate = 'FRONT_DESK_SERVICE_REQUEST_LIST____CREATE',
  FrontDeskServiceRequestListEdit = 'FRONT_DESK_SERVICE_REQUEST_LIST____EDIT',
  FrontDeskServiceRequestListUpdateStatus = 'FRONT_DESK_SERVICE_REQUEST_LIST____UPDATE_STATUS',
  FrontDeskServiceRequestListAssignment = 'FRONT_DESK_SERVICE_REQUEST_LIST____ASSIGNMENT',
  FrontDeskNonguestFolioView = 'FRONT_DESK_NONGUEST_FOLIO_______VIEW',
  FrontDeskNonguestFolioListCreate = 'FRONT_DESK_NONGUEST_FOLIO_LIST____CREATE',
  FrontDeskNonguestFolioListEdit = 'FRONT_DESK_NONGUEST_FOLIO_LIST____EDIT',
  FrontDeskNonguestFolioListDelete = 'FRONT_DESK_NONGUEST_FOLIO_LIST____DELETE',
  FrontDeskNonguestFolioListFolio = 'FRONT_DESK_NONGUEST_FOLIO_LIST____FOLIO',
  FrontDeskNonguestFolioListFolioCharges = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_CHARGES',
  FrontDeskNonguestFolioListFolioPayment = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_PAYMENT',
  FrontDeskNonguestFolioListFolioClose = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_CLOSE',
  FrontDeskNonguestFolioListFolioPrint = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_PRINT',
  FrontDeskSuspendFolioView = 'FRONT_DESK_SUSPEND_FOLIO_______VIEW',
  FrontDeskSuspendFolioListCreate = 'FRONT_DESK_SUSPEND_FOLIO_LIST____CREATE',
  FrontDeskSuspendFolioListEdit = 'FRONT_DESK_SUSPEND_FOLIO_LIST____EDIT',
  FrontDeskSuspendFolioListDelete = 'FRONT_DESK_SUSPEND_FOLIO_LIST____DELETE',
  FrontDeskSuspendFolioListFolio = 'FRONT_DESK_SUSPEND_FOLIO_LIST____FOLIO',
  FrontDeskSuspendFolioListFolioCharges = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_CHARGES',
  FrontDeskSuspendFolioListFolioPayment = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_PAYMENT',
  FrontDeskSuspendFolioListFolioClose = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_CLOSE',
  FrontDeskSuspendFolioListFolioPrint = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_PRINT',
  FrontDeskContactTracingView = 'FRONT_DESK_CONTACT_TRACING_______VIEW',
  FrontDeskContactTracingInfoView = 'FRONT_DESK_CONTACT_TRACING_INFO____VIEW',
  FrontDeskContactTracingInfoShare = 'FRONT_DESK_CONTACT_TRACING_INFO____SHARE',
  HouseKeepingIgnoreView = 'HOUSE_KEEPING_IGNORE_______VIEW',
  HouseKeepingBlockRoomView = 'HOUSE_KEEPING_BLOCK_ROOM_______VIEW',
  HouseKeepingBlockRoomEdit = 'HOUSE_KEEPING_BLOCK_ROOM_______EDIT',
  HouseKeepingBlockRoomBlockRoomStatusView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_______VIEW',
  HouseKeepingBlockRoomBlockRoomStatusOooView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_OOO_______VIEW',
  HouseKeepingBlockRoomBlockRoomStatusOoiView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_OOI_______VIEW',
  HouseKeepingRoomStatusView = 'HOUSE_KEEPING_ROOM_STATUS_______VIEW',
  HouseKeepingRoomStatusUpdateStatus = 'HOUSE_KEEPING_ROOM_STATUS_______UPDATE_STATUS',
  HouseKeepingServiceRequestView = 'HOUSE_KEEPING_SERVICE_REQUEST_______VIEW',
  HouseKeepingServiceRequestCreate = 'HOUSE_KEEPING_SERVICE_REQUEST_______CREATE',
  HouseKeepingServiceRequestEdit = 'HOUSE_KEEPING_SERVICE_REQUEST_______EDIT',
  HouseKeepingServiceRequestUpdateStatus = 'HOUSE_KEEPING_SERVICE_REQUEST_______UPDATE_STATUS',
  HouseKeepingIncidentalChargesView = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______VIEW',
  HouseKeepingIncidentalChargesCreate = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______CREATE',
  HouseKeepingIncidentalChargesVoid = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______VOID',
  ServiceRequestIgnoreView = 'SERVICE_REQUEST_IGNORE_______VIEW',
  ServiceRequestIgnoreEdit = 'SERVICE_REQUEST_IGNORE_______EDIT',
  ServiceRequestIgnoreCreate = 'SERVICE_REQUEST_IGNORE_______CREATE',
  ServiceRequestIgnoreUpdateStatus = 'SERVICE_REQUEST_IGNORE_______UPDATE_STATUS',
  ServiceRequestIgnoreAssignment = 'SERVICE_REQUEST_IGNORE_______ASSIGNMENT',
  PackagesRedemptionIgnoreView = 'PACKAGES_REDEMPTION_IGNORE_______VIEW',
  PackagesRedemptionIgnoreEdit = 'PACKAGES_REDEMPTION_IGNORE_______EDIT',
  CorpGovtIgnoreView = 'CORP_GOVT_IGNORE_______VIEW',
  CorpGovtPrivateCorporationView = 'CORP_GOVT_PRIVATE_CORPORATION_______VIEW',
  CorpGovtPrivateCorporationCreate = 'CORP_GOVT_PRIVATE_CORPORATION_______CREATE',
  CorpGovtPrivateCorporationActive = 'CORP_GOVT_PRIVATE_CORPORATION_______ACTIVE',
  CorpGovtPrivateCorporationDelete = 'CORP_GOVT_PRIVATE_CORPORATION_______DELETE',
  CorpGovtPrivateCorporationCompanyInfoView = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____VIEW',
  CorpGovtPrivateCorporationCompanyInfoEdit = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____EDIT',
  CorpGovtPrivateCorporationContractsView = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____VIEW',
  CorpGovtPrivateCorporationContractsCreate = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____CREATE',
  CorpGovtPrivateCorporationContractsEdit = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____EDIT',
  CorpGovtPrivateCorporationContractsActive = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____ACTIVE',
  CorpGovtPrivateCorporationContractsDelete = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____DELETE',
  CorpGovtPrivateCorporationBookingRecordsView = 'CORP_GOVT_PRIVATE_CORPORATION_BOOKING_RECORDS____VIEW',
  CorpGovtPrivateCorporationAnalysisView = 'CORP_GOVT_PRIVATE_CORPORATION_ANALYSIS____VIEW',
  CorpGovtGovtBodiesView = 'CORP_GOVT_GOVT_BODIES_______VIEW',
  CorpGovtGovtBodiesCreate = 'CORP_GOVT_GOVT_BODIES_______CREATE',
  CorpGovtGovtBodiesActive = 'CORP_GOVT_GOVT_BODIES_______ACTIVE',
  CorpGovtGovtBodiesDelete = 'CORP_GOVT_GOVT_BODIES_______DELETE',
  CorpGovtGovtBodiesCompanyInfoView = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____VIEW',
  CorpGovtGovtBodiesCompanyInfoEdit = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____EDIT',
  CorpGovtGovtBodiesContractsView = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____VIEW',
  CorpGovtGovtBodiesContractsCreate = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____CREATE',
  CorpGovtGovtBodiesContractsEdit = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____EDIT',
  CorpGovtGovtBodiesContractsActive = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____ACTIVE',
  CorpGovtGovtBodiesContractsDelete = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____DELETE',
  CorpGovtGovtBodiesBookingRecordsView = 'CORP_GOVT_GOVT_BODIES_BOOKING_RECORDS____VIEW',
  CorpGovtGovtBodiesAnalysisView = 'CORP_GOVT_GOVT_BODIES_ANALYSIS____VIEW',
  CorpGovtRatePolicyView = 'CORP_GOVT_RATE_POLICY_______VIEW',
  TravelAgentIgnoreView = 'TRAVEL_AGENT_IGNORE_______VIEW',
  TravelAgentAgentListingView = 'TRAVEL_AGENT_AGENT_LISTING_______VIEW',
  TravelAgentAgentListingCreate = 'TRAVEL_AGENT_AGENT_LISTING_______CREATE',
  TravelAgentAgentListingActive = 'TRAVEL_AGENT_AGENT_LISTING_______ACTIVE',
  TravelAgentAgentListingDelete = 'TRAVEL_AGENT_AGENT_LISTING_______DELETE',
  TravelAgentAgentListingCompanyInfoView = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____VIEW',
  TravelAgentAgentListingCompanyInfoEdit = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____EDIT',
  TravelAgentAgentListingContractsView = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____VIEW',
  TravelAgentAgentListingContractsCreate = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____CREATE',
  TravelAgentAgentListingContractsEdit = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____EDIT',
  TravelAgentAgentListingContractsActive = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____ACTIVE',
  TravelAgentAgentListingContractsDelete = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____DELETE',
  TravelAgentAgentListingBookingRecordsView = 'TRAVEL_AGENT_AGENT_LISTING_BOOKING_RECORDS____VIEW',
  TravelAgentAgentListingAnalysisView = 'TRAVEL_AGENT_AGENT_LISTING_ANALYSIS____VIEW',
  TravelAgentRatePolicyView = 'TRAVEL_AGENT_RATE_POLICY_______VIEW',
  GuestProfileIgnoreView = 'GUEST_PROFILE_IGNORE_______VIEW',
  GuestProfileIgnoreEdit = 'GUEST_PROFILE_IGNORE_______EDIT',
  DigitalMarketingIgnoreView = 'DIGITAL_MARKETING_IGNORE_______VIEW',
  DigitalMarketingPromoCodeView = 'DIGITAL_MARKETING_PROMO_CODE_______VIEW',
  DigitalMarketingPromoCodeCreate = 'DIGITAL_MARKETING_PROMO_CODE_______CREATE',
  DigitalMarketingPromoCodeEdit = 'DIGITAL_MARKETING_PROMO_CODE_______EDIT',
  DigitalMarketingPromoCodeActive = 'DIGITAL_MARKETING_PROMO_CODE_______ACTIVE',
  DigitalMarketingMarketingAdsView = 'DIGITAL_MARKETING_MARKETING_ADS_______VIEW',
  DigitalMarketingMarketingAdsCreate = 'DIGITAL_MARKETING_MARKETING_ADS_______CREATE',
  DigitalMarketingMarketingAdsEdit = 'DIGITAL_MARKETING_MARKETING_ADS_______EDIT',
  DigitalMarketingMarketingAdsShare = 'DIGITAL_MARKETING_MARKETING_ADS_______SHARE',
  DigitalMarketingMarketingAdsEmailBlasting = 'DIGITAL_MARKETING_MARKETING_ADS_______EMAIL_BLASTING',
  DigitalMarketingGuestRatingReviewView = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_______VIEW',
  DigitalMarketingGuestRatingReviewInfoView = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____VIEW',
  DigitalMarketingGuestRatingReviewInfoUpdateStatus = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____UPDATE_STATUS',
  DigitalMarketingGuestRatingReviewInfoShare = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____SHARE',
  RoomServiceIgnoreView = 'ROOM_SERVICE_IGNORE_______VIEW',
  RoomServiceIgnoreCreate = 'ROOM_SERVICE_IGNORE_______CREATE',
  RoomServiceIgnoreCancel = 'ROOM_SERVICE_IGNORE_______CANCEL',
  RoomServiceIgnoreUpdateStatus = 'ROOM_SERVICE_IGNORE_______UPDATE_STATUS',
  CheckoutAdjustmentIgnoreView = 'CHECKOUT_ADJUSTMENT_IGNORE_______VIEW',
  CheckoutAdjustmentIgnoreCreate = 'CHECKOUT_ADJUSTMENT_IGNORE_______CREATE',
  CheckoutAdjustmentIgnoreBenificiary = 'CHECKOUT_ADJUSTMENT_IGNORE_______BENIFICIARY',
  CheckoutAdjustmentIgnoreCreditNote = 'CHECKOUT_ADJUSTMENT_IGNORE_______CREDIT_NOTE',
  CheckoutAdjustmentIgnoreDebitNote = 'CHECKOUT_ADJUSTMENT_IGNORE_______DEBIT_NOTE',
  CheckoutAdjustmentIgnoreChangePayment = 'CHECKOUT_ADJUSTMENT_IGNORE_______CHANGE_PAYMENT',
  CheckoutAdjustmentIgnoreShare = 'CHECKOUT_ADJUSTMENT_IGNORE_______SHARE',
  FrontDeskCheckOutCheckoutReinstatementCreate = 'FRONT_DESK_CHECK_OUT_CHECKOUT_REINSTATEMENT____CREATE',
  BusinessInsightIgnoreView = 'BUSINESS_INSIGHT_IGNORE_______VIEW',
  BusinessInsightDigitalReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_______VIEW',
  BusinessInsightDigitalReportCashierSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_SUMMARY____VIEW',
  BusinessInsightDigitalReportCashierDetailListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_DETAIL_LISTING____VIEW',
  BusinessInsightDigitalReportSalesAndServicesListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SALES_AND_SERVICES_LISTING____VIEW',
  BusinessInsightDigitalReportTransactionLedgerView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_LEDGER____VIEW',
  BusinessInsightDigitalReportBookingStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_BOOKING_STATUS____VIEW',
  BusinessInsightDigitalReportManagerReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_MANAGER_REPORT____VIEW',
  BusinessInsightDigitalReportManagerReportFinancialView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_MANAGER_REPORT_FINANCIAL____VIEW',
  BusinessInsightDigitalReportProductionRoomNoView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_ROOM_NO___VIEW',
  BusinessInsightDigitalReportProductionRateCodeeView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_RATE_CODEE____VIEW',
  BusinessInsightDigitalReportProductionRoomTypeView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_ROOM_TYPE____VIEW',
  BusinessInsightDigitalReportProductionCorpGovView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_CORP_GOV____VIEW',
  BusinessInsightDigitalReportProductionSegmentView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SEGMENT____VIEW',
  BusinessInsightDigitalReportProductionSourceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SOURCE____VIEW',
  BusinessInsightDigitalReportProductionStateView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_STATE____VIEW',
  BusinessInsightDigitalReportPackageListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PACKAGE_LISTING____VIEW',
  BusinessInsightDigitalReportRoomStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_STATUS____VIEW',
  BusinessInsightDigitalReportRoomStatusMovementView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_STATUS_MOVEMENT____VIEW',
  BusinessInsightDigitalReportProductionSalesChannelView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SALES_CHANNEL____VIEW',
  BusinessInsightDigitalReportInhouseGuestView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_INHOUSE_GUEST____VIEW',
  BusinessInsightDigitalReportSecurityDepositView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SECURITY_DEPOSIT____VIEW',
  BusinessInsightDigitalReportRoomDiscrepancyView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_DISCREPANCY____VIEW',
  BusinessInsightDigitalReportTourismTaxView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TOURISM_TAX____VIEW',
  BusinessInsightDigitalReportTransactionCancelView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_CANCEL____VIEW',
  BusinessInsightDigitalReportServiceRequestView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SERVICE_REQUEST____VIEW',
  BusinessInsightDigitalReportGuestBalanceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GUEST_BALANCE____VIEW',
  BusinessInsightDigitalReportGuestRoomView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GUEST_ROOM____VIEW',
  BusinessInsightDigitalReportOccupiedView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_OCCUPIED____VIEW',
  BusinessInsightDigitalReportOutstandingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_OUTSTANDING____VIEW',
  BusinessInsightDigitalReportGroupReservationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GROUP_RESERVATION____VIEW',
  BusinessInsightDigitalReportGroupReservationSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GROUP_RESERVATION_SUMMARY____VIEW',
  BusinessInsightDigitalReportRoomAvailabilityView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_AVAILABILITY____VIEW',
  BusinessInsightDigitalReportDownloadGlView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DOWNLOAD_GL____VIEW',
  BusinessInsightDigitalReportDebtorLedgerView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_LEDGER____VIEW',
  BusinessInsightDigitalReportDebtorLedgerDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_LEDGER_DETAIL____VIEW',
  BusinessInsightDigitalReportDebtorAllocationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_ALLOCATION____VIEW',
  BusinessInsightDigitalReportDebtorAgingSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_AGING_SUMMARY____VIEW',
  BusinessInsightDigitalReportDebtorAgingDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_AGING_DETAIL____VIEW',
  BusinessInsightDigitalReportStatementOfAccountView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_STATEMENT_OF_ACCOUNT____VIEW',
  BusinessInsightDigitalReportStatementOfAccountOutstandingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_STATEMENT_OF_ACCOUNT_OUTSTANDING____VIEW',
  BusinessInsightDigitalReportPendingCreditAllocationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PENDING_CREDIT_ALLOCATION____VIEW',
  BusinessInsightDigitalReportDebtorStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_STATUS____VIEW',
  BusinessInsightDigitalReportDebtorRevenueView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_REVENUE____VIEW',
  BusinessInsightDigitalReportDailyPostingDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DAILY_POSTING_DETAIL____VIEW',
  BusinessInsightDigitalReportHistoricalForecastView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_HISTORICAL_FORECAST____VIEW',
  BusinessInsightDigitalReportRoomMaintenanceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_MAINTENANCE____VIEW',
  BusinessInsightDigitalReportTransactionTransferView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_TRANSFER____VIEW',
  BusinessInsightDigitalReportConsolidatedEinvoiceReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CONSOLIDATED_EINVOICE_REPORT____VIEW',
  BusinessInsightDigitalReportEinvoiceListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_EINVOICE_LISTING____VIEW',
  BusinessInsightDigitalReportEinvoiceReconciliationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_EINVOICE_RECONCILIATION____VIEW',
  BusinessInsightDigitalReportEinvoiceReconciliationSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_EINVOICE_RECONCILIATION_SUMMARY____VIEW',
  BusinessInsightDigitalDocumentView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_______VIEW',
  BusinessInsightDigitalDocumentRegistrationCardView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____VIEW',
  BusinessInsightDigitalDocumentRegistrationCardShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____SHARE',
  BusinessInsightDigitalDocumentRegistrationCardPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____PRINT',
  BusinessInsightDigitalDocumentFolioView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____VIEW',
  BusinessInsightDigitalDocumentFolioShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____SHARE',
  BusinessInsightDigitalDocumentFolioPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____PRINT',
  BusinessInsightDigitalDocumentReceiptView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____VIEW',
  BusinessInsightDigitalDocumentReceiptShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____SHARE',
  BusinessInsightDigitalDocumentReceiptPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____PRINT',
  BusinessInsightDigitalDocumentDepositView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____VIEW',
  BusinessInsightDigitalDocumentDepositShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____SHARE',
  BusinessInsightDigitalDocumentDepositPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____PRINT',
  BusinessInsightAnalyticsView = 'BUSINESS_INSIGHT_ANALYTICS_______VIEW',
  BusinessInsightAnalyticsHotelStatusView = 'BUSINESS_INSIGHT_ANALYTICS_HOTEL_STATUS____VIEW',
  BusinessInsightAnalyticsHotelStatisticView = 'BUSINESS_INSIGHT_ANALYTICS_HOTEL_STATISTIC____VIEW',
  BusinessInsightAnalyticsRevenueAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsSourceAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_SOURCE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsCountryAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_COUNTRY_ANALYSIS____VIEW',
  BusinessInsightAnalyticsNationalityAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_NATIONALITY_ANALYSIS____VIEW',
  BusinessInsightAnalyticsDebtorAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_DEBTOR_ANALYSIS____VIEW',
  BusinessInsightAnalyticsMarketingPersonnalView = 'BUSINESS_INSIGHT_ANALYTICS_MARKETING_PERSONNAL____VIEW',
  BusinessInsightAnalyticsRevenueByRoomtypeView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_BY_ROOMTYPE____VIEW',
  BusinessInsightAnalyticsOnlineTravelAgentView = 'BUSINESS_INSIGHT_ANALYTICS_ONLINE_TRAVEL_AGENT____VIEW',
  BusinessInsightAnalyticsSegmentAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_SEGMENT_ANALYSIS____VIEW',
  BusinessInsightAnalyticsRevenueByTransactionView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_BY_TRANSACTION____VIEW',
  BusinessInsightAnalyticsServiceRequestView = 'BUSINESS_INSIGHT_ANALYTICS_SERVICE_REQUEST____VIEW',
  BusinessInsightAnalyticsMaidProductivityView = 'BUSINESS_INSIGHT_ANALYTICS_MAID_PRODUCTIVITY____VIEW',
  BusinessInsightAnalyticsRatingReviewAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_RATING_REVIEW_ANALYSIS____VIEW',
  BusinessInsightAnalyticsStateAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_STATE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsDebtorLedgerStatusView = 'BUSINESS_INSIGHT_ANALYTICS_DEBTOR_LEDGER_STATUS____VIEW',
  BusinessInsightAnalyticsTotalAgingView = 'BUSINESS_INSIGHT_ANALYTICS_TOTAL_AGING____VIEW',
  BusinessInsightAnalyticsAmountDueByAgingView = 'BUSINESS_INSIGHT_ANALYTICS_AMOUNT_DUE_BY_AGING____VIEW',
  BusinessInsightAnalyticsCustomerByRevenueView = 'BUSINESS_INSIGHT_ANALYTICS_CUSTOMER_BY_REVENUE____VIEW',
  BusinessInsightAnalyticsCollectionByDebtortypeView = 'BUSINESS_INSIGHT_ANALYTICS_COLLECTION_BY_DEBTORTYPE____VIEW',
  BusinessInsightDigitalReportCashierCollectionSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_COLLECTION_SUMMARY____VIEW',
  BusinessInsightDigitalReportCityLedgerTransactionView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CITY_LEDGER_TRANSACTION____VIEW',
  BusinessInsightDigitalReportBookingAdvancePaymentListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_BOOKING_ADVANCE_PAYMENT_LISTING____VIEW',
  BusinessInsightDigitalReportRefundableDepositListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_REFUNDABLE_DEPOSIT_LISTING____VIEW',
  BusinessInsightDigitalReportUserActivityLogReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_USER_ACTIVITY_LOG_REPORT____VIEW',
  BusinessInsightDigitalReportKeyCardIssueReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_KEY_CARD_ISSUE_REPORT____VIEW',
  HotelSettingsIgnoreView = 'HOTEL_SETTINGS_IGNORE_______VIEW',
  HotelSettingsHotelSetupView = 'HOTEL_SETTINGS_HOTEL_SETUP_______VIEW',
  HotelSettingsHotelSetupProfileView = 'HOTEL_SETTINGS_HOTEL_SETUP_PROFILE____VIEW',
  HotelSettingsHotelSetupProfileEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_PROFILE____EDIT',
  HotelSettingsHotelSetupAboutView = 'HOTEL_SETTINGS_HOTEL_SETUP_ABOUT____VIEW',
  HotelSettingsHotelSetupAboutEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_ABOUT____EDIT',
  HotelSettingsHotelSetupGalleryView = 'HOTEL_SETTINGS_HOTEL_SETUP_GALLERY____VIEW',
  HotelSettingsHotelSetupGalleryEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_GALLERY____EDIT',
  HotelSettingsHotelSetupFacilitiesView = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES____VIEW',
  HotelSettingsHotelSetupFacilitiesEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES____EDIT',
  HotelSettingsHotelSetupFacilitiesQrCodePrint = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES_QR_CODE_PRINT',
  HotelSettingsDepartmentView = 'HOTEL_SETTINGS_DEPARTMENT_______VIEW',
  HotelSettingsDepartmentCreate = 'HOTEL_SETTINGS_DEPARTMENT_______CREATE',
  HotelSettingsDepartmentIncidentalChargesView = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____VIEW',
  HotelSettingsDepartmentIncidentalChargesCreate = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____CREATE',
  HotelSettingsDepartmentIncidentalChargesActive = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____ACTIVE',
  HotelSettingsDepartmentIncidentalChargesDelete = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____DELETE',
  HotelSettingsDepartmentIncidentalChargesQrCodeView = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES_QR_CODE_VIEW',
  HotelSettingsDepartmentIncidentalChargesQrCodePrint = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES_QR_CODE_PRINT',
  HotelSettingsDepartmentReasonView = 'HOTEL_SETTINGS_DEPARTMENT_REASON____VIEW',
  HotelSettingsDepartmentReasonCreate = 'HOTEL_SETTINGS_DEPARTMENT_REASON____CREATE',
  HotelSettingsDepartmentReasonEdit = 'HOTEL_SETTINGS_DEPARTMENT_REASON____EDIT',
  HotelSettingsDepartmentReasonDelete = 'HOTEL_SETTINGS_DEPARTMENT_REASON____DELETE',
  HotelSettingsDepartmentSalesChannelView = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____VIEW',
  HotelSettingsDepartmentSalesChannelCreate = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____CREATE',
  HotelSettingsDepartmentSalesChannelEdit = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____EDIT',
  HotelSettingsDepartmentSalesChannelDelete = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____DELETE',
  HotelSettingsLocationView = 'HOTEL_SETTINGS_LOCATION_______VIEW',
  HotelSettingsLocationCreate = 'HOTEL_SETTINGS_LOCATION_______CREATE',
  HotelSettingsLocationEdit = 'HOTEL_SETTINGS_LOCATION_______EDIT',
  HotelSettingsRoomTypeView = 'HOTEL_SETTINGS_ROOM_TYPE_______VIEW',
  HotelSettingsRoomTypeCreate = 'HOTEL_SETTINGS_ROOM_TYPE_______CREATE',
  HotelSettingsRoomTypeEdit = 'HOTEL_SETTINGS_ROOM_TYPE_______EDIT',
  HotelSettingsRoomTypeActive = 'HOTEL_SETTINGS_ROOM_TYPE_______ACTIVE',
  HotelSettingsRoomView = 'HOTEL_SETTINGS_ROOM_______VIEW',
  HotelSettingsRoomListView = 'HOTEL_SETTINGS_ROOM_LIST____VIEW',
  HotelSettingsRoomListCreate = 'HOTEL_SETTINGS_ROOM_LIST____CREATE',
  HotelSettingsRoomListEdit = 'HOTEL_SETTINGS_ROOM_LIST____EDIT',
  HotelSettingsRoomListActive = 'HOTEL_SETTINGS_ROOM_LIST____ACTIVE',
  HotelSettingsRoomListDelete = 'HOTEL_SETTINGS_ROOM_LIST____DELETE',
  HotelSettingsRoomStatusView = 'HOTEL_SETTINGS_ROOM_STATUS_______VIEW',
  HotelSettingsRoomStatusEdit = 'HOTEL_SETTINGS_ROOM_STATUS_______EDIT',
  HotelSettingsRatePolicyView = 'HOTEL_SETTINGS_RATE_POLICY_______VIEW',
  HotelSettingsRatePolicySeasonSetupView = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____VIEW',
  HotelSettingsRatePolicySeasonSetupCreate = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____CREATE',
  HotelSettingsRatePolicySeasonSetupEdit = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____EDIT',
  HotelSettingsRatePolicySeasonSetupActive = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____ACTIVE',
  HotelSettingsRatePolicySeasonCalendarView = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_CALENDAR____VIEW',
  HotelSettingsRatePolicySeasonCalendarEdit = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_CALENDAR____EDIT',
  HotelSettingsRatePolicyRateElementView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____VIEW',
  HotelSettingsRatePolicyRateElementCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____CREATE',
  HotelSettingsRatePolicyRateElementEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____EDIT',
  HotelSettingsRatePolicyRateElementActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____ACTIVE',
  HotelSettingsRatePolicyAddOnView = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____VIEW',
  HotelSettingsRatePolicyAddOnCreate = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____CREATE',
  HotelSettingsRatePolicyAddOnEdit = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____EDIT',
  HotelSettingsRatePolicyAddOnActive = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____ACTIVE',
  HotelSettingsRatePolicyRateTypeView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____VIEW',
  HotelSettingsRatePolicyRateTypeCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____CREATE',
  HotelSettingsRatePolicyRateTypeEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____EDIT',
  HotelSettingsRatePolicyRateTypeActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____ACTIVE',
  HotelSettingsRatePolicyRateSetupView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____VIEW',
  HotelSettingsRatePolicyRateSetupCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____CREATE',
  HotelSettingsRatePolicyRateSetupEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____EDIT',
  HotelSettingsRatePolicyRateSetupActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____ACTIVE',
  HotelSettingsRatePolicyRateSetupValidityPeriodView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_VIEW',
  HotelSettingsRatePolicyRateSetupValidityPeriodCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_CREATE',
  HotelSettingsRatePolicyRateSetupValidityPeriodEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_EDIT',
  HotelSettingsRatePolicyRateSetupValidityPeriodActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_ACTIVE',
  HotelSettingsRoomSaleOptView = 'HOTEL_SETTINGS_ROOM_SALE_OPT_______VIEW',
  HotelSettingsRoomSaleOptEdit = 'HOTEL_SETTINGS_ROOM_SALE_OPT_______EDIT',
  HotelSettingsStandardPolicyView = 'HOTEL_SETTINGS_STANDARD_POLICY_______VIEW',
  HotelSettingsStandardPolicyHotelOperationalView = 'HOTEL_SETTINGS_STANDARD_POLICY_HOTEL_OPERATIONAL____VIEW',
  HotelSettingsStandardPolicyHotelOperationalEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_HOTEL_OPERATIONAL____EDIT',
  HotelSettingsStandardPolicySecurityDepositView = 'HOTEL_SETTINGS_STANDARD_POLICY_SECURITY_DEPOSIT____VIEW',
  HotelSettingsStandardPolicySecurityDepositEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_SECURITY_DEPOSIT____EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE____VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_EDIT',
  HotelSettingsStandardPolicyRoomStatusView = 'HOTEL_SETTINGS_STANDARD_POLICY_ROOM_STATUS____VIEW',
  HotelSettingsStandardPolicyRoomStatusEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_ROOM_STATUS____EDIT',
  HotelSettingsStandardPolicyCeillingFloorPriceView = 'HOTEL_SETTINGS_STANDARD_POLICY_CEILLING_FLOOR_PRICE____VIEW',
  HotelSettingsStandardPolicyCeillingFloorPriceEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_CEILLING_FLOOR_PRICE____EDIT',
  HotelSettingsLatecheckoutChargeView = 'HOTEL_SETTINGS_LATECHECKOUT_CHARGE_______VIEW',
  HotelSettingsLatecheckoutChargeEdit = 'HOTEL_SETTINGS_LATECHECKOUT_CHARGE_______EDIT',
  HotelSettingsSegmentView = 'HOTEL_SETTINGS_SEGMENT_______VIEW',
  HotelSettingsSegmentCreate = 'HOTEL_SETTINGS_SEGMENT_______CREATE',
  HotelSettingsSegmentEdit = 'HOTEL_SETTINGS_SEGMENT_______EDIT',
  HotelSettingsSegmentActive = 'HOTEL_SETTINGS_SEGMENT_______ACTIVE',
  HotelSettingsEdoctemplateView = 'HOTEL_SETTINGS_EDOCTEMPLATE_______VIEW',
  HotelSettingsGuestappView = 'HOTEL_SETTINGS_GUESTAPP_______VIEW',
  HotelSettingsGuestappActive = 'HOTEL_SETTINGS_GUESTAPP_______ACTIVE',
  CommonSettingsIgnoreView = 'COMMON_SETTINGS_IGNORE_______VIEW',
  CommonSettingsTaxPolicyView = 'COMMON_SETTINGS_TAX_POLICY_______VIEW',
  CommonSettingsTaxPolicyCreate = 'COMMON_SETTINGS_TAX_POLICY_______CREATE',
  CommonSettingsTaxPolicyEdit = 'COMMON_SETTINGS_TAX_POLICY_______EDIT',
  CommonSettingsTaxPolicyDelete = 'COMMON_SETTINGS_TAX_POLICY_______DELETE',
  CommonSettingsTaxSchemePolicyView = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______VIEW',
  CommonSettingsTaxSchemePolicyCreate = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______CREATE',
  CommonSettingsTaxSchemePolicyEdit = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______EDIT',
  CommonSettingsTaxSchemePolicyDelete = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______DELETE',
  CommonSettingsCompanyListingView = 'COMMON_SETTINGS_COMPANY_LISTING_______VIEW',
  CommonSettingsCompanyListingCreate = 'COMMON_SETTINGS_COMPANY_LISTING_______CREATE',
  CommonSettingsCompanyListingEdit = 'COMMON_SETTINGS_COMPANY_LISTING_______EDIT',
  CommonSettingsCompanyListingActive = 'COMMON_SETTINGS_COMPANY_LISTING_______ACTIVE',
  ConsolidatedEInvoiceIgnoreView = 'CONSOLIDATED_E_INVOICE_IGNORE_______VIEW',
  ConsolidatedEInvoiceIgnoreCreate = 'CONSOLIDATED_E_INVOICE_IGNORE_______CREATE',
  ConsolidatedEInvoiceIgnoreRead = 'CONSOLIDATED_E_INVOICE_IGNORE_______READ',
  CityLedgerIgnoreView = 'CITY_LEDGER_IGNORE_______VIEW',
  CityLedgerScheduleBillingView = 'CITY_LEDGER_SCHEDULE_BILLING_______VIEW',
  CityLedgerScheduleBillingPost = 'CITY_LEDGER_SCHEDULE_BILLING_______POST',
  CityLedgerAccountTransactionView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_______VIEW',
  CityLedgerAccountTransactionInvoiceView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____VIEW',
  CityLedgerAccountTransactionInvoiceCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____CREATE',
  CityLedgerAccountTransactionInvoiceEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____EDIT',
  CityLedgerAccountTransactionInvoiceDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____DRAFT',
  CityLedgerAccountTransactionInvoiceSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____SUBMIT',
  CityLedgerAccountTransactionInvoiceAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____ALLOCATE',
  CityLedgerAccountTransactionInvoicePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____PRINT',
  CityLedgerAccountTransactionGroupInvoiceView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____VIEW',
  CityLedgerAccountTransactionGroupInvoiceCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____CREATE',
  CityLedgerAccountTransactionGroupInvoiceEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____EDIT',
  CityLedgerAccountTransactionGroupInvoiceDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____DRAFT',
  CityLedgerAccountTransactionGroupInvoiceSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____SUBMIT',
  CityLedgerAccountTransactionGroupInvoiceAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____ALLOCATE',
  CityLedgerAccountTransactionGroupInvoicePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____PRINT',
  CityLedgerAccountTransactionDebitNoteView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____VIEW',
  CityLedgerAccountTransactionDebitNoteCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____CREATE',
  CityLedgerAccountTransactionDebitNoteEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____EDIT',
  CityLedgerAccountTransactionDebitNoteDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____DRAFT',
  CityLedgerAccountTransactionDebitNoteSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____SUBMIT',
  CityLedgerAccountTransactionDebitNoteAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____ALLOCATE',
  CityLedgerAccountTransactionDebitNotePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____PRINT',
  CityLedgerAccountTransactionCreditNoteView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____VIEW',
  CityLedgerAccountTransactionCreditNoteCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____CREATE',
  CityLedgerAccountTransactionCreditNoteEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____EDIT',
  CityLedgerAccountTransactionCreditNoteDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____DRAFT',
  CityLedgerAccountTransactionCreditNoteSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____SUBMIT',
  CityLedgerAccountTransactionCreditNoteAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____ALLOCATE',
  CityLedgerAccountTransactionCreditNotePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____PRINT',
  CityLedgerAccountTransactionOfficialReceiptView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____VIEW',
  CityLedgerAccountTransactionOfficialReceiptCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____CREATE',
  CityLedgerAccountTransactionOfficialReceiptEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____EDIT',
  CityLedgerAccountTransactionOfficialReceiptDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____DRAFT',
  CityLedgerAccountTransactionOfficialReceiptSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____SUBMIT',
  CityLedgerAccountTransactionOfficialReceiptAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____ALLOCATE',
  CityLedgerAccountTransactionOfficialReceiptPrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____PRINT',
  CityLedgerAccountTransactionRefundView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____VIEW',
  CityLedgerAccountTransactionRefundCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____CREATE',
  CityLedgerAccountTransactionRefundEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____EDIT',
  CityLedgerAccountTransactionRefundDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____DRAFT',
  CityLedgerAccountTransactionRefundSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____SUBMIT',
  CityLedgerAccountTransactionRefundAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____ALLOCATE',
  CityLedgerAccountTransactionRefundPrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____PRINT',
  CityLedgerSetupView = 'CITY_LEDGER_SETUP_______VIEW',
  CityLedgerSetupDebtorTypeView = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____VIEW',
  CityLedgerSetupDebtorTypeCreate = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____CREATE',
  CityLedgerSetupDebtorTypeEdit = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____EDIT',
  CityLedgerSetupDebtorTypeDelete = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____DELETE',
  CityLedgerSetupDebtorProfileView = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____VIEW',
  CityLedgerSetupDebtorProfileCreate = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____CREATE',
  CityLedgerSetupDebtorProfileEdit = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____EDIT',
  CityLedgerSetupDebtorProfileActive = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____ACTIVE',
  CityLedgerCreditControlView = 'CITY_LEDGER_CREDIT_CONTROL_______VIEW',
  CityLedgerStatementOfAccView = 'CITY_LEDGER_STATEMENT_OF_ACC_______VIEW',
  CityLedgerStatementOfAccShare = 'CITY_LEDGER_STATEMENT_OF_ACC_______SHARE',
  CityLedgerAccountTransactionInvoiceCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____CANCEL',
  CityLedgerAccountTransactionGroupInvoiceCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____CANCEL',
  CityLedgerAccountTransactionDebitNoteCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____CANCEL',
  CityLedgerAccountTransactionCreditNoteCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____CANCEL',
  CityLedgerAccountTransactionOfficialReceiptCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____CANCEL',
  CityLedgerAccountTransactionRefundCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____CANCEL',
  AcctJournalIgnoreView = 'ACCT_JOURNAL_IGNORE_______VIEW',
  AcctJournalAcctMappingHotelGlView = 'ACCT_JOURNAL_ACCT_MAPPING_HOTEL_GL____VIEW',
  AcctJournalAcctMappingHotelGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_HOTEL_GL____EDIT',
  AcctJournalAcctMappingTaxGlView = 'ACCT_JOURNAL_ACCT_MAPPING_TAX_GL____VIEW',
  AcctJournalAcctMappingTaxGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_TAX_GL____EDIT',
  AcctJournalAcctMappingCityGlView = 'ACCT_JOURNAL_ACCT_MAPPING_CITY_GL____VIEW',
  AcctJournalAcctMappingCityGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_CITY_GL____EDIT',
  AcctJournalAcctMappingBankAcctView = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____VIEW',
  AcctJournalAcctMappingBankAcctCreate = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____CREATE',
  AcctJournalAcctMappingBankAcctEdit = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____EDIT',
  AcctJournalAcctMappingBankAcctDelete = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____DELETE',
  AcctJournalAcctMappingBankAcctActive = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____ACTIVE',
  AcctJournalAcctMappingStatisticGlView = 'ACCT_JOURNAL_ACCT_MAPPING_STATISTIC_GL____VIEW',
  AcctJournalAcctMappingStatisticGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_STATISTIC_GL____EDIT',
  AcctJournalGlJournalView = 'ACCT_JOURNAL_GL_JOURNAL_______VIEW',
  AcctJournalGlJournalCreate = 'ACCT_JOURNAL_GL_JOURNAL_______CREATE',
  AcctJournalGlJournalDownload = 'ACCT_JOURNAL_GL_JOURNAL_______DOWNLOAD',
  AcctJournalGlJournalShare = 'ACCT_JOURNAL_GL_JOURNAL_______SHARE',
  AcctJournalGlJournalDetailView = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____VIEW',
  AcctJournalGlJournalDetailDownload = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalGlJournalDetailShare = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____SHARE',
  AcctJournalGlJournalDetailIndividualView = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_VIEW',
  AcctJournalGlJournalDetailIndividualDownload = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_DOWNLOAD',
  AcctJournalGlJournalDetailIndividualShare = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_SHARE',
  AcctJournalCbJournalView = 'ACCT_JOURNAL_CB_JOURNAL_______VIEW',
  AcctJournalCbJournalCreate = 'ACCT_JOURNAL_CB_JOURNAL_______CREATE',
  AcctJournalCbJournalDownload = 'ACCT_JOURNAL_CB_JOURNAL_______DOWNLOAD',
  AcctJournalCbJournalShare = 'ACCT_JOURNAL_CB_JOURNAL_______SHARE',
  AcctJournalCbJournalDetailView = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____VIEW',
  AcctJournalCbJournalDetailDownload = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalCbJournalDetailShare = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____SHARE',
  AcctJournalCbJournalDetailIndividualView = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_VIEW',
  AcctJournalCbJournalDetailIndividualDownload = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_DOWNLOAD',
  AcctJournalCbJournalDetailIndividualShare = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_SHARE',
  AcctJournalStatisticJournalView = 'ACCT_JOURNAL_STATISTIC_JOURNAL_______VIEW',
  AcctJournalStatisticJournalCreate = 'ACCT_JOURNAL_STATISTIC_JOURNAL_______CREATE',
  AcctJournalStatisticJournalDownload = 'ACCT_JOURNAL_STATISTIC_JOURNAL_______DOWNLOAD',
  AcctJournalStatisticJournalDelete = 'ACCT_JOURNAL_STATISTIC_JOURNAL_______DELETE',
  AcctJournalStatisticJournalShare = 'ACCT_JOURNAL_STATISTIC_JOURNAL_______SHARE',
  AcctJournalStatisticJournalDetailView = 'ACCT_JOURNAL_STATISTIC_JOURNAL_DETAIL____VIEW',
  AcctJournalStatisticJournalDetailDownload = 'ACCT_JOURNAL_STATISTIC_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalStatisticJournalDetailShare = 'ACCT_JOURNAL_STATISTIC_JOURNAL_DETAIL____SHARE',
  AcctJournalAcctMappingView = 'ACCT_JOURNAL_ACCT_MAPPING_______VIEW',
  FrontDeskInhouseBillingInstructionView = 'FRONT_DESK_INHOUSE_BILLING_INSTRUCTION____VIEW',
  FrontDeskInhouseBillingInstructionCreate = 'FRONT_DESK_INHOUSE_BILLING_INSTRUCTION____CREATE',
  FrontDeskCheckOutBillingInstructionView = 'FRONT_DESK_CHECK_OUT_BILLING_INSTRUCTION____VIEW',
  FrontDeskCheckOutBillingInstructionCreate = 'FRONT_DESK_CHECK_OUT_BILLING_INSTRUCTION____CREATE',
  FrontDeskCheckInBillingInstructionView = 'FRONT_DESK_CHECK_IN_BILLING_INSTRUCTION____VIEW',
  FrontDeskCheckInBillingInstructionCreate = 'FRONT_DESK_CHECK_IN_BILLING_INSTRUCTION____CREATE',
  SystemAdminIgnoreView = 'SYSTEM_ADMIN_IGNORE_______VIEW',
  SystemAdminUserView = 'SYSTEM_ADMIN_USER____VIEW',
  SystemAdminRolesView = 'SYSTEM_ADMIN_ROLES____VIEW',
  SystemAdminHotelView = 'SYSTEM_ADMIN_HOTEL____VIEW'
}

export type HotelQ3RevenueAcctJson = {
  __typename?: 'HotelQ3RevenueAcctJson';
  HTLRevenueAcct?: Maybe<Array<Q3RevenueAcctJson>>;
  RoundingRevenueAcct?: Maybe<Array<Q3RevenueAcctJson>>;
  GLRevenueAcct?: Maybe<Scalars['JSON']>;
};

export type HotelQ3RevenueAcctJsonInput = {
  HTLRevenueAcct?: Maybe<Array<Q3RevenueAcctJsonInput>>;
  RoundingRevenueAcct?: Maybe<Array<Q3RevenueAcctJsonInput>>;
  GLRevenueAcct?: Maybe<Scalars['JSON']>;
};

export type HotelRatingEntity = InterfaceBase & {
  __typename?: 'HotelRatingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  TravelAs?: Maybe<Scalars['String']>;
  Cleanliness?: Maybe<Scalars['Float']>;
  Facilities?: Maybe<Scalars['Float']>;
  Location?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Service?: Maybe<Scalars['Float']>;
  OverallExperience?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  ReviewDate: Scalars['DateTime'];
  ReviewCount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
};

export type HotelRatingInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  TravelAs?: Maybe<Scalars['String']>;
  Cleanliness?: Maybe<Scalars['Float']>;
  Facilities?: Maybe<Scalars['Float']>;
  Location?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Service?: Maybe<Scalars['Float']>;
  OverallExperience?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  ReviewDate?: Maybe<Scalars['DateTime']>;
  ReviewCount?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelStatisticRespond = {
  __typename?: 'HotelStatisticRespond';
  TotalRoomRevenue?: Maybe<Scalars['Float']>;
  TotalFnBRevenue?: Maybe<Scalars['Float']>;
  TotalOtherRevenue?: Maybe<Scalars['Float']>;
  TotalOccupancyRate?: Maybe<Scalars['Float']>;
  TotalArr?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  OOOOOI?: Maybe<Scalars['Float']>;
  TotalRentableRoom?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalComplimentary?: Maybe<Scalars['Float']>;
  TotalHouseUse?: Maybe<Scalars['Float']>;
  TotalDayUse?: Maybe<Scalars['Float']>;
  TotalPax?: Maybe<Scalars['Float']>;
};

export type HotelTaxOutput = {
  __typename?: 'HotelTaxOutput';
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['String']>;
  SeqNo?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
};

export type HotelTaxPolicyEntity = InterfaceBase & {
  __typename?: 'HotelTaxPolicyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TaxType?: Maybe<TaxType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  TTxExemptedReason?: Maybe<Scalars['String']>;
  TTxNonExemptedReason?: Maybe<Scalars['String']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  TTxExempted?: Maybe<ReasonEntity>;
  TTxNonExempted?: Maybe<ReasonEntity>;
};

export type HotelTaxPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TaxType?: Maybe<TaxType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  TTxExemptedReason?: Maybe<Scalars['String']>;
  TTxNonExemptedReason?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelVendorInfoEntity = InterfaceTrx & {
  __typename?: 'HotelVendorInfoEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  VendorName: Scalars['String'];
  VendorInfo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
};

export type HotelVendorInfoInput = {
  HotelID: Scalars['String'];
  VendorName?: Maybe<Scalars['String']>;
  VendorInfo?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type HotelXOtpEntity = InterfaceTrx & {
  __typename?: 'HotelXOtpEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Expiry?: Maybe<Scalars['DateTime']>;
  IPAddress?: Maybe<Scalars['String']>;
};

export type HouseKeepingLogEntity = InterfaceTrx & {
  __typename?: 'HouseKeepingLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  Status: Scalars['String'];
  CleanedBy?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
};

export type HouseKeepingLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  Status: Scalars['String'];
  CleanedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type HouseLimitInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
};

export type HouseUseOutput = {
  __typename?: 'HouseUseOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IncidentalChargeEntity = InterfaceBase & {
  __typename?: 'IncidentalChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  IsNegative?: Maybe<Scalars['Boolean']>;
  IsPackage?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  IsRateElement?: Maybe<Scalars['Boolean']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  EInvClassification?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
  Gallery?: Maybe<GalleryEntity>;
  Package?: Maybe<Array<PackagesEntity>>;
  isEInvIntegrated?: Maybe<Scalars['Boolean']>;
  MSICCode?: Maybe<Scalars['String']>;
};

export type IncidentalChargeInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  IsNegative?: Maybe<Scalars['Boolean']>;
  IsPackage?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsRateElement?: Maybe<Scalars['Boolean']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  EInvClassification?: Maybe<Scalars['String']>;
  isEInvIntegrated?: Maybe<Scalars['Boolean']>;
  MSICCode?: Maybe<Scalars['String']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
};

export type InputItem = {
  LocationID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID: Array<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type InputReg = {
  RegID?: Maybe<Scalars['String']>;
};

export type InterestEntity = InterfaceTrx & {
  __typename?: 'InterestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  OverDueDays: Scalars['Float'];
  OverDueAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  TrxStatus: ArTransactionStatus;
  IsWaived: Scalars['Boolean'];
};

export type InterestEntityInput = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  LedgerID: Scalars['String'];
  OverDueDays: Scalars['Float'];
  OverDueAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  TrxStatus?: Maybe<ArTransactionStatus>;
  IsWaived: Scalars['Boolean'];
};

export type InterestResponse = {
  __typename?: 'InterestResponse';
  TotalTrxAmt: Scalars['Float'];
  TotalInterestAmt: Scalars['Float'];
};

export type InterfaceBase = {
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceTaxTrx = InterfaceTrx & {
  __typename?: 'InterfaceTaxTrx';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
};

export type InterfaceTrx = {
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceTrxPabx = {
  rowid: Scalars['String'];
};

export type InvoiceEntity = InterfaceTrx & {
  __typename?: 'InvoiceEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  GroupDocumentNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type InvoiceEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
};

export type InvoiceTrxEntity = InterfaceTrx & {
  __typename?: 'InvoiceTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  InvoiceID: Scalars['String'];
  InvoiceTrxTax?: Maybe<Array<InvoiceTrxTaxEntity>>;
};

export type InvoiceTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type InvoiceTrxTaxEntity = InterfaceTrx & {
  __typename?: 'InvoiceTrxTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  InvoiceTrxID: Scalars['String'];
  InvoiceTrx?: Maybe<InvoiceTrxEntity>;
};

export enum InvoiceType {
  Individual = 'Individual',
  Company = 'Company',
  ForeignBuyer = 'ForeignBuyer',
  Government = 'Government'
}

export type Item = {
  __typename?: 'item';
  checkoutId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type KeyCardEntity = {
  __typename?: 'KeyCardEntity';
  rowid: Scalars['Float'];
  s_data?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
  audit_date?: Maybe<Scalars['DateTime']>;
};

export type KeyCardInfoEntity = {
  __typename?: 'KeyCardInfoEntity';
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  RegistrationID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  IssueType?: Maybe<Scalars['String']>;
  EncoderNo?: Maybe<Scalars['String']>;
};

export type KeyCardInput = {
  s_data?: Maybe<Scalars['String']>;
  Operation?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['String']>;
  CheckOutDate?: Maybe<Scalars['String']>;
  encoder?: Maybe<Scalars['String']>;
  lift?: Maybe<Scalars['String']>;
  commonDoor?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
  audit_date?: Maybe<Scalars['DateTime']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
};

export type KiwireLogEntity = InterfaceTrx & {
  __typename?: 'KiwireLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type LateCheckOutChargeEntity = InterfaceBase & {
  __typename?: 'LateCheckOutChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type LateCheckOutChargeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LedgerIDsInput = {
  BillSchedulesSplit?: Maybe<Array<Scalars['Float']>>;
  BillSchedulesDescription?: Maybe<Array<Scalars['String']>>;
  BillSchedules?: Maybe<Array<Scalars['String']>>;
  BillSchedulesDate?: Maybe<Array<Scalars['DateTime']>>;
  RoomLedgersSplit?: Maybe<Array<Scalars['Float']>>;
  RoomLedgersDescription?: Maybe<Array<Scalars['String']>>;
  RoomLedgers?: Maybe<Array<Scalars['String']>>;
  RoomLedgersDate?: Maybe<Array<Scalars['DateTime']>>;
  BillLedgerIDs?: Maybe<Array<Scalars['String']>>;
  TaxLedgerIDs?: Maybe<Array<Scalars['String']>>;
  PaymentIDs?: Maybe<Array<Scalars['String']>>;
  AdvancePaymentIDs?: Maybe<Array<Scalars['String']>>;
  DepositLedgerIDs?: Maybe<Array<Scalars['String']>>;
};

export type LedgerInfoOutput = {
  __typename?: 'LedgerInfoOutput';
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
};

export type LedgerOutput = {
  __typename?: 'LedgerOutput';
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type ListEntRoleUsrAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  hotelID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LocationEntity = InterfaceBase & {
  __typename?: 'LocationEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<Array<RoomEntity>>;
  GalleryID?: Maybe<Scalars['String']>;
  Gallery?: Maybe<GalleryEntity>;
};


export type LocationEntityRoomArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
};

export type LocationFloorPlanEntity = InterfaceBase & {
  __typename?: 'LocationFloorPlanEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomID: Scalars['String'];
  IsHotel?: Maybe<Scalars['Boolean']>;
  IsPreCheckin?: Maybe<Scalars['Boolean']>;
  RoomTypeID: Scalars['String'];
  Coordinates: Scalars['String'];
  RoomNo: Scalars['String'];
  Room?: Maybe<RoomEntity>;
};

export type LocationFloorPlanInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  LocationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  IsHotel?: Maybe<Scalars['Boolean']>;
  IsPreCheckin?: Maybe<Scalars['Boolean']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Coordinates?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type ManagerReportOutput = {
  __typename?: 'ManagerReportOutput';
  SegmentReport?: Maybe<Array<SegmentReportOutput>>;
  SourceReport?: Maybe<Array<SourceReportOutput>>;
  CountryReport?: Maybe<Array<CountryReportOutput>>;
  DepartmentReport?: Maybe<Array<DepartmentReportOutput>>;
  TaxReport?: Maybe<Array<TaxReportOutput>>;
  PaymentReport?: Maybe<Array<PaymentOutput>>;
  BookingCancellation?: Maybe<Array<BookingCancellation>>;
  VIPInHouse?: Maybe<Array<VipInHouseOutput>>;
  GroupInHouse?: Maybe<Array<GroupInHouseOutput>>;
  TomorrowGroup?: Maybe<Array<TmrGroupOutput>>;
  HouseUseRoom?: Maybe<Array<HouseUseOutput>>;
  CompRoom?: Maybe<Array<CompOutput>>;
  ExtendStay?: Maybe<Array<ExtendStayOutPut>>;
  ThreeDayForcast?: Maybe<Array<ForecastOutPut>>;
};

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Seperated = 'Seperated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export type MeasurementEntity = InterfaceBase & {
  __typename?: 'MeasurementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Measurement: Scalars['String'];
  Size?: Maybe<Scalars['Float']>;
};

export type MeasurementInput = {
  ID: Scalars['String'];
  Type: Scalars['String'];
  Measurement: Scalars['String'];
  Size?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum MeasureType {
  SquareMetre = 'Square_Metre',
  FootSquare = 'Foot_Square'
}

export type Mutation = {
  __typename?: 'Mutation';
  HotelTaxPolicyInsert: Scalars['Boolean'];
  HotelTaxPolicyUpdate: Scalars['Boolean'];
  HotelTaxPolicyDelete: Scalars['Boolean'];
  HLSBookingNotification: Scalars['Boolean'];
  UploadImage: Array<Scalars['String']>;
  DeleteImage: Scalars['Boolean'];
  SpecialReqUpdate: Scalars['Boolean'];
  AssignRoom: Scalars['Boolean'];
  MultiCheckIn: Scalars['Boolean'];
  CheckIn: Scalars['Boolean'];
  TransferRoom: Scalars['Boolean'];
  GuestInsert: Scalars['Boolean'];
  DepositInsert: Scalars['Boolean'];
  RefundDeposit: Scalars['Boolean'];
  CloseFolio: Scalars['Boolean'];
  VoidLedger: Scalars['Boolean'];
  RegistrationSignatureUpdate: RegistrationEntity;
  VoidBillLedger: Scalars['Boolean'];
  PosV7VoidBillLedger: Scalars['Boolean'];
  RefundPayment?: Maybe<CheckOut>;
  FrontDeskPaymentInsert: PaymentEntity;
  CheckOut: Scalars['Boolean'];
  WalkIngBookingInsert: Scalars['JSON'];
  LateCheckOutInsert: Scalars['JSON'];
  ExtendStayInsert: Scalars['JSON'];
  ShortenStayInsert: Scalars['String'];
  RoomUpGradeInsert: Scalars['JSON'];
  FolioDelete: Scalars['Boolean'];
  NonGuestFolioInsert: Scalars['Boolean'];
  SuspendFolioInsert: Scalars['Boolean'];
  NonGuestFolioUpdate: Scalars['Boolean'];
  NonGuestVoidLedger: Scalars['Boolean'];
  NonGuestPaymentInsert: PaymentEntity;
  NonGuestCloseFolio: Scalars['Boolean'];
  NonGuestTransferFolio: Scalars['Boolean'];
  SplitPaymentV2: Scalars['Boolean'];
  TransferPayment: Scalars['Boolean'];
  RegistrationSign: Scalars['Boolean'];
  PaymentUpdate: Scalars['Boolean'];
  CancelCheckIn: Scalars['Boolean'];
  FolioBeneficiaryNameUpdate: Scalars['Boolean'];
  FDReinstateBooking: Scalars['Boolean'];
  LockUserProcess: Scalars['JSON'];
  HotelNotificationUpdate: Scalars['Boolean'];
  GuestRegister: Scalars['Boolean'];
  GuestLogin: GuestProfileEntity;
  GuestAppAuthGenerator: GuestProfileEntity;
  GuestChangePassword: GuestProfileEntity;
  GuestForgotPassword: Scalars['Boolean'];
  EmailResetPassword: Scalars['Boolean'];
  GuestResetPassword: Scalars['Boolean'];
  OnlineBookingInsert: Scalars['JSON'];
  GuestRoomServiceInsert: Scalars['JSON'];
  HotelRatingInsert: HotelRatingEntity;
  QrScannerInsert: Scalars['String'];
  PromotionUpdate: Scalars['Boolean'];
  PromotionInsert: PromotionEntity;
  PromotionActiveUpdate: Scalars['Boolean'];
  PromotionDetailUpdate: Scalars['Boolean'];
  PromotionDetailInsert: PromotionDetailEntity;
  EmailCampignUpdate: Scalars['Boolean'];
  EmailCampignInsert: EmailCampignEntity;
  EmailCampaign: Scalars['Boolean'];
  PromotionDelete: Scalars['Boolean'];
  EmailDelete: Scalars['Boolean'];
  GuestReviewActiveUpdate: Scalars['Boolean'];
  BookingInsert: Scalars['String'];
  GuestAppRoomTypeDelete: Scalars['Boolean'];
  BookingRoomTypeUpdate: Scalars['Boolean'];
  HouseLimitUpdate: Scalars['Boolean'];
  BookingUpdate: Scalars['Boolean'];
  CancelBooking: Scalars['Boolean'];
  AdvancePaymentInsert: AdvancePaymentEntity;
  AdvancePaymentRefund: Scalars['Boolean'];
  AdvancePaymentUpdate: Scalars['Boolean'];
  ReinstateBooking: Scalars['Boolean'];
  ImageUpload: GalleryEntity;
  ManualNightAudit: Scalars['Boolean'];
  AvailabilityUpdate: Scalars['Boolean'];
  AtestInsert: Scalars['Boolean'];
  walkInRoomAssigned: Scalars['Boolean'];
  BookingRoomAssignment: Scalars['Boolean'];
  BillScheduleAdjustment: Scalars['Boolean'];
  NewBookingInfoUpdate: Scalars['Boolean'];
  NewBookingRemark: Scalars['Boolean'];
  BookingUpdateV2: Scalars['Boolean'];
  CancelRoom: Scalars['Boolean'];
  ReinstateRoom: Scalars['Boolean'];
  WaitinglistNewAPI: Scalars['Boolean'];
  BookingIncChargesCancel: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  MenuOptionUpdate: Scalars['Boolean'];
  NotificationInsert: Scalars['Boolean'];
  OnlinePayment?: Maybe<CheckOut>;
  sendRegisterOTP: Scalars['Boolean'];
  verifyOTP: Scalars['Boolean'];
  createUser: UserEntity;
  createPassword: Scalars['Boolean'];
  updateUser: UserEntity;
  updateProfile: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: UserEntity;
  logout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  unLockUser: Scalars['Boolean'];
  reSendActivationEmail: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  createSuperUser: UserEntity;
  InsertInterestAmt: Scalars['Boolean'];
  testapi: Scalars['Boolean'];
  UpdateInterestAmt: Scalars['Float'];
  ManualNightAuditforAllHotel: Scalars['Boolean'];
  genHashPassword: Scalars['JSON'];
  RegenerateBillSchedule: Scalars['Boolean'];
  RegeneratePostTaxes: Scalars['Boolean'];
  ManualHotelNA: Scalars['Boolean'];
  ManualNARegistrationInsert: Scalars['String'];
  ManualAvailabilityUpdate: Scalars['String'];
  CMSManualAvailUpdate: Scalars['Boolean'];
  CutOffBilling: Scalars['String'];
  KeyCardInsert: Scalars['Boolean'];
  DebitNoteInsert: DebitNoteEntity;
  DebitNoteUpdate: Scalars['Boolean'];
  DebitNoteCancel: Scalars['Boolean'];
  AllocateDebitNote: Scalars['Boolean'];
  DebitNoteDelete: Scalars['Boolean'];
  CreditNoteInsert: CreditNoteEntity;
  CreditNoteUpdate: Scalars['Boolean'];
  CreditNoteCancel: Scalars['Boolean'];
  AllocateCreditNote: Scalars['Boolean'];
  CreditNoteDelete: Scalars['Boolean'];
  RefundInsert: RefundEntity;
  RefundUpdate: Scalars['Boolean'];
  RefundCancel: Scalars['Boolean'];
  AllocateRefund: Scalars['Boolean'];
  RefundDelete: Scalars['Boolean'];
  InvoiceInsert: InvoiceEntity;
  InvoiceUpdate: Scalars['Boolean'];
  InvoiceCancel: Scalars['Boolean'];
  AllocateInvoice: Scalars['Boolean'];
  InvoiceDelete: Scalars['Boolean'];
  OfficialReceiptInsert: OfficialReceiptEntity;
  OfficialReceiptUpdate: Scalars['Boolean'];
  OfficialReceiptCancel: Scalars['Boolean'];
  AllocateOfficialReceipt: Scalars['Boolean'];
  OfficialReceiptDelete: Scalars['Boolean'];
  ReminderInsert: ReminderEntity;
  ReminderUpdate: Scalars['Boolean'];
  ReminderDelete: Scalars['Boolean'];
  asyncInterestInsert: InterestEntity;
  InterestUpdate: Scalars['Boolean'];
  InterestDelete: Scalars['Boolean'];
  DocumentNumberingSetupInsert: Scalars['Boolean'];
  DocumentNumberingUpdate: Scalars['Boolean'];
  DebtorTypeInsert: DebtorTypeEntity;
  DebtorTypeUpdate: Scalars['Boolean'];
  DebtorTypeDelete: Scalars['Boolean'];
  PostScheduleBilling: Scalars['Boolean'];
  RoomSaleOptimizationUpdate: Scalars['Boolean'];
  MigrationAPI: Scalars['Boolean'];
  BookingAttachmentInsert: Scalars['Boolean'];
  BookingAttachmentUpdate: Scalars['Boolean'];
  BookingAttachmentDelete: Scalars['Boolean'];
  EmailSend: Scalars['Boolean'];
  BISharedGAlleryInsert: SharedGalleryEntity;
  ChooseRoomAllocationInsert: Scalars['Boolean'];
  ChooseRoomAllocationUpdate: Scalars['Boolean'];
  ChooseRoomAllocationDelete: Scalars['Boolean'];
  CompanyInsert: CompanyEntity;
  CompanyUpdate: Scalars['Boolean'];
  CompanyUpdateStatus: Scalars['Boolean'];
  CorporateInsert: DebtorEntity;
  GovermentTestingUpdate: Scalars['Boolean'];
  CorporateUpdate: Scalars['Boolean'];
  CorporateContractUpdate: Scalars['Boolean'];
  CorporateContractInsert: ContractEntity;
  CorporateDelete: Scalars['Boolean'];
  ActivityInsert: Scalars['Boolean'];
  ActivityUpdate: Scalars['Boolean'];
  GenerateConsolidatedDocOld: Scalars['Boolean'];
  EventScheduleBilling: Scalars['Boolean'];
  EventVoidBillLedger: Scalars['Boolean'];
  EventBillLedgerInsert: Scalars['Boolean'];
  RegenerateNARegistration: Scalars['JSON'];
  GuestUpdate: Scalars['Boolean'];
  GuestProfileInsert: RegistrationEntity;
  GuestContactUpdate: Scalars['Boolean'];
  GuestProfileDelete: Scalars['Boolean'];
  NoOfPaxUpdate: Scalars['Boolean'];
  GuestMovementInsert: Scalars['Boolean'];
  CBPost: Scalars['JSON'];
  GLPost: GlPostResponse;
  PaymentTypeGLInsert: Scalars['Boolean'];
  BillLedgerUpdate: Scalars['Boolean'];
  BillLedgerCancel: Scalars['Boolean'];
  TaxLedgerInsert: Array<Scalars['String']>;
  BillLedgerInsert: Scalars['Boolean'];
  PosV7BillLedgerInsert: Scalars['Boolean'];
  FolioInsert: Scalars['Boolean'];
  RoomServicePaymentUpdate: Scalars['Boolean'];
  OnlinePaymentRoomServices: OnlineItemEntity;
  FolioAdjustmentInsert: PaymentEntity;
  ChangePayment: Scalars['Boolean'];
  POSChargeToCityLedger: Scalars['Boolean'];
  POSV7ScheduleBilling: Scalars['Boolean'];
  POSV7DailyRevenue: Scalars['Boolean'];
  MenuXDailyRevenue: Scalars['Boolean'];
  StatisticExportDelete: Scalars['Boolean'];
  StatisticJournalPost: Scalars['JSON'];
  HuaweiOCR?: Maybe<Scalars['JSON']>;
  Innov8OCR?: Maybe<Scalars['JSON']>;
  PackageRedemptionInsert: Scalars['Boolean'];
  PreRegPaymentInsert: Scalars['JSON'];
  UpdateRoomAssignment: Scalars['Boolean'];
  ExpressCheckinConfirmation: Scalars['Boolean'];
  CheckInInsertV2: Scalars['Boolean'];
  PreCheckInInsert: Scalars['Boolean'];
  Q3DocumentInsert: Scalars['Boolean'];
  RevenueMappingInsert: Scalars['Boolean'];
  SegmentMappingInsert: Scalars['Boolean'];
  StatisticMappingInsert: Scalars['Boolean'];
  BlockRoomLogInsert: BlockRoomLogEntity;
  BlockRoomLogInsertV2: Array<BlockRoomLogEntity>;
  ReleaseRoomLogUpdateV2: Scalars['Boolean'];
  BlockRoomLogUpdate: Scalars['Boolean'];
  HouseKeepingLogInsert: Scalars['Boolean'];
  HousekeepingServiceRequestInsert: ServiceRequestEntity;
  HousekeepingServiceRequestUpdate: Scalars['Boolean'];
  HousekeepingBillLedgerUpdate: Scalars['Boolean'];
  DepartmentInsert: Scalars['Boolean'];
  DepartmentUpdate: Scalars['Boolean'];
  IncidentalChargesUpdate: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  EDocInsert: Scalars['Boolean'];
  EDocTemplateUpdate: Scalars['Boolean'];
  ConfirmationTemplateDelete: Scalars['Boolean'];
  AccountMappingGLInsert: Scalars['Boolean'];
  CityLedgerGLInsert: Scalars['Boolean'];
  AccountMappingTaxGLInsert: Scalars['Boolean'];
  CityLedgerTaxGLInsert: Scalars['Boolean'];
  HotelStatisticMappingInsert: Scalars['Boolean'];
  BankAccountUpdate: Scalars['Boolean'];
  BankAccountInsert: BankAccountEntity;
  BankAccountDelete: Scalars['Boolean'];
  BankAccountInActive: Scalars['Boolean'];
  FacilitiesInactiveDelete: Scalars['Boolean'];
  GuestAppSetupUpdate: Scalars['Boolean'];
  InvoiceRemark: Scalars['Boolean'];
  HotelUpdate: Scalars['Boolean'];
  HotelInsert: HotelEntity;
  HotelContactUpdate: Scalars['Boolean'];
  HotelContactInsert: ContactEntity;
  HotelFacilityUpdate: Scalars['Boolean'];
  HotelFacilityInsert: FacilityEntity;
  HotelNewsUpdate: Scalars['Boolean'];
  HotelNewsInsert: NewsEntity;
  IncidentalChargeInsert: Scalars['Boolean'];
  IncidentalChargeUpdate: Scalars['Boolean'];
  IncidentalChargeDelete: Scalars['Boolean'];
  LateCheckOutChargeInsert: Scalars['Boolean'];
  FloorPlanUpdate: Scalars['Boolean'];
  FloorPlanReset: Scalars['Boolean'];
  LocationInsert: LocationEntity;
  LocationUpdate: Scalars['Boolean'];
  DisplaySequenceUpdate: Scalars['Boolean'];
  LocationDelete: Scalars['Boolean'];
  RateElementInsert: RateElementEntity;
  RateElementUpdate: Scalars['Boolean'];
  RateAddOnInsert: RateAddOnEntity;
  RateAddOnUpdate: Scalars['Boolean'];
  RateTypeInsert: RateTypeEntity;
  RateTypeUpdate: Scalars['Boolean'];
  RateInsert: RateEntity;
  RateUpdate: Scalars['Boolean'];
  RateEffectiveInsert: Scalars['Boolean'];
  RateEffectiveUpdate: Scalars['Boolean'];
  CMSRateSync: Scalars['Boolean'];
  RateEffectiveStatusUpdate: Scalars['Boolean'];
  RateAdjustmentAuditInsert: Scalars['Boolean'];
  RegenBSQueueInsert: Scalars['Boolean'];
  RegenBSQueueConfirm: Scalars['Boolean'];
  SeasonInsert: SeasonEntity;
  SeasonUpdate: Scalars['Boolean'];
  SeasonCalendarUpdate: Scalars['Boolean'];
  RatePolicyInsert: RatePolicyEntity;
  RatePolicyUpdate: Scalars['Boolean'];
  PackageInsert: Scalars['Boolean'];
  PackageUpdate: Scalars['Boolean'];
  RatePolicyDelete: Scalars['Boolean'];
  ReasonInsert: Scalars['Boolean'];
  ReasonUpdate: Scalars['Boolean'];
  ReasonDelete: Scalars['Boolean'];
  RoomInsert: RoomEntity;
  RoomUpdate: Scalars['Boolean'];
  RoomDelete: Scalars['Boolean'];
  RoomStatusInsert: Scalars['Boolean'];
  RoomTypeUpdate: Scalars['Boolean'];
  RoomTypeInsert: RoomTypeEntity;
  RoomTypeDelete: Scalars['Boolean'];
  SalesChannelInsert: Scalars['Boolean'];
  SalesChannelUpdate: Scalars['Boolean'];
  SalesChannelDelete: Scalars['Boolean'];
  SegmentUpdate: Scalars['Boolean'];
  SegmentInsert: SegmentEntity;
  SegmentDelete: Scalars['Boolean'];
  TaxInsert: Scalars['Boolean'];
  TaxUpdate: Scalars['Boolean'];
  TaxDelete: Scalars['Boolean'];
  TaxSchemeInsert: Scalars['Boolean'];
  TaxSchemeUpdate: Scalars['Boolean'];
  TaxSchemeDelete: Scalars['Boolean'];
  SetDefaultTaxScheme: Scalars['Boolean'];
  TravelAgentInsert: DebtorEntity;
  TravelAgentMainUpdate: Scalars['Boolean'];
  TravelAgentUpdate: Scalars['Boolean'];
  TravelAgentContractUpdate: Scalars['Boolean'];
  TravelAgentContractInsert: ContractEntity;
  UpdateAllotment: Scalars['Boolean'];
  TravelAgentDelete: Scalars['Boolean'];
  DebtorLogin: DebtorProfileEntity;
  DebtorLastAccessedHotelUpdate: Scalars['Boolean'];
  DebtorChangePassword: DebtorProfileEntity;
  DebtorForgotPassword: Scalars['Boolean'];
  AgentDelete: Scalars['Boolean'];
  DebtorResetPassword: Scalars['Boolean'];
  DebtorPaymentInsert: Scalars['Boolean'];
};


export type MutationHotelTaxPolicyInsertArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyUpdateArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationHlsBookingNotificationArgs = {
  BookingId: Scalars['String'];
  HotelId: Scalars['String'];
};


export type MutationUploadImageArgs = {
  FileInput: Array<Scalars['Upload']>;
};


export type MutationDeleteImageArgs = {
  OldGalleryID: Array<Scalars['String']>;
};


export type MutationSpecialReqUpdateArgs = {
  SpecialReq: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type MutationAssignRoomArgs = {
  Mode?: Maybe<Scalars['Boolean']>;
  RoomID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationMultiCheckInArgs = {
  GroupCheckIn?: Maybe<Scalars['Boolean']>;
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  ContactInput?: Maybe<Array<ContactInput>>;
  AdditionalMultiRegInput?: Maybe<Array<AdditionalMultiRegInput>>;
  RegistrationIDs: Array<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationCheckInArgs = {
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  GroupCheckIn?: Maybe<Scalars['Boolean']>;
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  IdPictureBack?: Maybe<Array<Scalars['Upload']>>;
  IdPicture?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ContactInput?: Maybe<ContactInput>;
  RegistrationIDs: Array<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationTransferRoomArgs = {
  Remark?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  NewRoomID: Scalars['String'];
  OldRoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationGuestInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  guestType?: Maybe<GuestType>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ContactInput: Array<ContactInput>;
};


export type MutationDepositInsertArgs = {
  input: DepositInput;
};


export type MutationRefundDepositArgs = {
  input: RefundDepositInput;
};


export type MutationCloseFolioArgs = {
  PaymentID: Scalars['String'];
  RegistrationID: Array<Scalars['String']>;
  FolioInput: FolioInput;
};


export type MutationVoidLedgerArgs = {
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  ID: Scalars['String'];
  LedgerType: Scalars['String'];
};


export type MutationRegistrationSignatureUpdateArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  RegistrationID: Scalars['String'];
};


export type MutationVoidBillLedgerArgs = {
  input: BillLedgerInput;
};


export type MutationPosV7VoidBillLedgerArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  input: BillLedgerInput;
};


export type MutationRefundPaymentArgs = {
  ReceiptNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationFrontDeskPaymentInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  AdjustedAmount?: Maybe<Scalars['Float']>;
  IsGroupFolio?: Maybe<Scalars['Boolean']>;
  DebtorID?: Maybe<Scalars['String']>;
  BeneficiaryAddress?: Maybe<Scalars['String']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  TotalPaymentAmount: Scalars['Float'];
  input: Array<FrontDeskPaymentInput>;
};


export type MutationCheckOutArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type MutationWalkIngBookingInsertArgs = {
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RegistrationInput: RegistrationInput;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
};


export type MutationLateCheckOutInsertArgs = {
  AdjustedAmount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  AppName?: Maybe<Scalars['String']>;
  hour: Scalars['Float'];
  input: FrontDeskPaymentInput;
};


export type MutationExtendStayInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  AdjustedAmount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  input: FrontDeskPaymentInput;
};


export type MutationShortenStayInsertArgs = {
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  RoomTypeID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
};


export type MutationRoomUpGradeInsertArgs = {
  AdjustedAmount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  RoomUpgradeInput: RoomUpgradeInput;
  PaymentInput?: Maybe<FrontDeskPaymentInput>;
};


export type MutationFolioDeleteArgs = {
  FolioID: Scalars['String'];
};


export type MutationNonGuestFolioInsertArgs = {
  FolioInput: FolioInput;
};


export type MutationSuspendFolioInsertArgs = {
  FolioInput: FolioInput;
};


export type MutationNonGuestFolioUpdateArgs = {
  FolioInput: FolioInput;
};


export type MutationNonGuestVoidLedgerArgs = {
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
};


export type MutationNonGuestPaymentInsertArgs = {
  HotelID?: Maybe<Scalars['String']>;
  BillLedgerIDs?: Maybe<Array<Scalars['String']>>;
  PaymentInput: PaymentInput;
};


export type MutationNonGuestCloseFolioArgs = {
  eInvoiceType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  FolioID: Scalars['String'];
};


export type MutationNonGuestTransferFolioArgs = {
  HotelID?: Maybe<Scalars['String']>;
  IsSuspend?: Maybe<Scalars['Boolean']>;
  RecordIDs: Array<Scalars['String']>;
  FolioID: Scalars['String'];
};


export type MutationSplitPaymentV2Args = {
  SplitValue: Scalars['Float'];
  SplitType: Scalars['String'];
  SplitBy: Scalars['Float'];
  RecordToSplit: Array<PaymentFolioInput>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationTransferPaymentArgs = {
  FolioID?: Maybe<Scalars['String']>;
  IsNonguest?: Maybe<Scalars['Boolean']>;
  RecordIDs: Array<Scalars['String']>;
  FromRegistrationID?: Maybe<Scalars['String']>;
  NewRegistrationID: Scalars['String'];
};


export type MutationRegistrationSignArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  ID: Scalars['String'];
};


export type MutationPaymentUpdateArgs = {
  Mode?: Maybe<Scalars['String']>;
  AppName?: Maybe<Scalars['String']>;
  IsGuestApp?: Maybe<Scalars['Boolean']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};


export type MutationCancelCheckInArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationFolioBeneficiaryNameUpdateArgs = {
  BeneficiaryAddress: Scalars['String'];
  BeneficiaryName: Scalars['String'];
  FolioID: Scalars['String'];
};


export type MutationFdReinstateBookingArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationLockUserProcessArgs = {
  RoomNo: Array<Scalars['String']>;
  Process: Scalars['String'];
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
};


export type MutationHotelNotificationUpdateArgs = {
  operationType?: Maybe<Scalars['String']>;
  input: Array<HotelNotificationInput>;
};


export type MutationGuestRegisterArgs = {
  AccountID: Scalars['String'];
  Password: Scalars['String'];
  Login: Scalars['String'];
  NRIC: Scalars['String'];
  FullName: Scalars['String'];
  MobileNo: Scalars['String'];
  Email: Scalars['String'];
};


export type MutationGuestLoginArgs = {
  AccountID: Scalars['String'];
  Password: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};


export type MutationGuestAppAuthGeneratorArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationGuestChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationGuestForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationEmailResetPasswordArgs = {
  Email?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type MutationGuestResetPasswordArgs = {
  password: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
};


export type MutationOnlineBookingInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  ContactInput: ContactInput;
  RegistrationInput: Array<RegistrationInput>;
  BookingInput: BookingInput;
};


export type MutationGuestRoomServiceInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  PaymentMode: Scalars['String'];
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationHotelRatingInsertArgs = {
  input: HotelRatingInput;
};


export type MutationQrScannerInsertArgs = {
  DeviceData?: Maybe<Scalars['String']>;
  QrData?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationPromotionUpdateArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionInsertArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionActiveUpdateArgs = {
  PromotionInput: PromotionInput;
};


export type MutationPromotionDetailUpdateArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationPromotionDetailInsertArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationEmailCampignUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampignInsertArgs = {
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampaignArgs = {
  HotelID: Scalars['String'];
  IsSelectAll: Scalars['Boolean'];
  id: Scalars['String'];
  email: Array<Scalars['String']>;
};


export type MutationPromotionDeleteArgs = {
  Date: Scalars['DateTime'];
  PromoCode: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationEmailDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationGuestReviewActiveUpdateArgs = {
  HotelRatingInput: HotelRatingInput;
};


export type MutationBookingInsertArgs = {
  IsSharedConfirmationLetter?: Maybe<Scalars['Boolean']>;
  DebtorType?: Maybe<Scalars['String']>;
  RegistrationInput: Array<RegistrationInput>;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
};


export type MutationGuestAppRoomTypeDeleteArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  BookingID: Scalars['String'];
};


export type MutationBookingRoomTypeUpdateArgs = {
  EditRegInput: EditRegInput;
};


export type MutationHouseLimitUpdateArgs = {
  HouseLimitInput?: Maybe<Array<HouseLimitInput>>;
};


export type MutationBookingUpdateArgs = {
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput>>;
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
};


export type MutationCancelBookingArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationAdvancePaymentInsertArgs = {
  AdvancePaymentInput: AdvancePaymentInput;
};


export type MutationAdvancePaymentRefundArgs = {
  AdvancePaymentInput: Array<AdvancePaymentInput>;
};


export type MutationAdvancePaymentUpdateArgs = {
  AppName?: Maybe<Scalars['String']>;
  IsGuestApp?: Maybe<Scalars['Boolean']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};


export type MutationReinstateBookingArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
};


export type MutationImageUploadArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationManualNightAuditArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationAvailabilityUpdateArgs = {
  TravelAgentID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type MutationAtestInsertArgs = {
  BookingID: Scalars['String'];
};


export type MutationWalkInRoomAssignedArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationBookingRoomAssignmentArgs = {
  RoomTypeID: Scalars['String'];
  BookingRoomAssignInput?: Maybe<Array<BookingRoomAssignInput>>;
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  RoomIDs?: Maybe<Array<Scalars['String']>>;
  BookingID: Scalars['String'];
};


export type MutationBillScheduleAdjustmentArgs = {
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  Reason: Scalars['String'];
  Discount: Scalars['Float'];
  RateID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  TrxDates: Array<Scalars['DateTime']>;
};


export type MutationNewBookingInfoUpdateArgs = {
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput>>;
  BookingInput: BookingInput;
  BookingID: Scalars['String'];
};


export type MutationNewBookingRemarkArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  BookingRemark: Scalars['String'];
  BookingID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type MutationBookingUpdateV2Args = {
  EditRegInput?: Maybe<Array<EditRegInput>>;
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput>>;
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  PrintRate?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
};


export type MutationCancelRoomArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationReinstateRoomArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationWaitinglistNewApiArgs = {
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type MutationBookingIncChargesCancelArgs = {
  BillID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<HotelPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<HotelPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntRoleUsrAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  hotelID: Scalars['String'];
};


export type MutationMenuOptionUpdateArgs = {
  input: RoleInput;
};


export type MutationOnlinePaymentArgs = {
  PaymentID?: Maybe<Scalars['String']>;
  AdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  redirectUrl: Scalars['String'];
  PayAmount: Scalars['Float'];
  Detail: Scalars['String'];
  Title: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationSendRegisterOtpArgs = {
  OtpType: Scalars['String'];
  MobileNo: Scalars['String'];
};


export type MutationVerifyOtpArgs = {
  OtpType: Scalars['String'];
  OtpCode: Scalars['String'];
  MobileNo: Scalars['String'];
};


export type MutationCreateUserArgs = {
  HotelID: Scalars['String'];
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  softwareCode: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateProfileArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUnLockUserArgs = {
  password: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationReSendActivationEmailArgs = {
  userID: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationUpdateInterestAmtArgs = {
  InterestRate: Scalars['Float'];
};


export type MutationManualNightAuditforAllHotelArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};


export type MutationGenHashPasswordArgs = {
  Password?: Maybe<Scalars['String']>;
};


export type MutationRegenerateBillScheduleArgs = {
  IsInhouse: Scalars['Boolean'];
  IsCms: Scalars['Boolean'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationRegeneratePostTaxesArgs = {
  IsCms: Scalars['Boolean'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationManualHotelNaArgs = {
  Action: Scalars['String'];
  EndDate: Scalars['DateTime'];
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationManualNaRegistrationInsertArgs = {
  UserID: Scalars['String'];
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationManualAvailabilityUpdateArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  BlockDate: Scalars['DateTime'];
  days: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type MutationCmsManualAvailUpdateArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationCutOffBillingArgs = {
  IsSuspend?: Maybe<Scalars['Boolean']>;
  FolioItemID?: Maybe<Scalars['String']>;
  FolioName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Operation: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationKeyCardInsertArgs = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  input: KeyCardInput;
};


export type MutationDebitNoteInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: DebitNoteEntityInput;
};


export type MutationDebitNoteUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: DebitNoteEntityInput;
  ID: Scalars['String'];
};


export type MutationDebitNoteCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateDebitNoteArgs = {
  allocInput: Array<DebitAllocationInput>;
  DebitNoteID: Scalars['String'];
};


export type MutationDebitNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationCreditNoteInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<CreditNoteTrxInput>;
  input: CreditNoteEntityInput;
};


export type MutationCreditNoteUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<CreditNoteTrxInput>;
  input: CreditNoteEntityInput;
  ID: Scalars['String'];
};


export type MutationCreditNoteCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateCreditNoteArgs = {
  allocInput: Array<CreditAllocationInput>;
  CreditNoteID: Scalars['String'];
};


export type MutationCreditNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRefundInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: RefundEntityInput;
};


export type MutationRefundUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: RefundEntityInput;
  ID: Scalars['String'];
};


export type MutationRefundCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateRefundArgs = {
  allocInput: Array<DebitAllocationInput>;
  RefundID: Scalars['String'];
};


export type MutationRefundDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationInvoiceInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<InvoiceTrxInput>;
  input: InvoiceEntityInput;
};


export type MutationInvoiceUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<InvoiceTrxInput>;
  input: InvoiceEntityInput;
  ID: Scalars['String'];
};


export type MutationInvoiceCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateInvoiceArgs = {
  allocInput: Array<DebitAllocationInput>;
  InvoiceID: Scalars['String'];
};


export type MutationInvoiceDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationOfficialReceiptInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: OfficialReceiptEntityInput;
};


export type MutationOfficialReceiptUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: OfficialReceiptEntityInput;
  ID: Scalars['String'];
};


export type MutationOfficialReceiptCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateOfficialReceiptArgs = {
  allocInput: Array<CreditAllocationInput>;
  ReceiptID: Scalars['String'];
};


export type MutationOfficialReceiptDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationReminderInsertArgs = {
  input: ReminderEntityInput;
};


export type MutationReminderUpdateArgs = {
  input: ReminderEntityInput;
  ID: Scalars['String'];
};


export type MutationReminderDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationAsyncInterestInsertArgs = {
  input: InterestEntityInput;
};


export type MutationInterestUpdateArgs = {
  input: InterestEntityInput;
  ID: Scalars['String'];
};


export type MutationInterestDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDocumentNumberingSetupInsertArgs = {
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderinput: DocumentNumberHeaderInput;
};


export type MutationDocumentNumberingUpdateArgs = {
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  SampleOutput: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDebtorTypeInsertArgs = {
  input: DebtorTypeInput;
};


export type MutationDebtorTypeUpdateArgs = {
  input: DebtorTypeInput;
  ID: Scalars['String'];
};


export type MutationDebtorTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationPostScheduleBillingArgs = {
  input: Array<ScheduleBillingInput>;
  InvoiceDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type MutationRoomSaleOptimizationUpdateArgs = {
  input: Array<AvailabilityInput>;
};


export type MutationBookingAttachmentInsertArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  Remark?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationBookingAttachmentUpdateArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  Remark?: Maybe<Scalars['String']>;
  AttachmentDetailsID?: Maybe<Array<Scalars['String']>>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingAttachmentID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationBookingAttachmentDeleteArgs = {
  BookingAttachmentID?: Maybe<Scalars['String']>;
};


export type MutationEmailSendArgs = {
  Attachments?: Maybe<EmailAttachmentInput>;
  isGuestEmail?: Maybe<Scalars['Boolean']>;
  BookingID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Body: EmailBodyInput;
};


export type MutationBiSharedGAlleryInsertArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  HotelID: Scalars['String'];
};


export type MutationChooseRoomAllocationInsertArgs = {
  RoomAllocationInput: ChooseRoomAllocationInput;
};


export type MutationChooseRoomAllocationUpdateArgs = {
  OldEffectiveDate: Scalars['DateTime'];
  RoomAllocationInput: ChooseRoomAllocationInput;
};


export type MutationChooseRoomAllocationDeleteArgs = {
  EffectiveDate: Scalars['DateTime'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationCompanyInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateStatusArgs = {
  CompanyID: Scalars['String'];
  Status: Scalars['String'];
};


export type MutationCorporateInsertArgs = {
  DebtorContactInput: Array<ContactInput>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationGovermentTestingUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationCorporateUpdateArgs = {
  DebtorContactInput?: Maybe<Array<ContactInput>>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationCorporateContractUpdateArgs = {
  RatePolicyIDs?: Maybe<Array<Scalars['String']>>;
  ContractInput: ContractInput;
};


export type MutationCorporateContractInsertArgs = {
  RatePolicyIDs: Array<Scalars['String']>;
  ContractInput: ContractInput;
};


export type MutationCorporateDeleteArgs = {
  DebtorID: Scalars['String'];
};


export type MutationActivityInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ActivitiesInput?: Maybe<Array<ActivitiesInput>>;
};


export type MutationActivityUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ActivitiesInput?: Maybe<Array<ActivitiesInput>>;
};


export type MutationGenerateConsolidatedDocOldArgs = {
  limit?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  DocType?: Maybe<Scalars['String']>;
  EInvoiceConsolidationInput: EInvoiceConsolidationInput;
  TrxDate: Scalars['DateTime'];
};


export type MutationEventScheduleBillingArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationEventVoidBillLedgerArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  input: BillLedgerInput;
};


export type MutationEventBillLedgerInsertArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationRegenerateNaRegistrationArgs = {
  HotelID?: Maybe<Scalars['String']>;
  EndArrDate: Scalars['DateTime'];
  StartArrDate: Scalars['DateTime'];
};


export type MutationGuestUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  ProfilePictureAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  GuestProfileInput: GuestProfileInput;
  ContactInput: ContactInput;
};


export type MutationGuestProfileInsertArgs = {
  ContactInput: ContactInput;
  RegistrationID: Scalars['String'];
};


export type MutationGuestContactUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  guestType?: Maybe<GuestType>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  VehicleNo?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  ContactInput: ContactInput;
};


export type MutationGuestProfileDeleteArgs = {
  PrincipalRegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
};


export type MutationNoOfPaxUpdateArgs = {
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
  NoOfAdult: Scalars['Float'];
  SharerNo: Scalars['Float'];
  RoomTypeID: Scalars['String'];
};


export type MutationGuestMovementInsertArgs = {
  GuestMovementInput: GuestMovementInput;
};


export type MutationCbPostArgs = {
  IsTransfer?: Maybe<Scalars['Boolean']>;
  GLDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGlPostArgs = {
  IsTransfer?: Maybe<Scalars['Boolean']>;
  GLDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationPaymentTypeGlInsertArgs = {
  HotelID?: Maybe<Scalars['String']>;
  PaymentType: Array<Scalars['String']>;
};


export type MutationBillLedgerUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationBillLedgerCancelArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationTaxLedgerInsertArgs = {
  TaxLedgerInput: Array<TaxLedgerInput>;
};


export type MutationBillLedgerInsertArgs = {
  IsBookingIncCharges?: Maybe<Scalars['Boolean']>;
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationPosV7BillLedgerInsertArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationFolioInsertArgs = {
  RefFolioID?: Maybe<Scalars['String']>;
  PaymentInput: PaymentInput;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationRoomServicePaymentUpdateArgs = {
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type MutationOnlinePaymentRoomServicesArgs = {
  PaymentInput: PaymentInput;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationFolioAdjustmentInsertArgs = {
  IsEInvoice?: Maybe<Scalars['Boolean']>;
  RefFolioID?: Maybe<Scalars['String']>;
  FolioType: Scalars['String'];
  IsSuspend?: Maybe<Scalars['Boolean']>;
  PaymentInput: PaymentInput;
  AdvancePaymentInput: Array<AdvancePaymentInput>;
  DepositLedgerInput: Array<DepositLedgerInput>;
  TaxLedgerInput: Array<TaxLedgerInput>;
  RoomLedgerInput: Array<RoomLedgerInput>;
  BillLedgerInput: Array<BillLedgerInput>;
  HotelID: Scalars['String'];
};


export type MutationChangePaymentArgs = {
  PaymentInput: PaymentInput;
  ReversedPaymentID: Scalars['String'];
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationPosChargeToCityLedgerArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationPosv7ScheduleBillingArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationPosv7DailyRevenueArgs = {
  POSV7DailyRevenueInput: Array<Posv7DailyRevenueInput>;
};


export type MutationMenuXDailyRevenueArgs = {
  Input?: Maybe<Array<Posv7DailyRevenueInput>>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationStatisticExportDeleteArgs = {
  BatchNo: Scalars['Float'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationStatisticJournalPostArgs = {
  IsTransfer?: Maybe<Scalars['Boolean']>;
  StatisticJournalInput: Array<StatisticJournalInput>;
  HotelDate: Scalars['DateTime'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationHuaweiOcrArgs = {
  File: Scalars['String'];
};


export type MutationInnov8OcrArgs = {
  File: Scalars['String'];
};


export type MutationPackageRedemptionInsertArgs = {
  PackageRedemptionInput: PackageRedemptionInput;
};


export type MutationPreRegPaymentInsertArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  Amount: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationUpdateRoomAssignmentArgs = {
  IsCompleted?: Maybe<Scalars['Boolean']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationExpressCheckinConfirmationArgs = {
  AddConfirmationInput?: Maybe<Array<AddConfirmationInput>>;
  EditConfirmationInput?: Maybe<Array<EditConfirmationInput>>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationCheckInInsertV2Args = {
  AddInformationInput?: Maybe<Array<AddInformationInput>>;
  EditInformationInput?: Maybe<Array<EditInformationInput>>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationPreCheckInInsertArgs = {
  Signature: Array<Scalars['Upload']>;
  IcPhotoBack?: Maybe<Array<Scalars['Upload']>>;
  IcPhotoFront?: Maybe<Array<Scalars['Upload']>>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  PreCheckInInput: PreCheckInInput;
};


export type MutationQ3DocumentInsertArgs = {
  Q3DocumentDetailInput: Array<Q3DocumentDetailInput>;
  Q3DocumentHeaderInput: Q3DocumentHeaderInput;
};


export type MutationRevenueMappingInsertArgs = {
  GuestLedgerAcctInput: RevenueGuestLedgerAcctInput;
  RevenueInput: RevenueMappingInput;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationSegmentMappingInsertArgs = {
  SegmentInput: Array<SegmentInput>;
  HotelID: Scalars['String'];
};


export type MutationStatisticMappingInsertArgs = {
  Q3Statistic: Array<Q3StatisticInput>;
  HotelID: Scalars['String'];
};


export type MutationBlockRoomLogInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationBlockRoomLogInsertV2Args = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: Array<BlockRoomLogInput>;
};


export type MutationReleaseRoomLogUpdateV2Args = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationBlockRoomLogUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationHouseKeepingLogInsertArgs = {
  RoomIDs?: Maybe<Array<Scalars['String']>>;
  HouseKeepingLogInput: HouseKeepingLogInput;
};


export type MutationHousekeepingServiceRequestInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingServiceRequestUpdateArgs = {
  AppName?: Maybe<Scalars['String']>;
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingBillLedgerUpdateArgs = {
  BillLedgerInput: BillLedgerInput;
};


export type MutationDepartmentInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationIncidentalChargesUpdateArgs = {
  status: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationDepartmentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationEDocInsertArgs = {
  EDocTemplateInput: EDocTemplateInput;
};


export type MutationEDocTemplateUpdateArgs = {
  EDocTemplateInput: EDocTemplateInput;
};


export type MutationConfirmationTemplateDeleteArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAccountMappingGlInsertArgs = {
  GuestLedgerAcctInput: GuestLedgerAcctInput;
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
};


export type MutationCityLedgerGlInsertArgs = {
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
};


export type MutationAccountMappingTaxGlInsertArgs = {
  TaxGLInput: AccountMappingTaxGlInput;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationCityLedgerTaxGlInsertArgs = {
  TaxGLInput: AccountMappingTaxGlInput;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationHotelStatisticMappingInsertArgs = {
  ExternalStatistic: Array<ExternalStatisticInput>;
  Statistic: Array<StatisticInput>;
  HotelID: Scalars['String'];
};


export type MutationBankAccountUpdateArgs = {
  BankAccountInput: BankAccountInput;
};


export type MutationBankAccountInsertArgs = {
  BankAccountInput: BankAccountInput;
};


export type MutationBankAccountDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationBankAccountInActiveArgs = {
  IsActive: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationFacilitiesInactiveDeleteArgs = {
  id: Scalars['String'];
  isInactive: Scalars['Boolean'];
};


export type MutationGuestAppSetupUpdateArgs = {
  GuestAppSettingInput: GuestAppSettingInput;
};


export type MutationInvoiceRemarkArgs = {
  InvoiceRemark?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type MutationHotelUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  Type?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
};


export type MutationHotelInsertArgs = {
  Type?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
};


export type MutationHotelContactUpdateArgs = {
  ContactInput: ContactInput;
};


export type MutationHotelContactInsertArgs = {
  HotelID: Scalars['String'];
  ContactInput: ContactInput;
};


export type MutationHotelFacilityUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelFacilityInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelNewsUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationHotelNewsInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationIncidentalChargeInsertArgs = {
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationLateCheckOutChargeInsertArgs = {
  LateCheckOutChargeInput: Array<LateCheckOutChargeInput>;
};


export type MutationFloorPlanUpdateArgs = {
  Type?: Maybe<Scalars['String']>;
  LocationFloorPlanInput: Array<LocationFloorPlanInput>;
};


export type MutationFloorPlanResetArgs = {
  Type?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
};


export type MutationLocationInsertArgs = {
  LocationInput: LocationInput;
};


export type MutationLocationUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  LocationInput: LocationInput;
};


export type MutationDisplaySequenceUpdateArgs = {
  Type: Scalars['String'];
  Input: Array<DisplaySequenceInput>;
};


export type MutationLocationDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRateElementInsertArgs = {
  RateElementInput: RateElementInput;
};


export type MutationRateElementUpdateArgs = {
  RateElementInput: RateElementInput;
};


export type MutationRateAddOnInsertArgs = {
  RateAddOnInput: RateAddOnInput;
};


export type MutationRateAddOnUpdateArgs = {
  RateAddOnInput: RateAddOnInput;
};


export type MutationRateTypeInsertArgs = {
  RateTypeInput: RateTypeInput;
};


export type MutationRateTypeUpdateArgs = {
  RateTypeInput: RateTypeInput;
};


export type MutationRateInsertArgs = {
  RateInput: RateInput;
};


export type MutationRateUpdateArgs = {
  RateInput: RateInput;
};


export type MutationRateEffectiveInsertArgs = {
  SeasonalRateInput?: Maybe<Array<SeasonalRateInput>>;
  RateEffectiveInput: RateEffectiveInput;
};


export type MutationRateEffectiveUpdateArgs = {
  SeasonalRateInput?: Maybe<Array<SeasonalRateInput>>;
  RateEffectiveInput: RateEffectiveInput;
};


export type MutationCmsRateSyncArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationRateEffectiveStatusUpdateArgs = {
  RateEffectiveID: Scalars['String'];
  IsActive: Scalars['Boolean'];
};


export type MutationRateAdjustmentAuditInsertArgs = {
  RateAdjustmentAuditInput: RateAdjustmentAuditInput;
};


export type MutationRegenBsQueueInsertArgs = {
  IsCms?: Maybe<Scalars['Boolean']>;
  FullName?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RateCodeID?: Maybe<Scalars['String']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationRegenBsQueueConfirmArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationSeasonInsertArgs = {
  SeasonInput: SeasonInput;
};


export type MutationSeasonUpdateArgs = {
  SeasonInput: SeasonInput;
};


export type MutationSeasonCalendarUpdateArgs = {
  HotelID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  SeasonCalendarInput: Array<SeasonCalendarInput>;
};


export type MutationRatePolicyInsertArgs = {
  PackagesInput: Array<PackagesInput>;
  PricingInput: Array<PricingInput>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationRatePolicyUpdateArgs = {
  PricingInput?: Maybe<Array<PricingInput>>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationPackageInsertArgs = {
  PackagesInput: Array<PackagesInput>;
};


export type MutationPackageUpdateArgs = {
  PackagesInput: PackagesInput;
};


export type MutationRatePolicyDeleteArgs = {
  DebtorCategory: Scalars['String'];
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationReasonInsertArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonUpdateArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRoomInsertArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomUpdateArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomDeleteArgs = {
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRoomStatusInsertArgs = {
  RoomStatusInput: Array<RoomStatusInput>;
};


export type MutationRoomTypeUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput?: Maybe<Array<RoomTypeAmenitiesInput>>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput: Array<RoomTypeAmenitiesInput>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSalesChannelInsertArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelUpdateArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSegmentUpdateArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentInsertArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationTaxInsertArgs = {
  TaxInsertInput: TaxPolicyInput;
  AccountID: Scalars['String'];
};


export type MutationTaxUpdateArgs = {
  TaxEditInput: EditTaxPolicyInput;
  AccountID: Scalars['String'];
};


export type MutationTaxDeleteArgs = {
  TaxDeleteInput: Array<TaxDetailInput>;
  TaxID: Scalars['String'];
};


export type MutationTaxSchemeInsertArgs = {
  TaxSchemeInput: TaxSchemeInput;
  AccountID: Scalars['String'];
};


export type MutationTaxSchemeUpdateArgs = {
  EditTaxSchemeInput: EditTaxSchemeInput;
  AccountID: Scalars['String'];
};


export type MutationTaxSchemeDeleteArgs = {
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationSetDefaultTaxSchemeArgs = {
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationTravelAgentInsertArgs = {
  DebtorContactInput: Array<ContactInput>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentMainUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentUpdateArgs = {
  DebtorContactInput?: Maybe<Array<ContactInput>>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentContractUpdateArgs = {
  HotelID: Scalars['String'];
  RoomTypeList?: Maybe<Array<ContractAllotmentInput>>;
  RatePolicyIDs?: Maybe<Array<Scalars['String']>>;
  ContractInput: ContractInput;
};


export type MutationTravelAgentContractInsertArgs = {
  HotelID: Scalars['String'];
  RoomTypeList: Array<ContractAllotmentInput>;
  RatePolicyIDs: Array<Scalars['String']>;
  ContractInput: ContractInput;
};


export type MutationUpdateAllotmentArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  DebtorID: Scalars['String'];
  AllotmentInput: Array<ContractAllotmentInput>;
};


export type MutationTravelAgentDeleteArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type MutationDebtorLoginArgs = {
  Password: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};


export type MutationDebtorLastAccessedHotelUpdateArgs = {
  HotelID: Scalars['String'];
  Email: Scalars['String'];
};


export type MutationDebtorChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationDebtorForgotPasswordArgs = {
  Email: Scalars['String'];
};


export type MutationAgentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDebtorResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationDebtorPaymentInsertArgs = {
  DebtorPaymentInput: Array<DebtorPaymentInput>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type NaProcessLogEntity = InterfaceBase & {
  __typename?: 'NAProcessLogEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  PendingBooking?: Maybe<Scalars['Boolean']>;
  NoShowBooking?: Maybe<Scalars['Boolean']>;
  HouseKeepingUpdate?: Maybe<Scalars['Boolean']>;
  ReleaseBlockRoom?: Maybe<Scalars['Boolean']>;
  PostAdvancePayment?: Maybe<Scalars['Boolean']>;
  PostRoomRevenue?: Maybe<Scalars['Boolean']>;
  AvailabilityUpdate?: Maybe<Scalars['Boolean']>;
  AutoExtendStay?: Maybe<Scalars['Boolean']>;
  DailyRegistration?: Maybe<Scalars['Boolean']>;
  NAStatus?: Maybe<Scalars['String']>;
  PendingBookingComplete?: Maybe<Scalars['DateTime']>;
  NoShowBookingComplete?: Maybe<Scalars['DateTime']>;
  HouseKeepingUpdateComplete?: Maybe<Scalars['DateTime']>;
  ReleaseBlockRoomComplete?: Maybe<Scalars['DateTime']>;
  PostAdvancePaymentComplete?: Maybe<Scalars['DateTime']>;
  PostRoomRevenueComplete?: Maybe<Scalars['DateTime']>;
  AvailabilityUpdateComplete?: Maybe<Scalars['DateTime']>;
  AutoExtendStayComplete?: Maybe<Scalars['DateTime']>;
  DailyRegistrationComplete?: Maybe<Scalars['DateTime']>;
};

export type NaRegistration2Entity = InterfaceTrx & {
  __typename?: 'NARegistration2Entity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  SegmentID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SharerNo?: Maybe<Scalars['Float']>;
  TourismTax?: Maybe<Scalars['Boolean']>;
  TTxAmount?: Maybe<Scalars['Float']>;
};

export type NaRegistrationEntity = InterfaceTrx & {
  __typename?: 'NARegistrationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  SegmentID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SharerNo?: Maybe<Scalars['Float']>;
  TourismTax?: Maybe<Scalars['Boolean']>;
  TTxAmount?: Maybe<Scalars['Float']>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Bahraini = 'Bahraini',
  Belarusian = 'Belarusian',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  Bruneian = 'Bruneian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kazakhstani = 'Kazakhstani',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mauritian = 'Mauritian',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Omani = 'Omani',
  Others = 'Others',
  Pakistani = 'Pakistani',
  Palestinians = 'Palestinians',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Uzbekistani = 'Uzbekistani',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Yemeni = 'Yemeni',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean',
  Maldivian = 'Maldivian',
  Deutsch = 'Deutsch',
  RepublicOfTrinidad = 'Republic_Of_Trinidad'
}

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  NewsGallery?: Maybe<Array<NewsGalleryEntity>>;
};

export type NewsGalleryEntity = InterfaceBase & {
  __typename?: 'NewsGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  GalleryID: Scalars['String'];
  News?: Maybe<NewsEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type NewsGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NewsInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NonGuestBalance = {
  __typename?: 'NonGuestBalance';
  TotalBill?: Maybe<Scalars['Float']>;
  TotalPaid?: Maybe<Scalars['Float']>;
  TotalLeft?: Maybe<Scalars['Float']>;
};

export type NonGuestFolioResponse = {
  __typename?: 'NonGuestFolioResponse';
  ID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  IncidentalDescription?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ReversedID?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Qty?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  TaxBillLedger?: Maybe<Array<BillLedgerTaxEntity>>;
  TaxRoomLedger?: Maybe<Array<RoomLedgerTaxEntity>>;
  Reason?: Maybe<Scalars['String']>;
};

export type NonGuestInfoJson = {
  __typename?: 'NonGuestInfoJson';
  EffectiveDate?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  ReferenceID?: Maybe<Scalars['String']>;
};

export type NonGuestInfoJsonInput = {
  EffectiveDate?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  ReferenceID?: Maybe<Scalars['String']>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  ID: Scalars['ID'];
  HotelID?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
};

export type Occupancy = {
  __typename?: 'Occupancy';
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Season: Scalars['String'];
  Occupancy?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  TotalVacantShow?: Maybe<Scalars['Float']>;
  TotalBlock?: Maybe<Scalars['Float']>;
};

export type OfficialReceiptEntity = InterfaceTrx & {
  __typename?: 'OfficialReceiptEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetails;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type OfficialReceiptEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetailsInput;
};

export type OnlineItemEntity = InterfaceTrx & {
  __typename?: 'OnlineItemEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type OnlineItemInput = {
  FolioID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type OpenClosedResponse = {
  __typename?: 'OpenClosedResponse';
  OpenInvoice?: Maybe<Array<Data>>;
  OpendebitNote?: Maybe<Array<Data>>;
  OpenCreditNote?: Maybe<Array<Data>>;
  OpenOR?: Maybe<Array<Data>>;
  OpenRefund?: Maybe<Array<Data>>;
  ClosedInvoice?: Maybe<Array<Data>>;
  CloseddebitNote?: Maybe<Array<Data>>;
  ClosedCreditNote?: Maybe<Array<Data>>;
  ClosedOR?: Maybe<Array<Data>>;
  ClosedRefund?: Maybe<Array<Data>>;
};

export type OutgoingDocTemplateEntity = InterfaceBase & {
  __typename?: 'OutgoingDocTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
  DebitNote?: Maybe<Scalars['String']>;
  CreditNote?: Maybe<Scalars['String']>;
  OfficialReceipt?: Maybe<Scalars['String']>;
  Refund?: Maybe<Scalars['String']>;
  DepositRefund?: Maybe<Scalars['String']>;
  DepositInvoice?: Maybe<Scalars['String']>;
  APStatement?: Maybe<Scalars['String']>;
};

export type OutgoingDocTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type Output = {
  __typename?: 'Output';
  isNRICExist?: Maybe<Scalars['String']>;
  isEmailExist?: Maybe<Scalars['String']>;
  isLoginIDExist?: Maybe<Scalars['String']>;
};

export type PabxLogEntity = InterfaceTrx & {
  __typename?: 'PABXLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type PackagePriceOutput = {
  __typename?: 'PackagePriceOutput';
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomTypeCode?: Maybe<Scalars['String']>;
  RoomTypeName?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RateFrequency?: Maybe<Scalars['String']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  Pricing?: Maybe<Array<PricingOutput>>;
};

export type PackageRedemptionEntity = InterfaceBase & {
  __typename?: 'PackageRedemptionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsBillLedger?: Maybe<Scalars['Boolean']>;
  Registration?: Maybe<RegistrationEntity>;
};

export type PackageRedemptionInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsBillLedger?: Maybe<Scalars['Boolean']>;
};

export type PackageRedemptionListOutput = {
  __typename?: 'PackageRedemptionListOutput';
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ComputationRule?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestNames?: Maybe<Array<Scalars['String']>>;
  GuestPackageRedeem?: Maybe<Array<GuestPackageRedeem>>;
  Amount?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
  Redeemed?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
  MaxPackage?: Maybe<Scalars['Float']>;
};

export type PackagesEntity = InterfaceBase & {
  __typename?: 'PackagesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RatePolicyID: Scalars['String'];
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  ComputationRules?: Maybe<ComputationRules>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type PackagesInput = {
  ID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  ComputationRules?: Maybe<ComputationRules>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PassCodeInput = {
  keyboardPwd?: Maybe<Scalars['String']>;
  keyboardPwdId?: Maybe<Scalars['String']>;
};

export enum PaymentClass {
  Advance = 'Advance',
  Room = 'Room',
  Bill = 'Bill',
  Tax = 'Tax',
  Folio = 'Folio',
  Refund = 'Refund',
  Forfeit = 'Forfeit',
  Rounding = 'Rounding'
}

export type PaymentEntity = InterfaceTrx & {
  __typename?: 'PaymentEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReversedPaymentID?: Maybe<Scalars['String']>;
  ReferenceID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo: Scalars['Float'];
  CheckOutID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  ChangeAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  PaymentStatus: PaymentStatus;
  IsARPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Folio?: Maybe<FolioEntity>;
  ForfeitTax?: Maybe<Array<ForfeitTaxEntity>>;
};

export type PaymentFoilioResponse = {
  __typename?: 'PaymentFoilioResponse';
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedName?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  GuestAppDepartment?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  BillScheduleTax?: Maybe<Array<BillScheduleTaxEntity>>;
  RoomLedgerTax?: Maybe<Array<RoomLedgerTaxEntity>>;
  BillLedgerTax?: Maybe<Array<BillLedgerTaxEntity>>;
};

export type PaymentFolioInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedName?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
};

export type PaymentGatewayEntity = InterfaceBase & {
  __typename?: 'PaymentGatewayEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Client?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type PaymentGatewayInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Client?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PaymentGlEntity = InterfaceTrx & {
  __typename?: 'PaymentGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  PaymentType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type PaymentInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass: PaymentClass;
  PaymentType: PaymentType;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentStatus: PaymentStatus;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
};

export type PaymentListing123 = {
  __typename?: 'PaymentListing123';
  RegistrationID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<Scalars['String']>;
  PaymentClass?: Maybe<Scalars['String']>;
  PaymentDate?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  Result?: Maybe<Array<RegistrationResult>>;
};

export type PaymentOutput = {
  __typename?: 'PaymentOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected'
}

export type PaymentTestClass = {
  __typename?: 'PaymentTestClass';
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReversedPaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo: Scalars['Float'];
  CheckOutID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  ChangeAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  PaymentStatus: PaymentStatus;
  Hotel?: Maybe<Array<HotelEntity>>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  Booking?: Maybe<BookingEntity>;
};

export enum PaymentType {
  Online = 'Online',
  Cash = 'Cash',
  VisaCard = 'VisaCard',
  MasterCard = 'MasterCard',
  AmericanExpressCard = 'AmericanExpressCard',
  DinersCard = 'DinersCard',
  JcbCard = 'JCBCard',
  Unionpay = 'UNIONPAY',
  CityLedger = 'CityLedger',
  Other = 'Other',
  DebitCard = 'DebitCard',
  EWallet = 'EWallet',
  Room = 'Room',
  BankTt = 'BankTT',
  Voucher = 'Voucher',
  Cheque = 'Cheque'
}

export type PermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
  hotelPerms?: Maybe<HotelPermission>;
};

export type PlatformAmenitiesEntity = InterfaceBase & {
  __typename?: 'PlatformAmenitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Gallery?: Maybe<GalleryEntity>;
};

export type PlatformDepartmentEntity = InterfaceBase & {
  __typename?: 'PlatformDepartmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
};

export type PlatformHeritageTaxEntity = InterfaceBase & {
  __typename?: 'PlatformHeritageTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformHeritageTaxInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformLevyEntity = InterfaceBase & {
  __typename?: 'PlatformLevyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformLevyInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformServiceChargeEntity = InterfaceBase & {
  __typename?: 'PlatformServiceChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformServiceChargeInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformStateFundEntity = InterfaceBase & {
  __typename?: 'PlatformStateFundEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformStateFundInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type PlatformTaxInput = {
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformTourismTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTourismTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformTourismTaxInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type Posv7DailyRevenueEntity = InterfaceBase & {
  __typename?: 'POSV7DailyRevenueEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentDate: Scalars['DateTime'];
  OutletDescription?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  PaymentDescription?: Maybe<Scalars['String']>;
  TrxType?: Maybe<Scalars['String']>;
  DiscountedAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  OutletCode?: Maybe<Scalars['String']>;
};

export type Posv7DailyRevenueHistoryEntity = InterfaceBase & {
  __typename?: 'POSV7DailyRevenueHistoryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentDate: Scalars['DateTime'];
  OutletDescription?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  PaymentDescription?: Maybe<Scalars['String']>;
  TrxType?: Maybe<Scalars['String']>;
  DiscountedAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  OutletCode?: Maybe<Scalars['String']>;
};

export type Posv7DailyRevenueInput = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  CardNo?: Maybe<Scalars['String']>;
  OutletDescription?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  PaymentDescription?: Maybe<Scalars['String']>;
  TrxType?: Maybe<Scalars['String']>;
  DiscountedAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  OutletCode?: Maybe<Scalars['String']>;
};

export type PreCheckInInput = {
  NRICNo: Scalars['String'];
  DateofBirth: Scalars['DateTime'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  Address?: Maybe<AddressInput>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type PricingEntity = InterfaceBase & {
  __typename?: 'PricingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RatePolicyID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['Float']>;
  Ceiling?: Maybe<Scalars['Float']>;
  Floor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  PackageAmount?: Maybe<Scalars['Float']>;
  SellingPrice?: Maybe<Scalars['Float']>;
  Season?: Maybe<Scalars['String']>;
};

export type PricingInput = {
  ID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['Float']>;
  Ceiling?: Maybe<Scalars['Float']>;
  Floor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PricingOutput = {
  __typename?: 'PricingOutput';
  Date?: Maybe<Scalars['DateTime']>;
  Price?: Maybe<Scalars['Float']>;
  BasePrice?: Maybe<Scalars['Float']>;
  PackageName?: Maybe<Scalars['Float']>;
  PackagePrice?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Packages?: Maybe<Array<PackagesEntity>>;
};

export type PromoCodeInput = {
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  Pricing?: Maybe<Array<PromoPricingInput>>;
};

export type PromoCodeResponse = {
  __typename?: 'PromoCodeResponse';
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  DiscountAmt?: Maybe<Scalars['Float']>;
};

export type PromoPricingInput = {
  Date?: Maybe<Scalars['DateTime']>;
  Price?: Maybe<Scalars['Float']>;
};

export type PromotionDetailEntity = InterfaceBase & {
  __typename?: 'PromotionDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PromotionID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  Promotion?: Maybe<PromotionEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  RatePolicy?: Maybe<RateEntity>;
};

export type PromotionDetailInput = {
  ID?: Maybe<Scalars['String']>;
  PromotionID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PromotionEntity = InterfaceBase & {
  __typename?: 'PromotionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  PromotionDetail?: Maybe<Array<PromotionDetailEntity>>;
  PromotionDetailRatePolicy?: Maybe<Array<PromotionDetailEntity>>;
};


export type PromotionEntityPromotionDetailRatePolicyArgs = {
  RatePolicyID?: Maybe<Scalars['String']>;
};

export type PromotionInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum PropertyType {
  Apartment = 'Apartment',
  Bungalow = 'Bungalow',
  Cabin = 'Cabin',
  Campsite = 'Campsite',
  Cottage = 'Cottage',
  Dorm = 'Dorm',
  Room = 'Room',
  Villa = 'Villa'
}

export type Q3DocumentDetailEntity = InterfaceTrx & {
  __typename?: 'Q3DocumentDetailEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DownloadDate?: Maybe<Scalars['DateTime']>;
  HeaderID: Scalars['String'];
  DownloadNo?: Maybe<Scalars['Float']>;
  BatchNo?: Maybe<Scalars['Float']>;
  Q3DocumentHeader?: Maybe<Q3DocumentHeaderEntity>;
};

export type Q3DocumentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  DownloadDate?: Maybe<Scalars['DateTime']>;
  HeaderID?: Maybe<Scalars['String']>;
};

export type Q3DocumentHeaderEntity = InterfaceTrx & {
  __typename?: 'Q3DocumentHeaderEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocumentType: Scalars['String'];
  Q3DocumentDetail?: Maybe<Array<Q3DocumentDetailEntity>>;
};

export type Q3DocumentHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
};

export type Q3FStatisticResponse = {
  __typename?: 'Q3FStatisticResponse';
  RoomsAvailable?: Maybe<Scalars['Float']>;
  TotalRoomOccupied?: Maybe<Scalars['Float']>;
  RoomOccupiedWithComplimentary?: Maybe<Scalars['Float']>;
  ComplimentaryRoom?: Maybe<Scalars['Float']>;
  HouseUse?: Maybe<Scalars['Float']>;
  NoOfRoomGuests?: Maybe<Scalars['Float']>;
  NoOfShowRoom?: Maybe<Scalars['Float']>;
  TotalRooms?: Maybe<Scalars['Float']>;
  OutOfOrderRoom?: Maybe<Scalars['Float']>;
  CancellationRoom?: Maybe<Scalars['Float']>;
  DayUseRoom?: Maybe<Scalars['Float']>;
};

export type Q3MarketSegmentMapping = {
  __typename?: 'Q3MarketSegmentMapping';
  RoomAnalysis?: Maybe<Scalars['String']>;
  RoomDepartment?: Maybe<Scalars['String']>;
  RoomDebit?: Maybe<Scalars['String']>;
  GuestsAnalysis?: Maybe<Scalars['String']>;
  GuestsDepartment?: Maybe<Scalars['String']>;
  GuestsDebit?: Maybe<Scalars['String']>;
};

export type Q3MarketSegmentMappingInput = {
  RoomAnalysis?: Maybe<Scalars['String']>;
  RoomDepartment?: Maybe<Scalars['String']>;
  RoomDebit?: Maybe<Scalars['String']>;
  GuestsAnalysis?: Maybe<Scalars['String']>;
  GuestsDepartment?: Maybe<Scalars['String']>;
  GuestsDebit?: Maybe<Scalars['String']>;
};

export type Q3RevenueAcctJson = {
  __typename?: 'Q3RevenueAcctJson';
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseCredit?: Maybe<Scalars['String']>;
};

export type Q3RevenueAcctJsonInput = {
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseCredit?: Maybe<Scalars['String']>;
};

export type Q3Statistic = {
  __typename?: 'Q3Statistic';
  Name?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['String']>;
  Analysis?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type Q3StatisticInput = {
  Name?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['String']>;
  Analysis?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type Q3StatisticsMapping = {
  __typename?: 'Q3StatisticsMapping';
  RoomsAvailable?: Maybe<Q3Statistic>;
  TotalRoomOccupied?: Maybe<Q3Statistic>;
  RoomOccupiedWithComplimentary?: Maybe<Q3Statistic>;
  ComplimentaryRoom?: Maybe<Q3Statistic>;
  HouseUse?: Maybe<Q3Statistic>;
  NoOfRoomGuests?: Maybe<Q3Statistic>;
  NoOfShowRoom?: Maybe<Q3Statistic>;
  TotalRooms?: Maybe<Q3Statistic>;
  OutOfOrderRoom?: Maybe<Q3Statistic>;
  CancellationRoom?: Maybe<Q3Statistic>;
  DayUseRoom?: Maybe<Q3Statistic>;
};

export type Q3StatisticsMappingInput = {
  RoomsAvailable?: Maybe<Q3StatisticInput>;
  TotalRoomOccupied?: Maybe<Q3StatisticInput>;
  RoomOccupiedWithComplimentary?: Maybe<Q3StatisticInput>;
  ComplimentaryRoom?: Maybe<Q3StatisticInput>;
  HouseUse?: Maybe<Q3StatisticInput>;
  NoOfRoomGuests?: Maybe<Q3StatisticInput>;
  NoOfShowRoom?: Maybe<Q3StatisticInput>;
  TotalRooms?: Maybe<Q3StatisticInput>;
  OutOfOrderRoom?: Maybe<Q3StatisticInput>;
  CancellationRoom?: Maybe<Q3StatisticInput>;
  DayUseRoom?: Maybe<Q3StatisticInput>;
};

export type QrScannerDetailEntity = InterfaceBase & {
  __typename?: 'QrScannerDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  QrData?: Maybe<Scalars['String']>;
  DeviceData?: Maybe<Scalars['String']>;
};

export type QrScannerDetailInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  QrData?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsScanned?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  TaxEffectiveDateListing: Array<TaxEffectiveDateEntity>;
  PlatformHeritageTaxListing: PlatformHeritageTaxEntity;
  TaxInUseListing: Scalars['Boolean'];
  ComputeTaxbyHotel: TaxOutput;
  ComputeUpgradebyHotel: TaxOutput;
  ComputeBookingbyHotel: Array<Scalars['JSON']>;
  ComputeTaxBooking: Array<Scalars['JSON']>;
  ComputeTaxArrByHotel: Array<TaxOutput>;
  ComputeTaxbyTaxType: HotelTaxOutput;
  GetAdjustmentTax: AdjustmentTaxOutput;
  GetLevyTax: CurrentTaxOutput;
  GetServiceChargeTax: CurrentTaxOutput;
  GetCurrentTourismTax: CurrentTaxOutput;
  GetPlatformTax: CurrentTaxOutput;
  GetCurrentGovTax: CurrentTaxOutput;
  TaxPolicyListing: Array<HotelTaxPolicyEntity>;
  CMSCustomControl: Scalars['Boolean'];
  WHBookingNotification: Scalars['String'];
  FrontDeskListing: Array<BookingEntity>;
  CheckInListing: Array<BookingEntity>;
  RegistrationByBookingListing: Array<RegistrationEntity>;
  FrontDeskListingV2: Array<RegistrationEntity>;
  CheckInListingCount: Scalars['JSON'];
  AssignRoomFrontDeskListing: Array<BookingEntity>;
  RoomAssignListing: Array<RoomEntity>;
  FloorPlanGallery: GalleryEntity;
  AllRoomAsignListing: Array<RoomEntity>;
  GuestProfile: GuestProfileEntity;
  BookingProfile: BookingEntity;
  SelectFolio: FolioEntity;
  FolioDetails: FolioEntity;
  DebtorFolioInfo?: Maybe<DebtorEntity>;
  FolioDetailsWithIDs: RegistrationEntity;
  DepositListingByBooking: Array<DepositLedgerEntity>;
  DepositListingByBookingV2: Scalars['JSON'];
  UnutilizedDepositListing?: Maybe<DepositResponse>;
  UtilizedDepositListing?: Maybe<DepositResponse>;
  UnutilizedDepositListingV2: Scalars['JSON'];
  UtilizedDepositListingV2: Scalars['JSON'];
  DepositLedgerDetails: Scalars['JSON'];
  DepositRefundDetails: DepositLedgerEntity;
  FrontDeskPaymentListing: Array<FrontDeskResponse>;
  BillingByRegistration: Array<PaymentFoilioResponse>;
  IsRoomAvailable: Scalars['Boolean'];
  IsRoomAvailableGroup: Scalars['JSON'];
  RoomUpgradeCheck: Scalars['Boolean'];
  FrontDeskIncidentalChargeListing: Array<BillLedgerEntity>;
  RegistrationTaxLedgerListing: Array<TaxLedgerEntity>;
  FrontDeskServiceRequestListing: Array<ServiceRequestEntity>;
  ActiveBookingRoomListing: Array<RoomEntity>;
  PrincipalGuestListing: Array<RegistrationEntity>;
  FolioListingByRegistration: Array<FolioEntity>;
  FolioHistoryListing: Array<FolioHistoryResponse>;
  FolioListing: Array<FolioEntity>;
  NonGuestFolioListing: Array<FolioEntity>;
  NonGuestFolioListingV2: SuspendFolioResponse;
  FolioUseListing: Scalars['Boolean'];
  SuspendFolioListing: Array<FolioEntity>;
  SuspendFolioListingV2: SuspendFolioResponse;
  NonGuestBillingListing: Array<NonGuestFolioResponse>;
  PendingBillingListing: Array<NonGuestFolioResponse>;
  SelectedRegistration: RegistrationEntity;
  SuspendDetail: FolioEntity;
  ReceiptListing: Array<PaymentFoilioResponse>;
  PaymentDetail: PaymentEntity;
  AdvancePaymentDetail: AdvancePaymentEntity;
  FrontDeskInfoLines: FrontDeskInfoLineResponse;
  ContactPagination: Array<ContactEntity>;
  RegistrationListing: Array<RegistrationEntity>;
  CurrentTaxDetails: CurrentTaxDetailsOutput;
  FrontDeskBookingInfo: BookingInfoResponse;
  HotelXCredentials: Scalars['JSON'];
  MenuXInhouseList: Scalars['JSON'];
  MenuXInhouseStatus: Scalars['Boolean'];
  MenuXDebtorList: Scalars['JSON'];
  InhouseQr: Scalars['JSON'];
  CheckoutReinstateInfo: CheckoutReinstateResponse;
  FrontDeskSubMenuInfo: Array<FrontDeskInfoResponse>;
  IsLockUserProcess: Scalars['JSON'];
  IsPresetIncChargesExist: Scalars['Boolean'];
  StayViewListing: Scalars['JSON'];
  HotelNotificationListing: Array<HotelNotificationEntity>;
  HotelsByCity: Array<HotelEntity>;
  HotelPricesByDateRange: HotelEntity;
  loggedInGuestProfile?: Maybe<GuestProfileEntity>;
  GuestBookingListing: Array<BookingEntity>;
  DisCountByPromoCode: Array<PromoCodeResponse>;
  GuestRoomServiceListing: Array<BillLedgerEntity>;
  GuestReviewDetail?: Maybe<Scalars['JSON']>;
  GAInHouseGuestListing: GaInHouseResponse;
  OnlineGAInHouseGuestListing: Array<RegistrationEntity>;
  ServicePendingCount: Scalars['JSON'];
  HotelReviewListing: Array<HotelRatingEntity>;
  GAMarketingAdsListing: Array<EmailCampignEntity>;
  IsGuestAppUser: GuestProfileEntity;
  GuestAppFeatureList: GuestAppFeatureEntity;
  PromotionListing: Array<PromotionEntity>;
  PromotionDetailsInUseListing: Scalars['Boolean'];
  PromotionDetailListing: Array<PromotionDetailEntity>;
  EmailMarketingListing: Array<EmailCampignEntity>;
  EmailMarketingDetail: Array<EmailCampignEntity>;
  PromotionInUseListing: Scalars['Boolean'];
  GuestReviewListing: Array<HotelRatingEntity>;
  GuestReviewInfoLines: GuestReviewInfoLineResponse;
  BookingListing: Array<BookingEntity>;
  BookingDetails: BookingEntity;
  AdvancePaymentListing: Array<AdvancePaymentEntity>;
  InhousePaymentListing: Array<PaymentEntity>;
  InhouseRefundListing: Array<PaymentEntity>;
  RoomServiceBookingListing: Array<BookingEntity>;
  RoomNumberForFoodListing: Array<BookingEntity>;
  AvailabilityListing: HotelAvailabilityOutput;
  RoomTypeAvailable: Scalars['JSON'];
  AvailabilityCheckingRevamp: Scalars['JSON'];
  CheckForBlockRoom: Scalars['Boolean'];
  RoleView: Scalars['JSON'];
  PackagePriceListing: Array<PackagePriceOutput>;
  BookingPackagePriceListing: Array<PackagePriceOutput>;
  ChangeOfDateCheckAvailability: Scalars['Boolean'];
  ChangeOfDateCheckAvailabilityBookEdit: Scalars['Boolean'];
  IsRoomNoAvailable: Scalars['JSON'];
  ShowBookingConfirmationEmail: Scalars['String'];
  BookingConfirmationTemplate: Scalars['String'];
  CurrentInhouseList: Array<CurrentInhouseOutput>;
  CurrentInhouseListV7: Scalars['JSON'];
  BillScheduleListByBooking: Scalars['JSON'];
  BillScheduleListing: Scalars['JSON'];
  OAuthHotelX: Scalars['JSON'];
  InventoryRatePlansHotelX: HotelEntity;
  GuestReviewsHotelX: Array<HotelRatingEntity>;
  PromotionListHotelX: Array<PromotionEntity>;
  PromoDiscHotelX: Array<PromoCodeResponse>;
  HotelTaxDetails: CurrentTaxDetailsOutput;
  ComputeTaxHotelX: TaxOutput;
  BookingSubMenuInfo: Scalars['JSON'];
  BookingBillScheduleInfo: BookingBillScheduleResponse;
  BookingIncChargesRegistrationListing: Array<RegistrationEntity>;
  IsExistRegistrationIncCharges: Scalars['Boolean'];
  ChannelManagerProfile: ChannelManagerEntity;
  EntRoleUsrAsgList: Array<EntRoleUsrAsgEntity>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  HotelAuthorizationInfoLine: Scalars['JSON'];
  getHotelPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  GetMenuOption: RoleEntity;
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  RoundingAdjustment: Scalars['JSON'];
  RoundingAmount: Scalars['JSON'];
  SubscribedPackages: Scalars['JSON'];
  GetUserByUsername: UserEntity;
  loggedInUserProfile: UserEntity;
  UserType: Scalars['JSON'];
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  updateLastestAccessHotel: UserEntity;
  isLoginPermitted: UserEntity;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  InterstTotal: InterestResponse;
  TestService: Scalars['String'];
  HLSInvetoryUpdate: Scalars['String'];
  ArrayChallenge: Scalars['JSON'];
  DebitNoteDetail: DebitNoteEntity;
  GLAccountPeriod: Scalars['JSON'];
  DebitNoteListings: Array<DebitNoteEntity>;
  CreditNoteListingsOld: Array<CreditNoteEntity>;
  CreditNoteListings: Scalars['JSON'];
  RefundDetail: RefundEntity;
  CheckBanknameInUse: Scalars['JSON'];
  RefundListings: Array<RefundEntity>;
  EInvoiceDocListings: Array<InvoiceEntity>;
  InvoiceDetail: InvoiceEntity;
  PostedScheduleBilling: Scalars['JSON'];
  InvoiceListings: Array<InvoiceEntity>;
  CreditNoteAndInvoiceCount: Scalars['JSON'];
  GroupInvoiceCount: Scalars['JSON'];
  CreditNoteAndInvoiceListings: Scalars['JSON'];
  GroupCreditNoteAndInvoiceListings: Scalars['JSON'];
  CityLedgerInvoiceListings: Scalars['JSON'];
  ARInvoiceListings: Array<InvoiceEntity>;
  StatementOfAccDocument: DocResponse;
  OfficialReceiptDetail: OfficialReceiptEntity;
  OfficialReceiptListingsOld: Array<OfficialReceiptEntity>;
  OfficialReceiptListings: Scalars['JSON'];
  ReminderListing: ReminderEntity;
  ReminderListings: Array<ReminderEntity>;
  InterestListing: InterestEntity;
  InterestListings: Array<ReminderEntity>;
  DebtorAgingListing: Scalars['JSON'];
  AgingByDebtor: Scalars['JSON'];
  MonthlyStatementListing: Scalars['JSON'];
  OpeningClosingAmount: OpenClosedResponse;
  OpeningClosingAmountV2: Scalars['JSON'];
  DebtorListing: Array<DebtorEntity>;
  DebtorTypeCount: Scalars['JSON'];
  DebtorCountListing: Scalars['JSON'];
  DebtorTypeCountListing: Scalars['JSON'];
  ScheduleBillingCountList: Scalars['JSON'];
  ActivitiesCountListing: Scalars['JSON'];
  DebtorListingCreditFacility: Array<DebtorEntity>;
  DebtorListingNonCreditFacility: Array<DebtorEntity>;
  CalculateDebtorListing: Scalars['JSON'];
  DebtorDetail: DebtorEntity;
  DebtorDetailByAccountCode: DebtorEntity;
  CreditNoteAllocationListing: Scalars['JSON'];
  DebitNoteAllocationListing: Scalars['JSON'];
  AllocationDetailListing: Scalars['JSON'];
  InvoiceInfo: ArSummaryInfo;
  DebitNoteInfo: ArSummaryInfo;
  CreditNoteInfo: ArSummaryInfo;
  OfficialReceiptInfo: ArSummaryInfo;
  RefundInfo: ArSummaryInfo;
  ARAccountSummary: ArAccountSummary;
  ARTotalOutstanding: ArAccountOsLastPayment;
  ARTotalDue: Scalars['Float'];
  ARAgingAnalysis: ArAgingAnalysisRespose;
  TotalAgingBI: Scalars['String'];
  DocumentNumberHeaderListing: Array<DocumentNumberHeaderEntity>;
  DebtorTypeListing: Array<DebtorTypeEntity>;
  DebtorTypeInUse: Scalars['Boolean'];
  ScheduleBillingList: Array<ScheduleBillingResponse>;
  AuditLogDetails: Scalars['JSON'];
  RoomSaleOptimizationListing: Array<RoomSaleOptimizationOutput>;
  BookingAttachmentListing: Array<BookingAttachmentEntity>;
  BookingAttachmentSummary: Array<BookingAttachmentEntity>;
  BIBillLedgerListing: Array<BillLedgerEntity>;
  BITransactionCancellation: Array<BillLedgerEntity>;
  BIDepositLedgerListing: Array<BookingEntity>;
  BIHotelListing: HotelEntity;
  BIAnalysis: Array<BookingEntity>;
  BISource: Array<BookingEntity>;
  BISourceV2: Scalars['JSON'];
  BISourceV3: Scalars['JSON'];
  BITop10Country: Array<BookingEntity>;
  BITop10CountryV2: Scalars['JSON'];
  BITop10Nationality: Array<BookingEntity>;
  BITop10NationalityV2: Scalars['JSON'];
  BICorpGovTa: Array<BookingEntity>;
  BICorpGovTaV2: Scalars['JSON'];
  BIMarketingPersonnal: Array<BookingEntity>;
  BIMarketingPersonnalV2: Scalars['JSON'];
  BIRoomTypeRevenueDetail: Array<BookingEntity>;
  BIRoomTypeRevenueDetailV2: Scalars['JSON'];
  BIRevenueByRoomTypeAnalysis: Scalars['JSON'];
  BITop5OTA: Array<BookingEntity>;
  BITop5OTAV2: Scalars['JSON'];
  BISegment: Array<BookingEntity>;
  BISegmentV2: Scalars['JSON'];
  BITop10Revenue: Array<BookingEntity>;
  BITop10RevenueV2: Scalars['JSON'];
  TotalOverdueByDebtorType: Scalars['JSON'];
  DebtorAging: Scalars['JSON'];
  TotalDueAging: Scalars['JSON'];
  Top10DebtorRevenue: Scalars['JSON'];
  CollectionByDebtorTypeVSOverdue: Scalars['JSON'];
  BusinessAnalyticInfoLine: Scalars['JSON'];
  CountItem: RegAndBooking;
  BIRoomRateListing: Array<BookingEntity>;
  BIUsersLisiting: UserEntity;
  HotelStateAnalysis: Scalars['JSON'];
  StateAnalysisRateAndRoom: Array<BookingEntity>;
  BIInHouse: Array<RegistrationEntity>;
  BiAdvPaymentListing: Array<AdvancePaymentEntity>;
  BIPaymentListing: Array<PaymentTestClass>;
  PaymentDetails: PaymentEntity;
  DepositClass: DepositLedgerEntity;
  DepositForfeitlist: Array<DepositLedgerEntity>;
  DepositRefundlist: Array<DepositLedgerEntity>;
  AdvancePaymentDetails: AdvancePaymentEntity;
  AdvancePaymentReversedDetails: Array<AdvancePaymentEntity>;
  RegistrationDetails: RegistrationEntity;
  RegistrationDetailsMulti: Array<RegistrationEntity>;
  BIPaymentListingTest: Array<PaymentListing123>;
  BIRegistrationListing: Array<BookingEntity>;
  BIHotelDetails: HotelEntity;
  BIRoomListing: Array<RoomEntity>;
  BIServiceRequestListing: Array<ServiceRequestEntity>;
  BIRevenueListing: Array<RevenueAnalysis>;
  BIRevenueListingV2: Scalars['JSON'];
  BIYTDRevenue: RevenueAnalysis;
  BICorpGovTAYTDRevenue: RevenueAnalysis;
  BISourceYTDRevenue: RevenueAnalysis;
  BITopCountry: Array<RevenueAnalysis>;
  BITopNationality: Array<RevenueAnalysis>;
  BIMarketingPersonnalTopSales: RevenueAnalysis;
  BIRoomTypeRevenue: RevenueAnalysis;
  BITravelAgent: Array<RevenueAnalysis>;
  BISegmentRevenue: RevenueAnalysis;
  BITopTransaction: RevenueAnalysis;
  BIServiceRequest: Array<RevenueAnalysis>;
  BIForeignListing: Array<BookingEntity>;
  BISecurityKeyCardDeposit: Array<DepositLedgerEntity>;
  BIRoomDiscrepancyLog: Array<RoomDiscrepancyLogEntity>;
  BIRoomStatus: Array<RoomEntity>;
  BIBookingStatus: Array<BookingEntity>;
  BIFolioListing: BiFolioResponse;
  DepositListing: BiFolioResponse;
  BIAdvancePaymentListing: Array<AdvancePaymentEntity>;
  BIReceiptRefundListing: BiReceiptRefundResponse;
  BITestListing: Array<FolioOutput>;
  BITransactionLedger: Array<BookingEntity>;
  BIPackage: Array<BillScheduleEntity>;
  ManagerReport: ManagerReportOutput;
  ManagerReportDepartment: ManagerReportOutput;
  CheckRecord?: Maybe<Output>;
  RoomAllocationLocationListing: Array<Scalars['JSON']>;
  ChooseRoomAllocationListing: Array<Scalars['JSON']>;
  ChooseRoomAllocationDetail: Array<ChooseRoomAllocationEntity>;
  IsChargeable: GuestAppFeatureEntity;
  CompanyListing: Array<CompanyEntity>;
  CurrencyListing: Array<CurrencyEntity>;
  CountryListing: Array<CountryEntity>;
  StateListing: Array<StateEntity>;
  DebtorListingDropDown: Array<DebtorEntity>;
  CorporateGovernmentListing: Array<DebtorEntity>;
  GovernmentListing: Array<DebtorEntity>;
  CorporateBookingListing: Array<BookingEntity>;
  CorporateGovernmentDetail: DebtorEntity;
  CorporateContractListing: Array<ContractEntity>;
  ContractDetails: ContractEntity;
  CorporateInUseListing: Scalars['Boolean'];
  CoporateInUseListing: Scalars['Boolean'];
  TotalAging: Scalars['JSON'];
  ActivitiesListing: Array<ActivitiesEntity>;
  DashBoardAllListing: DashBoardRespond;
  DashBoardListing: Array<BookingEntity>;
  AdvancePaymentFullListing: Array<AdvancePaymentEntity>;
  PaymentListing: Array<PaymentEntity>;
  RoomServicesCount: Scalars['Float'];
  HotelStatisticListing: HotelStatisticRespond;
  HotelStatisticListingV2: Scalars['JSON'];
  DocumentTemplateList: DocumentTemplateEntity;
  ConsolidatedInvoiceListing: Array<EInvoiceConsolidationEntity>;
  DocToConsolidateListing: Scalars['JSON'];
  DocToConsolidateView: Scalars['JSON'];
  getMsicCodeListing: Array<EInvoiceMsicEntity>;
  getClassificationListing: Array<EInvoiceClassificationEntity>;
  getClassificationListingByCode: EInvoiceClassificationEntity;
  getGeneralTin: Array<GeneralTinEntity>;
  EInvoiceInformation: Scalars['JSON'];
  getFolioInfo: EInvoiceQrResponse;
  EInvSubscription: Array<EInvoiceSubscriptionEntity>;
  NARegistrationDetails: Array<NaRegistrationEntity>;
  GuestProfileListing: GuestProfileResponse;
  GuestProfileListingV2: Array<GuestProfileEntity>;
  GuestProfileListingByID: Array<RegistrationEntity>;
  SelectedGuestListing: GuestProfileEntity;
  GuestHistoryListing: Array<RegistrationEntity>;
  GuestHistoryListingV2?: Maybe<Scalars['JSON']>;
  GuestProfileHistoryListing: Array<RegistrationEntity>;
  GuestProfileListbyRoom: Array<GuestProfileEntity>;
  SearchGuest?: Maybe<Array<SearchGuestResponse>>;
  GuestMovementList?: Maybe<Scalars['JSON']>;
  GuestMovementListing: Array<GuestMovementEntity>;
  GuestListingByLocationTime: Array<GuestMovementEntity>;
  CBExport: Scalars['JSON'];
  ExportedCBListing: Scalars['JSON'];
  ExportedCBDetails: Array<Scalars['JSON']>;
  CBExportDetailsByTrxType: Scalars['JSON'];
  ExportedGLListing: Scalars['JSON'];
  ExportedGLDetails: Array<Scalars['JSON']>;
  ExportedGLTransferDetails: Array<ExportedGlTransferResponse>;
  GLExport: Scalars['JSON'];
  GLExportDetailsByTrxType: Scalars['JSON'];
  RoomServiceFolioListing: Array<FolioEntity>;
  PaymentRoomServiceDetail: PaymentEntity;
  BillLedgerFullListing: Array<BillLedgerEntity>;
  PaymentBillLedgerListing: Array<BillLedgerEntity>;
  PaymentRegistrationListing: RegistrationEntity;
  checkoutfoliodeposiit: Array<FolioEntity>;
  CheckedOutFolioListingOLD: Scalars['JSON'];
  CheckedOutFolioListing: Scalars['JSON'];
  AdjustedFolioDetails: Array<FolioEntity>;
  CheckoutAdjustmentListing: Array<FolioEntity>;
  SearchFolio?: Maybe<FolioEntity>;
  RoomServiceListing: Array<BillLedgerEntity>;
  DeliveredRoomServiceListing: Array<BillLedgerEntity>;
  RoomServiceRegistrationListing: Array<RegistrationEntity>;
  DeliveredRoomServiceRegistrationListing: Array<RegistrationEntity>;
  GuestRoomResult: Scalars['JSON'];
  FolioRegIds: Scalars['JSON'];
  CheckIsGroupInvoice: Scalars['JSON'];
  FolioChargeToList: Scalars['JSON'];
  ExportedStatisticListing: Scalars['JSON'];
  ExportedStatisticDetails: Array<StatisticExportEntity>;
  StatisticJournal: Scalars['JSON'];
  IsHotelLockUser?: Maybe<Scalars['Boolean']>;
  TTlockInfo?: Maybe<Scalars['JSON']>;
  HotelAccountXList?: Maybe<HotelVendorInfoEntity>;
  LoyaltyAppHotelGroup: Scalars['JSON'];
  CheckNAProcessLog: Scalars['Boolean'];
  OutGoingDocTemplate?: Maybe<OutgoingDocTemplateEntity>;
  PackageRedemptionListing: Array<PackageRedemptionListOutput>;
  PackageRedemptionByItem: Array<PackageRedemptionListOutput>;
  PreRegBookingInfo?: Maybe<Scalars['JSON']>;
  PreRegRegistrationDetails: RegistrationEntity;
  BillSummaryDetails: Scalars['JSON'];
  PreRegRoomAllocation: Array<Scalars['JSON']>;
  IsRoomExist: Scalars['Boolean'];
  PreRegBooking?: Maybe<Scalars['JSON']>;
  GuestAppFeature: GuestAppFeatureEntity;
  PreCheckInQr: Scalars['JSON'];
  IsBookingExist: Scalars['JSON'];
  Q3DocumentHeader: Array<Q3DocumentHeaderEntity>;
  Q3Listings: Array<Scalars['JSON']>;
  IsQ3FInterface: Scalars['Boolean'];
  Q3FSegment: Scalars['JSON'];
  Q3FRevenueOld: Scalars['JSON'];
  Q3FRevenue: Scalars['JSON'];
  Q3FStatistic: Scalars['JSON'];
  Q3DocumentDetailByHeader: Array<Q3DocumentDetailEntity>;
  Q3MappingChargesList: Scalars['JSON'];
  Q3MappingPaymentList: Scalars['JSON'];
  Q3MappingAdvPaymentList: Scalars['JSON'];
  Q3MappingRefundList: Scalars['JSON'];
  Q3MappingDepositList: Scalars['JSON'];
  Q3MappingTaxList: Scalars['JSON'];
  Q3MappingDebtorList: Scalars['JSON'];
  HousekeepingListing: Array<RoomEntity>;
  TodayBookingListing: Array<BookingEntity>;
  HousekeepingDetail: RoomEntity;
  IsBlockRoomAvailable: Scalars['JSON'];
  HousekeepingServiceRequestListing: Array<ServiceRequestEntity>;
  HousekeepingRoomStatusListing: Array<RoomStatusEntity>;
  BillLedgerListing: Array<BillLedgerEntity>;
  HousekeepingIncidentalChargeListing: Array<IncidentalChargeEntity>;
  HKMasterListing: Scalars['JSON'];
  ServiceRequestDetail: Array<ServiceRequestEntity>;
  ServiceRequestFullListing: Array<ServiceRequestEntity>;
  ServiceRequestListing: Array<ServiceRequestEntity>;
  ServiceRequestv2Listing: Array<ServiceRequestEntity>;
  SrLocationListing: Array<LocationEntity>;
  DepartmentListing: Array<DepartmentEntity>;
  DepartmentInUseListing: Scalars['Boolean'];
  IncidentalDepartmentListing: Array<DepartmentEntity>;
  DeparmentChecking: Scalars['Boolean'];
  EDocTemplateListing: Array<EDocTemplateEntity>;
  EDocParamsListing: Array<EDocParamsEntity>;
  ConfirmationLetterListing: Array<EDocTemplateEntity>;
  AccountMappingChargesList: Scalars['JSON'];
  AccountMappingPaymentList: Scalars['JSON'];
  AccountMappingAdvPaymentList: Scalars['JSON'];
  AccountMappingRefundList: Scalars['JSON'];
  AccountMappingDepositList: Scalars['JSON'];
  CityLedgerChargesList: Scalars['JSON'];
  CityLedgerDebtorList: Scalars['JSON'];
  AccountMappingTaxGLList: Scalars['JSON'];
  CityLedgerTaxGLList: Scalars['JSON'];
  ExternalStatisticMapping: Array<StatisticMappingExternalEntity>;
  BankAccountListing: Array<BankAccountEntity>;
  HotelListing: Array<HotelEntity>;
  MenuXHotelListing: Array<HotelEntity>;
  HotelDetails: HotelEntity;
  HotelPreRegQr: Scalars['JSON'];
  GuestappsettingDetail?: Maybe<Scalars['JSON']>;
  IsPaymentGateWayHotel: Scalars['Boolean'];
  ServicePaymentSetting: Scalars['JSON'];
  CustomizedDocListing: Array<CustomizedDocumentEntity>;
  IncidentalChargeListing: Array<IncidentalChargeEntity>;
  IncidentalChargeByID: IncidentalChargeEntity;
  IncidentalChargeInUseListing: Scalars['Boolean'];
  IncidentalChargeInRateElement: Scalars['JSON'];
  RateElementInactiveCheck: Scalars['JSON'];
  IncidentalChargeListingByHotel: Array<IncidentalChargeEntity>;
  LateCheckOutChargeListing: Array<LateCheckOutChargeEntity>;
  FloorPlanListing: Array<LocationFloorPlanEntity>;
  LocationListing: Array<LocationEntity>;
  LocationFloorPlanListing: Array<LocationEntity>;
  LocationInUseListing: Scalars['Boolean'];
  RoomInUseListing: Scalars['Boolean'];
  RoomInHouseListing: Scalars['Boolean'];
  RoomBlockRoomListing: Scalars['Boolean'];
  HKLocationMasterListing: Scalars['JSON'];
  MeasurementListing: Array<MeasurementEntity>;
  PlatformAmenitiesListing: Array<PlatformAmenitiesEntity>;
  PlatformDepartmentListing: Array<PlatformDepartmentEntity>;
  RateElementListing: Array<RateElementEntity>;
  RateAddOnListing: Array<RateAddOnEntity>;
  RateTypeListing: Array<RateTypeEntity>;
  RateListing: Array<RateEntity>;
  RateInHouseListing: Scalars['Boolean'];
  RateEffectiveListing: Array<RateEffectiveEntity>;
  RatePolicyInfo: RatePolicyResponse;
  RateAdjustmentAuditListing: Array<RateAdjustmentAuditEntity>;
  IsRateInUse: Scalars['Boolean'];
  RegenBSQueueListing: Array<RegenBsQueueEntity>;
  SeasonListing: Array<SeasonEntity>;
  SeasonListingCalender: Array<SeasonEntity>;
  SeasonCalendarListing: Array<SeasonCalendarEntity>;
  RatePolicyListing: Array<RateEntity>;
  RatePolicyRoomTypeListing: Array<RoomTypeEntity>;
  RatePolicyDetails: RatePolicyEntity;
  RatePolicyInUseListing: Scalars['Boolean'];
  ReasonListing: Array<ReasonEntity>;
  CancelReasonListing: Array<ReasonEntity>;
  BookingCancelReasonListing: Array<ReasonEntity>;
  ReaonInUseListing: Scalars['Boolean'];
  RoomListing: Array<RoomEntity>;
  BlockRoomLogListing: Scalars['Boolean'];
  BlockRoomLogListingV2: Array<BlockRoomLogEntity>;
  HotelRoomNumberList: Array<RoomEntity>;
  RoomStatusListing: Array<RoomStatusEntity>;
  RoomTypeAmenitiesListing: Array<RoomTypeAmenitiesEntity>;
  RoomTypeListing: Array<RoomTypeEntity>;
  RegistrationRoomTypeListing: Scalars['Boolean'];
  SalesChannelListing: Array<SalesChannelEntity>;
  HotelSalesChannelListing: Array<SalesChannelEntity>;
  SalesChannelinUseListing: Scalars['Boolean'];
  SegmentListing: Array<SegmentEntity>;
  SegmentInUseListing: Scalars['Boolean'];
  GetTaxTypeListing: Scalars['JSON'];
  GetTaxListing: Scalars['JSON'];
  GetTaxListingDropdown: Array<TaxListingOutput>;
  GetTaxDetails: TaxEntity;
  IsTaxInUse: Scalars['Boolean'];
  IsTaxSameCode: Scalars['Boolean'];
  GetTaxSchemeListing: Array<TaxSchemeEntity>;
  GetTaxSchemeDetailItemRate: Array<TaxSchemeDetailItemRateOutput>;
  GetTaxSchemeDetail: Array<TaxSchemeDetailOutput>;
  IsTaxSchemeInUse: Scalars['Boolean'];
  IsTaxSameName: Scalars['Boolean'];
  TravelAgentHotelListing: Array<HotelEntity>;
  TravelAgentListing: Array<DebtorEntity>;
  AgentInUseLisiting: Scalars['Boolean'];
  TravelAgentDetail: DebtorEntity;
  PMSTravelAgentDetail: DebtorEntity;
  TravelAgentContractListing: Array<ContractEntity>;
  TravelAgBookingListing: Array<BookingEntity>;
  TravelAgentInUseListing: Scalars['Boolean'];
  AllotmentListing: Array<ContractAllotmentEntity>;
  loggedInDebtorProfile: DebtorProfileEntity;
  DebtorAnalysis: Array<ContractEntity>;
  TravelAgentRoomRevenue: Array<RegistrationEntity>;
};


export type QueryTaxInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryComputeTaxbyHotelArgs = {
  input: TaxInput;
};


export type QueryComputeUpgradebyHotelArgs = {
  Amount?: Maybe<Scalars['Float']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Mode: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryComputeBookingbyHotelArgs = {
  HotelID: Scalars['String'];
  input: Array<TaxInput>;
};


export type QueryComputeTaxBookingArgs = {
  DiscountAmount: Scalars['Float'];
  HotelID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RateID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};


export type QueryComputeTaxArrByHotelArgs = {
  TaxInput: Array<TaxInput>;
};


export type QueryComputeTaxbyTaxTypeArgs = {
  HotelID: Scalars['String'];
  input: TaxType;
};


export type QueryGetAdjustmentTaxArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGetLevyTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetServiceChargeTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentTourismTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetPlatformTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentGovTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryTaxPolicyListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryCmsCustomControlArgs = {
  BookingID: Scalars['String'];
  ControlOP: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryWhBookingNotificationArgs = {
  HotelID: Scalars['String'];
};


export type QueryFrontDeskListingArgs = {
  SortByOrder?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  RoomTypeID2?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryCheckInListingArgs = {
  AccountName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  SearchValue?: Maybe<Scalars['String']>;
  BookingStatus: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRegistrationByBookingListingArgs = {
  RoomNo?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  SearchValue?: Maybe<Scalars['String']>;
  RegistrationStatus: Scalars['String'];
  BookingID: Array<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryFrontDeskListingV2Args = {
  Search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortByOrder?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  SearchValue?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryCheckInListingCountArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAssignRoomFrontDeskListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomAssignListingArgs = {
  RoomID?: Maybe<Array<Scalars['String']>>;
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  RoomTypeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFloorPlanGalleryArgs = {
  ID: Scalars['String'];
};


export type QueryAllRoomAsignListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  IsBooking?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryGuestProfileArgs = {
  GuestID: Scalars['String'];
};


export type QueryBookingProfileArgs = {
  ID: Scalars['String'];
};


export type QuerySelectFolioArgs = {
  ID: Scalars['String'];
};


export type QueryFolioDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryDebtorFolioInfoArgs = {
  HotelID: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
};


export type QueryFolioDetailsWithIDsArgs = {
  LedgerIDs?: Maybe<LedgerIDsInput>;
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositListingByBookingArgs = {
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositListingByBookingV2Args = {
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryUnutilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryUtilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryUnutilizedDepositListingV2Args = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryUtilizedDepositListingV2Args = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryDepositLedgerDetailsArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositRefundDetailsArgs = {
  HotelID: Scalars['String'];
  DepositID: Scalars['String'];
};


export type QueryFrontDeskPaymentListingArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBillingByRegistrationArgs = {
  IsHMS?: Maybe<Scalars['Boolean']>;
  RegistrationID: Array<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type QueryIsRoomAvailableArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryIsRoomAvailableGroupArgs = {
  RegistrationIDs: Array<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomIDs: Array<Scalars['String']>;
};


export type QueryRoomUpgradeCheckArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFrontDeskIncidentalChargeListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryRegistrationTaxLedgerListingArgs = {
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryFrontDeskServiceRequestListingArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryActiveBookingRoomListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPrincipalGuestListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryFolioListingByRegistrationArgs = {
  AccountID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryFolioHistoryListingArgs = {
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryFolioListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryNonGuestFolioListingArgs = {
  FolioID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryNonGuestFolioListingV2Args = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  Title?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ReferenceID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryFolioUseListingArgs = {
  HotelID: Scalars['String'];
  FolioID: Scalars['String'];
};


export type QuerySuspendFolioListingArgs = {
  FolioID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QuerySuspendFolioListingV2Args = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  AccountName?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  Title?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  ReferenceID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryNonGuestBillingListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
};


export type QueryPendingBillingListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
};


export type QuerySelectedRegistrationArgs = {
  ID: Scalars['String'];
};


export type QuerySuspendDetailArgs = {
  ID: Scalars['String'];
};


export type QueryReceiptListingArgs = {
  PaymentClass: PaymentClass;
  ReceiptNo: Scalars['String'];
};


export type QueryPaymentDetailArgs = {
  HotelID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Array<Scalars['String']>>;
};


export type QueryAdvancePaymentDetailArgs = {
  HotelID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
};


export type QueryFrontDeskInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryContactPaginationArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type QueryRegistrationListingArgs = {
  RoomID: Scalars['String'];
};


export type QueryCurrentTaxDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryFrontDeskBookingInfoArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHotelXCredentialsArgs = {
  isHotel?: Maybe<Scalars['Boolean']>;
  Authorization: Scalars['String'];
};


export type QueryMenuXInhouseListArgs = {
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryMenuXInhouseStatusArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryMenuXDebtorListArgs = {
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryInhouseQrArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryCheckoutReinstateInfoArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryFrontDeskSubMenuInfoArgs = {
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  HotelID: Scalars['String'];
};


export type QueryIsLockUserProcessArgs = {
  RoomNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsPresetIncChargesExistArgs = {
  newDepartureDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryStayViewListingArgs = {
  Location?: Maybe<Array<Scalars['String']>>;
  RoomNo?: Maybe<Array<Scalars['String']>>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  enddate: Scalars['DateTime'];
  startdate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryHotelNotificationListingArgs = {
  NotificationType: Scalars['String'];
  UserID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHotelsByCityArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  DebtorType?: Maybe<Scalars['String']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  NoOfGuests?: Maybe<Scalars['Float']>;
  NoOfRooms?: Maybe<Scalars['Float']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  City: Scalars['String'];
};


export type QueryHotelPricesByDateRangeArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGuestBookingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryDisCountByPromoCodeArgs = {
  PromoCodeInput: Array<PromoCodeInput>;
  PromoCode: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestRoomServiceListingArgs = {
  ServiceType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryGuestReviewDetailArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  Rating?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGaInHouseGuestListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
};


export type QueryOnlineGaInHouseGuestListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
};


export type QueryServicePendingCountArgs = {
  GuestID?: Maybe<Scalars['String']>;
};


export type QueryHotelReviewListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryGaMarketingAdsListingArgs = {
  isLoyaltyApp?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIsGuestAppUserArgs = {
  Login?: Maybe<Scalars['String']>;
};


export type QueryGuestAppFeatureListArgs = {
  HotelID: Scalars['String'];
};


export type QueryPromotionListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryPromotionDetailsInUseListingArgs = {
  PromoCode: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryPromotionDetailListingArgs = {
  PromotionID: Scalars['String'];
};


export type QueryEmailMarketingListingArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryEmailMarketingDetailArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryPromotionInUseListingArgs = {
  Date: Scalars['DateTime'];
  PromoCode: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGuestReviewListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  Rating?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGuestReviewInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryBookingListingArgs = {
  SearchValue?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortByOrder?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingDate?: Maybe<Scalars['DateTime']>;
  BookingStatus?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
};


export type QueryBookingDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAdvancePaymentListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryInhousePaymentListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryInhouseRefundListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryRoomServiceBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomNumberForFoodListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAvailabilityListingArgs = {
  TravelAgentID?: Maybe<Scalars['String']>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  Adddate?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRoomTypeAvailableArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryAvailabilityCheckingRevampArgs = {
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryCheckForBlockRoomArgs = {
  RoomID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRoleViewArgs = {
  UserID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryPackagePriceListingArgs = {
  NoOfRoom?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBookingPackagePriceListingArgs = {
  NoOfRoom?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Array<Scalars['String']>>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryChangeOfDateCheckAvailabilityArgs = {
  Reinstate?: Maybe<Scalars['Boolean']>;
  DepartureDate: Scalars['DateTime'];
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryChangeOfDateCheckAvailabilityBookEditArgs = {
  roomTypeIDs?: Maybe<Array<Scalars['String']>>;
  Reinstate?: Maybe<Scalars['Boolean']>;
  EditRegInput: Array<EditRegInput>;
  DepartureDate: Scalars['DateTime'];
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsRoomNoAvailableArgs = {
  RegistrationInput?: Maybe<Array<RegistrationInput>>;
  HotelID: Scalars['String'];
};


export type QueryShowBookingConfirmationEmailArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBookingConfirmationTemplateArgs = {
  TemplateID?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type QueryCurrentInhouseListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCurrentInhouseListV7Args = {
  HotelID: Scalars['String'];
};


export type QueryBillScheduleListByBookingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  IsFrontDesk?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
};


export type QueryBillScheduleListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  IsFrontDesk?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
};


export type QueryOAuthHotelXArgs = {
  ClientID?: Maybe<Scalars['String']>;
  Secret: Scalars['String'];
};


export type QueryInventoryRatePlansHotelXArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ClientID: Scalars['String'];
};


export type QueryGuestReviewsHotelXArgs = {
  ClientID?: Maybe<Scalars['String']>;
};


export type QueryPromotionListHotelXArgs = {
  ClientID: Scalars['String'];
};


export type QueryPromoDiscHotelXArgs = {
  PromoCodeInput: Array<PromoCodeInput>;
  PromoCode: Scalars['String'];
  ClientID: Scalars['String'];
};


export type QueryHotelTaxDetailsArgs = {
  ClientID: Scalars['String'];
};


export type QueryComputeTaxHotelXArgs = {
  input: TaxInput;
};


export type QueryBookingSubMenuInfoArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBookingBillScheduleInfoArgs = {
  IsCurrentDate?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryBookingIncChargesRegistrationListingArgs = {
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryIsExistRegistrationIncChargesArgs = {
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryChannelManagerProfileArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryEntRoleUsrAsgListArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  hotelID: Scalars['String'];
};


export type QueryHotelAuthorizationInfoLineArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetUsersRoleContractArgs = {
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryRoundingAdjustmentArgs = {
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryRoundingAmountArgs = {
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QuerySubscribedPackagesArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetUserByUsernameArgs = {
  UserName: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  status?: Maybe<CommonStatus>;
  superUser?: Maybe<Scalars['Boolean']>;
  LatetestUser?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryUpdateLastestAccessHotelArgs = {
  hotelID: Scalars['String'];
  userIDs: Scalars['String'];
};


export type QueryIsLoginPermittedArgs = {
  deviceIP: Scalars['String'];
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryHlsInvetoryUpdateArgs = {
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  RateIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryArrayChallengeArgs = {
  arr: Array<Scalars['Float']>;
};


export type QueryDebitNoteDetailArgs = {
  ID: Scalars['String'];
};


export type QueryGlAccountPeriodArgs = {
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryDebitNoteListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteListingsOldArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRefundDetailArgs = {
  ID: Scalars['String'];
};


export type QueryCheckBanknameInUseArgs = {
  BankName: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRefundListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryEInvoiceDocListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryInvoiceDetailArgs = {
  ID: Scalars['String'];
};


export type QueryPostedScheduleBillingArgs = {
  DebtorID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryInvoiceListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteAndInvoiceCountArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGroupInvoiceCountArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteAndInvoiceListingsArgs = {
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGroupCreditNoteAndInvoiceListingsArgs = {
  GroupReferenceNo?: Maybe<Scalars['String']>;
  GroupInvoiceNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCityLedgerInvoiceListingsArgs = {
  Status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryArInvoiceListingsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryStatementOfAccDocumentArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryOfficialReceiptDetailArgs = {
  ID: Scalars['String'];
};


export type QueryOfficialReceiptListingsOldArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryOfficialReceiptListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryReminderListingArgs = {
  ID: Scalars['String'];
};


export type QueryInterestListingArgs = {
  ID: Scalars['String'];
};


export type QueryDebtorAgingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAgingByDebtorArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryMonthlyStatementListingArgs = {
  ID: Scalars['String'];
};


export type QueryOpeningClosingAmountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryOpeningClosingAmountV2Args = {
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryDebtorListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorTypeCountArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorCountListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorTypeCountListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryScheduleBillingCountListArgs = {
  DebtorAcc?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryActivitiesCountListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorListingCreditFacilityArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorListingNonCreditFacilityArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryCalculateDebtorListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorDetailArgs = {
  ID: Scalars['String'];
};


export type QueryDebtorDetailByAccountCodeArgs = {
  HotelID: Scalars['String'];
  AccountCode: Scalars['String'];
};


export type QueryCreditNoteAllocationListingArgs = {
  DebtorID: Scalars['String'];
  CreditID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDebitNoteAllocationListingArgs = {
  DebtorID: Scalars['String'];
  DebitID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAllocationDetailListingArgs = {
  DebtorID: Scalars['String'];
  CreditID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryInvoiceInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryDebitNoteInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryCreditNoteInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryOfficialReceiptInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryRefundInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArAccountSummaryArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryArTotalOutstandingArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArTotalDueArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArAgingAnalysisArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryTotalAgingBiArgs = {
  HotelID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryDocumentNumberHeaderListingArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorTypeListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryDebtorTypeInUseArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryScheduleBillingListArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsGroupInvoice?: Maybe<Scalars['String']>;
  IsGroupBooking?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  DocEndDate?: Maybe<Scalars['DateTime']>;
  DocStartDate?: Maybe<Scalars['DateTime']>;
  DebtorTypeID: Array<Scalars['String']>;
  DebtorAcc: Array<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryAuditLogDetailsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  FieldID: Scalars['String'];
  TableName: Scalars['String'];
};


export type QueryRoomSaleOptimizationListingArgs = {
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBookingAttachmentListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryBookingAttachmentSummaryArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryBiBillLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTransactionCancellationArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiDepositLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiHotelListingArgs = {
  ID: Scalars['String'];
};


export type QueryBiAnalysisArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSourceArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSourceV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSourceV3Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10CountryArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10CountryV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiTop10NationalityArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10NationalityV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueDetailArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueDetailV2Args = {
  Mode?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRevenueByRoomTypeAnalysisArgs = {
  Mode?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiTop5OtaArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop5Otav2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSegmentArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSegmentV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiTop10RevenueArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10RevenueV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTotalOverdueByDebtorTypeArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorAgingArgs = {
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTotalDueAgingArgs = {
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTop10DebtorRevenueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryCollectionByDebtorTypeVsOverdueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBusinessAnalyticInfoLineArgs = {
  HotelID: Scalars['String'];
};


export type QueryCountItemArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiRoomRateListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  GuestID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBiUsersLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryHotelStateAnalysisArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryStateAnalysisRateAndRoomArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiInHouseArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiAdvPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryPaymentDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryDepositClassArgs = {
  ReversedID: Scalars['String'];
};


export type QueryDepositForfeitlistArgs = {
  ReversedID: Scalars['String'];
};


export type QueryDepositRefundlistArgs = {
  ReversedID: Scalars['String'];
};


export type QueryAdvancePaymentDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAdvancePaymentReversedDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryRegistrationDetailsArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryRegistrationDetailsMultiArgs = {
  InputReg?: Maybe<Array<InputReg>>;
};


export type QueryBiPaymentListingTestArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRegistrationListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiHotelDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBiServiceRequestListingArgs = {
  Department?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRevenueListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRevenueListingV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiytdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaytdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiSourceYtdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopCountryArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopNationalityArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalTopSalesArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTravelAgentArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiSegmentRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopTransactionArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiServiceRequestArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiForeignListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSecurityKeyCardDepositArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRoomDiscrepancyLogArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomStatusArgs = {
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryBiBookingStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiFolioListingArgs = {
  DocumentType?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SearchValue?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SearchValue?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiAdvancePaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiReceiptRefundListingArgs = {
  Limit?: Maybe<Scalars['Float']>;
  Offset?: Maybe<Scalars['Float']>;
  SearchValue?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTestListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTransactionLedgerArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiPackageArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryManagerReportArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryManagerReportDepartmentArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryCheckRecordArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Mode?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
};


export type QueryRoomAllocationLocationListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryChooseRoomAllocationListingArgs = {
  DateSearch?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryChooseRoomAllocationDetailArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsChargeableArgs = {
  HotelID: Scalars['String'];
};


export type QueryCompanyListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  AccountID: Scalars['String'];
};


export type QueryDebtorListingDropDownArgs = {
  HotelID: Scalars['String'];
};


export type QueryCorporateGovernmentListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SalesChannel?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  DebtorCategory?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryGovernmentListingArgs = {
  DebtorCategory?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryCorporateBookingListingArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateGovernmentDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryContractDetailsArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryCorporateInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryCoporateInUseListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryTotalAgingArgs = {
  DebtorID?: Maybe<Array<Scalars['String']>>;
  HotelID: Scalars['String'];
};


export type QueryActivitiesListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryDashBoardAllListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDashBoardListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAdvancePaymentFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomServicesCountArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelStatisticListingArgs = {
  BlockRoom?: Maybe<Scalars['Boolean']>;
  Complimentary?: Maybe<Scalars['Boolean']>;
  DayUse?: Maybe<Scalars['Boolean']>;
  HouseUse?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryHotelStatisticListingV2Args = {
  BlockRoom?: Maybe<Scalars['Boolean']>;
  Complimentary?: Maybe<Scalars['Boolean']>;
  DayUse?: Maybe<Scalars['Boolean']>;
  HouseUse?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryDocumentTemplateListArgs = {
  ID: Scalars['String'];
};


export type QueryConsolidatedInvoiceListingArgs = {
  Submenu?: Maybe<Scalars['String']>;
  DocType?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDocToConsolidateListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsSearch?: Maybe<Scalars['Boolean']>;
  DocNo?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  DocType?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDocToConsolidateViewArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  DocType?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  ConsolidationID: Scalars['String'];
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGetClassificationListingByCodeArgs = {
  code?: Maybe<Scalars['String']>;
};


export type QueryEInvoiceInformationArgs = {
  Type: Scalars['String'];
  ID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGetFolioInfoArgs = {
  FolioID: Scalars['String'];
  DocType: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryEInvSubscriptionArgs = {
  AccountID: Scalars['String'];
};


export type QueryNaRegistrationDetailsArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListingArgs = {
  SearchValue?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  FullName?: Maybe<Scalars['String']>;
  Age2?: Maybe<Scalars['String']>;
  Age1?: Maybe<Scalars['String']>;
  VisitNo2?: Maybe<Scalars['String']>;
  VisitNo1?: Maybe<Scalars['String']>;
  BirthMonth?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListingV2Args = {
  RoomNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Age2?: Maybe<Scalars['String']>;
  Age1?: Maybe<Scalars['String']>;
  VisitNo2?: Maybe<Scalars['String']>;
  VisitNo1?: Maybe<Scalars['String']>;
  BirthMonth?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListingByIdArgs = {
  RegistrationIDs: Array<Scalars['String']>;
};


export type QuerySelectedGuestListingArgs = {
  HotelID: Scalars['String'];
  GuestID: Scalars['String'];
};


export type QueryGuestHistoryListingArgs = {
  GuestID: Scalars['String'];
};


export type QueryGuestHistoryListingV2Args = {
  GuestID: Scalars['String'];
};


export type QueryGuestProfileHistoryListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListbyRoomArgs = {
  BookingID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QuerySearchGuestArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  GuestName: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestMovementListArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  GuestID: Scalars['String'];
};


export type QueryGuestMovementListingArgs = {
  Take?: Maybe<Scalars['Float']>;
  Skip?: Maybe<Scalars['Float']>;
  HotelID: Scalars['String'];
};


export type QueryGuestListingByLocationTimeArgs = {
  Take?: Maybe<Scalars['Float']>;
  Skip?: Maybe<Scalars['Float']>;
  CheckInDate: Scalars['DateTime'];
  GuestID: Scalars['String'];
  RefID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCbExportArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryExportedCbListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedCbDetailsArgs = {
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryCbExportDetailsByTrxTypeArgs = {
  TrxDate: Scalars['DateTime'];
  TrxType: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryExportedGlListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedGlDetailsArgs = {
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryExportedGlTransferDetailsArgs = {
  BatchNo: Scalars['Float'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGlExportArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGlExportDetailsByTrxTypeArgs = {
  AccountCode?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  BatchNo: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  TrxDesc: Scalars['String'];
  TrxType: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryRoomServiceFolioListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentRoomServiceDetailArgs = {
  RegistrationID: Scalars['String'];
  OrderID: Scalars['String'];
};


export type QueryBillLedgerFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentBillLedgerListingArgs = {
  FolioID: Scalars['String'];
};


export type QueryPaymentRegistrationListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryCheckoutfoliodeposiitArgs = {
  HotelID: Scalars['String'];
};


export type QueryCheckedOutFolioListingOldArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountName?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryCheckedOutFolioListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountName?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryAdjustedFolioDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryCheckoutAdjustmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySearchFolioArgs = {
  FolioNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomServiceListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryDeliveredRoomServiceListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRoomServiceRegistrationListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryDeliveredRoomServiceRegistrationListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryGuestRoomResultArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryFolioRegIdsArgs = {
  FolioID: Scalars['String'];
};


export type QueryCheckIsGroupInvoiceArgs = {
  HotelID: Scalars['String'];
  SourceID: Scalars['String'];
};


export type QueryFolioChargeToListArgs = {
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryExportedStatisticListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedStatisticDetailsArgs = {
  BatchNo: Scalars['Float'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryStatisticJournalArgs = {
  HotelDate: Scalars['DateTime'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsHotelLockUserArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryTTlockInfoArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryHotelAccountXListArgs = {
  HotelID: Scalars['String'];
};


export type QueryLoyaltyAppHotelGroupArgs = {
  LoyaltyAppURL?: Maybe<Scalars['String']>;
};


export type QueryCheckNaProcessLogArgs = {
  HotelID: Scalars['String'];
};


export type QueryOutGoingDocTemplateArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryPackageRedemptionListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryPackageRedemptionByItemArgs = {
  ComputationRule: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryPreRegBookingInfoArgs = {
  QR?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryPreRegRegistrationDetailsArgs = {
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBillSummaryDetailsArgs = {
  BillSummaryInput: BillSummaryInput;
};


export type QueryPreRegRoomAllocationArgs = {
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryIsRoomExistArgs = {
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryPreRegBookingArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestAppFeatureArgs = {
  HotelID: Scalars['String'];
};


export type QueryPreCheckInQrArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryIsBookingExistArgs = {
  BookingNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3DocumentHeaderArgs = {
  DocumentType: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3ListingsArgs = {
  HotelID: Scalars['String'];
};


export type QueryIsQ3FInterfaceArgs = {
  UserID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3FSegmentArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
};


export type QueryQ3FRevenueOldArgs = {
  DownloadDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryQ3FRevenueArgs = {
  BatchNo?: Maybe<Scalars['Float']>;
  DownloadMode: Scalars['String'];
  DownloadDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryQ3FStatisticArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
};


export type QueryQ3DocumentDetailByHeaderArgs = {
  HeaderID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3MappingChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingAdvPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingRefundListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingDepositListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingTaxListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3MappingDebtorListArgs = {
  HotelID: Scalars['String'];
};


export type QueryHousekeepingListingArgs = {
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryTodayBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHousekeepingDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsBlockRoomAvailableArgs = {
  BlockRoomID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomID: Array<Scalars['String']>;
};


export type QueryHousekeepingServiceRequestListingArgs = {
  RoomID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryHousekeepingRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBillLedgerListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryHousekeepingIncidentalChargeListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryHkMasterListingArgs = {
  IsBlocked?: Maybe<Scalars['Boolean']>;
  RoomStatusID?: Maybe<Scalars['String']>;
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryServiceRequestDetailArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestListingArgs = {
  DepartmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryServiceRequestv2ListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QuerySrLocationListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDepartmentInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDeparmentCheckingArgs = {
  DepartmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryEDocTemplateListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryEDocParamsListingArgs = {
  DocType: Scalars['String'];
};


export type QueryConfirmationLetterListingArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAccountMappingChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingAdvPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingRefundListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingDepositListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCityLedgerChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCityLedgerDebtorListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingTaxGlListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCityLedgerTaxGlListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryExternalStatisticMappingArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBankAccountListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryMenuXHotelListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryHotelDetailsArgs = {
  CorporateURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryHotelPreRegQrArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestappsettingDetailArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIsPaymentGateWayHotelArgs = {
  HotelID: Scalars['String'];
};


export type QueryServicePaymentSettingArgs = {
  HotelID: Scalars['String'];
};


export type QueryCustomizedDocListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIncidentalChargeListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryIncidentalChargeByIdArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalChargeInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalChargeInRateElementArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRateElementInactiveCheckArgs = {
  Action: Scalars['String'];
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIncidentalChargeListingByHotelArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryLateCheckOutChargeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryFloorPlanListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  LocationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryLocationListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryLocationFloorPlanListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryLocationInUseListingArgs = {
  LocationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoomInHouseListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomBlockRoomListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHkLocationMasterListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRateElementListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateAddOnListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateTypeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateListingArgs = {
  Category?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRateInHouseListingArgs = {
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRateEffectiveListingArgs = {
  RateID: Scalars['String'];
};


export type QueryRatePolicyInfoArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateAdjustmentAuditListingArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsRateInUseArgs = {
  RateEffectiveID: Scalars['String'];
  RateID: Scalars['String'];
};


export type QueryRegenBsQueueListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QuerySeasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySeasonListingCalenderArgs = {
  HotelID: Scalars['String'];
};


export type QuerySeasonCalendarListingArgs = {
  CalendarDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRatePolicyListingArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRatePolicyRoomTypeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRatePolicyDetailsArgs = {
  HotelID: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryRatePolicyInUseListingArgs = {
  DebtorCategory: Scalars['String'];
  RatePolicyID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryReasonListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryCancelReasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBookingCancelReasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryReaonInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoomListingArgs = {
  Location?: Maybe<Array<Scalars['String']>>;
  IsActive?: Maybe<Scalars['Boolean']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBlockRoomLogListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBlockRoomLogListingV2Args = {
  HotelID: Scalars['String'];
};


export type QueryHotelRoomNumberListArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomTypeListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRegistrationRoomTypeListingArgs = {
  RoomTypeID: Scalars['String'];
};


export type QuerySalesChannelListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  DepartmentID: Scalars['String'];
};


export type QueryHotelSalesChannelListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySalesChannelinUseListingArgs = {
  ID: Scalars['String'];
};


export type QuerySegmentListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QuerySegmentInUseListingArgs = {
  SegmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGetTaxTypeListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxListingDropdownArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxDetailsArgs = {
  TaxID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryIsTaxInUseArgs = {
  TaxID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryIsTaxSameCodeArgs = {
  TaxCode: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetTaxSchemeListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailItemRateArgs = {
  EffectiveDate: Scalars['DateTime'];
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailArgs = {
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryIsTaxSchemeInUseArgs = {
  TaxSchemeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsTaxSameNameArgs = {
  TaxSchemeName: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryTravelAgentHotelListingArgs = {
  DebtorCategory: Scalars['String'];
  Login: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryTravelAgentListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SalesChannel?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAgentInUseLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryTravelAgentDetailArgs = {
  ID: Scalars['String'];
};


export type QueryPmsTravelAgentDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryTravelAgentContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryTravelAgBookingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortByOrder?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingDate?: Maybe<Scalars['DateTime']>;
  BookingStatus?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryTravelAgentInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryAllotmentListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  ContractID: Scalars['String'];
};


export type QueryDebtorAnalysisArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryTravelAgentRoomRevenueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  HotelID: Scalars['String'];
};

export enum Race {
  Malay = 'Malay',
  Chinese = 'Chinese',
  Indian = 'Indian',
  Others = 'Others'
}

export type RateAddOnEntity = InterfaceBase & {
  __typename?: 'RateAddOnEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  Description?: Maybe<Scalars['String']>;
  PostingRythm?: Maybe<Scalars['String']>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateAddOnInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  Description: Scalars['String'];
  PostingRythm: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type RateAdjustmentAuditEntity = InterfaceBase & {
  __typename?: 'RateAdjustmentAuditEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  OldRateID: Scalars['String'];
  NewRateID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  Reason?: Maybe<Scalars['String']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
  Registration?: Maybe<RegistrationEntity>;
  OldRate?: Maybe<RateEntity>;
  NewRate?: Maybe<RateEntity>;
};

export type RateAdjustmentAuditInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  OldRateID: Scalars['String'];
  NewRateID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  Reason?: Maybe<Scalars['String']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  NewPromoCode?: Maybe<Scalars['String']>;
  NewRoomRate?: Maybe<Scalars['Float']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
};

export type RateAndDisc = {
  RoomRate?: Maybe<Scalars['Float']>;
  Discount?: Maybe<Scalars['Float']>;
};

export type RateAndDiscs = {
  RoomRate?: Maybe<Scalars['Float']>;
  Discount?: Maybe<Scalars['Float']>;
};

export enum RateCategory {
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'TravelAgent',
  Public = 'Public',
  DayUse = 'DayUse',
  HouseUse = 'HouseUse',
  Complimentary = 'Complimentary'
}

export type RateEffectiveEntity = InterfaceBase & {
  __typename?: 'RateEffectiveEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RateElementIDs?: Maybe<Array<EffectiveRateJson>>;
  RateAddOnIDs?: Maybe<Array<EffectiveRateJson>>;
  SeasonalRate?: Maybe<Array<SeasonalRateEntity>>;
  Rate?: Maybe<RateEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RateElementIDs?: Maybe<Array<EffectiveRateJsonInput>>;
  RateAddOnIDs?: Maybe<Array<EffectiveRateJsonInput>>;
};

export type RateElementEntity = InterfaceBase & {
  __typename?: 'RateElementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  ComputationRule?: Maybe<Scalars['String']>;
  Min: Scalars['Float'];
  Max: Scalars['Float'];
  PostingRythm?: Maybe<Scalars['String']>;
  OneTimeCharge: Scalars['Boolean'];
  PostingRythmDate: Scalars['Float'];
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateElementInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  ComputationRule: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Min?: Maybe<Scalars['Float']>;
  Max?: Maybe<Scalars['Float']>;
  PostingRythm?: Maybe<Scalars['String']>;
};

export type RateEntity = InterfaceBase & {
  __typename?: 'RateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RateTypeID?: Maybe<Scalars['String']>;
  RateCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RateFrequency?: Maybe<Scalars['String']>;
  Category?: Maybe<RateCategory>;
  OnlineRate: Scalars['Boolean'];
  RateType?: Maybe<RateTypeEntity>;
  Hotel?: Maybe<HotelEntity>;
  RateEffective?: Maybe<Array<RateEffectiveEntity>>;
  ContractRates?: Maybe<Array<ContractRateEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
};

export type RateGainBookingRecordEntity = InterfaceBase & {
  __typename?: 'RateGainBookingRecordEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  RGStatus?: Maybe<Scalars['String']>;
};

export type RateGainBookingRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RGStatus?: Maybe<Scalars['String']>;
};

export type RateInput = {
  ID?: Maybe<Scalars['String']>;
  RateTypeID: Scalars['String'];
  HotelID: Scalars['String'];
  RateCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RateFrequency?: Maybe<Scalars['String']>;
  OnlineRate: Scalars['Boolean'];
  Category?: Maybe<RateCategory>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type RatePolicyEntity = InterfaceBase & {
  __typename?: 'RatePolicyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Category?: Maybe<RateCategory>;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  Package?: Maybe<Array<PackagesEntity>>;
  Pricing?: Maybe<Array<PricingEntity>>;
};

export type RatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Category: RateCategory;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RatePolicyResponse = {
  __typename?: 'RatePolicyResponse';
  TotalSeason?: Maybe<Scalars['Float']>;
  TotalAddOn?: Maybe<Scalars['Float']>;
  SeasonCalendarDate?: Maybe<Scalars['DateTime']>;
  RateElementDate?: Maybe<Scalars['DateTime']>;
  RateTypeDate?: Maybe<Scalars['DateTime']>;
  RateSetupDate?: Maybe<Scalars['DateTime']>;
};

export type RateRoomTypeEntity = InterfaceBase & {
  __typename?: 'RateRoomTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RateEffectiveID: Scalars['String'];
  RoomTypeID: Scalars['DateTime'];
  RateEffective?: Maybe<RateEffectiveEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type RateRoomTypeInput = {
  RateEffectiveID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};

export type Rates = {
  Date?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  roomID?: Maybe<Scalars['String']>;
  RoomIndex?: Maybe<Scalars['Float']>;
};

export type Ratess = {
  Date?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  roomID?: Maybe<Scalars['String']>;
  RoomIndex?: Maybe<Scalars['Float']>;
};

export type RateTypeEntity = InterfaceBase & {
  __typename?: 'RateTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateTypeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type ReasonEntity = InterfaceBase & {
  __typename?: 'ReasonEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
};

export type ReasonInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RefundDepositInput = {
  DepositID: Scalars['String'];
  FolioID: Scalars['String'];
  RefundAmount: Scalars['Float'];
  ForfeitureAmount?: Maybe<Scalars['Float']>;
  Remarks: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  RegistrationID: Scalars['String'];
};

export type RefundEntity = InterfaceTrx & {
  __typename?: 'RefundEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetails;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type RefundEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetailsInput;
};

export type RefundGlEntity = InterfaceTrx & {
  __typename?: 'RefundGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RefundType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type RegAndBooking = {
  __typename?: 'RegAndBooking';
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
};

export type RegenBsLogEntity = InterfaceTrx & {
  __typename?: 'RegenBSLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
  AccountID: Scalars['String'];
  ActionType?: Maybe<Scalars['String']>;
};

export type RegenBsLogInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
  AccountID: Scalars['String'];
  ActionType?: Maybe<Scalars['String']>;
};

export type RegenBsQueueEntity = InterfaceBase & {
  __typename?: 'RegenBSQueueEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  UserID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  Status: RegenBsQueueStatus;
  Registration?: Maybe<RegistrationEntity>;
};

export type RegenBsQueueInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  UserID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BatchNo: Scalars['Float'];
  Status: RegenBsQueueStatus;
};

export enum RegenBsQueueStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}

export type RegistrationEntity = InterfaceTrx & {
  __typename?: 'RegistrationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  CheckInBy?: Maybe<Scalars['String']>;
  CheckOutBy?: Maybe<Scalars['String']>;
  ChargeRoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  PrintRate?: Maybe<Scalars['Boolean']>;
  RegistrationStatus?: Maybe<BookingStatus>;
  PassCode: Scalars['String'];
  IsRoomUpgrade?: Maybe<Scalars['Boolean']>;
  SharerGuest?: Maybe<Array<Scalars['String']>>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  Booking?: Maybe<BookingEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  RatePolicy?: Maybe<RateEntity>;
  Room?: Maybe<RoomEntity>;
  Guest?: Maybe<GuestProfileEntity>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  PackageRedemption?: Maybe<Array<PackageRedemptionEntity>>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  RegistrationSignature?: Maybe<GalleryEntity>;
  RoomCharges?: Maybe<Array<RoomLedgerEntity>>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  IsEmailBlasted?: Maybe<Scalars['Boolean']>;
  IsChooseRoom?: Maybe<Scalars['Boolean']>;
  BookingRemark?: Maybe<Scalars['String']>;
  SharerRegistration?: Maybe<Array<RegistrationEntity>>;
  DepositSum: Scalars['Float'];
  PaymentSum: Scalars['Float'];
  AdvancePaymentSum: Scalars['Float'];
  IncidentalChargesSum: Scalars['Float'];
  IncidentalChargesCount: Scalars['Float'];
  FolioCount?: Maybe<Scalars['Float']>;
  RoomOutStanding: Scalars['Float'];
  TotalRoomPrice: Scalars['Float'];
  TotalRoomTax: Scalars['Float'];
  TotalPackagePrice: Scalars['Float'];
  TotalPrice: Scalars['Float'];
  ServiceRequestCount: Scalars['Float'];
  TotalOutStandingAmount: Scalars['Float'];
  AdvPaymentSum: Scalars['Float'];
  RoomRateDisplay: Scalars['Float'];
};

export type RegistrationInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<BookingStatus>;
  UserID?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  AvailabilityID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  PrintRate?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CheckInBy?: Maybe<Scalars['String']>;
  CheckOutBy?: Maybe<Scalars['String']>;
  contactInput?: Maybe<Array<ContactInput>>;
  IsRoomUpgrade?: Maybe<Scalars['Boolean']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IsChooseRoom?: Maybe<Scalars['Boolean']>;
};

export type RegistrationResult = {
  __typename?: 'RegistrationResult';
  Room?: Maybe<RoomEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  GuestProfile?: Maybe<GuestProfileEntity>;
  Contact?: Maybe<ContactEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  BookingID?: Maybe<Scalars['String']>;
  Booking?: Maybe<BookingEntity>;
  BillSchedule?: Maybe<BillScheduleEntity>;
  AdvancePayment?: Maybe<AdvancePaymentEntity>;
  BillLedger?: Maybe<BillLedgerEntity>;
};

export enum Religion {
  Islam = 'Islam',
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Taoism = 'Taoism',
  Others = 'Others'
}

export type ReminderEntity = InterfaceTrx & {
  __typename?: 'ReminderEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  ReminderNo: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  ReminderDate: Scalars['DateTime'];
  ReminderSeq: Scalars['Float'];
};

export type ReminderEntityInput = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  LedgerID: Scalars['String'];
  ReminderNo: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  ReminderDate: Scalars['DateTime'];
  ReminderSeq: Scalars['Float'];
};

export type ReservationInput = {
  bookingId?: Maybe<Scalars['String']>;
  roomNo?: Maybe<Scalars['String']>;
  roomTypeCode?: Maybe<Scalars['String']>;
  checkIn?: Maybe<Scalars['DateTime']>;
  checkOut?: Maybe<Scalars['DateTime']>;
  nights?: Maybe<Scalars['Float']>;
  guestName?: Maybe<Scalars['String']>;
  guestID?: Maybe<Scalars['String']>;
  guestNationality?: Maybe<Scalars['String']>;
  guestCountry?: Maybe<Scalars['String']>;
  guestState?: Maybe<Scalars['String']>;
  guestCity?: Maybe<Scalars['String']>;
  guestAddress?: Maybe<Scalars['String']>;
  guestTelNo?: Maybe<Scalars['String']>;
  guestEmail?: Maybe<Scalars['String']>;
  noOfAdult?: Maybe<Scalars['Float']>;
  noOfChildren?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
};

export type RevenueAnalysis = {
  __typename?: 'RevenueAnalysis';
  HotelDate?: Maybe<Scalars['DateTime']>;
  OCCRoom?: Maybe<Scalars['Float']>;
  DueIn?: Maybe<Scalars['Float']>;
  DueOut?: Maybe<Scalars['Float']>;
  ExpOcc?: Maybe<Scalars['Float']>;
  OCC?: Maybe<Scalars['Float']>;
  NoOfPax?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  AvailableRoom?: Maybe<Scalars['Float']>;
  DayUse?: Maybe<Scalars['Float']>;
  CompUse?: Maybe<Scalars['Float']>;
  RoomRevenue?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  OtherRevenue?: Maybe<Scalars['Float']>;
  Arr?: Maybe<Scalars['Float']>;
  OOI?: Maybe<Scalars['Float']>;
  OOO?: Maybe<Scalars['Float']>;
};

export type RevenueGuestLedgerAcctInput = {
  Div?: Maybe<Scalars['String']>;
  Dept?: Maybe<Scalars['String']>;
  Acct?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type RevenueInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Q3FAcct?: Maybe<Q3RevenueAcctJsonInput>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
};

export type RevenueMappingInput = {
  Charges?: Maybe<Array<RevenueInput>>;
  Payment?: Maybe<Array<RevenueInput>>;
  Refund?: Maybe<Array<RevenueInput>>;
  Deposit?: Maybe<Array<RevenueInput>>;
  AdvancePayment?: Maybe<Array<RevenueInput>>;
  Tax?: Maybe<Array<RevenueTaxInput>>;
  DebtorType?: Maybe<Array<RevenueInput>>;
  RefundDebtor?: Maybe<Array<RevenueInput>>;
  OfficialReceipt?: Maybe<Array<RevenueInput>>;
};

export type RevenueTaxInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  Q3FAcct?: Maybe<Q3RevenueAcctJsonInput>;
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm?: Maybe<Array<RolePermAsgEntity>>;
  MenuOption?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  menuOption?: Maybe<Scalars['String']>;
};

export type RolePermAsgEntity = AuditEntity & {
  __typename?: 'RolePermAsgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoomDiscrepancyLogEntity = InterfaceTrx & {
  __typename?: 'RoomDiscrepancyLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomStatusID?: Maybe<Scalars['String']>;
  HouseKeepingStatusID?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  DiscrepancyReason: DiscrepancyReason;
  AttendedBy: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  IsSolved?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  HouseKeepingStatus?: Maybe<RoomStatusEntity>;
};

export type RoomDiscrepancyLogInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  DiscrepancyReason: DiscrepancyReason;
  AttendedBy: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  IsSolved?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomEntity = InterfaceBase & {
  __typename?: 'RoomEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Location?: Maybe<LocationEntity>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  PrincipalRegistration?: Maybe<Array<RegistrationEntity>>;
  Registrations?: Maybe<Array<RegistrationEntity>>;
  FloorPlanID?: Maybe<Scalars['String']>;
  FloorPlan?: Maybe<Array<LocationFloorPlanEntity>>;
  TodayBookingNo?: Maybe<Array<RegistrationEntity>>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  RoomAssignmentStatus?: Maybe<RoomStatusEntity>;
  ServiceRequestCount: Scalars['Float'];
  RoomStatusCode: Scalars['String'];
  HKRoomStatus: Scalars['JSON'];
};


export type RoomEntityRoomAssignmentStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};

export type RoomInfoResponse = {
  __typename?: 'RoomInfoResponse';
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RateDescription?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  BookingRemark?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  LedgerInfo?: Maybe<Array<LedgerInfoOutput>>;
  LedgerTax?: Maybe<Array<LedgerOutput>>;
};

export type RoomInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RoomSize: Scalars['Float'];
  DisplaySequence?: Maybe<Scalars['Float']>;
  FloorPlanID?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomLedgerEntity = InterfaceTrx & {
  __typename?: 'RoomLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TrxDescription?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType?: Maybe<TransactionType>;
  TransactionID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  RefRoomLedgerID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
  TaxRoomLedger?: Maybe<Array<RoomLedgerTaxEntity>>;
};

export type RoomLedgerInput = {
  ID: Scalars['String'];
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  RefRoomLedgerID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomLedgerTaxEntity = InterfaceTrx & {
  __typename?: 'RoomLedgerTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  RoomLedgerID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  RoomLedger?: Maybe<RoomLedgerEntity>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type RoomSaleOptimizationOutput = {
  __typename?: 'RoomSaleOptimizationOutput';
  RoomType?: Maybe<RoomTypeEntity>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
};

export enum RoomStatus {
  VacantClean = 'Vacant_Clean',
  VacantDirty = 'Vacant_Dirty',
  OccupiedClean = 'Occupied_Clean',
  OccupiedDirty = 'Occupied_Dirty',
  VacantInspection = 'Vacant_Inspection',
  VacantReady = 'Vacant_Ready',
  OutOfOrder = 'Out_of_Order',
  OutOfInventory = 'Out_of_Inventory'
}

export type RoomStatusEntity = InterfaceBase & {
  __typename?: 'RoomStatusEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Description: RoomStatus;
  Code?: Maybe<Scalars['String']>;
  VacantOrOccupied?: Maybe<Scalars['String']>;
  CleanOrDirty?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type RoomStatusInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description: RoomStatus;
  Code: Scalars['String'];
  VacantOrOccupied: Scalars['String'];
  CleanOrDirty: Scalars['String'];
  RGBColor?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomStatusMovementEntity = InterfaceTrx & {
  __typename?: 'RoomStatusMovementEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  HotelDate?: Maybe<Scalars['DateTime']>;
  RoomID: Scalars['String'];
  RoomNo: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomType: Scalars['String'];
  LocationID: Scalars['String'];
  Location: Scalars['String'];
  Name: Scalars['String'];
  OldStatusID: Scalars['String'];
  OldStatus: Scalars['String'];
  NewStatusID: Scalars['String'];
  NewStatus: Scalars['String'];
};

export type RoomStatusMovementInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  RoomID: Scalars['String'];
  RoomNo?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  RoomType?: Maybe<Scalars['String']>;
  LocationID: Scalars['String'];
  Location?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OldStatusID?: Maybe<Scalars['String']>;
  NewStatusID?: Maybe<Scalars['String']>;
  OldStatus?: Maybe<Scalars['String']>;
  NewStatus?: Maybe<Scalars['String']>;
};

export type RoomTransferLogEntity = InterfaceTrx & {
  __typename?: 'RoomTransferLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  FromRoomID: Scalars['String'];
  FromRoomTypeID?: Maybe<Scalars['String']>;
  FromRoomType?: Maybe<Scalars['String']>;
  Upgrade?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
};

export type RoomTransferLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  FromRoomID: Scalars['String'];
  FromRoomTypeID?: Maybe<Scalars['String']>;
  FromRoomType?: Maybe<Scalars['String']>;
  Upgrade?: Maybe<Scalars['String']>;
};

export type RoomTypeAmenitiesEntity = InterfaceBase & {
  __typename?: 'RoomTypeAmenitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  Name: Scalars['String'];
  GalleryID: Scalars['String'];
  IsSelected: Scalars['Boolean'];
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type RoomTypeAmenitiesInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  GalleryID?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTypeEntity = InterfaceBase & {
  __typename?: 'RoomTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  PropertyType?: Maybe<PropertyType>;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  MeasureType?: Maybe<MeasureType>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomTypeAmenities?: Maybe<Array<RoomTypeAmenitiesEntity>>;
  RoomTypeGallery?: Maybe<Array<RoomTypeGalleryEntity>>;
  Room?: Maybe<Array<RoomEntity>>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  Price?: Maybe<Array<PricingEntity>>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
  TotalPackages?: Maybe<Array<TotalPackagesResponse>>;
  Overbooking: Scalars['Float'];
};

export type RoomTypeGalleryEntity = InterfaceBase & {
  __typename?: 'RoomTypeGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  GalleryID: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type RoomTypeGalleryInput = {
  ID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTypeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  PropertyType?: Maybe<PropertyType>;
  MeasureType?: Maybe<MeasureType>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Overbooking: Scalars['Float'];
};

export type RoomTypesListOutput = {
  __typename?: 'RoomTypesListOutput';
  ID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  Overbooking: Scalars['Float'];
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
};

export type RoomUpgradeInput = {
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  OldRoomID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  FreeUpgrade?: Maybe<Scalars['Boolean']>;
  BookingID?: Maybe<Scalars['String']>;
  OldRoomTypeID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type SalesChannelEntity = InterfaceBase & {
  __typename?: 'SalesChannelEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
};

export type SalesChannelInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SchBillingInput = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BillNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  BillDate?: Maybe<Scalars['DateTime']>;
  BillAmount?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ScheduleBillingEntity = {
  __typename?: 'ScheduleBillingEntity';
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  BillNo: Scalars['String'];
  CardNo: Scalars['String'];
  BillDate: Scalars['DateTime'];
  BillAmount: Scalars['Float'];
  DebtorID: Scalars['String'];
  IsPosted: Scalars['Boolean'];
  IsGLPosted: Scalars['Boolean'];
};

export type ScheduleBillingInput = {
  BookingID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  InvoiceDate?: Maybe<Scalars['DateTime']>;
  FolioAmount: Scalars['Float'];
  Description?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
};

export type ScheduleBillingResponse = {
  __typename?: 'ScheduleBillingResponse';
  CreatedBy?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  GuestEmail?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  ActualFolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
};

export type ScheduleBillingResponse2 = {
  __typename?: 'ScheduleBillingResponse2';
  BookingNo?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  GuestEmail?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
};

export type SearchGuestResponse = {
  __typename?: 'SearchGuestResponse';
  GuestID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NRIC?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
};

export type SeasonalRateEntity = InterfaceBase & {
  __typename?: 'SeasonalRateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SeasonID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RateEffectiveID: Scalars['String'];
  DynamicRate: Array<DynamicRateJson>;
  StandardRate: Scalars['Float'];
  RateEffective?: Maybe<RateEffectiveEntity>;
  Season?: Maybe<SeasonEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type SeasonalRateInput = {
  SeasonID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  SeasonRate: SeasonRateInput;
};

export type SeasonCalendarEntity = InterfaceBase & {
  __typename?: 'SeasonCalendarEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  SeasonID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  SeasonType: SeasonType;
  Season?: Maybe<SeasonEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type SeasonCalendarInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  SeasonID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  SeasonType?: Maybe<SeasonType>;
};

export type SeasonEntity = InterfaceBase & {
  __typename?: 'SeasonEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  SeasonalRate?: Maybe<Array<SeasonalRateEntity>>;
};


export type SeasonEntitySeasonalRateArgs = {
  RateEffectiveID?: Maybe<Scalars['String']>;
};

export type SeasonInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type SeasonRateInput = {
  StandardRate: Scalars['Float'];
  DynamicRate: Array<DynamicRateJsonInput>;
};

export enum SeasonType {
  Peak = 'Peak',
  SuperPeak = 'SuperPeak',
  NonPeak = 'NonPeak'
}

export type SegmentEntity = InterfaceBase & {
  __typename?: 'SegmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Q3MarketSegmentMapping?: Maybe<Q3MarketSegmentMapping>;
  Hotel?: Maybe<HotelEntity>;
  StayViewIcon?: Maybe<Scalars['String']>;
};

export type SegmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  StayViewIcon?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Q3MarketSegmentMapping?: Maybe<Q3MarketSegmentMappingInput>;
};

export type SegmentReportOutput = {
  __typename?: 'SegmentReportOutput';
  SegmentID?: Maybe<Scalars['String']>;
  SegmentName?: Maybe<Scalars['String']>;
  SegmentYRoomCount?: Maybe<Scalars['Float']>;
  SegmentYGuestCount?: Maybe<Scalars['Float']>;
  SegmentYRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentYOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentYARR?: Maybe<Scalars['Float']>;
  SegmentMRoomCount?: Maybe<Scalars['Float']>;
  SegmentMGuestCount?: Maybe<Scalars['Float']>;
  SegmentMRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentMOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentMARR?: Maybe<Scalars['Float']>;
  SegmentDRoomCount?: Maybe<Scalars['Float']>;
  SegmentDGuestCount?: Maybe<Scalars['Float']>;
  SegmentDRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentDOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentDARR?: Maybe<Scalars['Float']>;
};

export type ServiceRequestEntity = InterfaceTrx & {
  __typename?: 'ServiceRequestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DepartmentID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RequestBy: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  EstimatedCompletion: Scalars['DateTime'];
  GalleryID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status: ServiceRequestStatus;
  RegistrationID?: Maybe<Scalars['String']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  Department?: Maybe<DepartmentEntity>;
  Room?: Maybe<RoomEntity>;
  Gallery?: Maybe<GalleryEntity>;
  Registration?: Maybe<RegistrationEntity>;
  ServiceRequestGallery?: Maybe<Array<ServiceRequestGalleryEntity>>;
};

export type ServiceRequestGalleryEntity = InterfaceBase & {
  __typename?: 'ServiceRequestGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ServiceRequestID: Scalars['String'];
  GalleryID: Scalars['String'];
  ServiceRequest?: Maybe<ServiceRequestEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type ServiceRequestInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DepartmentID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RequestBy: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  EstimatedCompletion?: Maybe<Scalars['DateTime']>;
  GalleryID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status: ServiceRequestStatus;
  RegistrationID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ServiceRequestStatus {
  Open = 'Open',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}

export type SharedGalleryEntity = InterfaceBase & {
  __typename?: 'SharedGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  Gallery?: Maybe<GalleryEntity>;
};

export type SharedGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Source {
  WalkIn = 'Walk_In',
  Online = 'Online',
  Booking = 'Booking',
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'Travel_Agent',
  ChannelManager = 'Channel_Manager',
  Alliance = 'Alliance',
  WhatsApp = 'WhatsApp',
  Telephone = 'Telephone',
  Email = 'Email',
  Membership = 'Membership',
  Ota = 'OTA',
  Kiosk = 'Kiosk'
}

export type SourceReportOutput = {
  __typename?: 'SourceReportOutput';
  SourceName?: Maybe<Scalars['String']>;
  SourceYRoomCount?: Maybe<Scalars['Float']>;
  SourceYGuestCount?: Maybe<Scalars['Float']>;
  SourceYRoomRevenue?: Maybe<Scalars['Float']>;
  SourceYOtherRevenue?: Maybe<Scalars['Float']>;
  SourceYARR?: Maybe<Scalars['Float']>;
  SourceMRoomCount?: Maybe<Scalars['Float']>;
  SourceMGuestCount?: Maybe<Scalars['Float']>;
  SourceMRoomRevenue?: Maybe<Scalars['Float']>;
  SourceMOtherRevenue?: Maybe<Scalars['Float']>;
  SourceMARR?: Maybe<Scalars['Float']>;
  SourceDRoomCount?: Maybe<Scalars['Float']>;
  SourceDGuestCount?: Maybe<Scalars['Float']>;
  SourceDRoomRevenue?: Maybe<Scalars['Float']>;
  SourceDOtherRevenue?: Maybe<Scalars['Float']>;
  SourceDARR?: Maybe<Scalars['Float']>;
};

export type SpecialRequestInput = {
  ID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type StaahBookingRecordEntity = InterfaceBase & {
  __typename?: 'StaahBookingRecordEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
};

export type StaahBookingRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type StandardAuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export enum State {
  Johor = 'Johor',
  Kedah = 'Kedah',
  Kelantan = 'Kelantan',
  KualaLumpur = 'Kuala_Lumpur',
  Labuan = 'Labuan',
  Malacca = 'Malacca',
  NegeriSembilan = 'Negeri_Sembilan',
  Pahang = 'Pahang',
  Penang = 'Penang',
  Perak = 'Perak',
  Perlis = 'Perlis',
  Putrajaya = 'Putrajaya',
  Sabah = 'Sabah',
  Sarawak = 'Sarawak',
  Selangor = 'Selangor',
  Terengganu = 'Terengganu'
}

export type StateEntity = {
  __typename?: 'stateEntity';
  ID: Scalars['String'];
  name: Scalars['String'];
  countryID: Scalars['String'];
  einv_code?: Maybe<Scalars['String']>;
};

export type Statistic = {
  __typename?: 'Statistic';
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type StatisticExportEntity = InterfaceTrx & {
  __typename?: 'StatisticExportEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  GLDate: Scalars['DateTime'];
  HotelDate: Scalars['DateTime'];
  StatisticType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Submenu?: Maybe<Scalars['String']>;
};

export type StatisticInput = {
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type StatisticJournalInput = {
  HotelDate?: Maybe<Scalars['DateTime']>;
  StatisticType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
};

export type StatisticJournalResponse = {
  __typename?: 'StatisticJournalResponse';
  RoomAvailable?: Maybe<Scalars['Float']>;
  OccupiedRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  NoShow?: Maybe<Scalars['Float']>;
  Occupancy?: Maybe<Scalars['Float']>;
  Cancellation?: Maybe<Scalars['Float']>;
  WalkIn?: Maybe<Scalars['Float']>;
  HouseUse?: Maybe<Scalars['Float']>;
  Complimentary?: Maybe<Scalars['Float']>;
  RoomOccupiedComplimentary?: Maybe<Scalars['Float']>;
  DayUse?: Maybe<Scalars['Float']>;
  OOO?: Maybe<Scalars['Float']>;
  OOI?: Maybe<Scalars['Float']>;
  VacantRoom?: Maybe<Scalars['Float']>;
};

export type StatisticMappingExternalEntity = InterfaceBase & {
  __typename?: 'StatisticMappingExternalEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  StatisticType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
};

export type StatisticsMapping = {
  __typename?: 'StatisticsMapping';
  RoomAvailable?: Maybe<Statistic>;
  OccupiedRoom?: Maybe<Statistic>;
  NoOfGuest?: Maybe<Statistic>;
  NoShow?: Maybe<Statistic>;
  Occupancy?: Maybe<Statistic>;
  Cancellation?: Maybe<Statistic>;
  WalkIn?: Maybe<Statistic>;
  HouseUse?: Maybe<Statistic>;
  Complimentary?: Maybe<Statistic>;
  RoomOccupiedComplimentary?: Maybe<Statistic>;
  DayUse?: Maybe<Statistic>;
  OOO?: Maybe<Statistic>;
  OOI?: Maybe<Statistic>;
  VacantRoom?: Maybe<Statistic>;
};

export type StatisticsMappingInput = {
  RoomAvailable?: Maybe<StatisticInput>;
  OccupiedRoom?: Maybe<StatisticInput>;
  NoOfGuest?: Maybe<StatisticInput>;
  NoShow?: Maybe<StatisticInput>;
  Occupancy?: Maybe<StatisticInput>;
  Cancellation?: Maybe<StatisticInput>;
  WalkIn?: Maybe<StatisticInput>;
  HouseUse?: Maybe<StatisticInput>;
  Complimentary?: Maybe<StatisticInput>;
  RoomOccupiedComplimentary?: Maybe<StatisticInput>;
  DayUse?: Maybe<StatisticInput>;
  OOO?: Maybe<StatisticInput>;
  OOI?: Maybe<StatisticInput>;
  VacantRoom?: Maybe<StatisticInput>;
};

export type Subscription = {
  __typename?: 'Subscription';
  RoomAssignmentNotify: Array<RoomEntity>;
  HotelNotify: Array<HotelNotificationEntity>;
  NewNotification: NotificationEntity;
};


export type SubscriptionRoomAssignmentNotifyArgs = {
  RoomID?: Maybe<Scalars['String']>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  RoomTypeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type SubscriptionHotelNotifyArgs = {
  NotificationType?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type SubscriptionNewNotificationArgs = {
  HotelID: Scalars['String'];
  registrationToken: Scalars['String'];
};

export type SubscriptionEntity = InterfaceTrx & {
  __typename?: 'SubscriptionEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  LoyaltyAppURL: Scalars['String'];
  LoyaltyAppGroupName: Scalars['String'];
  LoyaltyAppLogo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Gallery?: Maybe<GalleryEntity>;
};

export type SuspendFolioResponse = {
  __typename?: 'SuspendFolioResponse';
  TotalCount?: Maybe<Scalars['Float']>;
  Listing?: Maybe<Array<FolioEntity>>;
};

export type SynchrowebKiwireLogEntity = InterfaceTrx & {
  __typename?: 'SynchrowebKiwireLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};

export type TaxDetailEntity = {
  __typename?: 'taxDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  tax: TaxEntity;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type TaxDetailInput = {
  id?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

export type TaxEffectiveDateEntity = InterfaceBase & {
  __typename?: 'TaxEffectiveDateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
};

export type TaxEffectiveDateInput = {
  ID: Scalars['String'];
  PlatformID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxEntity = {
  __typename?: 'taxEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  schemeTypeId: Scalars['String'];
  schemeType: TaxSchemeTypeEntity;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  taxDetail: Array<TaxDetailEntity>;
  taxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
};

export type TaxGlEntity = InterfaceTrx & {
  __typename?: 'TaxGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxID: Scalars['String'];
  SchemeTypeID: Scalars['String'];
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  Hotel?: Maybe<HotelEntity>;
  tax?: Maybe<TaxEntity>;
  taxSchemeType?: Maybe<TaxSchemeTypeEntity>;
};

export type TaxGlInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  GL?: Maybe<GuestLedgerAcctJsonInput>;
};

export enum TaxIdentifier {
  Room = 'Room',
  Incidental = 'Incidental'
}

export type TaxInput = {
  HotelID?: Maybe<Scalars['String']>;
  TaxIdentifier: TaxIdentifier;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};

export type TaxLedgerEntity = InterfaceTrx & {
  __typename?: 'TaxLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedTaxLedgerID?: Maybe<Scalars['String']>;
  RefTaxLedgerID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  TaxAmount?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Qty?: Maybe<Scalars['Float']>;
  IsAdjustment?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  TaxDescription?: Maybe<Scalars['String']>;
};

export type TaxLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ReversedTaxLedgerID?: Maybe<Scalars['String']>;
  RefTaxLedgerID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TaxDescription?: Maybe<Scalars['String']>;
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  TaxAmount?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Qty?: Maybe<Scalars['Float']>;
  IsAdjustment?: Maybe<Scalars['Boolean']>;
};

export type TaxListingOutput = {
  __typename?: 'TaxListingOutput';
  tax_id: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  class_type: Scalars['String'];
  tax_status: Scalars['String'];
  effective_date: Scalars['DateTime'];
  rate: Scalars['String'];
  rn?: Maybe<Scalars['String']>;
  tax_type?: Maybe<Scalars['String']>;
  scheme_type_id?: Maybe<Scalars['String']>;
};

export type TaxOnSeq = {
  __typename?: 'TaxOnSeq';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxOnSeqInput = {
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

/** JsonType */
export type TaxOnSeqObjects = {
  __typename?: 'taxOnSeqObjects';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxOutput = {
  __typename?: 'TaxOutput';
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  HotelTaxOutput?: Maybe<Array<HotelTaxOutput>>;
};

export type TaxPolicyInput = {
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetailInput>;
};

export type TaxReportOutput = {
  __typename?: 'TaxReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type TaxSchemeDetailEntity = {
  __typename?: 'taxSchemeDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  taxScheme: TaxSchemeEntity;
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  taxSchemeDetailItems?: Maybe<Array<TaxSchemeDetailItemEntity>>;
};

export type TaxSchemeDetailInput = {
  detailId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  taxSchemeDetailItem: Array<TaxSchemeDetailItemInput>;
};

export type TaxSchemeDetailItemEntity = {
  __typename?: 'taxSchemeDetailItemEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  taxSchemeDetail: TaxSchemeDetailEntity;
  taxId: Scalars['String'];
  tax: TaxEntity;
  computeMethod: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  commonStatus: Scalars['String'];
};

export type TaxSchemeDetailItemInput = {
  detailItemId?: Maybe<Scalars['String']>;
  detailId?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  classType: Scalars['String'];
  computeMethod: ComputeMethod;
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqInput>>;
};

export type TaxSchemeDetailItemRateOutput = {
  __typename?: 'TaxSchemeDetailItemRateOutput';
  isExclusive: Scalars['Boolean'];
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  computeMethod: Scalars['String'];
  seqNo: Scalars['String'];
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  taxSchemeDetailId: Scalars['String'];
};

export type TaxSchemeDetailOutput = {
  __typename?: 'TaxSchemeDetailOutput';
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  taxCode: Scalars['String'];
  taxType: Scalars['String'];
  taxSeqNo: Scalars['String'];
};

export type TaxSchemeEntity = {
  __typename?: 'taxSchemeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  commonStatus: Scalars['String'];
  taxSchemeDetails?: Maybe<Array<TaxSchemeDetailEntity>>;
};

export type TaxSchemeInput = {
  createdBy: Scalars['String'];
  modBy: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  taxSchemeDetail?: Maybe<Array<TaxSchemeDetailInput>>;
};

export type TaxSchemeTypeEntity = {
  __typename?: 'taxSchemeTypeEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  softwareCode: Scalars['String'];
  commonStatus: Scalars['String'];
  tax?: Maybe<Array<TaxEntity>>;
};

export enum TaxType {
  HeritageTax = 'HeritageTax',
  TourismTax = 'TourismTax',
  Levy = 'Levy',
  GovTax = 'GovTax',
  ServiceCharge = 'ServiceCharge',
  StateFund = 'StateFund'
}

export type Test = InterfaceTrx & {
  __typename?: 'test';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  GroupDocumentNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
  Booking?: Maybe<Scalars['JSON']>;
};

export type TestCountry = {
  __typename?: 'TestCountry';
  con: Country;
};

export type TestState = {
  __typename?: 'TestState';
  con: State;
};

export type TmrGroupOutput = {
  __typename?: 'TmrGroupOutput';
  GroupName?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type TotalPackagesResponse = {
  __typename?: 'TotalPackagesResponse';
  PackageName?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  PriceID?: Maybe<Scalars['String']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TransactionTransferLogEntity = InterfaceTrx & {
  __typename?: 'TransactionTransferLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxType?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  FromRegistrationID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  FromRoomID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  FromFolioType?: Maybe<Scalars['String']>;
  FolioType?: Maybe<Scalars['String']>;
  FromFolioID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  FromGuestID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type TransactionTransferLogInput = {
  ID?: Maybe<Scalars['String']>;
  SourceTable: Scalars['String'];
  SourceID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  TrxType: Scalars['String'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  FromRegistrationID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  FromRoomID: Scalars['String'];
  RoomID: Scalars['String'];
  FromFolioType: Scalars['String'];
  FolioType: Scalars['String'];
  FromFolioID: Scalars['String'];
  FolioID: Scalars['String'];
  FromGuestID: Scalars['String'];
  GuestID: Scalars['String'];
  Description: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  IsActive: Scalars['Boolean'];
  CreatedDT: Scalars['DateTime'];
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum TransactionType {
  Rate = 'Rate',
  Package = 'Package',
  TourismTax = 'TourismTax'
}

export type TransferAxLogEntity = InterfaceTrx & {
  __typename?: 'TransferAXLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
};

export type TtLockLogEntity = InterfaceTrx & {
  __typename?: 'TTLockLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserAccessInfoJson = {
  __typename?: 'UserAccessInfoJson';
  OpenFolio?: Maybe<Array<Scalars['String']>>;
};

export type UserAccessInfoJsonInput = {
  OpenFolio?: Maybe<Array<Scalars['String']>>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserOnlineEntity = InterfaceBase & {
  __typename?: 'UserOnlineEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CommonStatus: Scalars['String'];
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
  SoftwareCode?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  LastLogin: Scalars['DateTime'];
  LastLogout?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
  UserAccessInfo?: Maybe<UserAccessInfoJson>;
};

export type UserOnlineInput = {
  ID?: Maybe<Scalars['String']>;
  CommonStatus?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  SoftwareCode?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LastLogin?: Maybe<Scalars['DateTime']>;
  LastLogout?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
  UserAccessInfo?: Maybe<UserAccessInfoJsonInput>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type VendFunBookingRecordEntity = InterfaceBase & {
  __typename?: 'VendFunBookingRecordEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
};

export type VendFunBookingRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type VipInHouseOutput = {
  __typename?: 'VIPInHouseOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type WhRates = {
  Date?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  roomID?: Maybe<Scalars['String']>;
};

export type XPossibleLogEntity = InterfaceTrx & {
  __typename?: 'XPossibleLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type GuestChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type GuestChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { GuestChangePassword: (
    { __typename?: 'GuestProfileEntity' }
    & Pick<GuestProfileEntity, 'ID'>
  ) }
);

export type GuestForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type GuestForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestForgotPassword'>
);

export type GuestResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  MobileNo: Scalars['String'];
}>;


export type GuestResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestResetPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type LoggedInGuestProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInGuestProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInGuestProfile?: Maybe<(
    { __typename?: 'GuestProfileEntity' }
    & Pick<GuestProfileEntity, 'ID' | 'IsActive' | 'ContactID' | 'Company' | 'IsVIP' | 'Login' | 'IdPicture' | 'VehicleNo' | 'NewsLetter' | 'accessToken'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'Nationality' | 'MobileNo' | 'NRIC'>
      & { ProfilePictureGallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL'>
      )>, Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
      )> }
    )>, GuestHistoryList: Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
    )> }
  )> }
);

export type GuestUpdateMutationVariables = Exact<{
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  ProfilePictureAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  GuestProfileInput: GuestProfileInput;
  ContactInput: ContactInput;
}>;


export type GuestUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestUpdate'>
);

export type GuestBookingListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GuestBookingListingQuery = (
  { __typename?: 'Query' }
  & { GuestBookingListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'CreatedDT' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'DebtorAccount' | 'BookingStatus' | 'TotalAdvPayment'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'ID' | 'SSTRegNo' | 'AccountID' | 'HotelName' | 'OnlineTnC' | 'RegCardTnC'>
      & { HotelRating?: Maybe<Array<(
        { __typename?: 'HotelRatingEntity' }
        & Pick<HotelRatingEntity, 'ID' | 'Comments' | 'BookingID' | 'Cleanliness' | 'Facilities' | 'Location' | 'ComfortQuality' | 'Service' | 'OverallExperience'>
      )>>, LogoImage?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL'>
      )>, AboutHotelImage?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL'>
      )>, HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'PhoneNo' | 'BusinessEmail' | 'WebUrl'>
      )>, Facility?: Maybe<Array<(
        { __typename?: 'FacilityEntity' }
        & Pick<FacilityEntity, 'ID' | 'HotelID' | 'Level' | 'IsActive' | 'Title' | 'Description'>
        & { FacilityGallery?: Maybe<Array<(
          { __typename?: 'FacilityGalleryEntity' }
          & Pick<FacilityGalleryEntity, 'ID' | 'FacilityID' | 'GalleryID'>
          & { Gallery?: Maybe<(
            { __typename?: 'GalleryEntity' }
            & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
          )> }
        )>> }
      )>> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'Name' | 'CreditLimit' | 'DebtorAccount' | 'Segment' | 'Password' | 'Login'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'PhoneNo' | 'PassportNo' | 'NRIC'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'SharerNo' | 'SpecialRequest' | 'PromoCode' | 'ID' | 'DepositSum' | 'PaymentSum' | 'CheckOutDate' | 'CheckInDate' | 'IncidentalChargesSum' | 'IsPrincipal' | 'RoomRate' | 'TotalRoomPrice' | 'TotalPackagePrice' | 'TotalPrice'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'MaxGuest' | 'Code' | 'Description' | 'RoomSize' | 'MeasureType'>
        & { RoomTypeGallery?: Maybe<Array<(
          { __typename?: 'RoomTypeGalleryEntity' }
          & Pick<RoomTypeGalleryEntity, 'ID'>
          & { Gallery?: Maybe<(
            { __typename?: 'GalleryEntity' }
            & Pick<GalleryEntity, 'ID' | 'ImageURL'>
          )> }
        )>>, RoomTypeAmenities?: Maybe<Array<(
          { __typename?: 'RoomTypeAmenitiesEntity' }
          & Pick<RoomTypeAmenitiesEntity, 'ID' | 'Name' | 'IsSelected'>
          & { Gallery?: Maybe<(
            { __typename?: 'GalleryEntity' }
            & Pick<GalleryEntity, 'ID' | 'ImageURL'>
          )> }
        )>> }
      )>, TaxLedger?: Maybe<Array<(
        { __typename?: 'TaxLedgerEntity' }
        & Pick<TaxLedgerEntity, 'ID' | 'FolioID' | 'RegistrationID' | 'ReversedTaxLedgerID' | 'Reason' | 'TaxType' | 'TrxDate' | 'TaxAmount'>
      )>>, DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'ID' | 'FolioID' | 'DepositAmount' | 'DepositClass'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
        & { RoomStatus?: Maybe<(
          { __typename?: 'RoomStatusEntity' }
          & Pick<RoomStatusEntity, 'ID' | 'CleanOrDirty' | 'Code'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'ContactID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Nationality' | 'MobileNo' | 'NRIC' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'state' | 'country' | 'postCode' | 'city'>
          )> }
        )> }
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )> }
    )>> }
  )> }
);

export type BookingDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type BookingDetailsQuery = (
  { __typename?: 'Query' }
  & { BookingDetails: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'CreatedDT' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'ContactID' | 'GroupName' | 'IsGroupBooking' | 'ChargeToCityLedger' | 'SalesChannelID' | 'Source' | 'SegmentID' | 'SpecialRequest' | 'DebtorAccount' | 'BillingInstruction' | 'Reason' | 'BookingStatus'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'ID' | 'AllowCancellationDays' | 'HotelName' | 'AccountID' | 'RegCardTnC'>
      & { LogoImage?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL'>
      )>, HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'Email' | 'Nationality' | 'ID' | 'MobileNo' | 'PassportNo' | 'NRIC'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorCategory' | 'ID' | 'Name'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'ID' | 'PaymentType' | 'PaymentClass'>
    )>>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'DiscountAmount' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'RoomTypeID' | 'RatePolicyID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalPackagePrice' | 'TotalPrice'>
      & { Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'ID' | 'PaymentClass' | 'PaymentType'>
      )>>, AdvancePayment?: Maybe<Array<(
        { __typename?: 'AdvancePaymentEntity' }
        & Pick<AdvancePaymentEntity, 'ID' | 'PaymentType' | 'PaymentClass' | 'ReceiptNo'>
      )>>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'Description' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
        & { RoomStatus?: Maybe<(
          { __typename?: 'RoomStatusEntity' }
          & Pick<RoomStatusEntity, 'ID' | 'Code' | 'CleanOrDirty'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'Nationality' | 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type SegmentListingQueryVariables = Exact<{
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
}>;


export type SegmentListingQuery = (
  { __typename?: 'Query' }
  & { SegmentListing: Array<(
    { __typename?: 'SegmentEntity' }
    & Pick<SegmentEntity, 'ID' | 'Description'>
  )> }
);

export type BookingPackagePriceListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
}>;


export type BookingPackagePriceListingQuery = (
  { __typename?: 'Query' }
  & { BookingPackagePriceListing: Array<(
    { __typename?: 'PackagePriceOutput' }
    & Pick<PackagePriceOutput, 'RoomTypeID' | 'RoomTypeCode' | 'RoomTypeName' | 'RatePolicyID' | 'Code' | 'TotalVacant'>
    & { Pricing?: Maybe<Array<(
      { __typename?: 'PricingOutput' }
      & Pick<PricingOutput, 'Date' | 'Type' | 'Price' | 'BasePrice'>
      & { Packages?: Maybe<Array<(
        { __typename?: 'PackagesEntity' }
        & Pick<PackagesEntity, 'ID' | 'IncidentalChargeID' | 'Description' | 'IncludeRate' | 'ComputationRules' | 'ServiceCharge' | 'Tax' | 'Amount'>
      )>> }
    )>> }
  )> }
);

export type GuestAppRoomTypeDeleteMutationVariables = Exact<{
  RegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  BookingID: Scalars['String'];
}>;


export type GuestAppRoomTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestAppRoomTypeDelete'>
);

export type GuestInsertMutationVariables = Exact<{
  ContactInput: Array<ContactInput> | ContactInput;
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  HotelID: Scalars['String'];
}>;


export type GuestInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestInsert'>
);

export type GuestContactUpdateMutationVariables = Exact<{
  ContactInput: ContactInput;
  VehicleNo?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
}>;


export type GuestContactUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestContactUpdate'>
);

export type GuestProfileDeleteMutationVariables = Exact<{
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
}>;


export type GuestProfileDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestProfileDelete'>
);

export type CancelBookingMutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ReasonID?: Maybe<Scalars['String']>;
}>;


export type CancelBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelBooking'>
);

export type BookingRoomTypeUpdateMutationVariables = Exact<{
  EditRegInput: EditRegInput;
}>;


export type BookingRoomTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingRoomTypeUpdate'>
);

export type PaymentRegistrationListingQueryVariables = Exact<{
  RegistrationID: Scalars['String'];
}>;


export type PaymentRegistrationListingQuery = (
  { __typename?: 'Query' }
  & { PaymentRegistrationListing: (
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'RegistrationStatus' | 'SharerNo' | 'SpecialRequest' | 'PromoCode'>
    & { RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'MaxGuest' | 'Code' | 'Description' | 'RoomSize' | 'MeasureType'>
      & { RoomTypeGallery?: Maybe<Array<(
        { __typename?: 'RoomTypeGalleryEntity' }
        & Pick<RoomTypeGalleryEntity, 'ID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>>, RoomTypeAmenities?: Maybe<Array<(
        { __typename?: 'RoomTypeAmenitiesEntity' }
        & Pick<RoomTypeAmenitiesEntity, 'ID' | 'Name'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>> }
    )>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'ID' | 'FolioID' | 'RegistrationID' | 'ReversedTaxLedgerID' | 'Reason' | 'TaxType' | 'TrxDate' | 'TaxAmount'>
    )>> }
  ) }
);

export type CheckInMutationVariables = Exact<{
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
  BookingID: Scalars['String'];
  ContactInput?: Maybe<ContactInput>;
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  IdPicture?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  HotelID: Scalars['String'];
}>;


export type CheckInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CheckIn'>
);

export type HotelDetailsQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
}>;


export type HotelDetailsQuery = (
  { __typename?: 'Query' }
  & { HotelDetails: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'HotelName' | 'AccountID' | 'AboutHotel' | 'PDPA' | 'OnlineTnC' | 'WeightedAverage' | 'IsOnlineBooking'>
    & { HotelGallery?: Maybe<Array<(
      { __typename?: 'HotelGalleryEntity' }
      & Pick<HotelGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL' | 'SequenceNo'>
      )> }
    )>>, HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, LogoImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )>, AboutHotelImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )>, Facility?: Maybe<Array<(
      { __typename?: 'FacilityEntity' }
      & Pick<FacilityEntity, 'ID' | 'Level' | 'HotelID' | 'IsActive' | 'Title' | 'Description'>
      & { FacilityGallery?: Maybe<Array<(
        { __typename?: 'FacilityGalleryEntity' }
        & Pick<FacilityGalleryEntity, 'ID' | 'FacilityID' | 'GalleryID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
        )> }
      )>> }
    )>>, RoomType?: Maybe<Array<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description' | 'PropertyType' | 'MeasureType' | 'RoomSize' | 'TotalRoom' | 'MaxGuest'>
      & { Room?: Maybe<Array<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
        & { RoomStatus?: Maybe<(
          { __typename?: 'RoomStatusEntity' }
          & Pick<RoomStatusEntity, 'ID' | 'Code' | 'VacantOrOccupied' | 'CleanOrDirty'>
        )> }
      )>>, RoomTypeGallery?: Maybe<Array<(
        { __typename?: 'RoomTypeGalleryEntity' }
        & Pick<RoomTypeGalleryEntity, 'ID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>>, RoomTypeAmenities?: Maybe<Array<(
        { __typename?: 'RoomTypeAmenitiesEntity' }
        & Pick<RoomTypeAmenitiesEntity, 'ID' | 'Name'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>>, Price?: Maybe<Array<(
        { __typename?: 'PricingEntity' }
        & Pick<PricingEntity, 'ID'>
        & { RatePolicy?: Maybe<(
          { __typename?: 'RatePolicyEntity' }
          & Pick<RatePolicyEntity, 'ID' | 'Code' | 'Description'>
          & { Package?: Maybe<Array<(
            { __typename?: 'PackagesEntity' }
            & Pick<PackagesEntity, 'ID' | 'Amount' | 'Description' | 'Tax' | 'ServiceCharge'>
          )>> }
        )> }
      )>> }
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'PhoneNo' | 'ReservationEmail'>
    )> }
  ) }
);

export type HotelPricesByDateRangeQueryVariables = Exact<{
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
}>;


export type HotelPricesByDateRangeQuery = (
  { __typename?: 'Query' }
  & { HotelPricesByDateRange: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'HotelName' | 'AccountID' | 'IsPostPaid' | 'WeightedAverage' | 'PDPA' | 'OnlineTnC' | 'OnlinePaymentTnC' | 'AboutHotel' | 'TaxSchemeID'>
    & { Facility?: Maybe<Array<(
      { __typename?: 'FacilityEntity' }
      & Pick<FacilityEntity, 'ID' | 'HotelID' | 'IsActive' | 'Title' | 'Description'>
      & { FacilityGallery?: Maybe<Array<(
        { __typename?: 'FacilityGalleryEntity' }
        & Pick<FacilityGalleryEntity, 'ID' | 'FacilityID' | 'GalleryID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
        )> }
      )>> }
    )>>, HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, AboutHotelImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )>, HotelGallery?: Maybe<Array<(
      { __typename?: 'HotelGalleryEntity' }
      & Pick<HotelGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL' | 'SequenceNo'>
      )> }
    )>>, HotelRate?: Maybe<Array<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description' | 'PropertyType' | 'MeasureType' | 'DisplaySequence' | 'RoomSize' | 'TotalRoom' | 'MaxGuest'>
      & { Room?: Maybe<Array<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
        & { RoomStatus?: Maybe<(
          { __typename?: 'RoomStatusEntity' }
          & Pick<RoomStatusEntity, 'ID' | 'Code' | 'VacantOrOccupied' | 'CleanOrDirty'>
        )> }
      )>>, RoomTypeGallery?: Maybe<Array<(
        { __typename?: 'RoomTypeGalleryEntity' }
        & Pick<RoomTypeGalleryEntity, 'ID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>>, RoomTypeAmenities?: Maybe<Array<(
        { __typename?: 'RoomTypeAmenitiesEntity' }
        & Pick<RoomTypeAmenitiesEntity, 'ID' | 'IsSelected' | 'Name'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )> }
      )>>, TotalPackages?: Maybe<Array<(
        { __typename?: 'TotalPackagesResponse' }
        & Pick<TotalPackagesResponse, 'PackageName' | 'Price' | 'RatePolicyID' | 'PriceID'>
      )>>, RoomTypeAvailability?: Maybe<Array<(
        { __typename?: 'AvailabilityOutput' }
        & Pick<AvailabilityOutput, 'ID' | 'EffectiveDate' | 'TotalVacant'>
        & { Pricing?: Maybe<Array<(
          { __typename?: 'PricingEntity' }
          & Pick<PricingEntity, 'ID' | 'PackageAmount' | 'SellingPrice'>
          & { RatePolicy?: Maybe<(
            { __typename?: 'RatePolicyEntity' }
            & Pick<RatePolicyEntity, 'ID' | 'Description'>
          )> }
        )>> }
      )>> }
    )>> }
  ) }
);

export type HotelReviewListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
}>;


export type HotelReviewListingQuery = (
  { __typename?: 'Query' }
  & { HotelReviewListing: Array<(
    { __typename?: 'HotelRatingEntity' }
    & Pick<HotelRatingEntity, 'ID' | 'Cleanliness' | 'Facilities' | 'Location' | 'ComfortQuality' | 'Service' | 'OverallExperience' | 'Comments' | 'ReviewDate'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName'>
      )> }
    )> }
  )> }
);

export type IsGuestAppUserQueryVariables = Exact<{
  Login?: Maybe<Scalars['String']>;
}>;


export type IsGuestAppUserQuery = (
  { __typename?: 'Query' }
  & { IsGuestAppUser: (
    { __typename?: 'GuestProfileEntity' }
    & Pick<GuestProfileEntity, 'ID' | 'accessToken'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'MobileNo' | 'Email' | 'Nationality'>
    )> }
  ) }
);

export type CurrentTaxDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CurrentTaxDetailsQuery = (
  { __typename?: 'Query' }
  & { CurrentTaxDetails: (
    { __typename?: 'CurrentTaxDetailsOutput' }
    & Pick<CurrentTaxDetailsOutput, 'HotelID' | 'GovTax' | 'ServiceCharge'>
  ) }
);

export type OnlinePaymentMutationVariables = Exact<{
  redirectUrl: Scalars['String'];
  PayAmount: Scalars['Float'];
  Detail: Scalars['String'];
  Title: Scalars['String'];
  HotelID: Scalars['String'];
  ReceiptNo: Scalars['String'];
  AdvancePaymentID?: Maybe<Scalars['String']>;
}>;


export type OnlinePaymentMutation = (
  { __typename?: 'Mutation' }
  & { OnlinePayment?: Maybe<(
    { __typename?: 'CheckOut' }
    & Pick<CheckOut, 'code' | 'PaySessionNo'>
    & { item?: Maybe<(
      { __typename?: 'item' }
      & Pick<Item, 'checkoutId' | 'url' | 'qrCodeUrl' | 'status'>
    )>, error?: Maybe<(
      { __typename?: 'Errors' }
      & Pick<Errors, 'code' | 'message' | 'debug'>
    )> }
  )> }
);

export type OnlineBookingInsertMutationVariables = Exact<{
  AppName: Scalars['String'];
  RegistrationInput: Array<RegistrationInput> | RegistrationInput;
  BookingInput: BookingInput;
  ContactInput: ContactInput;
}>;


export type OnlineBookingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OnlineBookingInsert'>
);

export type PaymentUpdateMutationVariables = Exact<{
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  IsGuestApp?: Maybe<Scalars['Boolean']>;
}>;


export type PaymentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PaymentUpdate'>
);

export type AdvancePaymentUpdateMutationVariables = Exact<{
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  AppName?: Maybe<Scalars['String']>;
}>;


export type AdvancePaymentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AdvancePaymentUpdate'>
);

export type PaymentDetailQueryVariables = Exact<{
  OrderID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PaymentDetailQuery = (
  { __typename?: 'Query' }
  & { PaymentDetail: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'FolioID' | 'RegistrationID' | 'HotelID' | 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'CardNo' | 'Amount' | 'ReferenceNo' | 'PaymentStatus'>
  ) }
);

export type ReceiptListingQueryVariables = Exact<{
  PaymentClass: PaymentClass;
  ReceiptNo: Scalars['String'];
}>;


export type ReceiptListingQuery = (
  { __typename?: 'Query' }
  & { ReceiptListing: Array<(
    { __typename?: 'PaymentFoilioResponse' }
    & Pick<PaymentFoilioResponse, 'RegistrationID' | 'TrxDate' | 'ChargeType' | 'Description' | 'ReferenceNo' | 'FullName' | 'RoomNo' | 'BaseAmount' | 'TrxAmount' | 'ID'>
  )> }
);

export type DisCountByPromoCodeQueryVariables = Exact<{
  PromoCodeInput: Array<PromoCodeInput> | PromoCodeInput;
  PromoCode: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type DisCountByPromoCodeQuery = (
  { __typename?: 'Query' }
  & { DisCountByPromoCode: Array<(
    { __typename?: 'PromoCodeResponse' }
    & Pick<PromoCodeResponse, 'RoomTypeID' | 'RatePolicyID' | 'DiscountAmt'>
  )> }
);

export type ComputeTaxArrByHotelQueryVariables = Exact<{
  TaxInput: Array<TaxInput> | TaxInput;
}>;


export type ComputeTaxArrByHotelQuery = (
  { __typename?: 'Query' }
  & { ComputeTaxArrByHotel: Array<(
    { __typename?: 'TaxOutput' }
    & Pick<TaxOutput, 'DiscountAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'TotalAmount'>
  )> }
);

export type FrontDeskPaymentInsertMutationVariables = Exact<{
  input: Array<FrontDeskPaymentInput> | FrontDeskPaymentInput;
  TotalPaymentAmount: Scalars['Float'];
  DebtorID?: Maybe<Scalars['String']>;
  IsGroupFolio?: Maybe<Scalars['Boolean']>;
  AdjustedAmount?: Maybe<Scalars['Float']>;
}>;


export type FrontDeskPaymentInsertMutation = (
  { __typename?: 'Mutation' }
  & { FrontDeskPaymentInsert: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'CardNo' | 'Amount' | 'ReferenceNo' | 'PaymentStatus'>
  ) }
);

export type IsPaymentGateWayHotelQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type IsPaymentGateWayHotelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsPaymentGateWayHotel'>
);

export type ComputeTaxbyHotelQueryVariables = Exact<{
  input: TaxInput;
}>;


export type ComputeTaxbyHotelQuery = (
  { __typename?: 'Query' }
  & { ComputeTaxbyHotel: (
    { __typename?: 'TaxOutput' }
    & Pick<TaxOutput, 'DiscountAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'TotalAmount'>
  ) }
);

export type ComputeTaxBookingQueryVariables = Exact<{
  RoomTypeID: Scalars['String'];
  RateID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  DiscountAmount: Scalars['Float'];
}>;


export type ComputeTaxBookingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ComputeTaxBooking'>
);

export type BillingByRegistrationQueryVariables = Exact<{
  RegistrationID: Array<Scalars['String']> | Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type BillingByRegistrationQuery = (
  { __typename?: 'Query' }
  & { BillingByRegistration: Array<(
    { __typename?: 'PaymentFoilioResponse' }
    & Pick<PaymentFoilioResponse, 'RegistrationID' | 'TrxDate' | 'ChargeType' | 'Description' | 'ReferenceNo' | 'FullName' | 'RoomNo' | 'FolioID' | 'TrxAmount' | 'BaseAmount' | 'DiscountAmount' | 'UnitPrice' | 'ID' | 'LedgerType' | 'ServiceCharge' | 'TaxAmount' | 'Type' | 'DebtorName' | 'DebtorID' | 'CreatedName' | 'CreatedDT' | 'CreatedBy'>
  )> }
);

export type FolioListingByRegistrationQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
}>;


export type FolioListingByRegistrationQuery = (
  { __typename?: 'Query' }
  & { FolioListingByRegistration: Array<(
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'HotelID' | 'FolioType' | 'RefID' | 'FolioNo' | 'FolioDate' | 'FolioAmount'>
    & { RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'BaseAmount' | 'ReferenceNo' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'ID' | 'Description' | 'TrxDate' | 'ChargeType' | 'TransactionType'>
      & { Transaction?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description' | 'ID'>
      )> }
    )>>, Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'ModifiedBy' | 'FolioID' | 'RegistrationID' | 'HotelID' | 'ReceiptNo' | 'Amount' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo'>
      & { Folio?: Maybe<(
        { __typename?: 'FolioEntity' }
        & Pick<FolioEntity, 'ID'>
        & { RoomLedger?: Maybe<Array<(
          { __typename?: 'RoomLedgerEntity' }
          & Pick<RoomLedgerEntity, 'ID' | 'Description' | 'TrxDate' | 'ChargeType' | 'TransactionType'>
          & { Transaction?: Maybe<(
            { __typename?: 'IncidentalChargeEntity' }
            & Pick<IncidentalChargeEntity, 'Description' | 'ID'>
          )> }
        )>> }
      )> }
    )>>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'ID' | 'FolioID' | 'RegistrationID' | 'ReversedTaxLedgerID' | 'Reason' | 'TaxType' | 'TrxDate' | 'TaxAmount'>
    )>> }
  )> }
);

export type CheckOutMutationVariables = Exact<{
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type CheckOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CheckOut'>
);

export type HotelRatingInsertMutationVariables = Exact<{
  input: HotelRatingInput;
}>;


export type HotelRatingInsertMutation = (
  { __typename?: 'Mutation' }
  & { HotelRatingInsert: (
    { __typename?: 'HotelRatingEntity' }
    & Pick<HotelRatingEntity, 'ID'>
  ) }
);

export type AvailabilityListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate?: Maybe<Scalars['DateTime']>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  TravelAgentID?: Maybe<Scalars['String']>;
}>;


export type AvailabilityListingQuery = (
  { __typename?: 'Query' }
  & { AvailabilityListing: (
    { __typename?: 'HotelAvailabilityOutput' }
    & { RoomTypesList?: Maybe<Array<(
      { __typename?: 'RoomTypesListOutput' }
      & Pick<RoomTypesListOutput, 'ID' | 'Code' | 'Description' | 'TotalRoom'>
      & { RoomTypeAvailability?: Maybe<Array<(
        { __typename?: 'AvailabilityOutput' }
        & Pick<AvailabilityOutput, 'ID' | 'EffectiveDate' | 'BookedRoom' | 'BlockedRoom' | 'AllottedRoom' | 'TotalOccupied' | 'TotalVacant' | 'SeasonType'>
      )>> }
    )>>, Occupancy?: Maybe<Array<(
      { __typename?: 'Occupancy' }
      & Pick<Occupancy, 'EffectiveDate' | 'Season' | 'Occupancy' | 'TotalOccupied' | 'TotalRoom'>
    )>> }
  ) }
);

export type PackagePriceListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
}>;


export type PackagePriceListingQuery = (
  { __typename?: 'Query' }
  & { PackagePriceListing: Array<(
    { __typename?: 'PackagePriceOutput' }
    & Pick<PackagePriceOutput, 'RoomTypeID' | 'RoomTypeCode' | 'RoomTypeName' | 'RatePolicyID' | 'Code' | 'TotalVacant'>
    & { Pricing?: Maybe<Array<(
      { __typename?: 'PricingOutput' }
      & Pick<PricingOutput, 'Date' | 'Type' | 'Price' | 'BasePrice'>
      & { Packages?: Maybe<Array<(
        { __typename?: 'PackagesEntity' }
        & Pick<PackagesEntity, 'ID' | 'IncidentalChargeID' | 'Description' | 'IncludeRate' | 'ComputationRules' | 'ServiceCharge' | 'Tax' | 'Amount'>
      )>> }
    )>> }
  )> }
);

export type ExtendStayInsertMutationVariables = Exact<{
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  input: FrontDeskPaymentInput;
}>;


export type ExtendStayInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ExtendStayInsert'>
);

export type RegistrationListingQueryVariables = Exact<{
  RoomID: Scalars['String'];
}>;


export type RegistrationListingQuery = (
  { __typename?: 'Query' }
  & { RegistrationListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'CheckInDate' | 'CheckOutDate' | 'RegistrationStatus'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ArrivalDate' | 'DepartureDate'>
    )> }
  )> }
);

export type OnlineGaInHouseGuestListingQueryVariables = Exact<{
  GuestID?: Maybe<Scalars['String']>;
}>;


export type OnlineGaInHouseGuestListingQuery = (
  { __typename?: 'Query' }
  & { OnlineGAInHouseGuestListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'DiscountAmount' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'ArrivalDate' | 'DepartureDate' | 'RoomTypeID' | 'ServiceRequestCount' | 'IncidentalChargesCount' | 'TotalOutStandingAmount' | 'RatePolicyID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalPackagePrice' | 'TotalPrice'>
    & { Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'PaymentClass' | 'PaymentType'>
    )>>, AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'ID' | 'PaymentType' | 'PaymentClass' | 'ReceiptNo'>
    )>>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code' | 'Description' | 'ID'>
    )>, RatePolicy?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
      & { RoomStatus?: Maybe<(
        { __typename?: 'RoomStatusEntity' }
        & Pick<RoomStatusEntity, 'ID' | 'Code' | 'CleanOrDirty'>
      )> }
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'Nationality' | 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
        )> }
      )> }
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment'>
      & { Hotel?: Maybe<(
        { __typename?: 'HotelEntity' }
        & Pick<HotelEntity, 'ID' | 'HotelName' | 'AccountID' | 'CheckOutTime' | 'RegCardTnC'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'BusinessEmail' | 'PhoneNo' | 'ReservationEmail'>
        )>, LogoImage?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )>, HotelAddress?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
        )>, Facility?: Maybe<Array<(
          { __typename?: 'FacilityEntity' }
          & Pick<FacilityEntity, 'ID' | 'Title' | 'Level' | 'Description'>
          & { FacilityGallery?: Maybe<Array<(
            { __typename?: 'FacilityGalleryEntity' }
            & Pick<FacilityGalleryEntity, 'ID'>
            & { Gallery?: Maybe<(
              { __typename?: 'GalleryEntity' }
              & Pick<GalleryEntity, 'ID' | 'ImageURL'>
            )> }
          )>> }
        )>> }
      )> }
    )> }
  )> }
);

export type FolioDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type FolioDetailsQuery = (
  { __typename?: 'Query' }
  & { FolioDetails: (
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'FolioNo' | 'FolioType' | 'FolioDate' | 'FolioAmount' | 'CreatedBy'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo'>
      & { Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
        & { Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
            )> }
          )> }
        )> }
      )>> }
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
          )> }
        )> }
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo'>
        & { Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'DebtorAccount'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )>, AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'Amount' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
    )>>, Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'Amount' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
    )>>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'ID' | 'TrxDate' | 'TaxType' | 'TaxAmount' | 'Reason' | 'Description'>
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'ReferenceNo'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )> }
    )>>, RoomCharges?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'TrxDate' | 'BaseAmount' | 'TrxAmount' | 'Description' | 'TaxAmount' | 'ServiceCharge' | 'RegistrationID'>
    )>>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TrxDate' | 'TaxAmount' | 'BaseAmount' | 'TransactionType' | 'TransactionID' | 'ServiceCharge' | 'ReferenceNo' | 'Description'>
      & { Transaction?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )> }
    )>>, DepositLedger?: Maybe<Array<(
      { __typename?: 'DepositLedgerEntity' }
      & Pick<DepositLedgerEntity, 'DepositAmount' | 'DepositClass' | 'ReferenceNo' | 'TrxDate'>
    )>> }
  ) }
);

export type PaymentDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type PaymentDetailsQuery = (
  { __typename?: 'Query' }
  & { PaymentDetails: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'ReceiptNo' | 'ReferenceNo' | 'PaymentDate' | 'PaymentType' | 'Amount' | 'CreatedBy' | 'PaymentStatus' | 'PaymentClass' | 'DebtorAccount'>
    & { Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'FolioNo'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type LateCheckOutChargeListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type LateCheckOutChargeListingQuery = (
  { __typename?: 'Query' }
  & { LateCheckOutChargeListing: Array<(
    { __typename?: 'LateCheckOutChargeEntity' }
    & Pick<LateCheckOutChargeEntity, 'ID' | 'HotelID' | 'Name' | 'Amount' | 'IsActive' | 'CreatedBy' | 'ModifiedBy'>
  )> }
);

export type LateCheckOutInsertMutationVariables = Exact<{
  hour: Scalars['Float'];
  input: FrontDeskPaymentInput;
}>;


export type LateCheckOutInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LateCheckOutInsert'>
);

export type IsRoomAvailableQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type IsRoomAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsRoomAvailable'>
);

export type FrontDeskServiceRequestListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type FrontDeskServiceRequestListingQuery = (
  { __typename?: 'Query' }
  & { FrontDeskServiceRequestListing: Array<(
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID' | 'Title' | 'Description' | 'EstimatedCompletion' | 'CreatedDT' | 'RequestBy' | 'Status' | 'Remarks' | 'DepartmentID'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
      & { Location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'ID' | 'Description'>
      )> }
    )>, Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Name'>
    )>, ServiceRequestGallery?: Maybe<Array<(
      { __typename?: 'ServiceRequestGalleryEntity' }
      & Pick<ServiceRequestGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'BucketFileName' | 'ID' | 'ImageURL'>
      )> }
    )>> }
  )> }
);

export type HousekeepingServiceRequestInsertMutationVariables = Exact<{
  ServiceRequestInput: ServiceRequestInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type HousekeepingServiceRequestInsertMutation = (
  { __typename?: 'Mutation' }
  & { HousekeepingServiceRequestInsert: (
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID'>
  ) }
);

export type HousekeepingServiceRequestUpdateMutationVariables = Exact<{
  ServiceRequestInput: ServiceRequestInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type HousekeepingServiceRequestUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HousekeepingServiceRequestUpdate'>
);

export type DepartmentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DepartmentListingQuery = (
  { __typename?: 'Query' }
  & { DepartmentListing: Array<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'Code' | 'Name' | 'AllowReason' | 'AllowSalesChannel' | 'AllowIncidental' | 'AllowGuestApp'>
    & { ServiceRequest?: Maybe<Array<(
      { __typename?: 'ServiceRequestEntity' }
      & Pick<ServiceRequestEntity, 'ID'>
    )>>, IncidentalCharge?: Maybe<Array<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'IsRoomRevenue' | 'Description'>
    )>>, Reason?: Maybe<Array<(
      { __typename?: 'ReasonEntity' }
      & Pick<ReasonEntity, 'ID'>
    )>>, SalesChannel?: Maybe<Array<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID'>
    )>> }
  )> }
);

export type IncidentalChargeListingByHotelQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
}>;


export type IncidentalChargeListingByHotelQuery = (
  { __typename?: 'Query' }
  & { IncidentalChargeListingByHotel: Array<(
    { __typename?: 'IncidentalChargeEntity' }
    & Pick<IncidentalChargeEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DepartmentID' | 'Description' | 'Amount' | 'Tax' | 'ServiceCharge' | 'IsRoomRevenue' | 'GalleryID' | 'IsFnB' | 'IsEShop'>
    & { Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )> }
  )> }
);

export type GuestRoomServiceInsertMutationVariables = Exact<{
  BillLedgerInput: Array<BillLedgerInput> | BillLedgerInput;
  BookingID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  PaymentMode: Scalars['String'];
}>;


export type GuestRoomServiceInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestRoomServiceInsert'>
);

export type GuestRoomServiceListingQueryVariables = Exact<{
  ServiceType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type GuestRoomServiceListingQuery = (
  { __typename?: 'Query' }
  & { GuestRoomServiceListing: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'ID' | 'HotelID' | 'RegistrationID' | 'ReversedBillLedgerID' | 'IncidentalChargeID' | 'Reason' | 'Description' | 'TrxDate' | 'Qty' | 'UnitPrice' | 'TrxAmount' | 'DiscountAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'IsDelivered' | 'Signature' | 'ReferenceNo' | 'PaymentID'>
    & { IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Description'>
    )> }
  )> }
);

export type PackageRedemptionListingQueryVariables = Exact<{
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
}>;


export type PackageRedemptionListingQuery = (
  { __typename?: 'Query' }
  & { PackageRedemptionListing: Array<(
    { __typename?: 'PackageRedemptionListOutput' }
    & Pick<PackageRedemptionListOutput, 'ID' | 'IncidentalChargeID' | 'Description' | 'ComputationRule' | 'RegistrationID' | 'BookingID' | 'BillScheduleID' | 'DepartmentID' | 'RoomNo' | 'GuestNames' | 'Amount' | 'Total' | 'Redeemed' | 'Balance'>
  )> }
);

export type PackageRedemptionInsertMutationVariables = Exact<{
  PackageRedemptionInput: PackageRedemptionInput;
}>;


export type PackageRedemptionInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PackageRedemptionInsert'>
);

export type GuestMovementInsertMutationVariables = Exact<{
  GuestMovementInput: GuestMovementInput;
}>;


export type GuestMovementInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestMovementInsert'>
);

export type GaMarketingAdsListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type GaMarketingAdsListingQuery = (
  { __typename?: 'Query' }
  & { GAMarketingAdsListing: Array<(
    { __typename?: 'EmailCampignEntity' }
    & Pick<EmailCampignEntity, 'ID' | 'Title' | 'Description' | 'html'>
    & { Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )> }
  )> }
);


export const GuestChangePasswordDocument = gql`
    mutation GuestChangePassword($newPassword: String!, $currentPassword: String!) {
  GuestChangePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    ID
  }
}
    `;
export type GuestChangePasswordMutationFn = Apollo.MutationFunction<GuestChangePasswordMutation, GuestChangePasswordMutationVariables>;

/**
 * __useGuestChangePasswordMutation__
 *
 * To run a mutation, you first call `useGuestChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestChangePasswordMutation, { data, loading, error }] = useGuestChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useGuestChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<GuestChangePasswordMutation, GuestChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestChangePasswordMutation, GuestChangePasswordMutationVariables>(GuestChangePasswordDocument, options);
      }
export type GuestChangePasswordMutationHookResult = ReturnType<typeof useGuestChangePasswordMutation>;
export type GuestChangePasswordMutationResult = Apollo.MutationResult<GuestChangePasswordMutation>;
export type GuestChangePasswordMutationOptions = Apollo.BaseMutationOptions<GuestChangePasswordMutation, GuestChangePasswordMutationVariables>;
export const GuestForgotPasswordDocument = gql`
    mutation GuestForgotPassword($email: String!) {
  GuestForgotPassword(email: $email)
}
    `;
export type GuestForgotPasswordMutationFn = Apollo.MutationFunction<GuestForgotPasswordMutation, GuestForgotPasswordMutationVariables>;

/**
 * __useGuestForgotPasswordMutation__
 *
 * To run a mutation, you first call `useGuestForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestForgotPasswordMutation, { data, loading, error }] = useGuestForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGuestForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<GuestForgotPasswordMutation, GuestForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestForgotPasswordMutation, GuestForgotPasswordMutationVariables>(GuestForgotPasswordDocument, options);
      }
export type GuestForgotPasswordMutationHookResult = ReturnType<typeof useGuestForgotPasswordMutation>;
export type GuestForgotPasswordMutationResult = Apollo.MutationResult<GuestForgotPasswordMutation>;
export type GuestForgotPasswordMutationOptions = Apollo.BaseMutationOptions<GuestForgotPasswordMutation, GuestForgotPasswordMutationVariables>;
export const GuestResetPasswordDocument = gql`
    mutation GuestResetPassword($password: String!, $MobileNo: String!) {
  GuestResetPassword(password: $password, MobileNo: $MobileNo)
}
    `;
export type GuestResetPasswordMutationFn = Apollo.MutationFunction<GuestResetPasswordMutation, GuestResetPasswordMutationVariables>;

/**
 * __useGuestResetPasswordMutation__
 *
 * To run a mutation, you first call `useGuestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestResetPasswordMutation, { data, loading, error }] = useGuestResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      MobileNo: // value for 'MobileNo'
 *   },
 * });
 */
export function useGuestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<GuestResetPasswordMutation, GuestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestResetPasswordMutation, GuestResetPasswordMutationVariables>(GuestResetPasswordDocument, options);
      }
export type GuestResetPasswordMutationHookResult = ReturnType<typeof useGuestResetPasswordMutation>;
export type GuestResetPasswordMutationResult = Apollo.MutationResult<GuestResetPasswordMutation>;
export type GuestResetPasswordMutationOptions = Apollo.BaseMutationOptions<GuestResetPasswordMutation, GuestResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const LoggedInGuestProfileDocument = gql`
    query loggedInGuestProfile {
  loggedInGuestProfile {
    ID
    IsActive
    ContactID
    Company
    IsVIP
    Login
    IdPicture
    VehicleNo
    NewsLetter
    Contact {
      ID
      ProfilePictureGallery {
        ID
        ImageURL
      }
      FullName
      Email
      Nationality
      MobileNo
      NRIC
      Address {
        address
        postCode
        state
        city
        country
      }
    }
    accessToken
    GuestHistoryList {
      ID
    }
  }
}
    `;

/**
 * __useLoggedInGuestProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInGuestProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInGuestProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInGuestProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInGuestProfileQuery(baseOptions?: Apollo.QueryHookOptions<LoggedInGuestProfileQuery, LoggedInGuestProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggedInGuestProfileQuery, LoggedInGuestProfileQueryVariables>(LoggedInGuestProfileDocument, options);
      }
export function useLoggedInGuestProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggedInGuestProfileQuery, LoggedInGuestProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggedInGuestProfileQuery, LoggedInGuestProfileQueryVariables>(LoggedInGuestProfileDocument, options);
        }
export type LoggedInGuestProfileQueryHookResult = ReturnType<typeof useLoggedInGuestProfileQuery>;
export type LoggedInGuestProfileLazyQueryHookResult = ReturnType<typeof useLoggedInGuestProfileLazyQuery>;
export type LoggedInGuestProfileQueryResult = Apollo.QueryResult<LoggedInGuestProfileQuery, LoggedInGuestProfileQueryVariables>;
export const GuestUpdateDocument = gql`
    mutation GuestUpdate($OldGalleryID: [String!], $AttachmentInput: [Upload!], $ProfilePictureAttachmentInput: [Upload!], $GuestProfileInput: GuestProfileInput!, $ContactInput: ContactInput!) {
  GuestUpdate(OldGalleryID: $OldGalleryID, AttachmentInput: $AttachmentInput, ProfilePictureAttachmentInput: $ProfilePictureAttachmentInput, GuestProfileInput: $GuestProfileInput, ContactInput: $ContactInput)
}
    `;
export type GuestUpdateMutationFn = Apollo.MutationFunction<GuestUpdateMutation, GuestUpdateMutationVariables>;

/**
 * __useGuestUpdateMutation__
 *
 * To run a mutation, you first call `useGuestUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestUpdateMutation, { data, loading, error }] = useGuestUpdateMutation({
 *   variables: {
 *      OldGalleryID: // value for 'OldGalleryID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      ProfilePictureAttachmentInput: // value for 'ProfilePictureAttachmentInput'
 *      GuestProfileInput: // value for 'GuestProfileInput'
 *      ContactInput: // value for 'ContactInput'
 *   },
 * });
 */
export function useGuestUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GuestUpdateMutation, GuestUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestUpdateMutation, GuestUpdateMutationVariables>(GuestUpdateDocument, options);
      }
export type GuestUpdateMutationHookResult = ReturnType<typeof useGuestUpdateMutation>;
export type GuestUpdateMutationResult = Apollo.MutationResult<GuestUpdateMutation>;
export type GuestUpdateMutationOptions = Apollo.BaseMutationOptions<GuestUpdateMutation, GuestUpdateMutationVariables>;
export const GuestBookingListingDocument = gql`
    query GuestBookingListing($HotelID: String, $GuestID: String!, $offset: Float, $limit: Float) {
  GuestBookingListing(HotelID: $HotelID, GuestID: $GuestID, offset: $offset, limit: $limit) {
    ID
    CreatedDT
    BookingNo
    ArrivalDate
    DepartureDate
    DebtorAccount
    BookingStatus
    TotalAdvPayment
    Hotel {
      ID
      SSTRegNo
      AccountID
      HotelName
      OnlineTnC
      RegCardTnC
      HotelRating {
        ID
        Comments
        BookingID
        Cleanliness
        Facilities
        Location
        ComfortQuality
        Service
        OverallExperience
      }
      LogoImage {
        ID
        ImageURL
      }
      AboutHotelImage {
        ID
        ImageURL
      }
      HotelName
      HotelAddress {
        address
        postCode
        state
        city
        country
      }
      Contact {
        PhoneNo
        BusinessEmail
        WebUrl
      }
      Facility {
        ID
        HotelID
        Level
        IsActive
        Title
        Description
        FacilityGallery {
          ID
          FacilityID
          GalleryID
          Gallery {
            ID
            ImageURL
            BucketFileName
          }
        }
      }
    }
    Debtor {
      ID
      Name
      Contact {
        FullName
        Email
        MobileNo
      }
      CreditLimit
      DebtorAccount
      Segment
      Password
      Login
    }
    Contact {
      ID
      FullName
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
    }
    BookedRegistration {
      RegistrationStatus
      SharerNo
      SpecialRequest
      PromoCode
      RoomType {
        ID
        MaxGuest
        Code
        Description
        RoomSize
        MeasureType
        RoomTypeGallery {
          ID
          Gallery {
            ID
            ImageURL
          }
        }
        RoomTypeAmenities {
          ID
          Name
          IsSelected
          Gallery {
            ID
            ImageURL
          }
        }
      }
      TaxLedger {
        ID
        FolioID
        RegistrationID
        ReversedTaxLedgerID
        Reason
        TaxType
        TrxDate
        TaxAmount
      }
      DepositLedger {
        ID
        FolioID
        DepositAmount
        DepositClass
      }
      ID
      DepositSum
      PaymentSum
      CheckOutDate
      CheckInDate
      IncidentalChargesSum
      IsPrincipal
      RoomRate
      TotalRoomPrice
      TotalPackagePrice
      TotalPrice
      RegistrationStatus
      Room {
        ID
        RoomNo
        RoomStatus {
          ID
          CleanOrDirty
          Code
        }
      }
      Guest {
        ID
        ContactID
        VehicleNo
        Contact {
          ID
          FullName
          Nationality
          MobileNo
          NRIC
          Email
          Address {
            address
            state
            country
            postCode
            city
          }
        }
      }
      TaxLedger {
        ID
        FolioID
        RegistrationID
        ReversedTaxLedgerID
        Reason
        TaxType
        TrxDate
        TaxAmount
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
    }
  }
}
    `;

/**
 * __useGuestBookingListingQuery__
 *
 * To run a query within a React component, call `useGuestBookingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestBookingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestBookingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      GuestID: // value for 'GuestID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGuestBookingListingQuery(baseOptions: Apollo.QueryHookOptions<GuestBookingListingQuery, GuestBookingListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuestBookingListingQuery, GuestBookingListingQueryVariables>(GuestBookingListingDocument, options);
      }
export function useGuestBookingListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestBookingListingQuery, GuestBookingListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuestBookingListingQuery, GuestBookingListingQueryVariables>(GuestBookingListingDocument, options);
        }
export type GuestBookingListingQueryHookResult = ReturnType<typeof useGuestBookingListingQuery>;
export type GuestBookingListingLazyQueryHookResult = ReturnType<typeof useGuestBookingListingLazyQuery>;
export type GuestBookingListingQueryResult = Apollo.QueryResult<GuestBookingListingQuery, GuestBookingListingQueryVariables>;
export const BookingDetailsDocument = gql`
    query BookingDetails($ID: String!, $HotelID: String!) {
  BookingDetails(ID: $ID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    Hotel {
      ID
      AllowCancellationDays
      HotelName
      AccountID
      RegCardTnC
      LogoImage {
        ID
        ImageURL
      }
      HotelAddress {
        address
        address2
        siteName
        country
        city
        state
        postCode
      }
    }
    CreatedDT
    Contact {
      FullName
      PhoneNo
      Email
      Nationality
      Address {
        address
        country
        city
        state
        postCode
      }
      ID
      MobileNo
      PassportNo
      NRIC
    }
    Debtor {
      DebtorCategory
      ID
      Name
    }
    BookingNo
    ArrivalDate
    DepartureDate
    ContactID
    Contact {
      ID
      FullName
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
      Address {
        address
        country
        city
        state
        postCode
      }
    }
    GroupName
    IsGroupBooking
    ChargeToCityLedger
    SalesChannelID
    SalesChannel {
      ID
      Description
    }
    Source
    SegmentID
    Segment {
      ID
      Description
    }
    SpecialRequest
    DebtorAccount
    BillingInstruction
    Reason
    BookingStatus
    AdvancePayment {
      ID
      PaymentType
      PaymentClass
    }
    BookedRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      DiscountAmount
      NoOfChild
      NoOfInfant
      IsPrincipal
      Signature
      SpecialRequest
      RegistrationStatus
      RoomTypeID
      Payment {
        ID
        PaymentClass
        PaymentType
      }
      AdvancePayment {
        ID
        PaymentType
        PaymentClass
        ReceiptNo
      }
      RatePolicyID
      RoomType {
        Code
        Description
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      Room {
        ID
        RoomNo
        RoomStatus {
          ID
          Code
          CleanOrDirty
        }
      }
      Guest {
        ID
        VehicleNo
        Contact {
          Nationality
          ID
          FullName
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
      }
      RoomRate
      TotalRoomPrice
      TotalPackagePrice
      TotalPrice
    }
  }
}
    `;

/**
 * __useBookingDetailsQuery__
 *
 * To run a query within a React component, call `useBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBookingDetailsQuery(baseOptions: Apollo.QueryHookOptions<BookingDetailsQuery, BookingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(BookingDetailsDocument, options);
      }
export function useBookingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingDetailsQuery, BookingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(BookingDetailsDocument, options);
        }
export type BookingDetailsQueryHookResult = ReturnType<typeof useBookingDetailsQuery>;
export type BookingDetailsLazyQueryHookResult = ReturnType<typeof useBookingDetailsLazyQuery>;
export type BookingDetailsQueryResult = Apollo.QueryResult<BookingDetailsQuery, BookingDetailsQueryVariables>;
export const SegmentListingDocument = gql`
    query SegmentListing($IsActive: Boolean, $HotelID: String!) {
  SegmentListing(IsActive: $IsActive, HotelID: $HotelID) {
    ID
    Description
  }
}
    `;

/**
 * __useSegmentListingQuery__
 *
 * To run a query within a React component, call `useSegmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentListingQuery({
 *   variables: {
 *      IsActive: // value for 'IsActive'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useSegmentListingQuery(baseOptions: Apollo.QueryHookOptions<SegmentListingQuery, SegmentListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SegmentListingQuery, SegmentListingQueryVariables>(SegmentListingDocument, options);
      }
export function useSegmentListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SegmentListingQuery, SegmentListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SegmentListingQuery, SegmentListingQueryVariables>(SegmentListingDocument, options);
        }
export type SegmentListingQueryHookResult = ReturnType<typeof useSegmentListingQuery>;
export type SegmentListingLazyQueryHookResult = ReturnType<typeof useSegmentListingLazyQuery>;
export type SegmentListingQueryResult = Apollo.QueryResult<SegmentListingQuery, SegmentListingQueryVariables>;
export const BookingPackagePriceListingDocument = gql`
    query BookingPackagePriceListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String, $DebtorID: String, $RoomTypeID: [String!], $RatePolicyID: String, $NoOfRoom: Float) {
  BookingPackagePriceListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType, DebtorID: $DebtorID, RoomTypeID: $RoomTypeID, RatePolicyID: $RatePolicyID, NoOfRoom: $NoOfRoom) {
    RoomTypeID
    RoomTypeCode
    RoomTypeName
    RatePolicyID
    Code
    TotalVacant
    Pricing {
      Date
      Type
      Price
      BasePrice
      Packages {
        ID
        IncidentalChargeID
        Description
        IncludeRate
        ComputationRules
        ServiceCharge
        Tax
        Amount
      }
    }
  }
}
    `;

/**
 * __useBookingPackagePriceListingQuery__
 *
 * To run a query within a React component, call `useBookingPackagePriceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingPackagePriceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingPackagePriceListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *      DebtorID: // value for 'DebtorID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RatePolicyID: // value for 'RatePolicyID'
 *      NoOfRoom: // value for 'NoOfRoom'
 *   },
 * });
 */
export function useBookingPackagePriceListingQuery(baseOptions: Apollo.QueryHookOptions<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>(BookingPackagePriceListingDocument, options);
      }
export function useBookingPackagePriceListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>(BookingPackagePriceListingDocument, options);
        }
export type BookingPackagePriceListingQueryHookResult = ReturnType<typeof useBookingPackagePriceListingQuery>;
export type BookingPackagePriceListingLazyQueryHookResult = ReturnType<typeof useBookingPackagePriceListingLazyQuery>;
export type BookingPackagePriceListingQueryResult = Apollo.QueryResult<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>;
export const GuestAppRoomTypeDeleteDocument = gql`
    mutation GuestAppRoomTypeDelete($RegistrationID: String, $SharerNo: Float, $BookingID: String!) {
  GuestAppRoomTypeDelete(RegistrationID: $RegistrationID, SharerNo: $SharerNo, BookingID: $BookingID)
}
    `;
export type GuestAppRoomTypeDeleteMutationFn = Apollo.MutationFunction<GuestAppRoomTypeDeleteMutation, GuestAppRoomTypeDeleteMutationVariables>;

/**
 * __useGuestAppRoomTypeDeleteMutation__
 *
 * To run a mutation, you first call `useGuestAppRoomTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestAppRoomTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestAppRoomTypeDeleteMutation, { data, loading, error }] = useGuestAppRoomTypeDeleteMutation({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      SharerNo: // value for 'SharerNo'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useGuestAppRoomTypeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<GuestAppRoomTypeDeleteMutation, GuestAppRoomTypeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestAppRoomTypeDeleteMutation, GuestAppRoomTypeDeleteMutationVariables>(GuestAppRoomTypeDeleteDocument, options);
      }
export type GuestAppRoomTypeDeleteMutationHookResult = ReturnType<typeof useGuestAppRoomTypeDeleteMutation>;
export type GuestAppRoomTypeDeleteMutationResult = Apollo.MutationResult<GuestAppRoomTypeDeleteMutation>;
export type GuestAppRoomTypeDeleteMutationOptions = Apollo.BaseMutationOptions<GuestAppRoomTypeDeleteMutation, GuestAppRoomTypeDeleteMutationVariables>;
export const GuestInsertDocument = gql`
    mutation GuestInsert($ContactInput: [ContactInput!]!, $BookingID: String!, $RegistrationID: String!, $AttachmentInput: [Upload!], $HotelID: String!) {
  GuestInsert(ContactInput: $ContactInput, BookingID: $BookingID, RegistrationID: $RegistrationID, AttachmentInput: $AttachmentInput, HotelID: $HotelID)
}
    `;
export type GuestInsertMutationFn = Apollo.MutationFunction<GuestInsertMutation, GuestInsertMutationVariables>;

/**
 * __useGuestInsertMutation__
 *
 * To run a mutation, you first call `useGuestInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestInsertMutation, { data, loading, error }] = useGuestInsertMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGuestInsertMutation(baseOptions?: Apollo.MutationHookOptions<GuestInsertMutation, GuestInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestInsertMutation, GuestInsertMutationVariables>(GuestInsertDocument, options);
      }
export type GuestInsertMutationHookResult = ReturnType<typeof useGuestInsertMutation>;
export type GuestInsertMutationResult = Apollo.MutationResult<GuestInsertMutation>;
export type GuestInsertMutationOptions = Apollo.BaseMutationOptions<GuestInsertMutation, GuestInsertMutationVariables>;
export const GuestContactUpdateDocument = gql`
    mutation GuestContactUpdate($ContactInput: ContactInput!, $VehicleNo: String, $BookingID: String, $RegistrationID: String!, $HotelID: String, $IsPrincipal: Boolean) {
  GuestContactUpdate(ContactInput: $ContactInput, VehicleNo: $VehicleNo, BookingID: $BookingID, HotelID: $HotelID, IsPrincipal: $IsPrincipal, RegistrationID: $RegistrationID)
}
    `;
export type GuestContactUpdateMutationFn = Apollo.MutationFunction<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>;

/**
 * __useGuestContactUpdateMutation__
 *
 * To run a mutation, you first call `useGuestContactUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestContactUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestContactUpdateMutation, { data, loading, error }] = useGuestContactUpdateMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      VehicleNo: // value for 'VehicleNo'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      HotelID: // value for 'HotelID'
 *      IsPrincipal: // value for 'IsPrincipal'
 *   },
 * });
 */
export function useGuestContactUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>(GuestContactUpdateDocument, options);
      }
export type GuestContactUpdateMutationHookResult = ReturnType<typeof useGuestContactUpdateMutation>;
export type GuestContactUpdateMutationResult = Apollo.MutationResult<GuestContactUpdateMutation>;
export type GuestContactUpdateMutationOptions = Apollo.BaseMutationOptions<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>;
export const GuestProfileDeleteDocument = gql`
    mutation GuestProfileDelete($RegistrationID: String, $BookingID: String, $GuestID: String) {
  GuestProfileDelete(RegistrationID: $RegistrationID, BookingID: $BookingID, GuestID: $GuestID)
}
    `;
export type GuestProfileDeleteMutationFn = Apollo.MutationFunction<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>;

/**
 * __useGuestProfileDeleteMutation__
 *
 * To run a mutation, you first call `useGuestProfileDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestProfileDeleteMutation, { data, loading, error }] = useGuestProfileDeleteMutation({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *      GuestID: // value for 'GuestID'
 *   },
 * });
 */
export function useGuestProfileDeleteMutation(baseOptions?: Apollo.MutationHookOptions<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>(GuestProfileDeleteDocument, options);
      }
export type GuestProfileDeleteMutationHookResult = ReturnType<typeof useGuestProfileDeleteMutation>;
export type GuestProfileDeleteMutationResult = Apollo.MutationResult<GuestProfileDeleteMutation>;
export type GuestProfileDeleteMutationOptions = Apollo.BaseMutationOptions<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>;
export const CancelBookingDocument = gql`
    mutation CancelBooking($BookingID: String!, $HotelID: String!, $ReasonID: String) {
  CancelBooking(BookingID: $BookingID, HotelID: $HotelID, ReasonID: $ReasonID)
}
    `;
export type CancelBookingMutationFn = Apollo.MutationFunction<CancelBookingMutation, CancelBookingMutationVariables>;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      ReasonID: // value for 'ReasonID'
 *   },
 * });
 */
export function useCancelBookingMutation(baseOptions?: Apollo.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(CancelBookingDocument, options);
      }
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = Apollo.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<CancelBookingMutation, CancelBookingMutationVariables>;
export const BookingRoomTypeUpdateDocument = gql`
    mutation BookingRoomTypeUpdate($EditRegInput: EditRegInput!) {
  BookingRoomTypeUpdate(EditRegInput: $EditRegInput)
}
    `;
export type BookingRoomTypeUpdateMutationFn = Apollo.MutationFunction<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>;

/**
 * __useBookingRoomTypeUpdateMutation__
 *
 * To run a mutation, you first call `useBookingRoomTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingRoomTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingRoomTypeUpdateMutation, { data, loading, error }] = useBookingRoomTypeUpdateMutation({
 *   variables: {
 *      EditRegInput: // value for 'EditRegInput'
 *   },
 * });
 */
export function useBookingRoomTypeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>(BookingRoomTypeUpdateDocument, options);
      }
export type BookingRoomTypeUpdateMutationHookResult = ReturnType<typeof useBookingRoomTypeUpdateMutation>;
export type BookingRoomTypeUpdateMutationResult = Apollo.MutationResult<BookingRoomTypeUpdateMutation>;
export type BookingRoomTypeUpdateMutationOptions = Apollo.BaseMutationOptions<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>;
export const PaymentRegistrationListingDocument = gql`
    query PaymentRegistrationListing($RegistrationID: String!) {
  PaymentRegistrationListing(RegistrationID: $RegistrationID) {
    RegistrationStatus
    SharerNo
    SpecialRequest
    PromoCode
    RoomType {
      ID
      MaxGuest
      Code
      Description
      RoomSize
      MeasureType
      RoomTypeGallery {
        ID
        Gallery {
          ID
          ImageURL
        }
      }
      RoomTypeAmenities {
        ID
        Name
        Gallery {
          ID
          ImageURL
        }
      }
    }
    TaxLedger {
      ID
      FolioID
      RegistrationID
      ReversedTaxLedgerID
      Reason
      TaxType
      TrxDate
      TaxAmount
    }
  }
}
    `;

/**
 * __usePaymentRegistrationListingQuery__
 *
 * To run a query within a React component, call `usePaymentRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRegistrationListingQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePaymentRegistrationListingQuery(baseOptions: Apollo.QueryHookOptions<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>(PaymentRegistrationListingDocument, options);
      }
export function usePaymentRegistrationListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>(PaymentRegistrationListingDocument, options);
        }
export type PaymentRegistrationListingQueryHookResult = ReturnType<typeof usePaymentRegistrationListingQuery>;
export type PaymentRegistrationListingLazyQueryHookResult = ReturnType<typeof usePaymentRegistrationListingLazyQuery>;
export type PaymentRegistrationListingQueryResult = Apollo.QueryResult<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>;
export const CheckInDocument = gql`
    mutation CheckIn($RegistrationIDs: [String!]!, $BookingID: String!, $ContactInput: ContactInput, $GuestAppCheckIn: Boolean, $IdPicture: [Upload!], $AttachmentInput: [Upload!], $HotelID: String!) {
  CheckIn(RegistrationIDs: $RegistrationIDs, BookingID: $BookingID, ContactInput: $ContactInput, GuestAppCheckIn: $GuestAppCheckIn, AttachmentInput: $AttachmentInput, IdPicture: $IdPicture, HotelID: $HotelID)
}
    `;
export type CheckInMutationFn = Apollo.MutationFunction<CheckInMutation, CheckInMutationVariables>;

/**
 * __useCheckInMutation__
 *
 * To run a mutation, you first call `useCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInMutation, { data, loading, error }] = useCheckInMutation({
 *   variables: {
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      BookingID: // value for 'BookingID'
 *      ContactInput: // value for 'ContactInput'
 *      GuestAppCheckIn: // value for 'GuestAppCheckIn'
 *      IdPicture: // value for 'IdPicture'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCheckInMutation(baseOptions?: Apollo.MutationHookOptions<CheckInMutation, CheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInMutation, CheckInMutationVariables>(CheckInDocument, options);
      }
export type CheckInMutationHookResult = ReturnType<typeof useCheckInMutation>;
export type CheckInMutationResult = Apollo.MutationResult<CheckInMutation>;
export type CheckInMutationOptions = Apollo.BaseMutationOptions<CheckInMutation, CheckInMutationVariables>;
export const HotelDetailsDocument = gql`
    query HotelDetails($HotelID: String, $GuestAppURL: String, $OnlineBookingURL: String) {
  HotelDetails(HotelID: $HotelID, GuestAppURL: $GuestAppURL, OnlineBookingURL: $OnlineBookingURL) {
    ID
    HotelName
    AccountID
    AboutHotel
    PDPA
    OnlineTnC
    HotelGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
        SequenceNo
      }
    }
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    LogoImage {
      ID
      ImageURL
    }
    AboutHotelImage {
      ID
      ImageURL
    }
    WeightedAverage
    Facility {
      ID
      Level
      HotelID
      IsActive
      Title
      Description
      FacilityGallery {
        ID
        FacilityID
        GalleryID
        Gallery {
          ID
          ImageURL
          BucketFileName
        }
      }
    }
    RoomType {
      ID
      Code
      Description
      PropertyType
      MeasureType
      RoomSize
      TotalRoom
      MaxGuest
      Room {
        ID
        RoomNo
        RoomStatus {
          ID
          Code
          VacantOrOccupied
          CleanOrDirty
          Code
        }
      }
      RoomTypeGallery {
        ID
        Gallery {
          ID
          ImageURL
        }
      }
      RoomTypeAmenities {
        ID
        Name
        Gallery {
          ID
          ImageURL
        }
      }
      Price {
        ID
        RatePolicy {
          ID
          Code
          Description
          Package {
            ID
            Amount
            Description
            Tax
            ServiceCharge
          }
        }
      }
    }
    IsOnlineBooking
    Contact {
      PhoneNo
      ReservationEmail
    }
  }
}
    `;

/**
 * __useHotelDetailsQuery__
 *
 * To run a query within a React component, call `useHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      GuestAppURL: // value for 'GuestAppURL'
 *      OnlineBookingURL: // value for 'OnlineBookingURL'
 *   },
 * });
 */
export function useHotelDetailsQuery(baseOptions?: Apollo.QueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, options);
      }
export function useHotelDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, options);
        }
export type HotelDetailsQueryHookResult = ReturnType<typeof useHotelDetailsQuery>;
export type HotelDetailsLazyQueryHookResult = ReturnType<typeof useHotelDetailsLazyQuery>;
export type HotelDetailsQueryResult = Apollo.QueryResult<HotelDetailsQuery, HotelDetailsQueryVariables>;
export const HotelPricesByDateRangeDocument = gql`
    query HotelPricesByDateRange($CheckOut: DateTime, $CheckIn: DateTime, $HotelID: String!) {
  HotelPricesByDateRange(CheckOut: $CheckOut, CheckIn: $CheckIn, HotelID: $HotelID) {
    ID
    HotelName
    AccountID
    IsPostPaid
    WeightedAverage
    PDPA
    OnlineTnC
    OnlinePaymentTnC
    IsPostPaid
    AboutHotel
    TaxSchemeID
    Facility {
      ID
      HotelID
      IsActive
      Title
      Description
      FacilityGallery {
        ID
        FacilityID
        GalleryID
        Gallery {
          ID
          ImageURL
          BucketFileName
        }
      }
    }
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    AboutHotelImage {
      ID
      ImageURL
    }
    HotelGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
        SequenceNo
      }
    }
    HotelRate {
      ID
      Code
      Description
      PropertyType
      MeasureType
      DisplaySequence
      RoomSize
      TotalRoom
      MaxGuest
      Room {
        ID
        RoomNo
        RoomStatus {
          ID
          Code
          VacantOrOccupied
          CleanOrDirty
          Code
        }
      }
      RoomTypeGallery {
        ID
        Gallery {
          ID
          ImageURL
        }
      }
      RoomTypeAmenities {
        ID
        IsSelected
        Name
        Gallery {
          ID
          ImageURL
        }
      }
      TotalPackages {
        PackageName
        Price
        RatePolicyID
        PriceID
      }
      RoomTypeAvailability {
        ID
        EffectiveDate
        TotalVacant
        Pricing {
          ID
          PackageAmount
          SellingPrice
          RatePolicy {
            ID
            Description
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHotelPricesByDateRangeQuery__
 *
 * To run a query within a React component, call `useHotelPricesByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelPricesByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelPricesByDateRangeQuery({
 *   variables: {
 *      CheckOut: // value for 'CheckOut'
 *      CheckIn: // value for 'CheckIn'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useHotelPricesByDateRangeQuery(baseOptions: Apollo.QueryHookOptions<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>(HotelPricesByDateRangeDocument, options);
      }
export function useHotelPricesByDateRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>(HotelPricesByDateRangeDocument, options);
        }
export type HotelPricesByDateRangeQueryHookResult = ReturnType<typeof useHotelPricesByDateRangeQuery>;
export type HotelPricesByDateRangeLazyQueryHookResult = ReturnType<typeof useHotelPricesByDateRangeLazyQuery>;
export type HotelPricesByDateRangeQueryResult = Apollo.QueryResult<HotelPricesByDateRangeQuery, HotelPricesByDateRangeQueryVariables>;
export const HotelReviewListingDocument = gql`
    query HotelReviewListing($HotelID: String) {
  HotelReviewListing(HotelID: $HotelID) {
    ID
    Cleanliness
    Facilities
    Location
    ComfortQuality
    Service
    OverallExperience
    Comments
    ReviewDate
    Booking {
      ID
      Contact {
        ID
        FullName
      }
    }
  }
}
    `;

/**
 * __useHotelReviewListingQuery__
 *
 * To run a query within a React component, call `useHotelReviewListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelReviewListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelReviewListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useHotelReviewListingQuery(baseOptions?: Apollo.QueryHookOptions<HotelReviewListingQuery, HotelReviewListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HotelReviewListingQuery, HotelReviewListingQueryVariables>(HotelReviewListingDocument, options);
      }
export function useHotelReviewListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HotelReviewListingQuery, HotelReviewListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HotelReviewListingQuery, HotelReviewListingQueryVariables>(HotelReviewListingDocument, options);
        }
export type HotelReviewListingQueryHookResult = ReturnType<typeof useHotelReviewListingQuery>;
export type HotelReviewListingLazyQueryHookResult = ReturnType<typeof useHotelReviewListingLazyQuery>;
export type HotelReviewListingQueryResult = Apollo.QueryResult<HotelReviewListingQuery, HotelReviewListingQueryVariables>;
export const IsGuestAppUserDocument = gql`
    query IsGuestAppUser($Login: String) {
  IsGuestAppUser(Login: $Login) {
    ID
    accessToken
    Contact {
      ID
      FullName
      MobileNo
      Email
      Nationality
    }
  }
}
    `;

/**
 * __useIsGuestAppUserQuery__
 *
 * To run a query within a React component, call `useIsGuestAppUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsGuestAppUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsGuestAppUserQuery({
 *   variables: {
 *      Login: // value for 'Login'
 *   },
 * });
 */
export function useIsGuestAppUserQuery(baseOptions?: Apollo.QueryHookOptions<IsGuestAppUserQuery, IsGuestAppUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsGuestAppUserQuery, IsGuestAppUserQueryVariables>(IsGuestAppUserDocument, options);
      }
export function useIsGuestAppUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsGuestAppUserQuery, IsGuestAppUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsGuestAppUserQuery, IsGuestAppUserQueryVariables>(IsGuestAppUserDocument, options);
        }
export type IsGuestAppUserQueryHookResult = ReturnType<typeof useIsGuestAppUserQuery>;
export type IsGuestAppUserLazyQueryHookResult = ReturnType<typeof useIsGuestAppUserLazyQuery>;
export type IsGuestAppUserQueryResult = Apollo.QueryResult<IsGuestAppUserQuery, IsGuestAppUserQueryVariables>;
export const CurrentTaxDetailsDocument = gql`
    query CurrentTaxDetails($HotelID: String!) {
  CurrentTaxDetails(HotelID: $HotelID) {
    HotelID
    GovTax
    ServiceCharge
  }
}
    `;

/**
 * __useCurrentTaxDetailsQuery__
 *
 * To run a query within a React component, call `useCurrentTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTaxDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCurrentTaxDetailsQuery(baseOptions: Apollo.QueryHookOptions<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>(CurrentTaxDetailsDocument, options);
      }
export function useCurrentTaxDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>(CurrentTaxDetailsDocument, options);
        }
export type CurrentTaxDetailsQueryHookResult = ReturnType<typeof useCurrentTaxDetailsQuery>;
export type CurrentTaxDetailsLazyQueryHookResult = ReturnType<typeof useCurrentTaxDetailsLazyQuery>;
export type CurrentTaxDetailsQueryResult = Apollo.QueryResult<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>;
export const OnlinePaymentDocument = gql`
    mutation OnlinePayment($redirectUrl: String!, $PayAmount: Float!, $Detail: String!, $Title: String!, $HotelID: String!, $ReceiptNo: String!, $AdvancePaymentID: String) {
  OnlinePayment(redirectUrl: $redirectUrl, PayAmount: $PayAmount, Detail: $Detail, Title: $Title, HotelID: $HotelID, ReceiptNo: $ReceiptNo, AdvancePaymentID: $AdvancePaymentID) {
    item {
      checkoutId
      url
      qrCodeUrl
      status
    }
    code
    error {
      code
      message
      debug
    }
    PaySessionNo
  }
}
    `;
export type OnlinePaymentMutationFn = Apollo.MutationFunction<OnlinePaymentMutation, OnlinePaymentMutationVariables>;

/**
 * __useOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onlinePaymentMutation, { data, loading, error }] = useOnlinePaymentMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *      PayAmount: // value for 'PayAmount'
 *      Detail: // value for 'Detail'
 *      Title: // value for 'Title'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      AdvancePaymentID: // value for 'AdvancePaymentID'
 *   },
 * });
 */
export function useOnlinePaymentMutation(baseOptions?: Apollo.MutationHookOptions<OnlinePaymentMutation, OnlinePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnlinePaymentMutation, OnlinePaymentMutationVariables>(OnlinePaymentDocument, options);
      }
export type OnlinePaymentMutationHookResult = ReturnType<typeof useOnlinePaymentMutation>;
export type OnlinePaymentMutationResult = Apollo.MutationResult<OnlinePaymentMutation>;
export type OnlinePaymentMutationOptions = Apollo.BaseMutationOptions<OnlinePaymentMutation, OnlinePaymentMutationVariables>;
export const OnlineBookingInsertDocument = gql`
    mutation OnlineBookingInsert($AppName: String!, $RegistrationInput: [RegistrationInput!]!, $BookingInput: BookingInput!, $ContactInput: ContactInput!) {
  OnlineBookingInsert(AppName: $AppName, RegistrationInput: $RegistrationInput, BookingInput: $BookingInput, ContactInput: $ContactInput)
}
    `;
export type OnlineBookingInsertMutationFn = Apollo.MutationFunction<OnlineBookingInsertMutation, OnlineBookingInsertMutationVariables>;

/**
 * __useOnlineBookingInsertMutation__
 *
 * To run a mutation, you first call `useOnlineBookingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnlineBookingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onlineBookingInsertMutation, { data, loading, error }] = useOnlineBookingInsertMutation({
 *   variables: {
 *      AppName: // value for 'AppName'
 *      RegistrationInput: // value for 'RegistrationInput'
 *      BookingInput: // value for 'BookingInput'
 *      ContactInput: // value for 'ContactInput'
 *   },
 * });
 */
export function useOnlineBookingInsertMutation(baseOptions?: Apollo.MutationHookOptions<OnlineBookingInsertMutation, OnlineBookingInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnlineBookingInsertMutation, OnlineBookingInsertMutationVariables>(OnlineBookingInsertDocument, options);
      }
export type OnlineBookingInsertMutationHookResult = ReturnType<typeof useOnlineBookingInsertMutation>;
export type OnlineBookingInsertMutationResult = Apollo.MutationResult<OnlineBookingInsertMutation>;
export type OnlineBookingInsertMutationOptions = Apollo.BaseMutationOptions<OnlineBookingInsertMutation, OnlineBookingInsertMutationVariables>;
export const PaymentUpdateDocument = gql`
    mutation PaymentUpdate($CheckOutID: String, $OrderID: String, $Status: String, $HotelID: String, $ReceiptNo: String, $RegistrationID: String, $IsGuestApp: Boolean) {
  PaymentUpdate(CheckOutID: $CheckOutID, OrderID: $OrderID, Status: $Status, HotelID: $HotelID, ReceiptNo: $ReceiptNo, RegistrationID: $RegistrationID, IsGuestApp: $IsGuestApp)
}
    `;
export type PaymentUpdateMutationFn = Apollo.MutationFunction<PaymentUpdateMutation, PaymentUpdateMutationVariables>;

/**
 * __usePaymentUpdateMutation__
 *
 * To run a mutation, you first call `usePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentUpdateMutation, { data, loading, error }] = usePaymentUpdateMutation({
 *   variables: {
 *      CheckOutID: // value for 'CheckOutID'
 *      OrderID: // value for 'OrderID'
 *      Status: // value for 'Status'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      RegistrationID: // value for 'RegistrationID'
 *      IsGuestApp: // value for 'IsGuestApp'
 *   },
 * });
 */
export function usePaymentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PaymentUpdateMutation, PaymentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentUpdateMutation, PaymentUpdateMutationVariables>(PaymentUpdateDocument, options);
      }
export type PaymentUpdateMutationHookResult = ReturnType<typeof usePaymentUpdateMutation>;
export type PaymentUpdateMutationResult = Apollo.MutationResult<PaymentUpdateMutation>;
export type PaymentUpdateMutationOptions = Apollo.BaseMutationOptions<PaymentUpdateMutation, PaymentUpdateMutationVariables>;
export const AdvancePaymentUpdateDocument = gql`
    mutation AdvancePaymentUpdate($CheckOutID: String, $OrderID: String, $Status: String, $HotelID: String, $ReceiptNo: String, $RegistrationID: String, $AppName: String) {
  AdvancePaymentUpdate(CheckOutID: $CheckOutID, OrderID: $OrderID, Status: $Status, HotelID: $HotelID, ReceiptNo: $ReceiptNo, RegistrationID: $RegistrationID, AppName: $AppName)
}
    `;
export type AdvancePaymentUpdateMutationFn = Apollo.MutationFunction<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>;

/**
 * __useAdvancePaymentUpdateMutation__
 *
 * To run a mutation, you first call `useAdvancePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advancePaymentUpdateMutation, { data, loading, error }] = useAdvancePaymentUpdateMutation({
 *   variables: {
 *      CheckOutID: // value for 'CheckOutID'
 *      OrderID: // value for 'OrderID'
 *      Status: // value for 'Status'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      RegistrationID: // value for 'RegistrationID'
 *      AppName: // value for 'AppName'
 *   },
 * });
 */
export function useAdvancePaymentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>(AdvancePaymentUpdateDocument, options);
      }
export type AdvancePaymentUpdateMutationHookResult = ReturnType<typeof useAdvancePaymentUpdateMutation>;
export type AdvancePaymentUpdateMutationResult = Apollo.MutationResult<AdvancePaymentUpdateMutation>;
export type AdvancePaymentUpdateMutationOptions = Apollo.BaseMutationOptions<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>;
export const PaymentDetailDocument = gql`
    query PaymentDetail($OrderID: String, $RegistrationID: [String!]) {
  PaymentDetail(OrderID: $OrderID, RegistrationID: $RegistrationID) {
    ID
    FolioID
    RegistrationID
    HotelID
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    CardNo
    Amount
    ReferenceNo
    PaymentStatus
  }
}
    `;

/**
 * __usePaymentDetailQuery__
 *
 * To run a query within a React component, call `usePaymentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailQuery({
 *   variables: {
 *      OrderID: // value for 'OrderID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePaymentDetailQuery(baseOptions?: Apollo.QueryHookOptions<PaymentDetailQuery, PaymentDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentDetailQuery, PaymentDetailQueryVariables>(PaymentDetailDocument, options);
      }
export function usePaymentDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentDetailQuery, PaymentDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentDetailQuery, PaymentDetailQueryVariables>(PaymentDetailDocument, options);
        }
export type PaymentDetailQueryHookResult = ReturnType<typeof usePaymentDetailQuery>;
export type PaymentDetailLazyQueryHookResult = ReturnType<typeof usePaymentDetailLazyQuery>;
export type PaymentDetailQueryResult = Apollo.QueryResult<PaymentDetailQuery, PaymentDetailQueryVariables>;
export const ReceiptListingDocument = gql`
    query ReceiptListing($PaymentClass: PaymentClass!, $ReceiptNo: String!) {
  ReceiptListing(PaymentClass: $PaymentClass, ReceiptNo: $ReceiptNo) {
    RegistrationID
    TrxDate
    ChargeType
    Description
    ReferenceNo
    FullName
    RoomNo
    BaseAmount
    TrxAmount
    ID
  }
}
    `;

/**
 * __useReceiptListingQuery__
 *
 * To run a query within a React component, call `useReceiptListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceiptListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceiptListingQuery({
 *   variables: {
 *      PaymentClass: // value for 'PaymentClass'
 *      ReceiptNo: // value for 'ReceiptNo'
 *   },
 * });
 */
export function useReceiptListingQuery(baseOptions: Apollo.QueryHookOptions<ReceiptListingQuery, ReceiptListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceiptListingQuery, ReceiptListingQueryVariables>(ReceiptListingDocument, options);
      }
export function useReceiptListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceiptListingQuery, ReceiptListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceiptListingQuery, ReceiptListingQueryVariables>(ReceiptListingDocument, options);
        }
export type ReceiptListingQueryHookResult = ReturnType<typeof useReceiptListingQuery>;
export type ReceiptListingLazyQueryHookResult = ReturnType<typeof useReceiptListingLazyQuery>;
export type ReceiptListingQueryResult = Apollo.QueryResult<ReceiptListingQuery, ReceiptListingQueryVariables>;
export const DisCountByPromoCodeDocument = gql`
    query DisCountByPromoCode($PromoCodeInput: [PromoCodeInput!]!, $PromoCode: String!, $HotelID: String!) {
  DisCountByPromoCode(PromoCodeInput: $PromoCodeInput, PromoCode: $PromoCode, HotelID: $HotelID) {
    RoomTypeID
    RatePolicyID
    DiscountAmt
  }
}
    `;

/**
 * __useDisCountByPromoCodeQuery__
 *
 * To run a query within a React component, call `useDisCountByPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisCountByPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisCountByPromoCodeQuery({
 *   variables: {
 *      PromoCodeInput: // value for 'PromoCodeInput'
 *      PromoCode: // value for 'PromoCode'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDisCountByPromoCodeQuery(baseOptions: Apollo.QueryHookOptions<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>(DisCountByPromoCodeDocument, options);
      }
export function useDisCountByPromoCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>(DisCountByPromoCodeDocument, options);
        }
export type DisCountByPromoCodeQueryHookResult = ReturnType<typeof useDisCountByPromoCodeQuery>;
export type DisCountByPromoCodeLazyQueryHookResult = ReturnType<typeof useDisCountByPromoCodeLazyQuery>;
export type DisCountByPromoCodeQueryResult = Apollo.QueryResult<DisCountByPromoCodeQuery, DisCountByPromoCodeQueryVariables>;
export const ComputeTaxArrByHotelDocument = gql`
    query ComputeTaxArrByHotel($TaxInput: [TaxInput!]!) {
  ComputeTaxArrByHotel(TaxInput: $TaxInput) {
    DiscountAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    TotalAmount
  }
}
    `;

/**
 * __useComputeTaxArrByHotelQuery__
 *
 * To run a query within a React component, call `useComputeTaxArrByHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeTaxArrByHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeTaxArrByHotelQuery({
 *   variables: {
 *      TaxInput: // value for 'TaxInput'
 *   },
 * });
 */
export function useComputeTaxArrByHotelQuery(baseOptions: Apollo.QueryHookOptions<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>(ComputeTaxArrByHotelDocument, options);
      }
export function useComputeTaxArrByHotelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>(ComputeTaxArrByHotelDocument, options);
        }
export type ComputeTaxArrByHotelQueryHookResult = ReturnType<typeof useComputeTaxArrByHotelQuery>;
export type ComputeTaxArrByHotelLazyQueryHookResult = ReturnType<typeof useComputeTaxArrByHotelLazyQuery>;
export type ComputeTaxArrByHotelQueryResult = Apollo.QueryResult<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>;
export const FrontDeskPaymentInsertDocument = gql`
    mutation FrontDeskPaymentInsert($input: [FrontDeskPaymentInput!]!, $TotalPaymentAmount: Float!, $DebtorID: String, $IsGroupFolio: Boolean, $AdjustedAmount: Float) {
  FrontDeskPaymentInsert(input: $input, TotalPaymentAmount: $TotalPaymentAmount, DebtorID: $DebtorID, IsGroupFolio: $IsGroupFolio, AdjustedAmount: $AdjustedAmount) {
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    CardNo
    Amount
    ReferenceNo
    PaymentStatus
  }
}
    `;
export type FrontDeskPaymentInsertMutationFn = Apollo.MutationFunction<FrontDeskPaymentInsertMutation, FrontDeskPaymentInsertMutationVariables>;

/**
 * __useFrontDeskPaymentInsertMutation__
 *
 * To run a mutation, you first call `useFrontDeskPaymentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskPaymentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [frontDeskPaymentInsertMutation, { data, loading, error }] = useFrontDeskPaymentInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TotalPaymentAmount: // value for 'TotalPaymentAmount'
 *      DebtorID: // value for 'DebtorID'
 *      IsGroupFolio: // value for 'IsGroupFolio'
 *      AdjustedAmount: // value for 'AdjustedAmount'
 *   },
 * });
 */
export function useFrontDeskPaymentInsertMutation(baseOptions?: Apollo.MutationHookOptions<FrontDeskPaymentInsertMutation, FrontDeskPaymentInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FrontDeskPaymentInsertMutation, FrontDeskPaymentInsertMutationVariables>(FrontDeskPaymentInsertDocument, options);
      }
export type FrontDeskPaymentInsertMutationHookResult = ReturnType<typeof useFrontDeskPaymentInsertMutation>;
export type FrontDeskPaymentInsertMutationResult = Apollo.MutationResult<FrontDeskPaymentInsertMutation>;
export type FrontDeskPaymentInsertMutationOptions = Apollo.BaseMutationOptions<FrontDeskPaymentInsertMutation, FrontDeskPaymentInsertMutationVariables>;
export const IsPaymentGateWayHotelDocument = gql`
    query IsPaymentGateWayHotel($HotelID: String!) {
  IsPaymentGateWayHotel(HotelID: $HotelID)
}
    `;

/**
 * __useIsPaymentGateWayHotelQuery__
 *
 * To run a query within a React component, call `useIsPaymentGateWayHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPaymentGateWayHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPaymentGateWayHotelQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIsPaymentGateWayHotelQuery(baseOptions: Apollo.QueryHookOptions<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>(IsPaymentGateWayHotelDocument, options);
      }
export function useIsPaymentGateWayHotelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>(IsPaymentGateWayHotelDocument, options);
        }
export type IsPaymentGateWayHotelQueryHookResult = ReturnType<typeof useIsPaymentGateWayHotelQuery>;
export type IsPaymentGateWayHotelLazyQueryHookResult = ReturnType<typeof useIsPaymentGateWayHotelLazyQuery>;
export type IsPaymentGateWayHotelQueryResult = Apollo.QueryResult<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>;
export const ComputeTaxbyHotelDocument = gql`
    query ComputeTaxbyHotel($input: TaxInput!) {
  ComputeTaxbyHotel(input: $input) {
    DiscountAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    TotalAmount
  }
}
    `;

/**
 * __useComputeTaxbyHotelQuery__
 *
 * To run a query within a React component, call `useComputeTaxbyHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeTaxbyHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeTaxbyHotelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useComputeTaxbyHotelQuery(baseOptions: Apollo.QueryHookOptions<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>(ComputeTaxbyHotelDocument, options);
      }
export function useComputeTaxbyHotelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>(ComputeTaxbyHotelDocument, options);
        }
export type ComputeTaxbyHotelQueryHookResult = ReturnType<typeof useComputeTaxbyHotelQuery>;
export type ComputeTaxbyHotelLazyQueryHookResult = ReturnType<typeof useComputeTaxbyHotelLazyQuery>;
export type ComputeTaxbyHotelQueryResult = Apollo.QueryResult<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>;
export const ComputeTaxBookingDocument = gql`
    query ComputeTaxBooking($RoomTypeID: String!, $RateID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $HotelID: String!, $DiscountAmount: Float!) {
  ComputeTaxBooking(RoomTypeID: $RoomTypeID, RateID: $RateID, StartDate: $StartDate, EndDate: $EndDate, HotelID: $HotelID, DiscountAmount: $DiscountAmount)
}
    `;

/**
 * __useComputeTaxBookingQuery__
 *
 * To run a query within a React component, call `useComputeTaxBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeTaxBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeTaxBookingQuery({
 *   variables: {
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RateID: // value for 'RateID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      HotelID: // value for 'HotelID'
 *      DiscountAmount: // value for 'DiscountAmount'
 *   },
 * });
 */
export function useComputeTaxBookingQuery(baseOptions: Apollo.QueryHookOptions<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>(ComputeTaxBookingDocument, options);
      }
export function useComputeTaxBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>(ComputeTaxBookingDocument, options);
        }
export type ComputeTaxBookingQueryHookResult = ReturnType<typeof useComputeTaxBookingQuery>;
export type ComputeTaxBookingLazyQueryHookResult = ReturnType<typeof useComputeTaxBookingLazyQuery>;
export type ComputeTaxBookingQueryResult = Apollo.QueryResult<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>;
export const BillingByRegistrationDocument = gql`
    query BillingByRegistration($RegistrationID: [String!]!, $BookingID: String!) {
  BillingByRegistration(RegistrationID: $RegistrationID, BookingID: $BookingID) {
    RegistrationID
    TrxDate
    ChargeType
    Description
    ReferenceNo
    FullName
    RoomNo
    FolioID
    TrxAmount
    BaseAmount
    DiscountAmount
    UnitPrice
    ID
    LedgerType
    ServiceCharge
    TaxAmount
    Type
    DebtorName
    DebtorID
    CreatedName
    CreatedDT
    CreatedBy
  }
}
    `;

/**
 * __useBillingByRegistrationQuery__
 *
 * To run a query within a React component, call `useBillingByRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingByRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingByRegistrationQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useBillingByRegistrationQuery(baseOptions: Apollo.QueryHookOptions<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>(BillingByRegistrationDocument, options);
      }
export function useBillingByRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>(BillingByRegistrationDocument, options);
        }
export type BillingByRegistrationQueryHookResult = ReturnType<typeof useBillingByRegistrationQuery>;
export type BillingByRegistrationLazyQueryHookResult = ReturnType<typeof useBillingByRegistrationLazyQuery>;
export type BillingByRegistrationQueryResult = Apollo.QueryResult<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>;
export const FolioListingByRegistrationDocument = gql`
    query FolioListingByRegistration($HotelID: String!, $BookingID: String!, $RegistrationID: String!, $AccountID: String) {
  FolioListingByRegistration(HotelID: $HotelID, BookingID: $BookingID, RegistrationID: $RegistrationID, AccountID: $AccountID) {
    ID
    HotelID
    FolioType
    RefID
    FolioNo
    FolioDate
    FolioAmount
    RoomLedger {
      BaseAmount
      ReferenceNo
      ServiceCharge
      TaxAmount
      TrxAmount
      ID
      Description
      TrxDate
      ChargeType
      TransactionType
      Transaction {
        Description
        ID
      }
    }
    Payment {
      ID
      ModifiedBy
      FolioID
      RegistrationID
      HotelID
      ReceiptNo
      Amount
      PaymentDate
      PaymentClass
      PaymentType
      DebtorAccount
      PaySessionNo
      Folio {
        ID
        RoomLedger {
          ID
          Description
          TrxDate
          ChargeType
          TransactionType
          Transaction {
            Description
            ID
          }
        }
      }
    }
    TaxLedger {
      ID
      FolioID
      RegistrationID
      ReversedTaxLedgerID
      Reason
      TaxType
      TrxDate
      TaxAmount
    }
  }
}
    `;

/**
 * __useFolioListingByRegistrationQuery__
 *
 * To run a query within a React component, call `useFolioListingByRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioListingByRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioListingByRegistrationQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useFolioListingByRegistrationQuery(baseOptions: Apollo.QueryHookOptions<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>(FolioListingByRegistrationDocument, options);
      }
export function useFolioListingByRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>(FolioListingByRegistrationDocument, options);
        }
export type FolioListingByRegistrationQueryHookResult = ReturnType<typeof useFolioListingByRegistrationQuery>;
export type FolioListingByRegistrationLazyQueryHookResult = ReturnType<typeof useFolioListingByRegistrationLazyQuery>;
export type FolioListingByRegistrationQueryResult = Apollo.QueryResult<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>;
export const CheckOutDocument = gql`
    mutation CheckOut($BookingID: String!, $RegistrationIDs: [String!]!, $RoomID: String!, $HotelID: String!) {
  CheckOut(BookingID: $BookingID, RegistrationIDs: $RegistrationIDs, RoomID: $RoomID, HotelID: $HotelID)
}
    `;
export type CheckOutMutationFn = Apollo.MutationFunction<CheckOutMutation, CheckOutMutationVariables>;

/**
 * __useCheckOutMutation__
 *
 * To run a mutation, you first call `useCheckOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutMutation, { data, loading, error }] = useCheckOutMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      RoomID: // value for 'RoomID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCheckOutMutation(baseOptions?: Apollo.MutationHookOptions<CheckOutMutation, CheckOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOutMutation, CheckOutMutationVariables>(CheckOutDocument, options);
      }
export type CheckOutMutationHookResult = ReturnType<typeof useCheckOutMutation>;
export type CheckOutMutationResult = Apollo.MutationResult<CheckOutMutation>;
export type CheckOutMutationOptions = Apollo.BaseMutationOptions<CheckOutMutation, CheckOutMutationVariables>;
export const HotelRatingInsertDocument = gql`
    mutation HotelRatingInsert($input: HotelRatingInput!) {
  HotelRatingInsert(input: $input) {
    ID
  }
}
    `;
export type HotelRatingInsertMutationFn = Apollo.MutationFunction<HotelRatingInsertMutation, HotelRatingInsertMutationVariables>;

/**
 * __useHotelRatingInsertMutation__
 *
 * To run a mutation, you first call `useHotelRatingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelRatingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelRatingInsertMutation, { data, loading, error }] = useHotelRatingInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHotelRatingInsertMutation(baseOptions?: Apollo.MutationHookOptions<HotelRatingInsertMutation, HotelRatingInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HotelRatingInsertMutation, HotelRatingInsertMutationVariables>(HotelRatingInsertDocument, options);
      }
export type HotelRatingInsertMutationHookResult = ReturnType<typeof useHotelRatingInsertMutation>;
export type HotelRatingInsertMutationResult = Apollo.MutationResult<HotelRatingInsertMutation>;
export type HotelRatingInsertMutationOptions = Apollo.BaseMutationOptions<HotelRatingInsertMutation, HotelRatingInsertMutationVariables>;
export const AvailabilityListingDocument = gql`
    query AvailabilityListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime, $RoomTypeIDs: [String!], $TravelAgentID: String) {
  AvailabilityListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, RoomTypeIDs: $RoomTypeIDs, TravelAgentID: $TravelAgentID) {
    RoomTypesList {
      ID
      Code
      Description
      TotalRoom
      RoomTypeAvailability {
        ID
        EffectiveDate
        BookedRoom
        BlockedRoom
        AllottedRoom
        TotalOccupied
        TotalVacant
        SeasonType
      }
    }
    Occupancy {
      EffectiveDate
      Season
      Occupancy
      TotalOccupied
      TotalRoom
    }
  }
}
    `;

/**
 * __useAvailabilityListingQuery__
 *
 * To run a query within a React component, call `useAvailabilityListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      RoomTypeIDs: // value for 'RoomTypeIDs'
 *      TravelAgentID: // value for 'TravelAgentID'
 *   },
 * });
 */
export function useAvailabilityListingQuery(baseOptions: Apollo.QueryHookOptions<AvailabilityListingQuery, AvailabilityListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailabilityListingQuery, AvailabilityListingQueryVariables>(AvailabilityListingDocument, options);
      }
export function useAvailabilityListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailabilityListingQuery, AvailabilityListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailabilityListingQuery, AvailabilityListingQueryVariables>(AvailabilityListingDocument, options);
        }
export type AvailabilityListingQueryHookResult = ReturnType<typeof useAvailabilityListingQuery>;
export type AvailabilityListingLazyQueryHookResult = ReturnType<typeof useAvailabilityListingLazyQuery>;
export type AvailabilityListingQueryResult = Apollo.QueryResult<AvailabilityListingQuery, AvailabilityListingQueryVariables>;
export const PackagePriceListingDocument = gql`
    query PackagePriceListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String, $DebtorID: String, $RoomTypeID: String, $RatePolicyID: String) {
  PackagePriceListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType, DebtorID: $DebtorID, RoomTypeID: $RoomTypeID, RatePolicyID: $RatePolicyID) {
    RoomTypeID
    RoomTypeCode
    RoomTypeName
    RatePolicyID
    Code
    TotalVacant
    Pricing {
      Date
      Type
      Price
      BasePrice
      Packages {
        ID
        IncidentalChargeID
        Description
        IncludeRate
        ComputationRules
        ServiceCharge
        Tax
        Amount
      }
    }
  }
}
    `;

/**
 * __usePackagePriceListingQuery__
 *
 * To run a query within a React component, call `usePackagePriceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagePriceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagePriceListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *      DebtorID: // value for 'DebtorID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RatePolicyID: // value for 'RatePolicyID'
 *   },
 * });
 */
export function usePackagePriceListingQuery(baseOptions: Apollo.QueryHookOptions<PackagePriceListingQuery, PackagePriceListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagePriceListingQuery, PackagePriceListingQueryVariables>(PackagePriceListingDocument, options);
      }
export function usePackagePriceListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagePriceListingQuery, PackagePriceListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagePriceListingQuery, PackagePriceListingQueryVariables>(PackagePriceListingDocument, options);
        }
export type PackagePriceListingQueryHookResult = ReturnType<typeof usePackagePriceListingQuery>;
export type PackagePriceListingLazyQueryHookResult = ReturnType<typeof usePackagePriceListingLazyQuery>;
export type PackagePriceListingQueryResult = Apollo.QueryResult<PackagePriceListingQuery, PackagePriceListingQueryVariables>;
export const ExtendStayInsertDocument = gql`
    mutation ExtendStayInsert($RoomTypeID: String!, $ArrivalDate: DateTime!, $DepartureDate: DateTime!, $input: FrontDeskPaymentInput!) {
  ExtendStayInsert(RoomTypeID: $RoomTypeID, DepartureDate: $DepartureDate, ArrivalDate: $ArrivalDate, input: $input)
}
    `;
export type ExtendStayInsertMutationFn = Apollo.MutationFunction<ExtendStayInsertMutation, ExtendStayInsertMutationVariables>;

/**
 * __useExtendStayInsertMutation__
 *
 * To run a mutation, you first call `useExtendStayInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendStayInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendStayInsertMutation, { data, loading, error }] = useExtendStayInsertMutation({
 *   variables: {
 *      RoomTypeID: // value for 'RoomTypeID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtendStayInsertMutation(baseOptions?: Apollo.MutationHookOptions<ExtendStayInsertMutation, ExtendStayInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtendStayInsertMutation, ExtendStayInsertMutationVariables>(ExtendStayInsertDocument, options);
      }
export type ExtendStayInsertMutationHookResult = ReturnType<typeof useExtendStayInsertMutation>;
export type ExtendStayInsertMutationResult = Apollo.MutationResult<ExtendStayInsertMutation>;
export type ExtendStayInsertMutationOptions = Apollo.BaseMutationOptions<ExtendStayInsertMutation, ExtendStayInsertMutationVariables>;
export const RegistrationListingDocument = gql`
    query RegistrationListing($RoomID: String!) {
  RegistrationListing(RoomID: $RoomID) {
    ID
    CheckInDate
    CheckOutDate
    RegistrationStatus
    Booking {
      ArrivalDate
      DepartureDate
    }
  }
}
    `;

/**
 * __useRegistrationListingQuery__
 *
 * To run a query within a React component, call `useRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationListingQuery({
 *   variables: {
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useRegistrationListingQuery(baseOptions: Apollo.QueryHookOptions<RegistrationListingQuery, RegistrationListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegistrationListingQuery, RegistrationListingQueryVariables>(RegistrationListingDocument, options);
      }
export function useRegistrationListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegistrationListingQuery, RegistrationListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegistrationListingQuery, RegistrationListingQueryVariables>(RegistrationListingDocument, options);
        }
export type RegistrationListingQueryHookResult = ReturnType<typeof useRegistrationListingQuery>;
export type RegistrationListingLazyQueryHookResult = ReturnType<typeof useRegistrationListingLazyQuery>;
export type RegistrationListingQueryResult = Apollo.QueryResult<RegistrationListingQuery, RegistrationListingQueryVariables>;
export const OnlineGaInHouseGuestListingDocument = gql`
    query OnlineGAInHouseGuestListing($GuestID: String) {
  OnlineGAInHouseGuestListing(GuestID: $GuestID) {
    ID
    RoomID
    CheckInDate
    SharerNo
    CheckOutDate
    PromoCode
    NoOfAdult
    DiscountAmount
    NoOfChild
    NoOfInfant
    IsPrincipal
    Signature
    SpecialRequest
    RegistrationStatus
    ArrivalDate
    DepartureDate
    RoomTypeID
    ServiceRequestCount
    IncidentalChargesCount
    TotalOutStandingAmount
    Payment {
      ID
      PaymentClass
      PaymentType
    }
    AdvancePayment {
      ID
      PaymentType
      PaymentClass
      ReceiptNo
    }
    RatePolicyID
    RoomType {
      Code
      Description
      ID
    }
    RatePolicy {
      RateCode
      ID
      Description
    }
    Room {
      ID
      RoomNo
      RoomStatus {
        ID
        Code
        CleanOrDirty
      }
    }
    Guest {
      ID
      VehicleNo
      Contact {
        Nationality
        ID
        FullName
        Email
        NRIC
        PhoneNo
        MobileNo
        Address {
          address
          country
          city
          state
          postCode
        }
      }
    }
    RoomRate
    TotalRoomPrice
    TotalPackagePrice
    TotalPrice
    Booking {
      ID
      AccountID
      HotelID
      TotalAdvPayment
      Hotel {
        ID
        HotelName
        AccountID
        CheckOutTime
        RegCardTnC
        Contact {
          BusinessEmail
          PhoneNo
          ReservationEmail
        }
        LogoImage {
          ID
          ImageURL
        }
        HotelAddress {
          address
          address2
          siteName
          country
          city
          state
          postCode
        }
        Facility {
          ID
          Title
          Level
          Description
          FacilityGallery {
            ID
            Gallery {
              ID
              ImageURL
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOnlineGaInHouseGuestListingQuery__
 *
 * To run a query within a React component, call `useOnlineGaInHouseGuestListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineGaInHouseGuestListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineGaInHouseGuestListingQuery({
 *   variables: {
 *      GuestID: // value for 'GuestID'
 *   },
 * });
 */
export function useOnlineGaInHouseGuestListingQuery(baseOptions?: Apollo.QueryHookOptions<OnlineGaInHouseGuestListingQuery, OnlineGaInHouseGuestListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnlineGaInHouseGuestListingQuery, OnlineGaInHouseGuestListingQueryVariables>(OnlineGaInHouseGuestListingDocument, options);
      }
export function useOnlineGaInHouseGuestListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnlineGaInHouseGuestListingQuery, OnlineGaInHouseGuestListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnlineGaInHouseGuestListingQuery, OnlineGaInHouseGuestListingQueryVariables>(OnlineGaInHouseGuestListingDocument, options);
        }
export type OnlineGaInHouseGuestListingQueryHookResult = ReturnType<typeof useOnlineGaInHouseGuestListingQuery>;
export type OnlineGaInHouseGuestListingLazyQueryHookResult = ReturnType<typeof useOnlineGaInHouseGuestListingLazyQuery>;
export type OnlineGaInHouseGuestListingQueryResult = Apollo.QueryResult<OnlineGaInHouseGuestListingQuery, OnlineGaInHouseGuestListingQueryVariables>;
export const FolioDetailsDocument = gql`
    query FolioDetails($ID: String!, $HotelID: String!) {
  FolioDetails(ID: $ID, HotelID: $HotelID) {
    ID
    FolioNo
    FolioType
    FolioDate
    FolioAmount
    CreatedBy
    Booking {
      ID
      BookingNo
      Registration {
        ID
        ArrivalDate
        DepartureDate
        CheckInDate
        CheckOutDate
        IsPrincipal
        Room {
          RoomNo
        }
        Guest {
          Contact {
            ID
            FullName
            PhoneNo
            MobileNo
            Email
            Address {
              address
              address2
              city
              country
              postCode
              state
            }
          }
        }
      }
    }
    Registration {
      ID
      ArrivalDate
      DepartureDate
      CheckInDate
      CheckOutDate
      Guest {
        Contact {
          ID
          FullName
          PhoneNo
          MobileNo
          Email
          Address {
            address
            address2
            city
            country
            postCode
            state
          }
        }
      }
      Booking {
        BookingNo
        Debtor {
          DebtorAccount
        }
      }
      Room {
        RoomNo
      }
    }
    AdvancePayment {
      Amount
      PaymentType
      PaymentClass
      PaySessionNo
      ReferenceNo
      ID
    }
    Payment {
      Amount
      PaymentType
      PaymentClass
      PaySessionNo
      ReferenceNo
      ID
    }
    TaxLedger {
      ID
      TrxDate
      TaxType
      TaxAmount
      Reason
      Description
    }
    BillLedger {
      ID
      TrxDate
      BaseAmount
      ServiceCharge
      TaxAmount
      TrxAmount
      ReferenceNo
      IncidentalCharge {
        Description
      }
    }
    RoomCharges {
      TrxDate
      BaseAmount
      TrxAmount
      Description
      TaxAmount
      ServiceCharge
      RegistrationID
    }
    RoomLedger {
      ID
      TrxAmount
      TrxDate
      TaxAmount
      BaseAmount
      TransactionType
      TransactionID
      Transaction {
        Description
      }
      ServiceCharge
      ReferenceNo
      Description
    }
    DepositLedger {
      DepositAmount
      DepositClass
      ReferenceNo
      TrxDate
    }
  }
}
    `;

/**
 * __useFolioDetailsQuery__
 *
 * To run a query within a React component, call `useFolioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useFolioDetailsQuery(baseOptions: Apollo.QueryHookOptions<FolioDetailsQuery, FolioDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolioDetailsQuery, FolioDetailsQueryVariables>(FolioDetailsDocument, options);
      }
export function useFolioDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolioDetailsQuery, FolioDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolioDetailsQuery, FolioDetailsQueryVariables>(FolioDetailsDocument, options);
        }
export type FolioDetailsQueryHookResult = ReturnType<typeof useFolioDetailsQuery>;
export type FolioDetailsLazyQueryHookResult = ReturnType<typeof useFolioDetailsLazyQuery>;
export type FolioDetailsQueryResult = Apollo.QueryResult<FolioDetailsQuery, FolioDetailsQueryVariables>;
export const PaymentDetailsDocument = gql`
    query PaymentDetails($ID: String!, $HotelID: String!) {
  PaymentDetails(ID: $ID, HotelID: $HotelID) {
    ID
    ReceiptNo
    ReferenceNo
    PaymentDate
    PaymentType
    Amount
    CreatedBy
    PaymentStatus
    PaymentClass
    DebtorAccount
    Folio {
      FolioNo
    }
    Registration {
      ID
      ArrivalDate
      DepartureDate
      CheckInDate
      CheckOutDate
      Booking {
        BookingNo
        DebtorAccount
      }
      Room {
        RoomNo
      }
      Guest {
        Contact {
          ID
          FullName
          PhoneNo
          MobileNo
          Email
          Address {
            address
            address2
            city
            country
            postCode
            state
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePaymentDetailsQuery__
 *
 * To run a query within a React component, call `usePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function usePaymentDetailsQuery(baseOptions: Apollo.QueryHookOptions<PaymentDetailsQuery, PaymentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentDetailsQuery, PaymentDetailsQueryVariables>(PaymentDetailsDocument, options);
      }
export function usePaymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentDetailsQuery, PaymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentDetailsQuery, PaymentDetailsQueryVariables>(PaymentDetailsDocument, options);
        }
export type PaymentDetailsQueryHookResult = ReturnType<typeof usePaymentDetailsQuery>;
export type PaymentDetailsLazyQueryHookResult = ReturnType<typeof usePaymentDetailsLazyQuery>;
export type PaymentDetailsQueryResult = Apollo.QueryResult<PaymentDetailsQuery, PaymentDetailsQueryVariables>;
export const LateCheckOutChargeListingDocument = gql`
    query LateCheckOutChargeListing($HotelID: String!) {
  LateCheckOutChargeListing(HotelID: $HotelID) {
    ID
    HotelID
    Name
    Amount
    IsActive
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useLateCheckOutChargeListingQuery__
 *
 * To run a query within a React component, call `useLateCheckOutChargeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLateCheckOutChargeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLateCheckOutChargeListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useLateCheckOutChargeListingQuery(baseOptions: Apollo.QueryHookOptions<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>(LateCheckOutChargeListingDocument, options);
      }
export function useLateCheckOutChargeListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>(LateCheckOutChargeListingDocument, options);
        }
export type LateCheckOutChargeListingQueryHookResult = ReturnType<typeof useLateCheckOutChargeListingQuery>;
export type LateCheckOutChargeListingLazyQueryHookResult = ReturnType<typeof useLateCheckOutChargeListingLazyQuery>;
export type LateCheckOutChargeListingQueryResult = Apollo.QueryResult<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>;
export const LateCheckOutInsertDocument = gql`
    mutation LateCheckOutInsert($hour: Float!, $input: FrontDeskPaymentInput!) {
  LateCheckOutInsert(hour: $hour, input: $input)
}
    `;
export type LateCheckOutInsertMutationFn = Apollo.MutationFunction<LateCheckOutInsertMutation, LateCheckOutInsertMutationVariables>;

/**
 * __useLateCheckOutInsertMutation__
 *
 * To run a mutation, you first call `useLateCheckOutInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLateCheckOutInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lateCheckOutInsertMutation, { data, loading, error }] = useLateCheckOutInsertMutation({
 *   variables: {
 *      hour: // value for 'hour'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLateCheckOutInsertMutation(baseOptions?: Apollo.MutationHookOptions<LateCheckOutInsertMutation, LateCheckOutInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LateCheckOutInsertMutation, LateCheckOutInsertMutationVariables>(LateCheckOutInsertDocument, options);
      }
export type LateCheckOutInsertMutationHookResult = ReturnType<typeof useLateCheckOutInsertMutation>;
export type LateCheckOutInsertMutationResult = Apollo.MutationResult<LateCheckOutInsertMutation>;
export type LateCheckOutInsertMutationOptions = Apollo.BaseMutationOptions<LateCheckOutInsertMutation, LateCheckOutInsertMutationVariables>;
export const IsRoomAvailableDocument = gql`
    query IsRoomAvailable($HotelID: String!, $RoomID: String!, $RegistrationID: String!) {
  IsRoomAvailable(HotelID: $HotelID, RoomID: $RoomID, RegistrationID: $RegistrationID)
}
    `;

/**
 * __useIsRoomAvailableQuery__
 *
 * To run a query within a React component, call `useIsRoomAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRoomAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRoomAvailableQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useIsRoomAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>(IsRoomAvailableDocument, options);
      }
export function useIsRoomAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>(IsRoomAvailableDocument, options);
        }
export type IsRoomAvailableQueryHookResult = ReturnType<typeof useIsRoomAvailableQuery>;
export type IsRoomAvailableLazyQueryHookResult = ReturnType<typeof useIsRoomAvailableLazyQuery>;
export type IsRoomAvailableQueryResult = Apollo.QueryResult<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>;
export const FrontDeskServiceRequestListingDocument = gql`
    query FrontDeskServiceRequestListing($HotelID: String!, $RoomID: String!, $RegistrationID: String!) {
  FrontDeskServiceRequestListing(HotelID: $HotelID, RoomID: $RoomID, RegistrationID: $RegistrationID) {
    ID
    Title
    Description
    EstimatedCompletion
    CreatedDT
    RequestBy
    Status
    Remarks
    DepartmentID
    Room {
      ID
      RoomNo
      Location {
        ID
        Description
      }
    }
    Gallery {
      ID
      ImageURL
    }
    Department {
      Name
    }
    ServiceRequestGallery {
      ID
      Gallery {
        BucketFileName
        ID
        ImageURL
      }
    }
  }
}
    `;

/**
 * __useFrontDeskServiceRequestListingQuery__
 *
 * To run a query within a React component, call `useFrontDeskServiceRequestListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskServiceRequestListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskServiceRequestListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useFrontDeskServiceRequestListingQuery(baseOptions: Apollo.QueryHookOptions<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>(FrontDeskServiceRequestListingDocument, options);
      }
export function useFrontDeskServiceRequestListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>(FrontDeskServiceRequestListingDocument, options);
        }
export type FrontDeskServiceRequestListingQueryHookResult = ReturnType<typeof useFrontDeskServiceRequestListingQuery>;
export type FrontDeskServiceRequestListingLazyQueryHookResult = ReturnType<typeof useFrontDeskServiceRequestListingLazyQuery>;
export type FrontDeskServiceRequestListingQueryResult = Apollo.QueryResult<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>;
export const HousekeepingServiceRequestInsertDocument = gql`
    mutation HousekeepingServiceRequestInsert($ServiceRequestInput: ServiceRequestInput!, $AttachmentInput: [Upload!]) {
  HousekeepingServiceRequestInsert(ServiceRequestInput: $ServiceRequestInput, AttachmentInput: $AttachmentInput) {
    ID
  }
}
    `;
export type HousekeepingServiceRequestInsertMutationFn = Apollo.MutationFunction<HousekeepingServiceRequestInsertMutation, HousekeepingServiceRequestInsertMutationVariables>;

/**
 * __useHousekeepingServiceRequestInsertMutation__
 *
 * To run a mutation, you first call `useHousekeepingServiceRequestInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingServiceRequestInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [housekeepingServiceRequestInsertMutation, { data, loading, error }] = useHousekeepingServiceRequestInsertMutation({
 *   variables: {
 *      ServiceRequestInput: // value for 'ServiceRequestInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useHousekeepingServiceRequestInsertMutation(baseOptions?: Apollo.MutationHookOptions<HousekeepingServiceRequestInsertMutation, HousekeepingServiceRequestInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HousekeepingServiceRequestInsertMutation, HousekeepingServiceRequestInsertMutationVariables>(HousekeepingServiceRequestInsertDocument, options);
      }
export type HousekeepingServiceRequestInsertMutationHookResult = ReturnType<typeof useHousekeepingServiceRequestInsertMutation>;
export type HousekeepingServiceRequestInsertMutationResult = Apollo.MutationResult<HousekeepingServiceRequestInsertMutation>;
export type HousekeepingServiceRequestInsertMutationOptions = Apollo.BaseMutationOptions<HousekeepingServiceRequestInsertMutation, HousekeepingServiceRequestInsertMutationVariables>;
export const HousekeepingServiceRequestUpdateDocument = gql`
    mutation HousekeepingServiceRequestUpdate($ServiceRequestInput: ServiceRequestInput!, $AttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  HousekeepingServiceRequestUpdate(ServiceRequestInput: $ServiceRequestInput, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type HousekeepingServiceRequestUpdateMutationFn = Apollo.MutationFunction<HousekeepingServiceRequestUpdateMutation, HousekeepingServiceRequestUpdateMutationVariables>;

/**
 * __useHousekeepingServiceRequestUpdateMutation__
 *
 * To run a mutation, you first call `useHousekeepingServiceRequestUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingServiceRequestUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [housekeepingServiceRequestUpdateMutation, { data, loading, error }] = useHousekeepingServiceRequestUpdateMutation({
 *   variables: {
 *      ServiceRequestInput: // value for 'ServiceRequestInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useHousekeepingServiceRequestUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HousekeepingServiceRequestUpdateMutation, HousekeepingServiceRequestUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HousekeepingServiceRequestUpdateMutation, HousekeepingServiceRequestUpdateMutationVariables>(HousekeepingServiceRequestUpdateDocument, options);
      }
export type HousekeepingServiceRequestUpdateMutationHookResult = ReturnType<typeof useHousekeepingServiceRequestUpdateMutation>;
export type HousekeepingServiceRequestUpdateMutationResult = Apollo.MutationResult<HousekeepingServiceRequestUpdateMutation>;
export type HousekeepingServiceRequestUpdateMutationOptions = Apollo.BaseMutationOptions<HousekeepingServiceRequestUpdateMutation, HousekeepingServiceRequestUpdateMutationVariables>;
export const DepartmentListingDocument = gql`
    query DepartmentListing($HotelID: String!) {
  DepartmentListing(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    Code
    Name
    AllowReason
    AllowSalesChannel
    AllowIncidental
    AllowGuestApp
    ServiceRequest {
      ID
    }
    IncidentalCharge {
      ID
      IsRoomRevenue
      Description
    }
    Reason {
      ID
    }
    SalesChannel {
      ID
    }
  }
}
    `;

/**
 * __useDepartmentListingQuery__
 *
 * To run a query within a React component, call `useDepartmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDepartmentListingQuery(baseOptions: Apollo.QueryHookOptions<DepartmentListingQuery, DepartmentListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentListingQuery, DepartmentListingQueryVariables>(DepartmentListingDocument, options);
      }
export function useDepartmentListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentListingQuery, DepartmentListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentListingQuery, DepartmentListingQueryVariables>(DepartmentListingDocument, options);
        }
export type DepartmentListingQueryHookResult = ReturnType<typeof useDepartmentListingQuery>;
export type DepartmentListingLazyQueryHookResult = ReturnType<typeof useDepartmentListingLazyQuery>;
export type DepartmentListingQueryResult = Apollo.QueryResult<DepartmentListingQuery, DepartmentListingQueryVariables>;
export const IncidentalChargeListingByHotelDocument = gql`
    query IncidentalChargeListingByHotel($HotelID: String) {
  IncidentalChargeListingByHotel(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DepartmentID
    Description
    Amount
    Tax
    ServiceCharge
    IsRoomRevenue
    GalleryID
    IsFnB
    IsEShop
    Gallery {
      ID
      BucketFileName
      ImageURL
    }
  }
}
    `;

/**
 * __useIncidentalChargeListingByHotelQuery__
 *
 * To run a query within a React component, call `useIncidentalChargeListingByHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeListingByHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentalChargeListingByHotelQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIncidentalChargeListingByHotelQuery(baseOptions?: Apollo.QueryHookOptions<IncidentalChargeListingByHotelQuery, IncidentalChargeListingByHotelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncidentalChargeListingByHotelQuery, IncidentalChargeListingByHotelQueryVariables>(IncidentalChargeListingByHotelDocument, options);
      }
export function useIncidentalChargeListingByHotelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncidentalChargeListingByHotelQuery, IncidentalChargeListingByHotelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncidentalChargeListingByHotelQuery, IncidentalChargeListingByHotelQueryVariables>(IncidentalChargeListingByHotelDocument, options);
        }
export type IncidentalChargeListingByHotelQueryHookResult = ReturnType<typeof useIncidentalChargeListingByHotelQuery>;
export type IncidentalChargeListingByHotelLazyQueryHookResult = ReturnType<typeof useIncidentalChargeListingByHotelLazyQuery>;
export type IncidentalChargeListingByHotelQueryResult = Apollo.QueryResult<IncidentalChargeListingByHotelQuery, IncidentalChargeListingByHotelQueryVariables>;
export const GuestRoomServiceInsertDocument = gql`
    mutation GuestRoomServiceInsert($BillLedgerInput: [BillLedgerInput!]!, $BookingID: String!, $AccountID: String, $PaymentMode: String!) {
  GuestRoomServiceInsert(BillLedgerInput: $BillLedgerInput, BookingID: $BookingID, AccountID: $AccountID, PaymentMode: $PaymentMode)
}
    `;
export type GuestRoomServiceInsertMutationFn = Apollo.MutationFunction<GuestRoomServiceInsertMutation, GuestRoomServiceInsertMutationVariables>;

/**
 * __useGuestRoomServiceInsertMutation__
 *
 * To run a mutation, you first call `useGuestRoomServiceInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestRoomServiceInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestRoomServiceInsertMutation, { data, loading, error }] = useGuestRoomServiceInsertMutation({
 *   variables: {
 *      BillLedgerInput: // value for 'BillLedgerInput'
 *      BookingID: // value for 'BookingID'
 *      AccountID: // value for 'AccountID'
 *      PaymentMode: // value for 'PaymentMode'
 *   },
 * });
 */
export function useGuestRoomServiceInsertMutation(baseOptions?: Apollo.MutationHookOptions<GuestRoomServiceInsertMutation, GuestRoomServiceInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestRoomServiceInsertMutation, GuestRoomServiceInsertMutationVariables>(GuestRoomServiceInsertDocument, options);
      }
export type GuestRoomServiceInsertMutationHookResult = ReturnType<typeof useGuestRoomServiceInsertMutation>;
export type GuestRoomServiceInsertMutationResult = Apollo.MutationResult<GuestRoomServiceInsertMutation>;
export type GuestRoomServiceInsertMutationOptions = Apollo.BaseMutationOptions<GuestRoomServiceInsertMutation, GuestRoomServiceInsertMutationVariables>;
export const GuestRoomServiceListingDocument = gql`
    query GuestRoomServiceListing($ServiceType: String, $HotelID: String!, $RegistrationID: String!) {
  GuestRoomServiceListing(HotelID: $HotelID, ServiceType: $ServiceType, RegistrationID: $RegistrationID) {
    ID
    HotelID
    RegistrationID
    ReversedBillLedgerID
    IncidentalChargeID
    Reason
    Description
    TrxDate
    Qty
    UnitPrice
    TrxAmount
    DiscountAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    IsDelivered
    Signature
    ReferenceNo
    PaymentID
    IncidentalCharge {
      ID
      Description
    }
  }
}
    `;

/**
 * __useGuestRoomServiceListingQuery__
 *
 * To run a query within a React component, call `useGuestRoomServiceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestRoomServiceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestRoomServiceListingQuery({
 *   variables: {
 *      ServiceType: // value for 'ServiceType'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useGuestRoomServiceListingQuery(baseOptions: Apollo.QueryHookOptions<GuestRoomServiceListingQuery, GuestRoomServiceListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuestRoomServiceListingQuery, GuestRoomServiceListingQueryVariables>(GuestRoomServiceListingDocument, options);
      }
export function useGuestRoomServiceListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestRoomServiceListingQuery, GuestRoomServiceListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuestRoomServiceListingQuery, GuestRoomServiceListingQueryVariables>(GuestRoomServiceListingDocument, options);
        }
export type GuestRoomServiceListingQueryHookResult = ReturnType<typeof useGuestRoomServiceListingQuery>;
export type GuestRoomServiceListingLazyQueryHookResult = ReturnType<typeof useGuestRoomServiceListingLazyQuery>;
export type GuestRoomServiceListingQueryResult = Apollo.QueryResult<GuestRoomServiceListingQuery, GuestRoomServiceListingQueryVariables>;
export const PackageRedemptionListingDocument = gql`
    query PackageRedemptionListing($RegistrationID: String, $HotelID: String!) {
  PackageRedemptionListing(RegistrationID: $RegistrationID, HotelID: $HotelID) {
    ID
    IncidentalChargeID
    Description
    ComputationRule
    RegistrationID
    BookingID
    BillScheduleID
    DepartmentID
    RoomNo
    GuestNames
    Amount
    Total
    Redeemed
    Balance
  }
}
    `;

/**
 * __usePackageRedemptionListingQuery__
 *
 * To run a query within a React component, call `usePackageRedemptionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageRedemptionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageRedemptionListingQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function usePackageRedemptionListingQuery(baseOptions: Apollo.QueryHookOptions<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>(PackageRedemptionListingDocument, options);
      }
export function usePackageRedemptionListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>(PackageRedemptionListingDocument, options);
        }
export type PackageRedemptionListingQueryHookResult = ReturnType<typeof usePackageRedemptionListingQuery>;
export type PackageRedemptionListingLazyQueryHookResult = ReturnType<typeof usePackageRedemptionListingLazyQuery>;
export type PackageRedemptionListingQueryResult = Apollo.QueryResult<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>;
export const PackageRedemptionInsertDocument = gql`
    mutation PackageRedemptionInsert($PackageRedemptionInput: PackageRedemptionInput!) {
  PackageRedemptionInsert(PackageRedemptionInput: $PackageRedemptionInput)
}
    `;
export type PackageRedemptionInsertMutationFn = Apollo.MutationFunction<PackageRedemptionInsertMutation, PackageRedemptionInsertMutationVariables>;

/**
 * __usePackageRedemptionInsertMutation__
 *
 * To run a mutation, you first call `usePackageRedemptionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackageRedemptionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packageRedemptionInsertMutation, { data, loading, error }] = usePackageRedemptionInsertMutation({
 *   variables: {
 *      PackageRedemptionInput: // value for 'PackageRedemptionInput'
 *   },
 * });
 */
export function usePackageRedemptionInsertMutation(baseOptions?: Apollo.MutationHookOptions<PackageRedemptionInsertMutation, PackageRedemptionInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PackageRedemptionInsertMutation, PackageRedemptionInsertMutationVariables>(PackageRedemptionInsertDocument, options);
      }
export type PackageRedemptionInsertMutationHookResult = ReturnType<typeof usePackageRedemptionInsertMutation>;
export type PackageRedemptionInsertMutationResult = Apollo.MutationResult<PackageRedemptionInsertMutation>;
export type PackageRedemptionInsertMutationOptions = Apollo.BaseMutationOptions<PackageRedemptionInsertMutation, PackageRedemptionInsertMutationVariables>;
export const GuestMovementInsertDocument = gql`
    mutation GuestMovementInsert($GuestMovementInput: GuestMovementInput!) {
  GuestMovementInsert(GuestMovementInput: $GuestMovementInput)
}
    `;
export type GuestMovementInsertMutationFn = Apollo.MutationFunction<GuestMovementInsertMutation, GuestMovementInsertMutationVariables>;

/**
 * __useGuestMovementInsertMutation__
 *
 * To run a mutation, you first call `useGuestMovementInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestMovementInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestMovementInsertMutation, { data, loading, error }] = useGuestMovementInsertMutation({
 *   variables: {
 *      GuestMovementInput: // value for 'GuestMovementInput'
 *   },
 * });
 */
export function useGuestMovementInsertMutation(baseOptions?: Apollo.MutationHookOptions<GuestMovementInsertMutation, GuestMovementInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestMovementInsertMutation, GuestMovementInsertMutationVariables>(GuestMovementInsertDocument, options);
      }
export type GuestMovementInsertMutationHookResult = ReturnType<typeof useGuestMovementInsertMutation>;
export type GuestMovementInsertMutationResult = Apollo.MutationResult<GuestMovementInsertMutation>;
export type GuestMovementInsertMutationOptions = Apollo.BaseMutationOptions<GuestMovementInsertMutation, GuestMovementInsertMutationVariables>;
export const GaMarketingAdsListingDocument = gql`
    query GAMarketingAdsListing($HotelID: String!) {
  GAMarketingAdsListing(HotelID: $HotelID) {
    ID
    Title
    Description
    html
    Gallery {
      ID
      ImageURL
    }
  }
}
    `;

/**
 * __useGaMarketingAdsListingQuery__
 *
 * To run a query within a React component, call `useGaMarketingAdsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGaMarketingAdsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGaMarketingAdsListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGaMarketingAdsListingQuery(baseOptions: Apollo.QueryHookOptions<GaMarketingAdsListingQuery, GaMarketingAdsListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GaMarketingAdsListingQuery, GaMarketingAdsListingQueryVariables>(GaMarketingAdsListingDocument, options);
      }
export function useGaMarketingAdsListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GaMarketingAdsListingQuery, GaMarketingAdsListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GaMarketingAdsListingQuery, GaMarketingAdsListingQueryVariables>(GaMarketingAdsListingDocument, options);
        }
export type GaMarketingAdsListingQueryHookResult = ReturnType<typeof useGaMarketingAdsListingQuery>;
export type GaMarketingAdsListingLazyQueryHookResult = ReturnType<typeof useGaMarketingAdsListingLazyQuery>;
export type GaMarketingAdsListingQueryResult = Apollo.QueryResult<GaMarketingAdsListingQuery, GaMarketingAdsListingQueryVariables>;